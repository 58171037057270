import { connect } from 'react-redux'
import Component from './SignupForm'
import { signUp, setField, setSubmitted, changeTab } from "../reducer/actions";

const mapDispatchToProps = {
  signUp,
  setField,
  setSubmitted,
  changeTab,
};

const mapStateToProps = (state) => {
  const s = state.signupPage;

  return {
    errors: s.errors,
    full_name: s.full_name,
    email: s.email,
    password: s.password,
    isSubmitted: s.isSubmitted,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
