import React from "react";
import { useDrop } from 'react-dnd'
import cn from 'classnames';
import Icon from "components/common/elements/Icon"
import { dndItemTypes } from "../../dndTypes";

import styles from './PlusNode.module.scss'

const PlusNode = ({ focus, node, onDnd }) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: dndItemTypes.ACTION,
    drop: (theNode) => onDnd(theNode.id, node.id),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if (node.before.length === 1 && node.before[0] === item.id) {
        return false
      }
      if (node.after.length === 1 && node.after[0] === item.id) {
        return false
      }
      if (item.union === node.id) {
        return false
      }

      return  true;
    }
  }), [node]);

  return (
    <div
      onClick={focus}
      className={cn(styles.symbol, {
        [styles.dropZone]: canDrop,
      })}
      style={{border: (canDrop && isOver) ? '1px solid green' : canDrop ? '1px dashed black' : undefined}}
      ref={drop}
    >
      <Icon icon="Cross" color="#425B76" size={9}/>
    </div>
  )
}

export default PlusNode;
