import React, { memo, useMemo } from 'react';
import Symbol from "../Symbol/Symbol";

import styles from './NodeForm.module.scss'

const ActionTab = ({ type, setAction, description }) => {
  const options = useMemo(
    () => Object.values(description[type]),
    [type]
  )
  if (!options.length) {
    setAction(null);
  }

  return (
    <ol className={styles.actionOptionsList + ' gs-ignore-scale-scroll'}>
      { options
        .filter(({isHidden}) => !isHidden)
        .map(({icon, name, id}) => (
          <li key={id} onClick={() => setAction(id)} className={styles.actionOption}>
            <Symbol type={type} icon={icon} className={styles.actionOptionIcon}/>
            <div> { name } </div>
          </li>
        ))
      }
    </ol>
  );
}

export default memo(ActionTab)
