import leadsPath from 'components/routes/Visitors/LeadLists/path';
import flowsPath from 'components/routes/flowsGroup/Flow/path'
// import pluginPath from 'components/routes/PluginGroup/path'
import messagesPath from 'components/routes/Messages/path'
import snippetsPath from 'components/routes/TemplatesGroup/path'
import tasksPath from 'components/routes/Tasks/path'
import dashboardPath from 'components/routes/dashboard/path'
import settingsPath from 'components/routes/SettingsGroup/path'

const menuItems = [
  {label: 'Overview', path: dashboardPath, icon: 'bar-chart', color: '#253342', showOnplugin: false},
  {label: 'Lists', path: leadsPath, icon: 'businessman-4', color: '#253342', showOnplugin: true},
  {label: 'Flows', path: flowsPath, icon: 'flows', color: '#253342', showOnplugin: false},
  {label: 'Templates', path: snippetsPath, icon: 'snippets', color: '#253342', showOnplugin: false},
  // {label: 'Plugin', path: pluginPath, icon: 'puzzle', color: '#253342', showOnplugin: false},
  {label: 'Messages', path: messagesPath, icon: 'messages', color: '#253342', badge: 'messagesBadge', showOnplugin: true},
  // {label: 'Tasks', path: tasksPath, icon: 'tasks', color: '#253342', badge: 'tasksBadge', badgeStyle: 'tasks', showOnplugin: true},
  {label: 'Settings', path: settingsPath, icon: 'gear', color: '#253342', showOnplugin: false},
];

export {
  menuItems
};
