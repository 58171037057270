import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";


const LoginPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName: 'ResetPassPage' */ './container')
      .catch(e => console.log('PAGE LOADING ERROR', e) || Error404)
  },
  loading: Loading,
})

export default LoginPage
