import React from "react";
import { getXpx, getYpx } from '../vars';

import NodeFrom from "./NodeForm/NodeForm";
import NodePreviewFacade from "./NodePreviewFacade/NodePreviewFacade";

import styles from './NodeVisualizer.module.scss'


const NodeVisualizer = ({ node, model, setFocusedNodeId, isFocused, onSubmit, onDelete, onDnd, description, errors }) => {
  return (
    <div
      className={styles.container}
      style={{
        // top: getYpx(node.y) + 'px',
        left: getXpx(node.x, model.minX) + 'px',
      }}
    >
      { isFocused && <NodeFrom node={node} model={model} setFocusedNodeId={setFocusedNodeId} onSubmit={onSubmit} onDelete={onDelete} description={description}/>}
      <NodePreviewFacade
        errors={errors}
        setFocusedNodeId={setFocusedNodeId}
        node={node}
        model={model}
        onDnd={onDnd}
        description={description}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default NodeVisualizer;
