import React, { PureComponent } from 'react'
import cn from "classnames";
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import isArray from 'lodash/isArray'
import find from 'lodash/find'
import Modal from 'react-modal';

import SelectRow from 'components/common/inputs/FormRows/SelectRow'
import { cutHtmlTags } from "services/utils";

import styles from './Styles.module.scss'

class SelectInput extends PureComponent {
  constructor({ field: { options } }) {
    super();

    this.state = {
      options: [],
      isLoading: true,
      error: false,
      selectValue: null,
      isCreationModalOpen: false,
      isEditing: false,
    }

    this.onInputChange = debounce(this.onInputChange.bind(this), 555);
    if (typeof options == 'function') {
      this.loadOptions = options;
    }
    if (isArray(options)) {
      this.state.options = options;
    }

    this.onChange = this.onChange.bind(this)
    this.onCreate = this.onCreate.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
  }

  componentDidMount() {
    this.onInputChange();
  }

  onInputChange(q = '') {
    const { field: { options }, nodeId, _payload } = this.props;

    if (typeof options !== 'function') return;


    this.setState({isLoading: true});
    this.loadOptions({q, nodeId, payload: _payload})
      .then(options => this.setState({
        options,
        isLoading: false,
      }))
      .catch( () => this.setState({
        error: true,
        isLoading: false,
      }))
  }

  getValue() {
    const { options, selectValue, isLoading } = this.state;
    const { value, field: { getValueLabel } } = this.props;

    if (value == null) return null;

    let theValue = find(options, {value});
    if (!theValue && selectValue && selectValue.value == value) {
      theValue = selectValue;
    }

    if (!isLoading && !theValue && getValueLabel) {
      getValueLabel(value)
        .then(selectValue => this.setState({selectValue}))
    }


    return theValue;
  }

  onChange(selected) {
    const { onChange, field } = this.props;

    if (selected.__isNew__) {
      if (field.CreateComponent) {
        this.setState({
          isCreationModalOpen: true,
          newVal: selected.value,
        });
      }
      if (field.createFn) {
        this.setState({isLoading: true});

        field.createFn(selected.value)
          .then(newValue => {
            onChange(newValue);
            this.setState({isLoading: false});
          })
          .catch(err => {
            this.setState({isLoading: false});
          })
      } else {
        console.log('Something wrong in node description', field);
      }
    } else {
      onChange(selected.value);
    }
  }
  onCreate(res) {
    this.setState({
      isCreationModalOpen: false,
      isEditing: false,
    });
    if (res) {
      this.props.onChange(res);
    }
  }
  onUpdate(value) {
    this.setState({
      isCreationModalOpen: false,
      isEditing: false,
    });

    if (value) {
      this.props.onChange(value);
      this.props.field.getValueLabel(value)
        .then(selectValue => {
          const { options } = this.state;

          const opt = find(options, {value: selectValue.value});
          const index = options.indexOf(opt);
          const newOptions =  [...options]
          newOptions[index] = selectValue;
          this.setState({
            options: newOptions,
          })
        })
    }
  }

  render() {
    const { field, readOnly } = this.props;
    const { options, isLoading, isEditing, isCreationModalOpen, error, newVal = '' } = this.state;

    const CreateComponent = field.CreateComponent;
    const value = this.getValue();

    return (
      <div className={styles.container}>
        <SelectRow label={field.label}
                   extra={CreateComponent && <div onClick={() => this.setState({isCreationModalOpen: true})}
                                                  className={cn(styles.dis, "gs-text")}> Create New </div>}
                   value={value}
                   onInputChange={this.onInputChange}
                   disabled={isLoading}
                   error={error}
                   isLoading={isLoading}
                   errorMessage={'Options error'}
                   placeHolder={isLoading ? 'Loading options...' : 'Select...'}
                   options={options}
                   onChange={this.onChange}
                   creatable={field.creatable}
                   isDisabled={readOnly}
        />
        { value && value.snippet && (
          <div className={styles.snippet}>
            <div onClick={() => this.setState({isCreationModalOpen: true, isEditing: true})}
                 className={cn(styles.update, styles.dis, "gs-text")}
            >
              Update
            </div>
            { cutHtmlTags(value.snippet) }
          </div>
        )}
        { CreateComponent && <Modal
          isOpen={isCreationModalOpen}
          onRequestClose={() => this.setState({isCreationModalOpen: false, isEditing: false})}
          className={cn("gs-modal", {_open: isCreationModalOpen})}
          overlayClassName={cn("gs-modal__overlay", {_open: isCreationModalOpen})}
        >
          <CreateComponent onFinish={isEditing ? this.onUpdate : this.onCreate}
                           id={isEditing && value && value.value}
                           isNew={isEditing ? false : true }
                           {...(field.createParam && field.createParam(newVal))}
          />
        </Modal> }
      </div>
    )
  }
}

SelectInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  field: PropTypes.shape({
    options: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.array,
    ]).isRequired,
    label: PropTypes.string.isRequired,
    getValueLabel: PropTypes.func,

    creatable: PropTypes.bool,
    createFn: PropTypes.func,
    CreateComponent: PropTypes.func,
    createParam: PropTypes.func,
  })
}

export default SelectInput
