import React from 'react'
import { Switch, Route } from 'react-router-dom'
import DetailsRoute from './Details'
import ListRoute from './List'
import NotFoundRoute from "../../NotFound";
import path from './path'
export { default as path } from "./path"

export default  () => (
  <Switch>
    <Route exact path={path} component={ListRoute} />
    <Route exact path={`${path}/:id`} component={DetailsRoute} />

    <Route component={NotFoundRoute} />
  </Switch>
)
