import * as actions from './consts'
import { entitySetter } from '../utils'
import { SET_ACCOUNT_STATUS } from "../../auth/consts";

const ACTION_HANDLERS = {
  [actions.SET_USERS]: (state, { users }) => {
    return entitySetter(state, users);
  },
  [SET_ACCOUNT_STATUS]: (state, {userId, status}) => {
    const s = {...state};
    s[userId] = {
      ...s[userId],
      status,
    };

    return s;
  },
  [actions.SET_USER_MAILBOX]: (state, { userId, mailbox }) => {
    const s = {...state};
    s[userId] = {
      ...s[userId],
      mailbox,
    };

    return s;
  },
  [actions.SET_USER_SERVER]: (state,{ userId, server }) => {
    const s = {...state};
    s[userId] = {
      ...s[userId],
      server,
    };

    return s;
  },
}

const initialState = {}

export default function usersReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
