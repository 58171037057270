import React from 'react'
import PropTypes from 'prop-types'

import TextRow from 'components/common/inputs/FormRows/TextRow'

import styles from "./Styles.module.scss"
import formStyles from "components/common/inputs/FormRows/Styles.module.scss"

const TextInput = ({ value, onChange, field, readOnly }) => (
  <label className={styles.container + ' ' + formStyles.gsFormRow}>
    <div className={formStyles.label}> { field.label } </div>
    <TextRow value={value}
             onChange={ e => onChange(+e.target.value.replace(/\D/g, '')) }
             style={{resize: 'none'}}
             placeholder={field.placeholder}
             readOnly={readOnly}
             rowClassName={styles.noMargin}
    />
  </label>
)

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TextInput
