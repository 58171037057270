import { useState, useEffect, useLayoutEffect } from 'react';
export const BODY_EVENT = 'gsPluginEvent'
export const BODY_RESPONSE_EVENT = 'gsPluginResponseEvent'

export const pluginEvents = {
  RESTART: 'RESTART',
  RECORDS: 'RECORDS',
  DYNAMIC_DATA: 'DYNAMIC_DATA',
  CURRENT_TAB_URL: 'CURRENT_TAB_URL',
  SET_ROUTE: 'SET_ROUTE',

  // proxy
  WRONG_PROFILE_PROXY: 'WRONG_PROFILE_PROXY',
  RIGHT_PROFILE_PROXY: 'RIGHT_PROFILE_PROXY',
  NEUTRAL_PROFILE_PROXY: 'NEUTRAL_PROFILE_PROXY',
}
// эти же константы в клиент плагине
export const pluginResponseEvents = {
  APPLY_QUERY_PARAMS: 'APPLY_QUERY_PARAMS',
  GET_URL: 'GET_URL',
  SET_URL: 'SET_URL',
  UI_LOGIN_SUCCESS: 'UI_LOGIN_SUCCESS',
  START_TASKS: 'START_TASKS',
  SET_PROXY: 'SET_PROXY',
  CLEAR_PROXY: 'CLEAR_PROXY',
  OPEN_PLUGIN_BY_UI: 'OPEN_PLUGIN_BY_UI',
}

export const sendMessageToPlugin = (type, payload) => {
  const event = new CustomEvent(BODY_RESPONSE_EVENT, {detail: {type, payload}});
  document.body.dispatchEvent(event);
}

const getDefaultRecords = () => {
  try {
    return JSON.parse(localStorage.getItem('_dynamicRecords')) || [];
  } catch (e) {
    return [];
  }
}

export const usePluginMeta = () => {
  const [meta, setMeta] = useState({});

  useEffect(() => {
    sendMessageToPlugin(pluginResponseEvents.GET_URL);

    function eventHandler(event) {
      switch (event.detail.type) {
        case pluginEvents.RECORDS: {
          setMeta(event.detail.meta);
          break;
        }
        case pluginEvents.CURRENT_TAB_URL: {
          setMeta({url: event.detail.url});
          break;

        }
      }
    }
    document.body.addEventListener(BODY_EVENT, eventHandler);

    return () => document.body.removeEventListener(BODY_EVENT, eventHandler);
  }, []);

  return meta
}
export const usePluginRecords = () => {
  const [records, setRecords] = useState(getDefaultRecords());
  const [context, setContext] = useState(localStorage.getItem('_dynamicContext') || '');

  useLayoutEffect(() => {
    function eventHandler(event) {
      switch (event.detail.type) {
        case pluginEvents.RECORDS: {
          setRecords(event.detail.records);
          break;
        }
        case pluginEvents.RESTART: {
          setRecords([]);
          break;
        }
        case pluginEvents.DYNAMIC_DATA: {
          const { data, context: theContext, route, uniqField } = event.detail;

          if (context != theContext) {
            setContext(theContext);
            setRecords(data);
          } else {

            const existed = {}
            for (const record of records) {
              existed[record[uniqField]] = true
            }
            setRecords([
              ...records,
              ...data.filter(item => !existed[item[uniqField]]),
            ]);
          }
          break;
        }
      }
    }
    document.body.addEventListener(BODY_EVENT, eventHandler);

    return () => document.body.removeEventListener(BODY_EVENT, eventHandler);
  }, [context, records]);
  useEffect(() => {
    setRecords(getDefaultRecords());
  }, []);

  return records;
}
