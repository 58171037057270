import React, { useState } from 'react'
import cn from 'classnames';
import {cutHtmlTags} from "services/utils";
import HighlightWithVarsAndTemplates from "components/common/DmOrEmail/HighlightWithVarsAndTemplates";
import Attachments from "components/common/inputs/Attachments";


import styles from "./Styles.module.scss"
import formStyles from "components/common/inputs/FormRows/Styles.module.scss"

const SnippetInput = ({ _payload, field, readOnly, _updatePayload }) => {
  const snippetLength = _payload.snippet ? _payload.snippet.length : 0;
  const [dirtySnippet, setDirtySnippet] = useState(() => cutHtmlTags(_payload.snippet || ''));

  return (
    <React.Fragment>
      <div className={cn(styles.container, formStyles.gsFormRow)}>
        <label className={styles.label}> { field.label } </label>
        <div style={{position: "relative"}}>
          <HighlightWithVarsAndTemplates
            value={dirtySnippet}
            onChange={newValue => {
              if (readOnly) return;
              setDirtySnippet(newValue);
              _updatePayload({
                snippet: cutHtmlTags(newValue),
              })
            }}
          />
        </div>
        { field.maxLength && (
          <div className={cn(
            styles.snippetLength, {
              [styles.snippetLengthError]: snippetLength > field.maxLength
            }
          )}>
            { snippetLength } symbols (max {field.maxLength})
          </div>
        ) }
      </div>
      {!field.withoutAttachments &&
        <Attachments value={_payload.attachments || []} onChange={(attachments) => _updatePayload({attachments})}/>
      }
    </React.Fragment>
  )
}

export default SnippetInput
