import pageStyles from "../styles/DetailsStyles.module.scss";

import { useCallback, useRef, useState } from "react";
import cloneDeep from 'lodash/cloneDeep';
import find from "lodash/find";
import differenceBy from "lodash/differenceBy";
import { getBeforeOfPlus, getNextServerNodeId } from "store/entities/flows/model/serverRelationships";
import modelRecalculate from "store/entities/flows/model/recalculate";
import modelAddBranch from "store/entities/flows/model/modifications/addBranch";
import modelRemoveBranch from "store/entities/flows/model/modifications/removeBranch";
import modelDeleteNode from "store/entities/flows/model/modifications/deleteNode";
import moveNode from "store/entities/flows/model/modifications/moveNode";
import restoreServerFlow from "store/entities/flows/model/restoreServerFlow";
import init from 'store/entities/flows/model/init';
import { flow as justEndFlow } from 'store/entities/flows/model/flowExamples/justEnd';
import modelAddNode from 'store/entities/flows/model/modifications/addNode';
import Scalable from "components/common/Scalable/Scalable";
import FlowVisualizer from "../flowsGroup/Flow/Details/FlowVisualizer/FlowVisualizer";
import {getDefaultBranchesCount, stepTypes} from "../flowsGroup/Flow/Details/reducer/utils/consts";
import { getFirstServerNodeId } from "../flowsGroup/Flow/Details/reducer/utils/description";
import unAuthDescription from "./description";
import { CopyBar } from './CopyBar';


const FlowConstructor = ({
    flow = justEndFlow,
    name = 'NY Tech CEO 10-50',
    description = unAuthDescription,
    withCopyBar = true,
    scalableContainer,
    controlClass,

  }) => {
  const [model, setModel] = useState(() => init(flow));
  const scaleControlRef = useRef();
  const nextServerNodeIdRef = useRef(Math.max(...model.flow.nodes.map(s => s.id)));

  const createEndForRule = (model, uiEnd, before) => {
    const step = {
      id: ++nextServerNodeIdRef.current,
      type: stepTypes.END,
      payload: {},
      before,
      after: [],
    };

    model.flow.nodes.push(step);
    uiEnd.serverNodeId = step.id;
  }

  const onAdd = useCallback((uiNode, { type, action, payload }) => {
    const newModel = cloneDeep(model);

    const afterCount = getDefaultBranchesCount(type, payload);
    const targetServerNodeId = getNextServerNodeId(newModel, uiNode.id);
    const after = [];
    for (let i = 1; i <= afterCount; i++) {
      if (payload.branches) {
        payload.branches[i-1].branch_id = i;
      }
      after.push({
        branch_id: i,
        node_id: targetServerNodeId,
      })
    }
    const step = {
      id: ++nextServerNodeIdRef.current,
      type,
      action,
      payload,
      before: getBeforeOfPlus(newModel, uiNode.id),
      after,
    };
    newModel.flow.nodes.push(step);
    const newUiNode = modelAddNode(newModel, uiNode.id, afterCount);
    newUiNode.serverNodeId = step.id;

    restoreServerFlow(newModel);
    setModel(newModel);
  }, [model]);

  const onUpdate = useCallback((uiNode, { type, action, payload }) => {
    const newModel = cloneDeep(model);
    const serverNode = find(newModel.flow.nodes, {id: uiNode.serverNodeId})
    if (payload.branches) {
      const toDelete = differenceBy(serverNode.after, payload.branches, 'branch_id');
      const toDeleteIndexes = toDelete.map(del => serverNode.after.indexOf(del));
      const toDeleteUiNodes = toDeleteIndexes.map(index => uiNode.after[index]);
      const toAdd = differenceBy(payload.branches, serverNode.after, 'branch_id');
      const toAddIndexes = toAdd.map(add => payload.branches.indexOf(add));

      for (const index of toAddIndexes) {
        modelAddBranch(newModel, uiNode.id/*, index*/) // index нужно подставлять, если создавать ветки после удаления веток
      }
      for (const uiId of toDeleteUiNodes) {
        modelRemoveBranch(newModel, uiId);
      }
      // взять все ui ноды с пустым after (все end ноды) и создать для всех у кого нет serverNodeId создать serverNode
      let i = 0;
      newModel.nodes
        .filter(({after, serverNodeId}) => !after.length && !serverNodeId)
        .forEach(newUiEnd => {
          createEndForRule(
            newModel,
            newUiEnd,
            {
              branch_id: toAdd[i++].branch_id,
              node_id: serverNode.id,
            },
          )
        })

      const afterServerNodeIds = uiNode.after.map(uiId => {
        try {
          return getFirstServerNodeId(newModel, uiId)
        } catch (e) {}
      }).filter(Boolean);

      serverNode.after = afterServerNodeIds.map((serverNodeId, i) => ({
        branch_id: payload.branches[i].branch_id,
        node_id: serverNodeId,
      }))
    };

    serverNode.type = type;
    serverNode.action = action;
    serverNode.payload = payload;

    restoreServerFlow(newModel);
    modelRecalculate(newModel);
    setModel(newModel);
  }, [model]);

  const onDelete = useCallback((uiNode) => {
    const newModel = cloneDeep(model);
    modelDeleteNode(newModel, uiNode.id);
    restoreServerFlow(newModel);
    setModel(newModel);
  }, [model]);

  const onDnd = useCallback((nodeId, plusId) => {
    const newModel = cloneDeep(model);
    moveNode(newModel, nodeId, plusId);
    restoreServerFlow(newModel);
    setModel(newModel);
  }, [model]);

  if (!model) {
    console.log('no model');
    return null;
  }

  return (
    <div className={pageStyles.Page} style={{background: 'white'}}>
      { withCopyBar && <CopyBar name={name} nodes={model.flow.nodes} /> }
      <div
        id="flow-wrapper"
        className={pageStyles.Flow}
        style={{width: '100%'}}
      >
        <Scalable
          controlClass={controlClass}
          scalableContainer={scalableContainer}
          controlWrapper={scaleControlRef.current}
          auxClassName={pageStyles.FlowScalable}
        >
          <FlowVisualizer
            flowModel={model}
            description={description}
            onAdd={onAdd}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onDnd={onDnd}
          />
        </Scalable>
        <div
          className={pageStyles.ScaleControl}
          id="scale-control"
          ref={scaleControlRef}
        />
      </div>
    </div>
  )
}

export default FlowConstructor;
