import { createSelector } from "reselect";

const sliceSelector = (state) => state.entities.tasks;
const tasksIds = (_state, {tasksIds}) => tasksIds;
const leadIdSelector = (_state, {leadId}) => leadId;

const tasksByIds = createSelector(
  [sliceSelector, tasksIds],
  (tasks, tasksIdsRes) => tasksIdsRes.map(id => tasks[id])
);

const tasksArray = createSelector(
  [sliceSelector],
  (tasks) => Object.values(tasks)
);

const tasksCount = createSelector(
  [tasksArray],
  (tasks) => tasks.length
);

const tasksByLeadIdArray = createSelector(
  [tasksArray, leadIdSelector],
  (tasks, leadId) => tasks.filter((task) => task.lead_id === leadId)
);

export {
  sliceSelector,
  tasksIds,
  tasksByIds,
  tasksArray,
  tasksCount,
  tasksByLeadIdArray,
}
