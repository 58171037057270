import React, { useState, memo } from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import NodeVisualizer from "./NodeVisualizer/NodeVisualizer";
import BranchVisualizer from "./BranchVisualizer/BranchVisualizer";
import styleVars from './vars'
import useLayers from "./useLayers";

import styles from './FlowVisualizer.module.scss'

const FlowVisualizer = ({ flowModel, onUpdate, onAdd, onDelete, onDnd, description, invalidNodes, onNodeFocus }) => {
  const [focusedNodeId, setFocusedNodeId] = useState(null);
  const setTheFocusNode = (nodeId) => {
    onNodeFocus?.(nodeId);
    setFocusedNodeId(nodeId);
  }
  const layersY = useLayers();

  const handleSubmit = (uiNode, { type, action, payload }) => {
    const toDo = uiNode.serverNodeId ? onUpdate : onAdd;

    return Promise.resolve(toDo(uiNode, { type, action, payload }))
      .then(() => setFocusedNodeId(null))
  }
  const handleDelete = (uiNode) => {
    // deleteNode(flowModel, uiNode.id);
    onDelete(uiNode);
    setFocusedNodeId(null);
  }

  // console.log('flowModel', flowModel)
  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={styles.container}
        style={{
          // height: 2 * styleVars.topOffset + (flowModel.maxY + 1) * styleVars.ySize + 'px',
          width: 2 * styleVars.leftOffset + (flowModel.maxX - flowModel.minX + 1) * styleVars.xSize + 'px',
        }}
      >
        {!!layersY.length && (
          <svg className={styles.sbgCanvas}>
            {flowModel.nodes.map(node => <React.Fragment key={'branch-' + node.id}>
              {node.after.map((afterId, index) =>
                <BranchVisualizer
                  key={node.id + '-' + afterId}
                  fromNodeId={node.id}
                  toNodeId={afterId}
                  model={flowModel}
                  index={index}
                  layersY={layersY}
                />
              )}
            </React.Fragment>)}
          </svg>
        )}

        { (() => {
          const layers = [];
          for (let i = flowModel.nodes.length - 1; i >= 0; i--) {
            const node = flowModel.nodes[i];
            if (!layers[node.y]) layers[node.y] = [];
            layers[node.y].push(node);
          }

          return layers
        })().map((layer, index) => (
          <div key={index} className={styles.layer} data-flow-layer={index}>
            { layer.map(node => (
              <NodeVisualizer
                key={node.id}
                node={node}
                model={flowModel}
                description={description}
                setFocusedNodeId={setTheFocusNode}
                isFocused={focusedNodeId === node.id}
                onSubmit={data => handleSubmit(node, data)}
                onDelete={() => handleDelete(node)}
                onDnd={onDnd}
                errors={invalidNodes?.[node.id]}
              />
            ))}
          </div>
        )) }
      </div>
    </DndProvider>
  )
}

export default memo(FlowVisualizer)
