import reduce from 'lodash/reduce'
import { getLocations, getCountriesOptions, companySizeOptions, getIndustryOptions, getDataSourceOptions, getDataSourceLabel, nodeStatusOptions, activityTypeOptions } from 'services/selectors'
import { getTagsOptions } from 'store/entities/tags/actions'
import { getNodeOptions } from "components/routes/flowsGroup/Flow/Details/reducer/utils/description";
import { referrerTypes } from "./consts";

export const fieldTypes = {
  DATE: 'DATE',
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  SELECT: 'SELECT',
  COMBO: 'COMBO',
}

export const operatorInputs = {
  SINGLE: 'SINGLE',
  DOUBLE: 'DOUBLE',
  NONE: 'NONE',
}

const commonOperators = {
  HAS: {
    key: 'has',
    label: 'Has',
    input: operatorInputs.SINGLE,
  },
  HAS_NOT: {
    key: 'has not',
    label: 'Has not',
    input: operatorInputs.SINGLE,
  },
  // DEFINED: {
  //   key: 'exists',
  //   label: 'Is defined',
  //   input: operatorInputs.NONE,
  // },
  // UNDEFINED: {
  //   key: 'missing',
  //   label: 'Is undefined',
  //   input: operatorInputs.NONE,
  // },
}
const dateIntCommonOperators = {
  HAS: {
    key: 'has',
    label: 'Is',
    input: operatorInputs.SINGLE,
  },
  HAS_NOT: {
    key: 'has not',
    label: 'Is not',
    input: operatorInputs.SINGLE,
  },
  GTE: {
    key: 'gte',
    label: "Greater or equal to",
    input: operatorInputs.SINGLE,
  },
  LTE: {
    key: 'lte',
    label: "Lower or equal to",
    input: operatorInputs.SINGLE,
  },
  BETWEEN: {
    key: 'between',
    label: 'Is between',
    input: operatorInputs.DOUBLE,
  },
};

export const typeOperators = {
  [fieldTypes.DATE]: {...commonOperators, ...dateIntCommonOperators},
  [fieldTypes.NUMBER]: {...commonOperators, ...dateIntCommonOperators},
  [fieldTypes.SELECT]: {...commonOperators},
  [fieldTypes.STRING]: {...commonOperators},
}
export const fieldGroups = {
  PERS: 'PERS',
  COMP: 'COMP',
  LEAD_EVENT: 'LEAD_EVENT',
}

export const groupsLabels = {
  [fieldGroups.PERS]: {
    value: fieldGroups.PERS,
    label: 'Lead',
    className: 'leadCont',
  },
  [fieldGroups.COMP]: {
    value: fieldGroups.COMP,
    label: 'Company',
    className: 'compCont',
  },
}

const subGroups = {
  BASIC: 'Basic',
  GEO: 'Geo',
  NETWORK: 'Network',
  SESSIONS: 'Sessions',
  SOCIAL: 'Social',
  STRUCTURAL: 'Structural',
}

const theVisitorFields = {
  // KEYWORD: {
  //   label: 'Keyword',
  //   name: 'keyword',
  //   sortable: false,
  //   filterable: true,
  //   type: fieldTypes.STRING,
  //
  //   group: fieldGroups.PERS,
  //   subGroup: subGroups.BASIC,
  // },
  ACTIVITY_WITH_STATUS: {
    label: 'Activity',
    name: 'activity',
    sortable: false,
    filterable: true,
    type: fieldTypes.COMBO,
    fields: [
      {
        label: 'Activity',
        name: 'type',
        type: fieldTypes.SELECT,
        placeholder: 'Activity',
        getOptions: () => Promise.resolve(activityTypeOptions),
        getLabel: value => value && activityTypeOptions.find(opt => opt.value == value).label,
      },
    ],
    customOperators: {
      HAS: commonOperators.HAS,
      HAS_NOT: commonOperators.HAS_NOT,
    },

    group: fieldGroups.PERS,
    subGroup: subGroups.BASIC,
  },
  NAME: {
    label: 'Name',
    name: 'name',
    sortable: false,
    filterable: true,
    type: fieldTypes.STRING,

    group: fieldGroups.PERS,
    subGroup: subGroups.BASIC,
  },
  ADDRESS: {
    label: 'Location',
    name: 'location.address',
    sortable: false,
    filterable: true,
    type: fieldTypes.STRING,

    group: fieldGroups.PERS,
    subGroup: subGroups.BASIC,
  },
  POSITION: {
    label: 'Position',
    name: 'position',
    sortable: false,
    filterable: true,
    type: fieldTypes.STRING,

    group: fieldGroups.PERS,
    subGroup: subGroups.BASIC,
  },
  EMAIL_PROBABILITY: {
    label: 'Email Probability',
    name: 'email.probability',
    sortable: false,
    filterable: true,
    type: fieldTypes.NUMBER,
    customOperators: {GTE: dateIntCommonOperators.GTE},

    group: fieldGroups.PERS,
    subGroup: subGroups.BASIC,
  },
  DATA_SOURCE: {
    label: 'Data Source',
    name: 'lists',
    sortable: false,
    filterable: true,
    type: fieldTypes.SELECT,
    getOptions: getDataSourceOptions,
    getLabel: getDataSourceLabel,

    group: fieldGroups.PERS,
    subGroup: subGroups.BASIC,
  },
  // EMAIL: {
  //   label: 'Email',
  //   name: 'email',
  //   sortable: false,
  //   filterable: true,
  //   type: fieldTypes.STRING,
  //
  //   group: fieldGroups.PERS,
  // },
  INDUSTRY: {
    label: 'Industry',
    name: 'company.industry',
    sortable: false,
    filterable: true,

    type: fieldTypes.SELECT,
    creatable: false,
    getOptions: getIndustryOptions,
    getLabel: (value) => Promise.resolve(value),

    group: fieldGroups.COMP,
    subGroup: subGroups.BASIC,
  },
  COMPANY_SIZE: {
    label: 'Company Size',
    name: 'company.employees_range',
    sortable: false,
    filterable: true,

    type: fieldTypes.SELECT,
    creatable: false,
    getOptions: () => Promise.resolve(companySizeOptions),
    getLabel: (value) => Promise.resolve(value),

    group: fieldGroups.COMP,
    subGroup: subGroups.STRUCTURAL,
  },
  // COMPANY_DOMAIN: {
  //   label: 'Domain',
  //   name: 'company.domain',
  //   sortable: false,
  //   filterable: true,
  //
  //   type: fieldTypes.STRING,
  //
  //   group: fieldGroups.COMP,
  //   subGroup: subGroups.BASIC,
  // },
  // NETWORK_TYPE: {
  //   label: 'Network type',
  //   name: 'company.network_type',
  //   sortable: false,
  //   filterable: true,
  //
  //   type: fieldTypes.SELECT,
  //   creatable: false,
  //   getOptions: () => [
  //     {
  //       value: 'business',
  //       label: 'Business',
  //     }, {
  //       value: 'education',
  //       label: 'Education',
  //     }, {
  //       value: 'isp',
  //       label: 'ISP',
  //     }, {
  //       value: 'hosting',
  //       label: 'Hosting',
  //     }
  //   ],
  //
  //   group: fieldGroups.COMP,
  //   subGroup: subGroups.NETWORK,
  // },
  // DURATION: {
  //   label: 'Visit duration',
  //   name: 'duration',
  //   sortable: false,
  //   filterable: true,
  //   type: fieldTypes.NUMBER,
  //
  //   group: fieldGroups.PERS,
  //   subGroup: subGroups.SESSIONS,
  // },
  // PAGE_COUNT: {
  //   label: 'Visited Pages Count',
  //   name: 'visited_urls_count',
  //   sortable: false,
  //   filterable: true,
  //   type: fieldTypes.NUMBER,
  //
  //   group: fieldGroups.PERS,
  //   subGroup: subGroups.SESSIONS,
  // },
  CREATED: {
    label: 'Creation Date',
    name: 'created_at',
    sortable: true,
    filterable: false,
    type: fieldTypes.DATE,

    group: fieldGroups.PERS,
    subGroup: subGroups.BASIC,
  },
  // UPDATED: {
  //   label: 'Last Update Date',
  //   name: 'updated_at',
  //   sortable: false,
  //   filterable: true,
  //   type: fieldTypes.DATE,
  //
  //   group: fieldGroups.PERS,
  //   subGroup: subGroups.BASIC,
  // },
  ENGAGEMENT: {
    label: 'Engagement',
    name: 'engagement_index',
    sortable: true,
    filterable: false,
    type: fieldTypes.NUMBER,
    min: 0,
    max: 100,

    group: fieldGroups.PERS,
    subGroup: subGroups.BASIC,
  },
  // LAST_VISIT: {
  //   label: 'Last Visits',
  //   name: 'last_visited_at',
  //   sortable: true,
  //   filterable: true,
  //   type: fieldTypes.DATE,
  //
  //   group: fieldGroups.PERS,
  //   subGroup: subGroups.SESSIONS,
  // },
  // CITY: {
  //   label: 'City',
  //   name: 'ip_location.city',
  //   sortable: false,
  //   filterable: true,
  //
  //   type: fieldTypes.SELECT,
  //   creatable: true,
  //   getOptions: (q) => {
  //     return getLocations({q, type: 'city'})
  //       .then( arr => Array.from(new Set(arr.map(v => v.name)))
  //         .map(value => ({
  //           value,
  //           label: value,
  //         })))
  //   },
  //
  //   group: fieldGroups.PERS,
  //   subGroup: subGroups.GEO,
  // },
  // COMP_CITY: {
  //   label: 'Company City',
  //   name: 'company.locations.city',
  //   sortable: false,
  //   filterable: true,
  //
  //   type: fieldTypes.SELECT,
  //   creatable: true,
  //   getOptions: (q) => {
  //     return getLocations({q, type: 'city'})
  //       .then( arr => Array.from(new Set(arr.map(v => v.name)))
  //         .map(value => ({
  //           value,
  //           label: value,
  //         })))
  //   },
  //
  //   group: fieldGroups.COMP,
  //   subGroup: subGroups.GEO,
  // },
  // COUNTRY: {
  //   label: 'Country',
  //   name: 'ip_location.country',
  //   sortable: false,
  //   filterable: true,
  //
  //   type: fieldTypes.SELECT,
  //   getOptions: (q) => {
  //     return getLocations({q, type: 'country'})
  //       .then( arr => Array.from(new Set(arr.map(v => v.name)))
  //         .map(value => ({
  //           value,
  //           label: value,
  //         })))
  //   },
  //
  //   group: fieldGroups.PERS,
  //   subGroup: subGroups.GEO,
  // },
  // COMP_COUNTRY: {
  //   label: 'Company Country',
  //   name: 'company.locations.country',
  //   sortable: false,
  //   filterable: true,
  //
  //   type: fieldTypes.SELECT,
  //   getOptions: (q) => {
  //     return getLocations({q, type: 'country'})
  //       .then( arr => Array.from(new Set(arr.map(v => v.name)))
  //         .map(value => ({
  //           value,
  //           label: value,
  //         })))
  //   },
  //
  //   group: fieldGroups.COMP,
  //   subGroup: subGroups.GEO,
  // },
  COMPANY_NAME: {
    label: 'Company Name',
    name: 'company.name',
    sortable: false,
    filterable: true,
    type: fieldTypes.STRING,

    group: fieldGroups.COMP,
    subGroup: subGroups.BASIC,
  },
  TAGS: {
    label: 'Tags',
    name: 'tags',
    sortable: false,
    filterable: true,
    type: fieldTypes.SELECT,
    getOptions: (q) => getTagsOptions(q).then( arr => arr.map(({label}) => ({value: label, label})) ),
    getLabel: (value) => Promise.resolve(value),

    group: fieldGroups.PERS,
    subGroup: subGroups.BASIC,
  },
  // REF_TYPE: {
  //   label: 'Referrer Type',
  //   name: 'website_sessions.referrer.type',
  //   sortable: false,
  //   filterable: true,
  //   type: fieldTypes.SELECT,
  //   getOptions: () => referrerTypes,
  //
  //   group: fieldGroups.PERS,
  //   subGroup: subGroups.SESSIONS,
  // },
  // REF_SOURCE: {
  //   label: 'Referrer Source',
  //   name: 'website_sessions.referrer.source',
  //   sortable: false,
  //   filterable: true,
  //   type: fieldTypes.STRING,
  //
  //   group: fieldGroups.PERS,
  //   subGroup: subGroups.SESSIONS,
  // },
  LINKEDIN: {
    label: 'Linkedin ',
    name: 'social_profiles.linkedin',
    sortable: false,
    filterable: true,
    type: fieldTypes.STRING,

    group: fieldGroups.PERS,
    subGroup: subGroups.SOCIAL,
  },
  // TWITTER: {
  //   label: 'Twitter ',
  //   name: 'social_profiles.twitter',
  //   sortable: false,
  //   filterable: true,
  //   type: fieldTypes.STRING,
  //
  //   group: fieldGroups.PERS,
  //   subGroup: subGroups.SOCIAL,
  // },
  // VISITED_URL: {
  //   label: 'Visited URL',
  //   name: 'website_sessions.visited_urls.url',
  //   sortable: false,
  //   filterable: true,
  //   type: fieldTypes.STRING,
  //
  //   group: fieldGroups.PERS,
  //   subGroup: subGroups.SESSIONS,
  // },
  // VISITED_TIME: {
  //   label: 'Visit Durations (seconds)',
  //   name: 'website_sessions.visited_urls.duration',
  //   sortable: false,
  //   filterable: true,
  //   type: fieldTypes.NUMBER,
  //   customOperators: {
  //     GTE: dateIntCommonOperators.GTE,
  //     LTE: dateIntCommonOperators.LTE,
  //     BETWEEN: dateIntCommonOperators.BETWEEN,
  //   },
  //
  //   group: fieldGroups.PERS,
  //   subGroup: subGroups.SESSIONS,
  // },
  NODE_WITH_STATUS: {
    label: 'Flow Node',
    name: 'flow_node_id',
    sortable: false,
    filterable: true,
    type: fieldTypes.COMBO,
    fields: [
      {
        label: 'Node Id',
        name: 'id',
        type: fieldTypes.SELECT,
        placeholder: 'Node Id',
        getOptions: getNodeOptions,
        getLabel: id => id ? "#" + id : null,
      },
      {
        label: 'Status',
        name: 'status',
        type: fieldTypes.SELECT,
        placeholder: 'Status',
        getOptions: () => Promise.resolve(nodeStatusOptions),
        getLabel: value => value && nodeStatusOptions.find(opt => opt.value == value).label,
      },
    ],
    customOperators: {
      HAS: dateIntCommonOperators.HAS,
      HAS_NOT: dateIntCommonOperators.HAS_NOT,
    },

    group: fieldGroups.PERS,
    subGroup: subGroups.BASIC,
  },
}

for (const key in theVisitorFields) {
  theVisitorFields[key].key = key;
}

export const visitorFields = theVisitorFields;

export const fieldsNameKeyMap = reduce(visitorFields, (acc, field) => {
  acc[field.name] = field.key;

  return acc;
}, {})
