import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import cn from 'classnames';
import throttle from 'lodash/throttle'
import createPopover from "components/hocs/createPopover";
import Spinner from "components/common/elements/Spinner";
import Icon from "components/common/elements/Icon";
import getAxios from "services/axios";

import styles from './AttachmentsPopover.module.scss'

const axios = getAxios('flow');

const LIMIT = 20;

const AttachmentsPopoverContent = ({ upload, select, onRequestClose}) => {
  const itemsRef = useRef();
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = () => {
    if (isLoading) return;
    if (!hasMore) return;
    setIsLoading(true);
    axios.get('/api/template-attachments', {params: {offset:  attachments.length, limit: LIMIT}})
      .then(res => {
        setHasMore(res.has_more);
        setAttachments(p => [...p, ...res.data]);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const onChangeHandler = event => {
    const file = event.target.files[0];
    upload(file);
    onRequestClose();
  }

  const onAttachSelect = (attach) => {
    select(attach);
    onRequestClose();
  }

  useEffect(() => {
    const onScroll = throttle((e) => {
      const elem = e.target;
      if (elem.scrollHeight - elem.offsetHeight - elem.scrollTop < 20) {
        loadMore();
      }
    }, 222)

    const elem = itemsRef.current;
    elem.addEventListener('scroll', onScroll);
    return () => elem.removeEventListener('scroll', onScroll);
  }, [isLoading, attachments, hasMore])

  useLayoutEffect(() => {
    loadMore();
  }, []);

  return (
    <div className={styles.container}>
      <div ref={itemsRef} className={styles.items}>
        {isLoading && <Spinner />}
        { attachments.map(attach => (
          <div key={attach.id} onClick={() => onAttachSelect(attach)} className={styles.item}>
            <Icon icon="attach" size={23} color="#425B76" />
            <div className={styles.itemPayload}>
              <div className={styles.itemName}>{ attach.payload.name }</div>
              <div className={styles.itemType}>{ attach.payload.type }</div>
            </div>
          </div>
        )) }
      </div>
      <label className={cn('gs-text _link _bold', styles.footer)}>
        Upload New File
        <input type="file" value="" onChange={onChangeHandler} className={styles.input}/>
      </label>
    </div>
  )
}

export const AttachmentsPopover = createPopover(AttachmentsPopoverContent);
