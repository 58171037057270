// ------------------------------------
// CONSTANTS
// ------------------------------------
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const HIDE_SEARCH = 'HIDE_SEARCH';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const HIDE_MENU = 'HIDE_MENU';
export const LOGIN_STEP_SET = 'LOGIN_STEP_SET';
export const LOGIN_STEP_UP = 'LOGIN_STEP_UP';
export const LOGIN_STEP_DOWN = 'LOGIN_STEP_DOWN';
export const GLOBAL_SET_MIDDLE_MENU_VISIBILITY = 'GLOBAL_SET_MIDDLE_MENU_VISIBILITY';
export const GLOBAL_CHANGE_MESSAGES_BADGE = 'GLOBAL_CHANGE_MESSAGES_BADGE';

// ------------------------------------
// Selectors
// ------------------------------------
export const getMessagesBadge = (state) => state.global.messagesBadge;
// ------------------------------------
// Actions
// ------------------------------------
export function toggleSearch (isCollapsed) {
  return {
    type    : TOGGLE_SEARCH,
    payload : isCollapsed
  }
}

export function hideSearch (isCollapsed) {
  return {
    type    : HIDE_SEARCH,
    payload : isCollapsed
  }
}

export function loginStepSet (index) {
  return {
    type    : LOGIN_STEP_SET,
    index
  }
}

export function loginStepUp () {
  return {
    type    : LOGIN_STEP_UP
  }
}

export function loginStepDown () {
  return {
    type    : LOGIN_STEP_DOWN
  }
}

export const setMiddleMenuVisibility = (middleMenuVisibility) => ({
  type: GLOBAL_SET_MIDDLE_MENU_VISIBILITY,
  middleMenuVisibility,
})

export const changeMessagesCount = (diffFn) => ({
  type: GLOBAL_CHANGE_MESSAGES_BADGE,
  diffFn,
})

// ------------------------------------
// ACTION HANDLERS
// ------------------------------------
const ACTION_HANDLERS = {
  [TOGGLE_SEARCH]    : (state, action) => {
    return {
      ...state,
      searchOpened:!state.searchOpened
    };
  },
  [HIDE_SEARCH]    : (state, action) => {
    return {
      ...state,
      searchOpened:false
    };
  },
  [TOGGLE_MENU]    : (state, {isOpen}) => {
    const navigationOpened = isOpen == null ? !state.navigationOpened : isOpen;
    return {
      ...state,
      navigationOpened,
    };
  },
  [HIDE_MENU]    : (state, action) => {
    return {
      ...state,
      navigationOpened:false
    };
  },
  [LOGIN_STEP_SET]    : (state, action) => {
    return {
      ...state,
      loginStep:action.reducer
    };
  },
  [LOGIN_STEP_UP]    : (state, action) => {
    if (state.loginStep == 4){
      return {
        ...state
      }
    }
    return {
      ...state,
      loginStep:state.loginStep+1
    };
  },
  [LOGIN_STEP_DOWN]    : (state, action) => {
    if (state.loginStep == 1){
      return {
        ...state
      }
    }
    return {
      ...state,
      loginStep:state.loginStep-1
    };
  },
  [GLOBAL_SET_MIDDLE_MENU_VISIBILITY]: (state, { middleMenuVisibility }) => ({
    ...state,
    middleMenuVisibility,
  }),
  [GLOBAL_CHANGE_MESSAGES_BADGE]: (state, { diffFn }) => ({
    ...state,
    messagesBadge: Math.max(0, diffFn(state.messagesBadge)),
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  navigationOpened: false,
  searchOpened: false,
  loginStep: 1,
  rowHeight: 43,
  middleMenuVisibility: true,
  messagesBadge: null,
  tasksBadge: null,
};

export default function globalReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
