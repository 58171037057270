import React from 'react';
import Whoops from './Whoops'

import styles from './Styles.module.scss'

const WhoopsPage = () => (
  <div className={styles.WhoopsPage}>
    <Whoops />
    <h2 className={styles.Message}>You found error page!!!!!</h2>
    <a className={styles.WhoopsPageAction} role="button" onClick={() => window.location.reload()}> Reload page </a>
    <a className={styles.WhoopsPageAction} role="button" href="/"> Go to Home Page </a>
  </div>
);

export default WhoopsPage;
