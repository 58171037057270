import { combineReducers } from 'redux'
import auth from './auth'
import proxy from './proxy'
import entities from './entities'
import globalReducer from './global'
import history from "services/browserHistory";
// import { connectRouter } from 'connected-react-router'
import { LOGOUT_SUCCESS } from "./auth/consts";

export const makeRootReducer = (asyncReducers, history) => {
  const appReducer = combineReducers({
    // router: connectRouter(history),
    global: globalReducer,
    auth,
    proxy,
    entities,
    ...asyncReducers
  })

  return (state, action) => {
    if (action.type === LOGOUT_SUCCESS) {
      state = undefined;
    }

    return appReducer(state, action);
  }
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers, history))
}

export default makeRootReducer
