import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { primaryColor } from 'styles/_variables.module.scss'

class Icon extends PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.icon == prevState.icon) return null;

    return {
      ...prevState,
      LoadedIcon: null,
      icon: nextProps.icon,
    };
  }

  constructor({ }) {
    super();
    this.state = {
      LoadedIcon: null,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.LoadedIcon === null || prevProps.icon != this.props.icon) {
      this.loadIcon()
    }
  }
  componentDidMount() {
    this.loadIcon();
  }

  loadIcon() {
    import(
      /* webpackChunkName: "SvgIcons" */
      /* webpackPreload: true */
      `components/common/elements/Icon/chunkMaker`
    )
      .then( getIcon => getIcon.default(this.props.icon))
      .then( res => this.setState({LoadedIcon: res.default}))
  }

	render() {
    // return 'Icon'
		const {
      size = 25,
      color = primaryColor,
      ...other
    } = this.props
    const { LoadedIcon } = this.state;

    if (!LoadedIcon) return null;

    return (
      <LoadedIcon
        width={size}
        height={size}
        fill={color}
        color={color}
        {...other}
      />
    )
	}
};

Icon.propTypes = {
	icon: PropTypes.string.isRequired,
	size: PropTypes.number,
	color: PropTypes.string,
};

export {
  Icon,
  Icon as default
};
