import React, { useEffect, useState, memo } from 'react';
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import getAxios from "services/axios";
import browserHistory from "services/browserHistory";
import { getTeam } from "store/entities/users/actions";
import { loginAs } from "store/auth/actions";
import { logout } from "store/auth/actions";
import Icon from "components/common/elements/Icon";
import leadsPath from "components/routes/Visitors/pathname";
import flowsPath from "components/routes/flowsGroup/path";
import leadsListPath from "components/routes/Visitors/LeadLists/path";
import createModal from "components/hocs/ModalTrigger";
import NewInvite from "components/routes/SettingsGroup/Team/NewInvite";

import styles from './TeamPopoverContent.module.scss'


const NewUserModal = createModal(NewInvite)

const VISIBLE_STATUSES = ['active', 'trialing', 'past_due'];

const TeamPopoverContent = ({ setIsPersistent }) => {
  const dispatch = useDispatch();
  const isLeadsPage = useRouteMatch(leadsPath);
  const isFlowsPage = useRouteMatch(flowsPath);
  const [team, setTeam] = useState([]);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    dispatch(getTeam())
      .then(team => team.filter(user => user.subscription && VISIBLE_STATUSES.includes(user.subscription.paddle_status)))
      .then(setTeam);
    getAxios('flow')
      .get('/api/social-profiles')
      .then(setProfile)
  }, [])

  const getMessagesCounter = (userId) => {
    try {
      return profile.aggs.users[userId].unread_chats_count;
    } catch (e) {}
  }

  const onLogout = () => {
    dispatch(logout());
    browserHistory.push('/login');
  }

  const onLoginAs = (userId) => {
    dispatch(loginAs(userId));
    if (isLeadsPage) {
      browserHistory.push(leadsListPath);
    }
    if (isFlowsPage) {
      browserHistory.push(flowsPath);
    }
  }

  return (
    <div>
      <div className={styles.menu}>
        <NewUserModal
          onAdd={() => dispatch(getTeam()).then(setTeam)}
          onOpen={() => setIsPersistent(true)}
          onClose={() => setIsPersistent(false)}
        >
          <div className="gs-text _link"> + Add Account </div>
        </NewUserModal>
        <div onClick={onLogout} className="gs-text _link"> Log Out </div>
      </div>
      <ul className={styles.team}>
        { team.map(user => (
          <li key={user.id} className={styles.user} onClick={() => onLoginAs(user.id)}>
            <div className={styles.ava} style={{backgroundColor: user.color || '#6A78D1'}}>
              { user.first_name[0] }{ user.last_name[0] }
            </div>
            <div className={styles.name}>
              { user.first_name } { user.last_name }
            </div>
            <div className={styles.stats}>
              <div className={styles.statItem}>
                <Icon icon="tasks" size={21}/>
              </div>
              <div className={styles.statItem}>
                { !!getMessagesCounter(user.id) && <div className={styles.badge}> { getMessagesCounter(user.id) } </div> }
                <Icon icon="messages" size={25}/>
              </div>
              <div className={styles.statItem}>
                <Icon icon="email" size={21}/>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default memo(TeamPopoverContent);
