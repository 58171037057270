export const VISIT_LP_LOAD_REQUEST = 'VISIT_LP_LOAD_REQUEST'
export const VISIT_LP_LOAD_SUCCESS = 'VISIT_LP_LOAD_SUCCESS'
export const VISIT_LP_LOAD_FAILED = 'VISIT_LP_LOAD_FAILED'
export const VISIT_LP_LEAVE = 'VISIT_LP_LEAVE'
export const VISIT_LP_DROP_SEARCH = 'VISIT_LP_DROP_SEARCH'

export const VISIT_LP_SET_SORTING = 'VISIT_LP_SET_SORTING'
export const VISIT_LP_SELECTION_TOGGLE_PLURAL = 'VISIT_LP_SELECTION_TOGGLE_PLURAL'
export const VISIT_LP_SET_FILTERS = 'VISIT_LP_SET_FILTERS'
export const VISIT_LP_SET_TRASHED = 'VISIT_LP_SET_TRASHED'
export const VISIT_LP_SET_STAGE = 'VISIT_LP_SET_STAGE'
export const VISIT_LP_SET_LIST = 'VISIT_LP_SET_LIST'
export const VISIT_LP_SET_ASSIGN = 'VISIT_LP_SET_ASSIGN'
export const VISIT_LP_CLEAR_TOP_FILTERS = 'VISIT_LP_CLEAR_TOP_FILTERS'
export const VISIT_LP_ADDITIONAL_FILTERS = 'VISIT_LP_ADDITIONAL_FILTERS'
export const VISIT_LP_UPDATE_AGGS = 'VISIT_LP_UPDATE_AGGS'

export const VISIT_LP_SELECT_SEGMENT = 'VISIT_LP_SELECT_SEGMENT'
export const VISIT_LP_SET_SEGMENT_MODE = 'VISIT_LP_SET_SEGMENT_MODE'
export const VISIT_LP_DISCARD_FILTERS = 'VISIT_LP_DISCARD_FILTERS'
export const VISIT_LP_APPLY_URL_FILTERS = 'VISIT_LP_APPLY_URL_FILTERS'
export const VISIT_LP_APPLY_LAST_UNSAVED_FILTER = 'VISIT_LP_APPLY_LAST_UNSAVED_FILTER'
export const VISIT_LP_SET_PURE_TOTAL = 'VISIT_LP_SET_PURE_TOTAL'

export const referrerTypes = [{
  value: 'social_media',
  label: 'Social Media',
}, {
  value: 'organic_search',
  label: 'Organic Search',
}, {
  value: 'referral',
  label: 'Referral',
}, {
  value: 'email_marketing',
  label: 'Email Marketing',
}, {
  value: 'paid_search',
  label: 'Paid Search',
}, {
  value: 'paid_social',
  label: 'Paid Social',
}, {
  value: 'direct_traffic',
  label: 'Direct Traffic',
}, {
  value: 'other_campaigns',
  label: 'Other Campaigns',
}, {
  value: 'offline_sources',
  label: 'Offline Sources',
}]
