import nodeTypes, { stepTypes } from "../nodeTypes";

export const flow = {
  nodes: [
    {
      id: 1,
      before: [],
      after: [],
      type: stepTypes.END,
    },
  ],
}

export const model = {
  flow,
  nodes: [
    {
      id: 1,
      serverNodeId: 1,
      parents: [],
      type: nodeTypes.REAL,
      before: [3],
      after: [],
      union: null,
      x: 0,
      y: 2,
    },
    {
      id: 2,
      serverNodeId: null,
      parents: null,
      type: nodeTypes.START,
      before: [],
      after: [3],
      union: null,
      x: 0,
      y: 0,
    },
    {
      id: 3,
      serverNodeId: null,
      parents: null,
      type: nodeTypes.PLUS,
      before: [2],
      after: [1],
      union: null,
      x: 0,
      y: 1,
    },
  ],
  nextNodeId: 4,
  minX: 0,
  maxX: 0,
  maxY: 2,
}
