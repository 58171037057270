import { getConfig as getContactsConfig } from "store/entities/contacts/actions";
import store from 'store/store'
import { loadLists } from "store/entities/lists/actions";
import { loadTags } from "store/entities/tags/actions";
import { loadFlowSources, loadFlowSource } from "store/entities/flowSources/actions"
import { createSelector } from "reselect";
import map from 'lodash/map'
import getAxios from 'services/axios'
import { createCachedFunction } from "services/utils";

const axios = getAxios('id');

const companySizes = [
  'Self-employed',
  '2-10 employees',
  '11-50 employees',
  '51-200 employees',
  '201-500 employees',
  '501-1000 employees',
  '1001-5000 employees',
  '5001-10000 employees',
  '10001+ employees',
]
export const companySizeOptions = companySizes.map(value => ({value, label: value}))
export const activityTypeOptions = [
  {
    value: 'social_connect_sent',
    label: 'Connect Sent',
  },
  {
    value: 'social_connected',
    label: 'Connected',
  },
  {
    value: 'social_connect_withdrawn',
    label: 'Connect Withdrawn',
  },
  {
    value: 'social_dm_sent',
    label: 'Direct Message Sent',
  },
  {
    value: 'social_dm_replied',
    label: 'Direct Message Replied',
  },
  {
    value: 'lead_unsubscribed',
    label: 'Email Unsubscribed',
  },
  {
    value: 'email_sent',
    label: 'Email Sent',
  },
  {
    value: 'email_opened',
    label: 'Email Opened',
  },
  {
    value: 'email_replied',
    label: 'Email Replied',
  },
  {
    value: 'email_bounced',
    label: 'Email Bounced',
  },
  {
    value: 'synced_crm',
    label: 'CRM Synced',
  },
  {
    value: 'exported_to_csv',
    label: 'Exported to CSV',
  },
]

export const getCountries = createCachedFunction(() => axios.get('/countries')).cachedFunction;
export const getCountriesOptions = () => getCountries().then( countriesObj => {
  return Object.keys(countriesObj).reduce((acc, key) => {
    acc.push({
      value: key,
      label: countriesObj[key],
    })
    return acc;
  }, []);
})

// params: {q: 'str',  type=city|country|region}
export const getLocations = createCachedFunction(
  (params) => getAxios('cid').get('api/geo-targets', {params}).then(r => r.data),
  {clearTimer: 120000} // 2 min cache
).cachedFunction


export const getTimezones = createCachedFunction(() => axios.get('/timezones')).cachedFunction;
export const getTimezonesOptions = () => getTimezones().then( timezones => timezones.map( v => ({
  value: v,
  label: v,
})))

export const industryOptionsPromise = getContactsConfig()
  .then( config => config['search-terms'].industry.map( ind => ({value: ind, label: ind})))
export const getIndustryOptions = () => industryOptionsPromise;

export const functionOptionsPromise = getContactsConfig()
  .then( config => config['search-terms'].industry.map( ind => ({value: ind, label: ind})))
export const getFunctionOptions = () => functionOptionsPromise;

export const seniorityOptionsPromise = getContactsConfig()
  .then( config => config['search-terms'].industry.map( ind => ({value: ind, label: ind})))
export const getSeniorityOptions = () => seniorityOptionsPromise;


export const lists = state => state.entities.lists;
export const glo = createSelector(
  [lists], lists => lists.map(list => ({value: list.id, label: list.name}))
);
export const getListsOptions = (state) => {
  store.dispatch(loadLists())

  return glo(state)
}

export const nodeStatusOptions = [
  {
    value: null,
    label: 'Any',
  }, {
    value: 'new',
    label: 'In Progress',
  }, {
    value: 'closed',
    label: 'Done',
  }, {
    value: 'cancelled',
    label: 'Canceled',
  }, {
    value: 'error',
    label: 'Error',
  }, {
    value: 'bounced',
    label: 'Bounced',
  },
]

export const tags = state => state.entities.tags;
export const gto = createSelector(
  [tags], tags => map(tags, tag => ({value: tag.id, label: tag.name}))
);
export const getTagsOptions = (state) => {
  store.dispatch(loadTags())

  return gto(state)
}

export const flows = state => state.entities.flows;
export const getFlowsOptions = createSelector(
  [flows], flows => map(flows, flow => ({value: flow.id, label: flow.name}))
)


export const schedules = state => state.entities.schedules;
export const getSchedulesOptions = createSelector(
  [schedules], schedules => map(schedules, schedule => ({value: schedule.id, label: schedule.name}))
)

export const getDataSourceOptions = () => store.dispatch(loadFlowSources()).then(res => res.data.map(el => ({
  value: el.id,
  label: el.name,
})))

export const getDataSourceLabel = (id) => {
  if (!id) return Promise.resolve(null)
  const state = store.getState();

  if (state.entities.flowSources[id]) {
    return Promise.resolve(state.entities.flowSources[id].name)
  }

  return store.dispatch(loadFlowSource(id))
    .then(ds => ds.name)
}
