import * as actions from './consts'
import { entitySetter } from '../utils'
import { immuteDelete } from "services/immuteActions";

const ACTION_HANDLERS = {
  [actions.HOOK_DELETE_SUCCESS]: (state, {id}) => immuteDelete(state, `${id}`),
  [actions.HOOKS_GET_SUCCESS]: (state, {hooks}) => entitySetter(state, hooks),
  [actions.HOOK_CREATE_SUCCESS]: (state, {hook}) => entitySetter(state, hook),
}

const initialState = {}

export default function hooksReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
