import React, { useRef } from "react";
import createPopover from "components/hocs/createPopover";
import Icon from "components/common/elements/Icon";
import HighlightText from "../HighlightText";
import TemplatesPopupContent from "../TemplatesPopupContent";
import VariablesDropdown, {useVariablesDropdown} from "../VariablesDropdown";

import styles from "./HighlightWithVarsAndTemplates.module.scss"


const TemplatesPopup = createPopover(TemplatesPopupContent);

const HighlightWithVarsAndTemplates = (props) => {
  const { onChange } = props;
  const handleSideChangesRef = useRef();
  const containerRef = useRef();
  const { onBlur, onVariableSelect } = useVariablesDropdown({containerRef, handleSideChangesRef})


  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.controls}>
        <VariablesDropdown
          onVariableSelect={onVariableSelect}
          className={styles.varsTrigger}
        />

        <TemplatesPopup
          onSelect={(s) => onChange(s.text)}
          type={TemplatesPopupContent.types.SNIPPET}
          processBody={false}
          preferPlace="above"
        >
          <div className={styles.templates}>
            <Icon icon="snippets" size={14} style={{marginRight: 7}}/>
            Use Template
          </div>
        </TemplatesPopup>
      </div>

      <HighlightText
        handleSideChanges={handleSideChangesRef}
        onBlur={onBlur}
        {...props}
      />
    </div>
  )

}

export default HighlightWithVarsAndTemplates;
