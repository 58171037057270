import { transition } from '../../../styles/DetailsStyles.module.scss'

export const transitionDuration = transition;

export const horizontalPadding = 170;
export const topPadding = 36;
export const marginUnit = 135;
export const depthUnit = 70;
export const nodePadding = 1;

export const focusedNodeHeightFactor = 6;
export const focusedNodeWidthFactor = 3;

// Q: не знаю зачем Саша эти константы вынес.
// A: пусть будут потому что они тут используются
export const icons = {
  SEND_EMAIL: "mail",
  SEND_MANUAL_EMAIL: "mail-manual",
  PHONE_CALL: "call",
  CUSTOM_TASK: "task",
  SEND_CONTACT_IN_NEW_FLOW: "flows",
  ADD_TO_LIST: "FlowTag",
  ASSIGN_TO: "businessman-4",
  CHANGE_VALUE_FIELD: "pen",
  REMOVE_FROM_LIST: "minus-in-square",
  IS_ASSIGNED_TO: "person-cColor",
  HAS_FIELD: "contactfields",
  IS_IN_FLOW: "personFromFlow",
  EMAIL_LINK_CLICK: "mail-auto",
  EMAIL_OPEN: "mail-opened",
  EMAIL_FORWARDED: "forward",
  FILE_DOWNLOAD: "import",

  VISIT_PAGE: "FlowVisitPage",

  CONNECT: "FlowConnect",
  CHECK_CONNECTION: "FlowConnect",
  LIKE: "FlowLike",
  CHAT: "FlowSocialMessage",
}
