import * as consts from './consts';
import getAxios, { createSource } from 'services/axios'
import { loadListsWithPagination } from '../../../../store/entities/lists/actions';
import { listsArraySelector } from '../../../../store/entities/lists/selectors';
import { loadFlows  } from '../../../../store/entities/flows/actions'
import { flowsArraySelector } from '../../../../store/entities/flows/selectors';
import { key } from './reducer';

const initAction = () => (dispatch, getState) => {
    const state = getState();

    const promises = [];

    if (!listsArraySelector(state).length) {
        dispatch({type: consts.LOAD_INIT_REQUEST})
        promises.push(dispatch(loadListsWithPagination({limit: 30, offset: 0})));
    }

    if (!flowsArraySelector(state).length) {
        dispatch({type: consts.LOAD_INIT_REQUEST})
        promises.push(dispatch(loadFlows({})));
    }

    Promise.all(promises)
      .then(() => dispatch({type: consts.INIT_COMPLITED}))
      .catch(err => {
        console.log('failed init', err);
        window.location.reload();
      });
};

const axios = getAxios('flow');

let loadStatsSource;
const loadStats = (params) => (dispatch, getState) => {
  const state = getState()[key];

  if (state.isLoading) {
    return Promise.resolve();
  }

  dispatch({
    type: consts.DASHBOARD_STATS_P_LOAD_REQUEST,
  });

  loadStatsSource = createSource();

  return axios.get('/api/dashboard', {
      cancelToken: loadStatsSource.token,
      params,
    })
    .then( res => {
      dispatch({
        type: consts.DASHBOARD_STATS_P_LOAD_SUCCESS,
        stats: res
      });
    })
    .catch(err => {
      dispatch({
        type: consts.DASHBOARD_STATS_P_LOAD_FAILURE,
      })

      throw err;
    })
}

const leaveStatsPage = () => {
  if (loadStatsSource) {
    loadStatsSource.cancel();
  }

  return {
    type: consts.DASHBOARD_STATS_P_LEAVE,
  }
}

export {
    initAction,
    loadStats,
    leaveStatsPage,
}
