import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from "react-redux";
import { proxyStatuses } from "store/proxy/consts";
import { selectProxyServer, clearProxyServer } from "store/proxy/actions";
import getAxios from "services/axios";
import { sendMessageToPlugin, pluginResponseEvents } from "components/routes/PluginGroup/pluginEvents";

import styles from './PluginProxy.module.scss'

const axios = getAxios('id');

const ProxiesPopup = () => {
  const dispatch = useDispatch();
  const [servers, setServers] = useState([]);
  const status = useSelector(s => s.proxy.status);
  const selectedServerId = useSelector(s => s.proxy.selectedId);
console.log('selectedServerId', selectedServerId)
  useEffect(() => {
    axios.get('/servers')
      .then(setServers)
  }, []);

  return (
    <div className={styles.popup}>
      {/*{ status === proxyStatuses.ERROR && (*/}
      {/*  <div className={styles.errorMessage}>*/}
      {/*    Selected Proxy does not match LinkedIn account you logged in*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*{ status !== proxyStatuses.ERROR && (*/}
        <div className={styles.tipMessage}>
          Choose the Proxy of account you need to log in on LinkedIn
        </div>
      {/*)}*/}
      { servers.map((server) => (
        <div
          key={server.id}
          onClick={() => dispatch(selectProxyServer(server))}
          className={cn(styles.serverOption, {
            [styles.selectedServer]: selectedServerId === server.id,
          })}
        >
          { server.login }
          { selectedServerId === server.id && (
            <div className={styles.connectedLabel}>
              Connected
            </div>
          )}
          { selectedServerId !== server.id && (
            <div className={styles.clickToConnect}>
              Click to connect
            </div>
          )}
        </div>
      )) }
      {selectedServerId && (
        <div
          onClick={() => dispatch(clearProxyServer())}
          className={styles.serverOption}
        >
          Clear Proxy
        </div>
      )}
    </div>
  )
}

export default ProxiesPopup
