import React, { useCallback } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../../common/elements/Icon';
import { useMobileDetector } from '../../../common/hooks/useMobileDetector';
import { MobileContext } from '../../../common/contexts/MobileContext';

import style from './style/MiddleMenu.module.scss'
import { primaryColor } from 'styles/_variables.module.scss'

const MiddleMenu = (props) => {
  const {
    title,
    items,
  } = props;

  return (
    <div className={cn(style.middleMenu)}>
      <div
        className={style.topLine}
        role='button'
      >
        <Icon
          icon={title.icon}
          className={style.topLine__icon}
          fill={primaryColor}
          width={20}
          height={20}
        />
        <div className={style.topLine__label}>
          { title.label }
        </div>
      </div>
      <div className={style.navigation}>
        { items.map(({ path, label }) =>(
            <NavLink
              key={path}
              to={path}
              activeClassName={style.navigation__item__active}
              className={style.navigation__item}
            >
              <div className={style.navigation__item__label}>
                { label }
              </div>
            </NavLink>
          ))
        }
      </div>
    </div>
  );
};

export {
  MiddleMenu
};
