import * as actions from './consts'
import { entitySetter, deleteByIds } from '../utils'
// import { immuteSet } from "services/immuteActions";
//
// const { stripeStatuses } = actions;

const ACTION_HANDLERS = {
  [actions.SUBSCRIPTIONS_GET_SUCCESS]: (state, { subscriptions }) => entitySetter(state, subscriptions),
  [actions.SUBSCRIPTIONS_CANCEL_SUCCESS]: (state, { subscriptionId }) => deleteByIds(state, subscriptionId),
  [actions.SUBSCRIPTIONS_RESUME_SUCCESS]: (state, { subscription }) => entitySetter(state, subscription, true),
}

const initialState = {}

export default function subscriptionsReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
