import { createSelector } from "reselect";

const listsSelector = (state) => state.entities.lists || {};

const listsArraySelector = createSelector(
  [listsSelector], (lists) => Object.values(lists)
);

const listsWithLeadsArraySelector = createSelector(
  [listsArraySelector], (listsArr) => listsArr.filter((list) => !!list.total_leads)
);

export {
  listsSelector,
  listsArraySelector,
  listsWithLeadsArraySelector,
}