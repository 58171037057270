import getAxios from 'services/axios';
const axios = getAxios('flow');


export const uploadAttachment = (file) => (dispatch, getState) => {
  const data = new FormData();
  data.append('attachment', file, file.name);
  data.set('payload[size]', file.size);
  data.set('payload[type]', file.type);
  data.set('payload[name]', file.name);

  return axios.post("/api/template-attachment", data)
}
