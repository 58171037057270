import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Radio from 'components/common/inputs/Radio'

import styles from './Styles.module.scss'

class DelayInput extends PureComponent {
  constructor({ field }) {
    super();

    this.state = {
      options: Array.isArray(field.options) ? field.options : [],
    }
  }
  componentDidMount() {
    if (typeof this.props.field.options === "function") {
      Promise.resolve(this.props.field.options())
        .then(options => this.setState({options}))
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.field !== prevProps.field) {
      if (typeof this.props.field.options === "function") {
        Promise.resolve(this.props.field.options())
          .then(options => this.setState({options}))
      } else {
        this.setState({
          options: Array.isArray(this.props.field.options) ? this.props.field.options : [],
        });
      }
    }
  }

  render() {
    const { field, value, onChange, readOnly } = this.props;
    const { options } = this.state;

    return (
      <div className={styles.container}>
        <label className={styles.label}> { field.label }</label>
        <div className={styles.radiosCont}>
          { options.map( v => (
            <Radio key={v.value}
                   label={v.label}
                   checked={v.value === value}
                   onClick={ () => onChange(v.value) }
                   disabled={readOnly || field.disabled}
                   reversed
            />
          ))}
        </div>
      </div>
    )
  }
}

DelayInput.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default DelayInput
