import * as actions from './consts'
import { entitySetter, deleteByIds } from '../utils'

const ACTION_HANDLERS = {
  [actions.SET_LEADS_STATUSES]: (state, { statuses }) => entitySetter(state, statuses),
  [actions.DELETE_LEADS_STATUS]: (state, { ids }) => deleteByIds(state, ids),
}

const initialState = {}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
