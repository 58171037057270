import getAxios from "services/axios";

const axios = getAxios('flow');

export class SSE {
  constructor() {
    this.loop = this.loop.bind(this)
    this.request = this.request.bind(this)
    this.cbs = [];
    this.errorsCount = 0;

    this.loop();
  }
  request(last_event_at) {
    return axios.get('/api/events/stream', {params: {last_event_at}})
      .then((events) => {
        if (events && events.length) {
          this.handleEvents(events)

          const eventDates = events.map(e => {
            // todo: only `return e.payload.updated_at;`
            if (e.payload) return e.payload.updated_at;
            if (e.message) return e.message.updated_at;
          });
          return eventDates[eventDates.length - 1];
        }
      })
  }
  loop(last_event_at) {
    return this.request(last_event_at)
      .then((last_event_at) => {
        this.errorsCount = 0;
        return this.loop(last_event_at)
      })
      .catch((err) => {
        console.log('stream error', err)
        if (err && err.status === 402) return;
        if (this.errorsCount > 2) return;
        this.errorsCount++;

        return this.loop(last_event_at)
      })
  }

  listen(cb) {
    this.cbs.push(cb);
    return () => {
      const index = this.cbs.indexOf(cb);

      this.cbs.splice(index, 1);
    }
  }
  handleEvents(events) {
    console.log('handle events', events);

    for (const cb of this.cbs) cb(events)
  }
}

SSE.eventTypes = {
  MESSAGE: "dm_event",
}

const sse = new SSE();


export default sse;
