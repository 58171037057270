// import store from 'store/store'
// import { injectReducer } from 'store/reducers'
import Loadable from 'react-loadable';
import Loading from "components/common/Loading";
import Error404 from 'components/routes/NotFound'

const EmailAccountPage = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName: 'EmailAccountPage' */ './EmailAccount');

    return component.catch( e => console.log('EmailAccountPage loading err', e) || Error404 );
    // return import(/* webpackChunkName: 'EmailAccountPage' */ './reducer')
    //   .then( reducer => injectReducer(store, { key: 'mailboxDetails', reducer: reducer.default }) )
    //   .then( () => component )
    //   .catch( e => console.log('Page loading err', e) || Error404 )
  },
  loading: Loading,
});

export default EmailAccountPage;
export { default as path } from './path'
