import cn from "classnames";
import { stepTypes } from "store/entities/flows/model/nodeTypes";
import Toggle from "components/common/inputs/Toggle";
import FieldPreview from "./FieldPreview";
import InnerStats from "./InnerStats";
import { serverRuleKeys, fieldTypes } from "../../../../reducer/utils/description";

import styles from "./RealNode.module.scss"
import fieldStyles from "./FieldPreview/FieldPreview.module.scss"
import filterValueStyles from "./FieldPreview/valueComponents/FilterValue.module.scss"

const isNotDelayOfAction = ({field, serverNode}) => {
  if (serverNode.type !== stepTypes.ACTION) return true;
  if (field.type !== fieldTypes.DELAY) return true;
  if (serverNode.payload[field.key]) return true;

  return false;
}

const AbRulePayload = ({ serverNode, onSubmit, node }) => {
  const total = serverNode.payload.branches
    .filter(({isEnabled}) => isEnabled !== false) // handle undefined as true
    .map(({value}) => value)
    .reduce((a, b) => a + b, 0);

  return (
    <div className={filterValueStyles.container}>
      {serverNode.payload.branches.map((branch, index) => {
        const isEnabled = branch.isEnabled !== false;
        const handleChange = (newValue) => {
          const newPayload = {
            ...serverNode.payload,
            branches: [...serverNode.payload.branches],
          };
          newPayload.branches[index] = {
            ...newPayload.branches[index],
            isEnabled: newValue,
          };

          onSubmit({
            ...serverNode,
            payload: newPayload,
          })
        }
        const realValue = total && (Math.round(100 * branch.value / total));

        return (
          <div key={branch.branch_id} className={filterValueStyles.branch}>
            <Toggle
              value={isEnabled}
              onChange={handleChange}
              stopPropagation
            />
            {branch.value}%
            {/*{ isEnabled && realValue !== branch.value && (*/}
            {/*  <>*/}
            {/*    &nbsp;*/}
            {/*    ({realValue}%)*/}
            {/*  </>*/}
            {/*)}*/}
          </div>
        )
      })}
    </div>
  )
}

const QualificationRulePayload = ({ serverNode }) => (
  <div className={filterValueStyles.container}>
    {serverNode.payload.branches.map(branch => (
      <div key={branch.branch_id} className={filterValueStyles.branch}>
        { branch.label }
      </div>
    ))}
  </div>
)

const FilterRulePayload = ({ serverNode }) => (
  <div className={filterValueStyles.container}>
    {serverNode.payload.branches.map(branch => (
      <div key={branch.branch_id} className={filterValueStyles.branch}>
        { branch.filters && `${ Object.keys(branch.filters).length } Filters` }
        { !branch.filters && "Alternative"}
      </div>
    ))}
  </div>
)

const ruleComponents = {
  [serverRuleKeys.A_B]: AbRulePayload,
  [serverRuleKeys.FILTERS]: FilterRulePayload,
  [serverRuleKeys.QUALIFICATION]: QualificationRulePayload,
}

const RulePayload = ({ serverNode, onSubmit }) => {
  const Component = ruleComponents[serverNode.action];

  return <Component serverNode={serverNode} onSubmit={onSubmit} />;
}

const DefaultPayload = ({ serverNode, stepStatistics, description, errors }) => {
  const itemInfo = description[serverNode.type][serverNode.action];

  return (
    <>
      <div className={fieldStyles.container}>
        <InnerStats states={stepStatistics && stepStatistics.states} serverNodeId={serverNode && serverNode.id}/>

        { Object.values(itemInfo.fields)
          .filter((field) => isNotDelayOfAction({field, serverNode}))
          .map(field => (
            <FieldPreview key={field.key} field={field} payload={serverNode.payload} />
          ))
        }

        { !!errors?.length && errors.map(error => (
          <div key={error} style={{color: '#F2545B'}}> { error }</div>
        ))}
      </div>

      {itemInfo.branchLabels && (
        <div className={styles.branchLabels}>
          { itemInfo.branchLabels.map((label, index) => (
            <div key={index} className={cn(styles.branchLabelItem, {
              [styles.branchLabelItemGreen]: index > 0,
            })}>
              { label }
            </div>
          )) }
        </div>
      )}
    </>
  )
}

const componentsMap = {
  [stepTypes.RULE]: RulePayload,
}

const Payload = (props) => {
  const { serverNode } = props;

  const PayloadComponent = componentsMap[serverNode.type] || DefaultPayload;

  return <PayloadComponent {...props} />
}

export default Payload;
