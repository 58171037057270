import store from 'store/store'
import { injectReducer } from 'store/reducers'
import Loadable from 'react-loadable';
import Loading from "components/common/Loading"
import Error404 from 'components/routes/NotFound';
export { default as path } from "./path"

const FlowDetailsPage = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName:'MessagesPage' */ './MessagesPage');

    return import(/* webpackChunkName:'MessagesPage' */ './reducer/reducer')
      .then( reducer => injectReducer(store, { key: reducer.key, reducer: reducer.default }) )
      .then( () => component )
      .catch( e => console.log('Page loading err', e) || Error404 )
  },
  loading: Loading,
});

export default FlowDetailsPage;
