import React, { useState, useEffect } from "react";
import cn from "classnames";
import { PathLine } from 'react-svg-pathline'
import find from 'lodash/find';
import { iconSize, getXpx, getYpx } from "../vars";
import { stepTypes } from "store/entities/flows/model/nodeTypes";

import styles from './BranchVisualizer.module.scss'

const getMiddlePointsForRule = (fromNode, toNode, model, xStartOffset, layersY) => {
  const points = [];

  if (fromNode.after.length > 1) {
    points.push({
      x: getXpx(fromNode.x, model.minX) + xStartOffset,
      y: getYpx(layersY[fromNode.y] + 30)
    })
    points.push({
      x: getXpx(toNode.x, model.minX),
      y: getYpx(layersY[fromNode.y] + 30)
    })
  } else {
    points.push({
      x: getXpx(fromNode.x, model.minX),
      y: getYpx(layersY[toNode.y] - 45)
    })
    points.push({
      x: getXpx(toNode.x, model.minX),
      y: getYpx(layersY[toNode.y] - 45)
    })
  }

  return points;
}
const getMiddlePointsForTrigger = (fromNode, toNode, model, layersY) => {
  const points = [];
console.log('trigger points', layersY)
  if (fromNode.after.length > 1) {
    points.push({
      x: getXpx(toNode.x, model.minX),
      y: getYpx(layersY[fromNode.y])
    })
  } else {
    points.push({
      x: getXpx(fromNode.x, model.minX),
      y: getYpx(layersY[toNode.y] - 22)
    })
    points.push({
      x: getXpx(toNode.x, model.minX),
      y: getYpx(layersY[toNode.y] - 22)
    })
  }
console.log('po', points)
  return points;
}

const BranchVisualizer = ({ fromNodeId, toNodeId, model, index, layersY}) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => setIsMounted(true), []);

  const isDashed = false;
  const fromNode = find(model.nodes, {id: fromNodeId});
  const toNode = find(model.nodes, {id: toNodeId});
  const fromServerNode = find(model.flow.nodes, {id: fromNode.serverNodeId});
  let xStartOffset = 0

  let points = []

  if (fromServerNode && ([stepTypes.RULE, stepTypes.TRIGGER].includes(fromServerNode.type))) {
    // const blockSize = iconSize;
    const blockSize = 145;
    xStartOffset = blockSize * (index + 0.5 - fromNode.after.length / 2);
  }
  points.push({
    x: getXpx(fromNode.x, model.minX) + xStartOffset,
    y: getYpx(layersY[fromNode.y]),
  })

  if (fromNode.y !== toNode.y) {
    switch (fromServerNode && fromServerNode.type) {
      // case stepTypes.TRIGGER:
      //   points = [...points, ...getMiddlePointsForTrigger(fromNode, toNode, model, xStartOffset)];
      //   break;
      default:
        points = [...points, ...getMiddlePointsForRule(fromNode, toNode, model, xStartOffset, layersY)];
    }
  }

  points.push({
    x: getXpx(toNode.x, model.minX),
    y: getYpx(layersY[toNode.y]),
  });

  return (
    <PathLine
      strokeWidth={3}
      fill="none"
      r={10}
      strokeDasharray={isDashed ? "12, 6" : null}
      points={points}
      vectorEffect="non-scaling-stroke"
      strokeLinecap="round"
      className={cn(styles.path, {[styles.mounted]: isMounted})}
    />
  )
}

export default BranchVisualizer;
