import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/common/buttons/Button';
import TextInput from 'components/common/inputs/FormRows/TextRow';
import CheckBox from 'components/common/inputs/CheckBox';
import {
  required,
  requiredPassword,
  requiredEmail,
  validateConfirmPassword,
} from 'services/validators';
import { findGetParameter, showError } from 'services/utils';
import history from 'services/browserHistory';
// import leadsPath from 'components/routes/Visitors/pathname';
import { path as addCardPath } from "components/routes/authGroup/AddCard";

import styles from '../Styles.module.scss'

const labelStyles = {
  display: 'flex',
  marginTop: '10px',
  marginBottom: '10px',
  width: '100%',
};
const buttonStyles = {marginTop: '5px'};
const linkStyles = {marginTop: '24px'};

const SignupForm = (props) => {
  const {
    signUp,
    setField,
    setSubmitted,
    changeTab,
    isSubmitted,
    errors,
    detached,
    onSignUp = () => history.push(addCardPath),
    ...fields
  } = props;

  React.useEffect(() => {
    const email = findGetParameter('email');

    if (email) {
      setField('email', email);
    }
  }, []);

  const [isAgree, setIsAgree] = React.useState(false);
  const [isAgreeValid, setIsAgreeValid] = React.useState(true);

  const {
    full_name,
    email,
    password,
  } = fields;


  const isFormValid = () => {
    if (required(full_name)) return false;
    if (requiredEmail(email)) return false;
    if (requiredPassword(password)) return false;
    if (validateConfirmPassword(password, password)) return false;
    return true;
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (!isSubmitted) setSubmitted();
    if (!isFormValid()) return;

    signUp(findGetParameter('tinv'))
      // .then( res => changeTab(TABS.PLANS) )
      .then(onSignUp)
      .catch( err => showError(err, 'Failed to sign up you. Please try again later'))
  }

  const getOnChange = (field) => (e) => setField(field, e.target.value);


  const handleClick = (e) => {
    if (!isAgree) {
      e.preventDefault();
      setIsAgreeValid(false);
    }
  }

  const handleAgree = (e) => {
    const checked = e.target.checked;
    setIsAgree(checked);

    if (checked) {
      setIsAgreeValid(true);
    }
  }

  return (
    <form onSubmit={onSubmit} id='signup-form' className='signup-form'>
      <h2 className={styles.header}>
        Let's get started
      </h2>
      <TextInput
        label="Name"
        placeholder="Name"
        value={full_name}
        error={required(full_name) || errors.full_name}
        isSubmitted={isSubmitted}
        type='text'
        onChange={getOnChange('full_name')}
      />
      <TextInput
        label="Email"
        placeholder="Email"
        value={email}
        onBlur={() => setField('email', email.trim())}
        error={requiredEmail(email) || errors.email}
        isSubmitted={isSubmitted}
        name="email"
        required
        onChange={getOnChange('email')}
      />
      <TextInput
        label="Password"
        placeholder="Password"
        value={password}
        error={requiredPassword(password) || errors.password}
        isSubmitted={isSubmitted}
        name="password"
        type="password"
        required
        onChange={getOnChange('password')}
      />
      {/*<div style={{ padding: "10px 3px 0", textAlign: "center"}}>*/}
      {/*  By continuing with the sign up you agree to our*/}
      {/*  <a target="_blank" href=" https://whovisited.me/terms.html"> Terms & Conditions </a>*/}
      {/*  and*/}
      {/*  <a target="_blank" href=" https://whovisited.me/privacy.html"> Privacy Policy </a>.*/}
      {/*</div>*/}
      <div style={{...labelStyles, flexDirection: 'column'}}>
        <label style={{display: 'flex'}}>
          <CheckBox
            checked={isAgree}
            onChange={handleAgree}
          />
          <p style={{marginLeft: '10px'}}>
            {` I agree to the `}
            <a
              href={`https://getsales.io/terms-conditions/`}
              className="violetlink"
            >
              Terms of Service
            </a>
          </p>
        </label>
        {!isAgreeValid &&
          <span className='gs-text _error _c-danger'>Please check this box if you want to proceed.</span>
        }
      </div>

      <Button
        text='Signup'
        onClick={handleClick}
        className={styles.submitBtn}
        style={buttonStyles}
      />
      { !detached && (
        <Link
          to={`/login${window.location.search}`}
          className="violetlink"
          style={linkStyles}
        >
          Already have an account? Log in!
        </Link>
      )}
    </form>
  );
};

SignupForm.propTypes = {
  setSubmitted: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
  full_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
}

export default SignupForm
