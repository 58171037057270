import cn from 'classnames'
import Spinner from 'components/common/elements/Spinner'

import {
  Preload,
  PreloadLoginForm
} from './Styles.module.scss'

const Preloader = ({isLoginForm = false}) => (
  <div
    className={cn(
      Preload, {
        [PreloadLoginForm]: isLoginForm,
      })
    }
  >
  <Spinner color='#FC5D47' size={50}/>
  </div>
);

export {
  Preloader as default,
  Preloader
}
