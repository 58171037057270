import * as consts from './consts'
import getAxios from "services/axios";

const axios = getAxios('flow');

const getTaskForLead = (id, cancelToken) => (dispatch) => {
  return axios.get(`/api/tasks/lead/${id}`, {cancelToken})
    .then(res => {

      dispatch({
        type: consts.TASKS_SET,
        payload: res,
      })

      return res;
    })
}

const getTasks = (params, cancelToken) => (dispatch) => {
  return axios.get(`/api/tasks`, {params, cancelToken})
    .then(res => {
      dispatch({
        type: consts.TASKS_SET,
        payload: res.data,
      })

      return res;
    })
}

const cancelTask = (id) => (dispatch) => {
  return axios.put(`/api/tasks/${id}/cancel`)
  .then(res => {
    if (res.updated) {
      dispatch({
        type: consts.TASKS_SET_EXECUTE,
        payload: id,
      });
    }
    return res;
  })
}

const updateTask = (id, params) => (dispatch) => {
  return axios.put(`/api/tasks/${id}`, params)
    .then(res => {
      dispatch({
        type: consts.TASKS_SET_UPDATE,
        payload: res,
      });
      return res;
    })
}

const setDelegateStatus = (id) => (dispatch) => {
  return axios.put(`/api/tasks/${id}/delegate`)
    .then(res => {
      dispatch({
        type: consts.TASKS_SET_EXECUTE,
        payload: id,
      });
      return res;
    })
}


const getNextTask = (params, cancelToken) => () => {
  return axios.get(`/api/tasks/get-next-task`, {params, cancelToken});
}

const executeTask = (id, branchId) => (dispatch) => {
  return axios.put(`/api/tasks/${id}/complete`, {payload:  { branch_id: branchId }})
    .then(res => {

      if (res.updated) {
        dispatch({
          type: consts.TASKS_SET_EXECUTE,
          payload: id,
        });
      }

      return res;
    })
}

export {
  getTaskForLead,
  getTasks,
  updateTask,
  setDelegateStatus,
  cancelTask,
  getNextTask,
  executeTask
};
