import React from "react";

import nodeTypes, { getNodeType } from "../../nodeTypes";
import { startColor, endColor } from "../../vars";
import PlusNode from "./PlusNode/PlusNode";
import CircleNode from "./CircleNode/CircleNode";
import RealNode from "./RealNode/RealNode";

// import styles from "./NodePreviewFacade.module.scss"

const NodePreviewFacade = ({setFocusedNodeId, node, model, onDnd, description, errors, onSubmit}) => {
  const type = getNodeType(node);

  switch (type) {
    case nodeTypes.PLUS: {
      return <PlusNode focus={() => setFocusedNodeId(node.id)} node={node} onDnd={onDnd} />
    }
    case nodeTypes.START: {
      return <CircleNode color={startColor} model={model}/>
    }
    case nodeTypes.END: {
      return <CircleNode node={node} color={endColor} model={model}/>
    }
    case nodeTypes.REAL: {
      return <RealNode onSubmit={onSubmit} errors={errors} focus={() => setFocusedNodeId(node.id)} node={node} model={model} description={description}/>
    }
    default: {
      const err = 'unknown node type'
      console.log(err)
      throw err
    }
  }
}

export default NodePreviewFacade;
