import React from "react";
import cn from 'classnames';
import { useSelector } from "react-redux";
import { proxyStatuses } from "store/proxy/consts";
import Icon from "components/common/elements/Icon";
import createPopover from "components/hocs/createPopover";
import ProxiesPopupContent from "./ProxiesPopup";

import styles from './PluginProxy.module.scss'

const ProxiesPopup = createPopover(ProxiesPopupContent);

const PluginProxy = () => {
  const proxyStatus = useSelector(s => s.proxy.status);

  return (
    <div className={styles.container}>
      <ProxiesPopup>
        <Icon icon="proxy" />
      </ProxiesPopup>
      <div className={cn(styles.badge, {
        [styles.inactive]: proxyStatus === proxyStatuses.NEUTRAL,
        [styles.active]: proxyStatus === proxyStatuses.OK || proxyStatus === proxyStatuses.ERROR,
        // [styles.error]: proxyStatus === proxyStatuses.ERROR,
      })}/>
    </div>
  )
}

export default PluginProxy;
