import React, { useState, useRef } from "react";
import cn from "classnames";
import createPopover from "components/hocs/createPopover";
import {tabs, varsTemplate} from "../../inputs/TextEditor/Toolbar/VarsController/VarsController";

import styles from "./VariablesDropdown.module.scss"
import {textarea as textareaClass} from "../HighlightText/HighlightText.module.scss"

const PopContent = ({onVarSelect, onRequestClose}) => {
  const [tab, setTab] = useState(tabs.LEAD);

  return (
    <div className={styles.varsPopover}>
      <div className={styles.varTabs}>
        { Object.values(tabs).map( theTab => (
          <div
            key={theTab}
            onClick={() => setTab(theTab)}
            className={cn(styles.varTab, {
              [styles.varTabActive]: theTab === tab,
            })}
          >
            { theTab.toLowerCase() }
          </div>
        ))}
      </div>
      <div className={styles.varOptions}>
        { varsTemplate[tab].map(({label, value}) => (
          <div
            key={label}
            className={styles.varOption}
            onClick={() => {
              onVarSelect(value.map(v => `{{${v}}}`).join(''));
              onRequestClose();
            }}
          >
            { label }
          </div>
        ))}
      </div>
    </div>
  )
}

const VarsPopover = createPopover(PopContent)

const VariablesDropdown = ({onTriggerClick, onVariableSelect, className}) => (
  <VarsPopover onVarSelect={onVariableSelect} className={cn(styles.varsTrigger, className)}>
    <div onClick={onTriggerClick}> {'{ } Variables'} </div>
  </VarsPopover>
)

export default VariablesDropdown
export const useVariablesDropdown = ({containerRef, handleSideChangesRef}) => {
  const selectionRef = useRef({});

  const onBlur = () => {
    const selection = window.getSelection();
    selectionRef.current.node = selection.focusNode;
    selectionRef.current.offset = selection.focusOffset;
  }

  const onVariableSelect = (variable) => {
    if (!selectionRef.current.node || !selectionRef.current.node.data) {
      let node = containerRef.current.querySelector(`.${textareaClass}`);
      while (node.nodeType !== 3) {
        if (!node.firstChild) {
          const textNode = document.createTextNode('');
          node.appendChild(textNode);
        }
        node = node.firstChild;
      }
      selectionRef.current.offset = 0;
      selectionRef.current.node = node;
    }
    const { offset, node } = selectionRef.current;
    node.data = node.data.slice(0, offset) + variable + node.data.slice(offset);
    const rng = document.createRange();
    rng.setStart(node, offset + variable.length);
    rng.setEnd(node, offset + variable.length);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(rng);

    handleSideChangesRef.current();
  }

  return {
    onBlur,
    onVariableSelect
  }
}
