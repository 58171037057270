const getLinkedInProfile = (socialProfiles) => {
  if (!socialProfiles || !socialProfiles.length) {
    return [];
  }

  return socialProfiles.find((socialProfile) => socialProfile.type === "linkedin");
};

export {
  getLinkedInProfile,
}
