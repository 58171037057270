import React from 'react'
import { Switch, Route } from 'react-router-dom'
import MessagesListPage from './index'
import NotFoundRoute from "components/routes/NotFound";
import pathname from "./path";

export default () => (
  <Switch>
    <Route path={`${pathname}/:id?/:detailTab?`} component={MessagesListPage} />
    <Route component={NotFoundRoute} /> {/* 404 */}
  </Switch>
)

export { default as path } from "./path";
