import { createSelector } from "reselect";
import { flowStatuses, flowServerStatuses } from "./consts";

const sliceSelector = (state) => state.entities.flows;

const getFlowById = (state, id) => sliceSelector(state)[id];

const flowsArraySelector = createSelector(
  [sliceSelector], (flows) => Object.values(flows)
);

const activeFlowsArraySelector = createSelector(
  [flowsArraySelector], (flowsArr) => flowsArr.filter((flow) => {
    return !!flow.status;
  })
);

export {
  sliceSelector,
  getFlowById,
  flowsArraySelector,
  activeFlowsArraySelector
};
