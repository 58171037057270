import React, { useState, useEffect } from "react";

import { getDelayValue } from '../../../../NodeForm/OptionInputFacade/inputs/DelayInput'

const DelayValue = ({field, payload}) => {
  const [value, setValue] = useState('');
  useEffect(
    () => {
      const { unit, value } = getDelayValue(payload[field.key]);
      setValue(`${value} ${unit}`);
    },
    [payload[field.key]],
  )
  return <span> { value } </span>
}

export default DelayValue;
