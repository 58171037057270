import React, { useState, useEffect } from 'react'
import cn from 'classnames';
import TextRow from 'components/common/inputs/FormRows/TextRow'
import Attachments from "components/common/inputs/Attachments";
import Icon from "components/common/elements/Icon";
import OutsideClick from "components/common/OutsideClick";
import { Summernote } from "components/common/inputs/Summernote"
import {VarsInfo} from "../../../../../../../../TemplatesGroup/EmailTemplates/Details/VarsInfo";


import styles from "./Styles.module.scss"
import formStyles from "components/common/inputs/FormRows/Styles.module.scss"


const tabs = {
  PLAIN: 'PLAIN',
  HTML: 'HTML',
};


const tabOptions = [
  {
    value: tabs.PLAIN,
    label: 'Editable Preview',
  }, {
    value: tabs.HTML,
    label: 'HTML',
  },
]

const EmailInput = ({ _payload, field, readOnly, _updatePayload }) => {
  const [isTemplatesOpen, setIsTemplatesOpen] = useState(false);
  const [tempOptions, setTempOptions] = useState([]);
  const [templateData, setTemplateData] = useState();
  const [templateBody, setTemplateBody] = useState(_payload.template || '');

  useEffect(() => {
    field.options({}).then(setTempOptions)
  }, []);

  useEffect(() => {
    if (!templateData) return;
    _updatePayload(templateData)
  },[templateData])


  return (
    <div className={cn(styles.container, formStyles.gsFormRow)}>
      <VarsInfo className={styles.varsInfo} />

      <label className={styles.label}> Subject </label>
      <TextRow
        value={_payload.subject || ''}
        onChange={e => _updatePayload({subject: e.target.value})}
        style={{marginLeft: 0}}
      />

      <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
        <label className={styles.label}> Body </label>

        <OutsideClick outsideClick={() => setIsTemplatesOpen(false)}>
          {({ ref }) => (
            <div
              ref={ref}
              onClick={() => setIsTemplatesOpen(!isTemplatesOpen)}
              className={styles.tempSelect}
            >
              <Icon icon="snippets" size={14} />
              &nbsp;
              Use Template

              { isTemplatesOpen && (
                <div className={styles.tempDropdown}>
                  {tempOptions.map(temp => (
                    <div
                      key={temp.value}
                      className={styles.tempOption}
                      onClick={() => {
                        setTemplateBody(temp.snippet);
                        _updatePayload({
                          template: temp.snippet,
                          template_id: temp.value,
                          subject: temp.subject,
                          design: temp.design,
                          attachments: temp.attachments,
                        })
                      }}
                    >
                      { temp.label }
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </OutsideClick>
      </div>

      <div className={styles.tt}>
        <Summernote
          defaultValue={templateBody}
          onChange={(body) => setTemplateData({
            template: body
          })}
        />
      </div>

      <br />
      <Attachments value={_payload.attachments || []} onChange={(attachments) => _updatePayload({attachments})}/>
    </div>
  )
}

export default EmailInput
