export const immuteCopy = (obj) => {
  // if not object -> return as is
  if( (typeof obj !== "object"  && typeof obj !== 'function') || (obj === null) ) {
    return  obj;
  }

  if (Array.isArray(obj)) {
    return [...obj];
  } else {
    return  {...obj}
  }
}

const immuteFollow = (obj, fullKey = '') => {
  const objCopy = immuteCopy(obj);
  let curObj = objCopy;

  const path = fullKey.split('.');
  for (let i = 0; i <= path.length - 2; i++) {
    const key = path[i];

    curObj[key] = immuteCopy(curObj[key])

    curObj = curObj[key]
  }

  return {
    objCopy,
    lastObj: curObj,
    lastKey: path[path.length - 1],
  };
}

export const immutePush = (obj, fullKey, val) => {
  const { objCopy, lastKey, lastObj } = immuteFollow(obj, fullKey)
  lastObj[lastKey] = [...lastObj[lastKey], val];

  return objCopy;
}

export const immutePull = (obj, fullKey, index) => {
  const { objCopy, lastKey, lastObj } = immuteFollow(obj, fullKey)

  if (lastKey === "") {
    lastObj.splice(index, 1);
    return lastObj;
  }

  lastObj[lastKey] = [...lastObj[lastKey]];
  lastObj[lastKey].splice(index, 1);

  return objCopy;
}

export const immuteSet = (obj, fullKey, val) => {
  const { objCopy, lastKey, lastObj } = immuteFollow(obj, fullKey)
  lastObj[lastKey] = val;

  return objCopy;
}

export const immuteDelete = (obj, fullKey) => {
  const { objCopy, lastKey, lastObj } = immuteFollow(obj, fullKey)
  delete lastObj[lastKey];

  return objCopy;
}
