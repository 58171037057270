import * as consts from './consts'
import { pluginResponseEvents, sendMessageToPlugin } from "components/routes/PluginGroup/pluginEvents";


export const setProxyStatus = (status) => ({
  type: consts.PROXY_SET_STATUS,
  status,
})
export const setProxyServerId = (id) => ({
  type: consts.PROXY_SELECT,
  id,
})

export const selectProxyServer = (server) => {
  sendMessageToPlugin(pluginResponseEvents.SET_PROXY, {server})

  return {
    type: consts.PROXY_SELECT,
    id: server.id,
  }
}

export const clearProxyServer = () => {
  sendMessageToPlugin(pluginResponseEvents.CLEAR_PROXY, {});

  return {type: consts.PROXY_CLEAR}
}
