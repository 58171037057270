import React from 'react'

import cn from 'classnames'
import styles from '../Styles.module.scss'

const CheckBox = (props) => {
  const {
    inputClassName=styles.checkboxInput,
    big=false,
    triple=false,
    any=false,
    all=false,
    className = '',
    ...other
  } = props;

  return (
    <label
      className={`${styles.checkboxLabel} ${className}`}
      role='button'
    >
      { !triple && (
        <input
          type="checkbox"
          className={`${inputClassName}`}
          {...other}
        />
      )}
      <i
        className={cn(styles.checkboxButton, {
            [styles.checkboxButtonBig]: big,
            [styles.checkboxButtonTriple]: triple,
            [styles.checkboxButtonTripleAny]: any,
            [styles.checkboxButtonTripleAll]: all,
         })}
        {...other}
      />
    </label>
  )
}

CheckBox.propTypes = {
};

export {
  CheckBox as default,
  CheckBox,
}
