import { useEffect } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import Preloader from 'components/common/elements/Preloader';
import { userStatuses } from 'store/auth';
import history from 'services/browserHistory';

import { SideMenu } from './SideMenu';
import { useInjectTaksReducer } from '../../common/hooks/useInjectTaksReducer';

import {
  gsBody,
  gsBodyContent,
  menuOpen,
  withoutTop,
  isPluginView,
} from './MainLayout.module.scss'
import useIsColumnPlugin from './useIsColumnPlugin';


const Layout = (props) => {
  const {
    userStatus,
    isMenuOpen,
    children,
  } = props;

  useInjectTaksReducer();

  const isPlugin = useIsColumnPlugin();

  if (userStatus == userStatuses.LOADING) {
    return (
      <Preloader/>
    );
  };

  if (userStatus == userStatuses.UNAUTH) {
    history.push(`/login${window.location.search}`);

    return (
      <Preloader/>
    );
  }

  return (
      <div
        className={cn(gsBody, {
          [menuOpen]: false && isMenuOpen,
          [withoutTop]: true
        })}
      >
        <SideMenu />
        <div
          id="gs-body-content"
          className={gsBodyContent}
          className={cn(gsBodyContent, {
            [isPluginView]: !!isPlugin
          })}
        >
          {children}
        </div>
      </div>
  );
}

const mapStateToProps = (state) => ({
  userStatus: state.auth.status,
  isMenuOpen: state.global.navigationOpened,
  middleMenuVisibility: state.global.middleMenuVisibility,
})

export default connect(mapStateToProps, {})(Layout)
