import React from "react";
import cn from "classnames";
import { stepTypes } from "store/entities/flows/model/nodeTypes";
import Symbol from "../../Symbol/Symbol";

import styles from "./RealNode.module.scss"

const RuleHeader = ({ serverNode, description }) => (
  <>
    <span> {description[serverNode.type][serverNode.action].name} </span>
    <span> #{ serverNode.id } </span>
  </>
);

const DefaultHeader = ({ serverNode, description }) => {
  const itemInfo = description[serverNode.type][serverNode.action];

  return (
    <React.Fragment>
      <Symbol
        type={serverNode.type}
        icon={itemInfo.icon}
        className={cn(styles.icon)}
      />
      <span> { itemInfo.name } </span>
      <span className={styles.mainLink}> #{ serverNode.id } </span>
    </React.Fragment>
  )
}

const componentsMap = {
  [stepTypes.RULE]: RuleHeader,
}

const Header = (props) => {
  const { serverNode } = props;

  const HeaderComponent = componentsMap[serverNode.type] || DefaultHeader;

  return <HeaderComponent {...props} />
}

export default Header;
