import React  from 'react'
import cn from 'classnames'
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import startCase from 'lodash/startCase';
import { Link } from "react-router-dom";
import { stepTypes } from "store/entities/flows/model/nodeTypes";
import { serializeFilters } from "components/common/Filters";
import leadsPath from 'components/routes/Visitors/pathname'
import { xSize } from "../../../../vars";

import styles from './BranchesStatistic.module.scss'


const getPercent = (val, total) => val && total && Math.round(100 * val / total) || 0;

const BranchesStatistic = ({ stepStatistics, node, serverNode, model }) => {
  if (!stepStatistics) return null;
  const conversationsActivityMap = {
    replied: serverNode.action === "email" ? "email_replied" : 'social_dm_replied',
    connected: 'social_connected',
    opened: 'email_opened',
    unsubscribed: 'lead_unsubscribed',
    bounced: 'email_bounced',
  }

  const getToNode = (branch_id) => {
    branch_id = +branch_id;
    const index = findIndex(serverNode.after, {branch_id})
    return find(model.nodes, {id: node.after[index]})
  }
  const getStyles = (branchIndex, isSolo) => {
    const size = xSize - 3;

    return {
      left: isSolo ? '50%' : size + 5 + branchIndex * 2 * size,
    }
  }
  const getLink = (branch_id) => {
    const after = serverNode.after.find( af => af.branch_id == branch_id);
    if (!after) return '#';
    return `${leadsPath}?filters=${serializeFilters({flow_node_id: {has: [{id: after.node_id, status: null}]}})}`
  }
  const getConversationLink = ({status, ...other}) => `${leadsPath}?filters=${serializeFilters({
    flow_node_id: {has: [{id: node.serverNodeId, status}]},
    ...other,
  })}`

  if (!stepStatistics.forks) {
    return null;
  }

  return Object.keys(stepStatistics.forks)
    .filter(getToNode)
    .map( (branch_id) => {
      const closed = stepStatistics.states.closed || 0;
      const inProgress = stepStatistics.states.new || 0;
      const amount = stepStatistics.forks[branch_id];
      const percent = getPercent(amount, closed + inProgress);

      const branchIndex = serverNode.after.findIndex(item => item.branch_id == branch_id);
      return (
        <div
          key={branch_id}
          className={cn("gs-text", styles.container, {
            [styles.rule] : serverNode.type == stepTypes.RULE,
          })}
          onClick={e => e.stopPropagation()}
          style={getStyles(branchIndex, node.after.length <= 1)}
        >
          <Link
            to={getLink(branch_id)}
            target="_blank"
          >
            { stepStatistics.forks[branch_id] } Lead{stepStatistics.forks[branch_id] > 1 ? 's' : '' } ({ percent }%)
          </Link>

          { stepStatistics.conversions && (
            <div className={styles.conversation}>
              { Object.keys(stepStatistics.conversions).map(key => (
                <div key={key}>
                  <span> { startCase(key) } </span>
                  <Link
                    to={getConversationLink({activity: {has: [{type: conversationsActivityMap[key]}]}})}
                    target="_blank"
                  >
                    { stepStatistics.conversions[key] } ({ getPercent(stepStatistics.conversions[key], stepStatistics.forks[branch_id]) }%)
                  </Link>
                </div>
              )) }
            </div>
          )}
        </div>
      )
    });
}

export default BranchesStatistic;
