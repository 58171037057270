import React from "react";
import { Route, Switch } from "react-router-dom";
import FlowRoutes, { path as flowsPath } from "./Flow";
// import EmailTemplatesRoutes, { path as emailTemplatesPath } from "./EmailTemplates";
// import Mailboxes, { path as mailboxesPath } from './Mailboxes'
// import Snippets, { path as snippetsPath } from './Snippets'
import NotFoundRoute from "../NotFound";
export { default as path } from "./path";

export default () => (
  <Switch>
    {/*<Route path={emailTemplatesPath} component={EmailTemplatesRoutes} />*/}
    {/*<Route path={mailboxesPath} component={Mailboxes} />*/}
    {/*<Route path={snippetsPath} component={Snippets} />*/}

    {/* Этот роут должен быть в конце свитча, иначе он все перехватит и управление
        не дойдет до остальных роутов */}
    <Route path={flowsPath} component={FlowRoutes} />

    <Route component={NotFoundRoute} /> {/* 404 */}
  </Switch>
)

export const flowsMiddleMenu = {
  title: { label: 'Flows', icon: 'flows', color: '#253342' },
  items: [
    {label: 'Flows List', path: flowsPath},
    // {label: 'Mailboxes', path: mailboxesPath},
    // {label: 'Email Templates', path: emailTemplatesPath},
    // {label: 'Social Templates', path: snippetsPath},
  ]
};


