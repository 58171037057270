import React from "react";
import store from 'store/store'
import { injectReducer } from 'store/reducers'
import { useDispatch } from "react-redux";
import { loadMoreTasks } from "../../routes/Tasks/reducer/actions";

function useInjectTaksReducer() {
  const dispatch = useDispatch();
  const initAction = React.useCallback(() => dispatch(loadMoreTasks()), []);

  React.useEffect(() => {
    import(/* webpackChunkName:'ChromePluginGroup' */ '../../routes/Tasks/reducer/reducer')
    .then( reducer => injectReducer(store, { key: reducer.key, reducer: reducer.default }) )
    .then(() => initAction());
  }, []);
};

export {
  useInjectTaksReducer
}
