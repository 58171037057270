import React from 'react'
import { visitorFields, typeOperators } from "./description";
import CurrentFilter from "./CurrentFilter";

import styles from './Filters.module.scss'

const allFields = Object.values(visitorFields).filter( field => field.filterable );

const Filters = ({ filters, onChange, allowedFilter }) => {
  const addValue = React.useCallback((field, operatorKey) => {
    const newFilters = {
      ...filters,
      [field.name]: {...filters[field.name]},
    };

    if (newFilters[field.name][operatorKey]) {
      newFilters[field.name][operatorKey] = [...newFilters[field.name][operatorKey], ''];
    } else {
      newFilters[field.name][operatorKey] = [''];
    }

    onChange(newFilters, true);
  }, [filters])
  const updateValue = React.useCallback((field, fieldFilters, needDebounce) => {
    const newFilters = {
      ...filters,
      [field.name]: fieldFilters,
    };
    if (Object.keys(newFilters[field.name]).length === 0) {
      delete newFilters[field.name]
    }

    onChange(newFilters, needDebounce);
  }, [filters])
  const clearValues = React.useCallback( field => {
    const newFilters = {...filters};
    delete newFilters[field.name];

    onChange(newFilters)
  }, [filters]);

  return (
    <div className={styles.wrapper}>
      { allFields
        .filter(({name}) => allowedFilter ? (allowedFilter.indexOf(name) !== -1) : true)
        .map( field => (
          <div key={field.key} className={styles.item} >
            <div className={styles.header}>
              <div className={styles.label}> { field.label } </div>

              { filters[field.name] && <div onClick={() =>  clearValues(field)} className={styles.clear}> Clear </div> }
            </div>

            <CurrentFilter filters={filters[field.name]} onChange={(fieldFilters, needDebounce) => updateValue(field, fieldFilters, needDebounce)} field={field}/>

            <div className={styles.operators}>
              { Object.values(field.customOperators || typeOperators[field.type]).map(operator => (
                <React.Fragment key={operator.key}>
                  <div onClick={() => addValue(field, operator.key)} className={styles.operator}>
                    { operator.label } +
                  </div>
                  &nbsp;
                </React.Fragment>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
}

function toBinary(string) {
  const codeUnits = new Uint16Array(string.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
}
function fromBinary(binary) {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

const serializeFilters = (filters, additional = {}) => btoa(toBinary(JSON.stringify({filters, additional})))
const deserializeFilters = (serialized) => {
  try {
    return JSON.parse(fromBinary(atob(serialized)))
  } catch (e) {
    return ''
  }
}

export {
  Filters as default,
  Filters,
  serializeFilters,
  deserializeFilters,
  toBinary,
  fromBinary,
}
