import * as actions from './consts';

const ACTION_HANDLERS = {
  [actions.TASKS_LOAD_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actions.TASKS_LOAD_SUCCESS]: (state, { taskIds, total, aggs }) => ({
    ...state,
    isLoading: false,
    taskIds: [...state.taskIds, ...taskIds],
    total,
    aggs,
  }),
  [actions.TASKS_FOR_LEAD_LOAD_REQUEST]: (state, { id }) => {
    return {
      ...state,
      isLeadTasksLoading: true,
      activeleadID: id,
    }
  },
  [actions.TASKS_FOR_LEAD_LOAD_SUCCESS]: (state, { id }) => {
    return {
      ...state,
      isLeadTasksLoading: false,
      activeleadID: id,
    }
  },
  [actions.TASKS_FOR_LEAD_LOAD_FAILURE]: (state) => ({
    ...state,
    isLeadTasksLoading: false,
    activeleadID: null,
  }),
  [actions.TASKS_LOAD_FAILURE]: (state, {total = 0}) => ({
    ...state,
    total,
    isLoading: false,
  }),
  [actions.TASKS_SELECTION_TOGGLE_PLURAL]: (state, { ids, all }) => ({
    ...state,
    selection: {
      ids,
      all,
    },
  }),
  [actions.TASKS_LEAVE]: () => initialState,
  [actions.TASKS_DROP_SEARCH]: (state) => ({
    ...state,
    taskIds: [],
    total: null,
    // aggs: null,
    selection: {
      all: false,
      ids: {},
    }
  }),
  [actions.TASKS_SET_TOP_FILTER]: (state, { topFilters }) => ({
    ...state,
    topFilters: {
      ...topFilters,
    },
  }),
  [actions.TASKS_LIVE_UPDATE]: (state, { lead }) => {
    if (state.taskIds.indexOf(lead.id) !== -1) return state;

    return {
      ...state,
      taskIds: [lead.id, ...state.taskIds],
      total: state.total + 1,
      aggs: state.aggs && {
        ...state.aggs,
      },
    }
  },
  [actions.TASKS_SET_URL_FILTERS]: (state, { topFilters }) => ({
    ...state,
    topFilters,
  }),
  [actions.TASKS_SET_EXECUTE]:(state, {task}) => {

    return {
      ...state,
      tasksIsActive: true,
      currentTask: task,
      total: state.total - 1 <= 0 ? 0 : state.total - 1,
    };
  },
  [actions.TASKS_SET_CURRENT_TASK]:(state, {task}) => ({
    ...state,
    currentTask: task,
  }),
  [actions.TASKS_SET_IS_ACTIVE]:(state, {task}) => ({
    ...state,
    tasksIsActive: true,
  }),
  [actions.TASKS_FINISH]:(state) => {

    return {
      ...state,
      tasksIsActive: false,
      currentTask: null,
    };
  },
}

const initialTopFilters = {
  taskType: actions.TASKS_TYPES.Manual,
  action: 0,
}

const initialState = {
  isLoading: false,
  isLeadTasksLoading: false,
  activeleadID: null,
  taskIds: [],
  topFilters: initialTopFilters,
  total: null,
  aggs: null,
  selection: {
    all: false,
    ids: {},
  },
  activityType: {},
  tasksIsActive: false,
  currentTask: null,
}

const reducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

const key = 'tasksPage';

export {
  reducer as default,
  key,
  initialTopFilters,
}
