import { __DEV__ } from 'services/env';
import { default as flowsPath } from '../path';
import { ISortedTemplateItem } from './interfaces';
import avatarAuthor from 'assets/author.jpeg';

const flowTemplatesPath = `${flowsPath}/templates`;



const importAll = (r: __WebpackModuleApi.RequireContext) => {
  let images: {[key: string]: any} = {};
  r.keys().forEach((fileName: string) => {
    images[fileName.replace('./', '')] = r(fileName)
  })

  return images;
}


const flow_previews: {[key: string]: any} = importAll(require.context('../../../../../assets/flow_templates', false));


export const templateCategories = [
  {
    name: 'BRAND AWARENESS',
    templatesList: [
      {
        label: 'Grow your network',
        preview: flow_previews['templates-4341272_120578.svg'].default,
        linkTo: `${flowTemplatesPath}/7ab42cb9-7280-4264-adf5-a6f431b836cf/public`,
        borderColor: '#FF7A59',
        aboutTemplate: {
          description: `
          <p>
          Automate your LinkedIn connection requests.
          If you haven't used automation to grow your network of business contacts, this is a great Flow to start with.
          <p>
          The Flow will send connection requests to leads and create additional
          activity (page visit + like a post) while waiting for the application to be approved,
          which has a positive effect on the conversion to approvals.
          All unapproved applications will be automatically withdrawn 14 days after submission.
          </p>
          `,
          author: {
            name: 'Victor Dol',
            position: 'B2B Lead Generation & Conversion Expert',
            avatar: avatarAuthor,
            description: 'Digital Marketing and Lead Generation expert whose strategy development and team leadership have created successful International B2B campaigns that have elevated brand awareness and adoption.'
          }
        }
      },
      {
        label: 'Attract your leads',
        preview: flow_previews['templates-4341271_120582.svg'].default,
        linkTo: `${flowTemplatesPath}/4883e1b9-4952-4351-86ae-2b66fdf0d1cb/public`,
        borderColor: '#FF7A59',
        aboutTemplate: {
          description: `
          <p>
          Attract leads to your LinkedIn page.
          Automate social activity on your lead pages to draw their attention to your profile and useful content within it.
          <p>
          The Flow immediately creates three types of automatic activities for everyone you send to this Flow:
          page visit + like the last post + comment on the last post, with small delays between them, as if it was done manually.
          </p>
          `,
          author: {
            name: 'Victor Dol',
            position: 'B2B Lead Generation & Conversion Expert',
            avatar: avatarAuthor,
            description: 'Digital Marketing and Lead Generation expert whose strategy development and team leadership have created successful International B2B campaigns that have elevated brand awareness and adoption.'
          }
        }
      }
    ]
  },
  {
    name: 'CUSTOMER INTERVIEW',
    templatesList: [
      {
        label: 'Get lead\'s interviews',
        preview: flow_previews['templates-4341274_120573.svg'].default,
        linkTo: `${flowTemplatesPath}/659ab38b-068b-44d2-89b6-bed5c5e41357/public`,
        borderColor: '#FF7A59',
        aboutTemplate: {
          description: `
          <p>
          It is important to check hypotheses for every new product or service about who the customer is and what the problem is that they are ready to pay to solve it.
          Use this Flow to automate your lead's invitations for interviews.
          </p>
          <p>The Flow will send connection requests to leads and interview invitation messages.</p>
          `,
          author: {
            name: 'Victor Dol',
            position: 'B2B Lead Generation & Conversion Expert',
            avatar: avatarAuthor,
            description: 'Digital Marketing and Lead Generation expert whose strategy development and team leadership have created successful International B2B campaigns that have elevated brand awareness and adoption.'
          }
        }
      },
      {
        label: 'Get lead\'s reward interviews',
        preview: flow_previews['templates-4341275_120581.svg'].default,
        linkTo: `${flowTemplatesPath}/37229836-a255-4929-bb77-9515ddf15abc/public`,
        borderColor: '#FF7A59',
        aboutTemplate: {
          description: `
          <p>
          It is important to check hypotheses for every new product or service about who the customer
          is and what the problem is that they are ready to pay to solve it. Offer to pay the respondent
          for the spent time in order to speed up the interview receipt.
          Use this flow to automate your leads' invitations to paid interviews.
          </p>
          <p>The Flow will send connection requests to leads and messages inviting paid interviews.</p>
          `,
          author: {
            name: 'Victor Dol',
            position: 'B2B Lead Generation & Conversion Expert',
            avatar: avatarAuthor,
            description: 'Digital Marketing and Lead Generation expert whose strategy development and team leadership have created successful International B2B campaigns that have elevated brand awareness and adoption.'
          }
        }
      },
      {
        label: 'Research SaaS Product Market',
        preview: flow_previews['templates-4341277_120577.svg'].default,
        linkTo: `${flowTemplatesPath}/d8a05e35-da54-4079-a0bc-f267561060df/public`,
        borderColor: '#FF7A59',
        aboutTemplate: {
          description: `
          <p>
          Promotion of products or services to new markets is not a simple task,
          since there are many questions about the market specifics and how valuable your offer will be on it.
          It will be useful to use the Flow to automate mailing to market experts and get answers from them about your product.
          </p>
          <p>The Flow will send connection requests to leads and messages asking for expert opinions.</p>
          `,
          author: {
            name: 'Victor Dol',
            position: 'B2B Lead Generation & Conversion Expert',
            avatar: avatarAuthor,
            description: 'Digital Marketing and Lead Generation expert whose strategy development and team leadership have created successful International B2B campaigns that have elevated brand awareness and adoption.'
          }
        }
      }
    ]
  },
  {
    name: 'SALES',
    templatesList: [
      {
        label: 'Promote a new product or service',
        preview: flow_previews['templates-4341270_120574.svg'].default,
        linkTo: `${flowTemplatesPath}/38f159d3-58fa-4a68-95dc-4468c3cb3f8e/public`,
        borderColor: '#FF7A59',
        aboutTemplate: {
          description: `
          <p>
          Creating hype around a new product launch is not a simple task,
          therefore it can be useful to use the Flow to send connection requests
          and messages to LinkedIn and parallel emails before the launch.
          You can indicate the benefits and functions of this new product in your email and LinkedIn messages.
          You can also use this workflow to promote an existing product.
          </p>
          <p>
          The Flow will send connection requests and messages with your value proposition to leads,
          and will also organize emails in parallel. Everyone who replies to your messages will be switched to manual mode.
          </p>
          `,
          author: {
            name: 'Victor Dol',
            position: 'B2B Lead Generation & Conversion Expert',
            avatar: avatarAuthor,
            description: 'Digital Marketing and Lead Generation expert whose strategy development and team leadership have created successful International B2B campaigns that have elevated brand awareness and adoption.'
          }
        }
      },
      {
        label: 'Get Sales SaaS',
        preview: flow_previews['templates-4341278_120575.svg'].default,
        linkTo: `${flowTemplatesPath}/eeb4d8ac-1c54-4994-905b-b706c1a9ffe6/public`,
        borderColor: '#FF7A59',
        aboutTemplate: {
          description: `
          <p>
          Use this Flow to automate your LinkedIn emails and promote your SaaS solutions.
          Fill out the message template, describe your SaaS solution by indicating its
          benefits and use cases that clients will receive if they choose your solution.
          </p>
          <p>
          The Flow will send connection requests to leads and messages with your value proposition.
          Everyone who replies to your messages will be switched to manual mode.
          </p>
          `,
          author: {
            name: 'Victor Dol',
            position: 'B2B Lead Generation & Conversion Expert',
            avatar: avatarAuthor,
            description: 'Digital Marketing and Lead Generation expert whose strategy development and team leadership have created successful International B2B campaigns that have elevated brand awareness and adoption.'
          }
        }
      },
      {
        label: 'Get Sales Services 1',
        preview: flow_previews['templates-4341275_120581.svg'].default,
        linkTo: `${flowTemplatesPath}/6d65c6d4-91d9-4912-8a5c-c4b251fd5867/public`,
        borderColor: '#FF7A59',
        aboutTemplate: {
          description: `
          <p>
          Use this Flow to automate your LinkedIn newsletters and market promotion of your services.
          Fill out the message templates by describing the services you provide,
          indicating what benefits the client will receive from collaboration with you.
          </p>
          <p>
          The Flow will send connection requests to leads and messages with your value proposition.
          Everyone who replies to your messages will be switched to manual mode.
          </p>
          `,
          author: {
            name: 'Victor Dol',
            position: 'B2B Lead Generation & Conversion Expert',
            avatar: avatarAuthor,
            description: 'Digital Marketing and Lead Generation expert whose strategy development and team leadership have created successful International B2B campaigns that have elevated brand awareness and adoption.'
          }
        }
      },
      {
        label: 'Get Sales Services 2',
        preview: flow_previews['templates-4341275_120581.svg'].default,
        linkTo: `${flowTemplatesPath}/b3221ab9-d156-4d19-8aba-6fa2c98041d5/public`,
        borderColor: '#FF7A59',
        aboutTemplate: {
          description: `
          <p>
          Use this Flow to automate your LinkedIn newsletters and market promotion of your services.
          Fill out the message templates by describing the services you provide,
          indicating what benefits the client will receive from collaboration with you.
          </p>
          <p>
          The Flow will send connection requests to leads and messages with your value proposition.
          Everyone who replies to your messages will be switched to manual mode.
          </p>
          `,
          author: {
            name: 'Victor Dol',
            position: 'B2B Lead Generation & Conversion Expert',
            avatar: avatarAuthor,
            description: 'Digital Marketing and Lead Generation expert whose strategy development and team leadership have created successful International B2B campaigns that have elevated brand awareness and adoption.'
          }
        }
      },
      {
        label: 'Get Sales Outsource / Outstaff',
        preview: flow_previews['templates-4341277_120577.svg'].default,
        linkTo: `${flowTemplatesPath}/9f2ccec5-9c76-41d5-8bdc-ece6685de07b/public`,
        borderColor: '#FF7A59',
        aboutTemplate: {
          description: `
          <p>
          Use this flow to automate your LinkedIn newsletters and promote services on Outsource or Outstaff.
          Fill out the message templates by describing the services you provide,
          indicating what benefits the client will receive from collaboration with you.
          </p>
          <p>
          The Flow will send connection requests to leads and messages with your value proposition.
          Everyone who replies to your messages will be switched to manual mode.
          </p>
          `,
          author: {
            name: 'Victor Dol',
            position: 'B2B Lead Generation & Conversion Expert',
            avatar: avatarAuthor,
            description: 'Digital Marketing and Lead Generation expert whose strategy development and team leadership have created successful International B2B campaigns that have elevated brand awareness and adoption.'
          }
        }
      }
    ]
  },
  {
    name: 'INVESTMENT',
    templatesList: [
      {
        label: 'Establish potential investors relationships',
        preview: flow_previews['templates-4341275_120581.svg'].default,
        linkTo: `${flowTemplatesPath}/a4350e2e-b6b3-4201-9a2d-af8487020a56/public`,
        borderColor: '#FF7A59',
        aboutTemplate: {
          description: `
          <p>
          Looking for potential investors? Use this automated Flow to send out and attract investors for your solutions.
          <p>
          The Flow will send connection requests to leads and messages with your offer.
          Everyone who replies to your messages will be switched to manual mode.
          </p>
          `,
          author: {
            name: 'Victor Dol',
            position: 'B2B Lead Generation & Conversion Expert',
            avatar: avatarAuthor,
            description: 'Digital Marketing and Lead Generation expert whose strategy development and team leadership have created successful International B2B campaigns that have elevated brand awareness and adoption.'
          }
        }
      }
    ]
  },
  {
    name: 'RE-ENGAGE',
    templatesList: [
      {
        label: 'Re-engage inactive leads',
        preview: flow_previews['templates-4341269_120580.svg'].default,
        linkTo: `${flowTemplatesPath}/bc70c057-8c98-4931-97b4-dc8a38e7b532/public`,
        borderColor: '#FF7A59',
        aboutTemplate: {
          description: `
          <p>
          Re-engagement Flows target your inactive leads.
          These are leads that may have once been strong candidates to become your client or customer.
          But they fell out halfway or at the stage of awareness.
          <p>
          Create a regular weekly newsletter that you can send to the Flow to re-engage this audience.
          Your first follow-up email might be about any recent product or service updates.
          Or you can tell them about lower prices or deals. You can also share your brand story to sound sentimental.
          </p>
          `,
          author: {
            name: 'Victor Dol',
            position: 'B2B Lead Generation & Conversion Expert',
            avatar: avatarAuthor,
            description: 'Digital Marketing and Lead Generation expert whose strategy development and team leadership have created successful International B2B campaigns that have elevated brand awareness and adoption.'
          }
        }
      }
    ]
  },
]

export const sortedTemplates = templateCategories.reduce((acc: ISortedTemplateItem[], item) => {
  acc.push(...item.templatesList);
  return acc;
},[]);

export const defaultTemplateItems = [...sortedTemplates.slice(0,6), {
  label: 'More Templates',
  icon: 'ellipsis',
  linkTo: sortedTemplates[0].linkTo
}];


