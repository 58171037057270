import * as actions from './consts'
import * as contactsActions from '../contacts/consts'
import find from 'lodash/find';
import getAxios from 'services/axios'
import forEach from 'lodash/forEach'
import { createCachedFunction } from "../utils";
import store from 'store/store'
import { getContactById } from '../contacts/selectors';
import { getFlowById } from './selectors';

const axios = getAxios('flow');

const { flowStatuses, flowServerStatuses } = actions;

export const loadFlowEnums = createCachedFunction(
  () => axios.get('/api/system/config')
).cachedFunction;

export const loadNodes = createCachedFunction(() => (dispatch, getState) => {
  return axios.get('/api/flows/nodes')
    .then( res => res.flow_nodes)
}, {recursively: true, clearTimer: 30000})
  .cachedFunction;

const cacher = createCachedFunction((params, cancelToken) => (dispatch, getState) => {
  return axios.get('/api/flows', {
    cancelToken,
    params,
  })
    .then( res => {
      dispatch({
        type: actions.FLOWS_LOAD_SUCCESS,
        flows: res.data,
      })

      return res;
    })
}, {recursively: true})

// export const loadFlows = cacher.cachedFunction;
export const loadFlows = (params, cancelToken) => (dispatch, getState) => {
  return axios.get('/api/flows', {
    cancelToken,
    params,
  })
    .then( res => {
      dispatch({
        type: actions.FLOWS_LOAD_SUCCESS,
        flows: res.data,
      })

      return res;
    })
}

export const loadFlow = (id) => (dispatch, getState) => {
  return axios.get(`/api/flows/${id}`)
    .then( flow => {
      // transformSteps(flow);
      setFirstId(flow);

      dispatch({
        type: actions.FLOW_LOAD_SUCCESS,
        flow,
      })
      return flow;
    })
}

export const loadPublicFlow = (id) => (dispatch) => {
  return axios.get(`/api/flows/${id}/share`)
}

export const copyFlow = ({name, nodes}) => (dispatch) => {
  return axios.put(`/api/flows/duplicate`,  {name, nodes})
    .then( flow => {
      dispatch({
        type: actions.FLOW_LOAD_SUCCESS,
        flow,
      })

      return flow;
    })
}

export const createPublicLink = (id) => (dispatch) => {
  return axios.put(`/api/flows/${id}/share-link`)
    .then( flow => {
      dispatch({
        type: actions.FLOW_LOAD_SUCCESS,
        flow,
      })

      return flow;
    })
}

export const getFlowOptions = (query) => store.dispatch(loadFlows({}))
  .then(({ data }) => data.reduce((res, flow) => {
      if (RegExp(query, 'i').test(flow.name)) {
        res.push({value: flow.id, label: flow.name});
      }

      return res;
    }, [])
  );

export const createFlow = (params) => (dispatch, getState) => {
  return axios.post(`/api/flows`, params)
    .then( flow => {
      cacher.clearCache();
      window.dataLayer.push({'event': 'flow-process-created'});
      dispatch({
        type: actions.FLOW_CREATE_SUCCESS,
        flow,
      });

      return flow
    })
}

export const moveStep = (flowId, nodeId, {before, after, version}) => (dispatch) => {
  return axios.put(`/api/flows/${flowId}/nodes/${nodeId}/move`, {before, after, version})
    .then( step => {
      dispatch({
        type: actions.FLOW_STEP_NEW_SUCCESS,
        flowId,
        step,
      })

      return step;
    })
}

export const createStep = (flowId, data) => (dispatch, getState) => {
  return axios.post(`/api/flows/${flowId}/nodes`, data)
    .then( step => {
      dispatch({
        type: actions.FLOW_STEP_NEW_SUCCESS,
        flowId,
        step,
      })

      return step;
    })
}

export const editStep = (flowId, stepId, data) => (dispatch, getState) => {
  dispatch({
    type: actions.FLOW_STEP_EDIT_REQUEST,
    flowId,
    stepId,
    data,
  })

  const oldData = find(getState().entities.flows[flowId].nodes, {id: stepId});

  return axios.put(`/api/flows/${flowId}/nodes/${stepId}`, data)
    .then( step => {
      dispatch({
        type: actions.FLOW_STEP_EDIT_SUCCESS,
        step,
      })

      return step;
    })
    .catch( error => {
      dispatch({
        type: actions.FLOW_STEP_EDIT_FAILED,
        flowId,
        stepId,
        oldData,
      })

      return Promise.reject(error);
    })
}

export const updateFlow = (flowId, params) => (dispatch, getState) => {
  return axios.put(`/api/flows/${flowId}`, params)
    .then( flow => {
      cacher.clearCache();
      dispatch({
        type: actions.FLOW_UPDATE_SUCCESS,
        flow,
      });

      return flow
    })
}

export const deleteFlow = (flowId) => (dispatch) => {
  return axios.delete(`/api/flows/${flowId}`)
    .then( () => dispatch({
      type: actions.FLOW_DELETE_SUCCESS,
      flowId,
    }))
}

export const deleteStep = (flowId, stepId) => (dispatch, getState) => {
  const flow = getState().entities.flows[flowId];
  const after = find(flow.nodes, {id: stepId}).after;
  let after_branch_id;
  if (after.length) {
    after_branch_id = after[after.length - 1].branch_id;
  }

  return axios.delete(`/api/flows/${flowId}/nodes/${stepId}`, {params: {after_branch_id, version: flow.version}})
    .then( step => dispatch({
      type: actions.FLOW_STEP_DELETE_SUCCESS,
      step,
    }))
}

export const startFlow = (flowId) => (dispatch) => {
  return axios.put(`/api/flows/${flowId}/start`)
    .then( res => {
      window.dataLayer.push({'event': 'flow-process-started'});
      dispatch({
        type: actions.FLOW_STATUS_CHANGE_SUCCESS,
        status: flowServerStatuses[flowStatuses.ACTIVE],
        flowId,
      })
    })
}

export const stopFlow = (flowId) => (dispatch) => {
  return axios.put(`/api/flows/${flowId}/stop`)
    .then( res => {
      dispatch({
        type: actions.FLOW_STATUS_CHANGE_SUCCESS,
        status: flowServerStatuses[flowStatuses.IDLE],
        flowId,
      })
    })
}

/////////// utils ///////////////
const setFirstId = (flow) => {
  forEach(flow.nodes, step => {
    if (flow.firstId) return;

    if (!step.before.length) {
      flow.firstId = step.id;
    }
  })

  if (!flow.firstId) {
    throw 'Invalid flow steps: No firstId';
  }
}

const transformSteps = (flow) => {
  flow.nodes = flow.nodes.reduce((res, step) => {
    res[step.id] = step;
    return res;
  }, {})
}

const setContactsToFlow = ({filter, flow, sync, include_coworkers}) => (dispatch, getState) => {
  const state = getState();
  const flowItem = getFlowById(state, flow);

  const contacts = filter.ids.map(id => {
    const contact = getContactById(state, {id});

    if (contact && Array.isArray(contact.flows) && flow) {
      contact.flows.push(flowItem);
    }

    return contact;
  });

  return axios.put(`/api/flows/${flow}/leads`, {filter, flow, sync, include_coworkers})
    .then(() => dispatch({
      type: contactsActions.SEED_CONTACTS,
      contacts,
    }))
}

const deleteContactsFromFlow = ({filter, flow, include_coworkers}) => (dispatch, getState) => {
  const contacts = filter.ids.map(id => {
    const contact = {...getContactById(getState(), {id})};

    if (contact && Array.isArray(contact.flows)) {
      const theFlow = contact.flows.find(theFlow => flow == theFlow.id);
      if (theFlow) {
        theFlow.status = 'cancelled';
      }
      // contact.flows = contact.flows.filter((flowItem) => flowItem.id !== flow);
    }

    return contact;
  });

  return axios.delete(`/api/flows/${flow}/leads`, {data: {filter, flow, include_coworkers}})
    .then(() => dispatch({
      type: contactsActions.SEED_CONTACTS,
      contacts,
    }))
}

export {
  setContactsToFlow,
  deleteContactsFromFlow,
}
