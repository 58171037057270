import * as actions from "./consts";
import getAxios from 'services/axios'
import { createCachedFunction } from "../utils";
import store from 'store/store'

const axios = getAxios('contact');

const cacher = createCachedFunction(() => (dispatch, getState) => {
  return axios.get(`/api/tags`)
    .then( tags => {
      dispatch({
        type: actions.TAGS_GET_SUCCESS,
        tags,
      })

      return tags;
    })
}, {recursively: true})

export const loadTags = cacher.cachedFunction;
export const clearTagsCache = () => cacher.clearCache();

export const getTagsOptions = (query) => store.dispatch(loadTags())
  .then(tags => tags.reduce((res, tag) => {
    if (RegExp(query, 'i').test(tag.name)) {
      res.push({value: tag.id, label: tag.name});
    }

    return res;
    }, []));

export const deleteTag = (id) => (dispatch, getState) => {
  return axios.delete(`/api/tags/${id}`)
    .then( res => {
      cacher.clearCache(); // надо ли??
      dispatch({
        type: actions.TAG_DELETE_SUCCESS,
        id,
      })

      return res;
    })
}

export const createTag = (params) => (dispatch) => {
  return axios.post(`/api/tags`, params)
    .then( tag => {
      console.log('new tag', tag)
      cacher.clearCache();
      dispatch({
        type: actions.TAG_CREATE_SUCCESS,
        tag,
      })

      return tag;
    })
}

export const quickTagCreate = name => store.dispatch(createTag({name}));
