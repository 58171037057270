import { NavLink } from 'react-router-dom';
import { INavigationCategoryProps } from '../../../interfaces';

export const NavigationCategory = ({categoryName, templates, className}: INavigationCategoryProps) => {
  return (
    <div className={className}>
      <h4>{categoryName}</h4>
      {templates.map(({ label, linkTo }) => {
        return (
          <NavLink
            key={label}
            className={isActive =>
              "nav-link" + (!isActive ? " unselected" : "")
            }
            style={isActive => ({
              backgroundColor: isActive ? "black" : "transparent",
              color: isActive ? "white" : "#253342",
            })}
            to={linkTo}
          >
            {label}
          </NavLink>
        )
      })}
    </div>
  )
}
