import * as actions from './consts'
import { entitySetter, deleteByIds } from '../utils'

const ACTION_HANDLERS = {
  [actions.BUYER_PERSONAS_SET]: (state, { personas }) => entitySetter(state, personas),
  [actions.BUYER_PERSONAS_DELETE]: (state, { ids }) => deleteByIds(state, ids),
}

const initialState = {}

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
