import React from 'react';
import Loadable from "react-loadable";
import Loading from 'components/common/Loading';
export { default as path } from "./path"


export default Loadable({
  loader: () => import(/* webpackChunkName:'ChromePluginGroup' */ './SalesNavigatorCompanies'),
  loading: Loading,
});
