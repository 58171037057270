import React, { useState, useEffect } from "react";

const transformBoolToString = (value) => {
  if (typeof value !== "boolean") return value;

  return value ? 'Yes' : 'No';
}

const DefaultValue = ({field, payload}) => {
  const [value, setValue] = useState(payload[field.key]);
  useEffect(
    () => {
      if (!field.options) return;

      field.options({payload})
        .then(entities => entities.find(e => e.value === value))
        .then(({label}) => setValue(label))
        .catch(e => console.log('DefaultValue err', e))
    },
    [value],
  )
  useEffect(
    () => setValue(payload[field.key]),
    [payload[field.key]],
  )

  return <span> { transformBoolToString(value)} </span>;
}

export default DefaultValue
