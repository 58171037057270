import React, { useState } from 'react';
import Spinner from "components/common/elements/Spinner";
import Icon from "components/common/elements/Icon";
import { showError } from "services/utils";
import {  AttachmentsPopover } from "./AttachmentsPopover";
import { uploadAttachment } from 'store/entities/attachments/actions';
import { useDispatch } from 'react-redux';

import styles from './Attachments.module.scss'

const Attachments = ({ value, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const select = (attach) => {
    onChange([...value, {id: attach.id, name: attach.payload.name}]);
  }

  const uploadAttachments = (file) => {
    if (!file) return;
    setIsLoading(true);

    dispatch(uploadAttachment(file))
      .then(attach => {
        select(attach)
        setIsLoading(false);
      })
      .catch(e => {
        setIsLoading(false);
        showError(e, 'Failed to load file. Please try again later')
      })
  }

  const handleRemove = (index) => {
    const newVal = [...value];
    newVal.splice(index, 1);

    onChange(newVal);
  }

  return (
    <div className={styles.container}>
      {value.map((value, i) => (
        <div key={value.id} className={styles.file}>
          <span className={styles.fileName}> { value.name } </span>
          <Icon icon="Cross" onClick={() => handleRemove(i)} className={styles.cross}/>
        </div>
      ))}

      <AttachmentsPopover className={styles.label} upload={uploadAttachments} select={select}>
        Attach File
      </AttachmentsPopover>
      {isLoading && <Spinner/>}
    </div>
  )
}

export default Attachments;
