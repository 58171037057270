export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_ACCOUNT_STATUS = 'SET_ACCOUNT_STATUS';

export const statuses = {
  ACTIVE: 'active',
  PENDING: 'pending',
}
