import React from 'react'
// import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from '../Styles.module.scss'

const Input = (props) => {
  const {
    additionalInfo,
    isError,
    isSuccess,
    isShortInput,
    inputClassName=styles.Input,
    className = '',
    radius,
    type='text',
    withValidation = true,
    ...other
  } = props;

  return (
    <div
      className={cn(
        styles.inputWrapper,
        className,
        {
          [styles.AdditionalInfo]: additionalInfo,
          [styles.Error]: isError,
          [styles.Success]: isSuccess,
          [styles.ShortInput]: isShortInput,
          [styles.inputWrapperRaduis]: radius,
        }
      )}
      data-additional-info={additionalInfo}
    >
      <input
        className={cn(
          inputClassName,
          {
            [styles.InputRaduis]: radius,
          }
        )}
        type={type}
        {...other}
      />
     {/*{ withValidation &&*/}
     {/*<svg viewBox="0 0 16 16" className={styles.validateIcon}>*/}
     {/*  <path d="M 15,8 C 15,4 12,1 8,1 4,1 1,4 1,8 c 0,4 3,7 7,7 4,0 7,-3 7,-7"></path>*/}
     {/*  <polyline points="5 8.375 7.59090909 11 14.5 4" transform='translate(0 -0.5)'></polyline>*/}
     {/*  <g transform="matrix(0.00230733,0,0,0.00230733,-6.8141241,-6.7820192)">*/}
     {/*    <path d="m 8875.7137,3967.8702 87.6352,87.6351 c 104.1141,104.1137 103.3653,274.89 -0.7489,379.0038 l -2055.3083,2045.5712 2056.0572,2065.795 c 103.3653,104.1137 103.3653,274.8901 -1.4979,379.0036 l -87.6351,86.8862 c -104.1133,104.1136 -274.8898,103.3647 -379.0036,-0.7489 L 6439.9038,6945.2213 4384.5952,8990.7926 c -104.8628,104.1137 -275.6392,103.3647 -379.0038,-0.7489 l -87.6351,-88.3844 c -104.1136,-104.1136 -103.3647,-274.8899 0.749,-378.2547 L 5974.0138,6477.8333 3938.9288,4433.011 c -103.3647,-104.8627 -103.3647,-274.89 1.498,-379.0037 l 87.6353,-87.6351 c 104.1136,-104.1137 274.8899,-103.3648 378.2547,0.7488 L 6442.1508,6012.6925 8497.4593,3967.121 c 104.1138,-104.1136 274.8899,-104.1136 378.2544,0.7492 z"/>*/}
     {/*  </g>*/}
     {/*</svg>*/}
     {/*}*/}
    </div>
  )
}

export {
  Input as default,
  Input,
}
