import * as actions from './consts';
import getAxios from 'services/axios';
import jwtService from "services/jwt";
import { setUsers } from "store/entities/users/actions";
import { identifyUser, unsetUserId, logEvent } from "services/analytics.js";
import { setMailboxes } from "../entities/mailboxes/actions";
import { paddleStatuses } from "../entities/subscriptions/consts";

const axios = getAxios('id')

const analyticsSetUser = (user) => {
  const paddle_customer_id = user.paddle_customer_id || user.subscription?.paddle_customer_id
  if (paddle_customer_id && window.profitwel) {
    window.profitwell('start', { 'user_id': paddle_customer_id });
  }
  identifyUser(user.id, {email: user.email});

  if (window.hj) {
    window.hj("identify", user.id, {
      email: user.email,
      created_at: user.created_at,
      subscription_id: user.subscription_id,
    });
  }
  if (window.zE) {
    window.zE(function () {
      window.zE.identify({
        name: [user.first_name, user.last_name].filter(Boolean).join(" "),
        email: user.email,
      });
    });
  }

  if (window.Intercom) {
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "zwqq10ut",
      name: [user.first_name, user.last_name].filter(Boolean).join(" "),
      email: user.email,
      created_at: user.created_at,
    });
  }
}

export const signIn = (params) => (dispatch) => {
  jwtService.removeToken();

  dispatch({type: actions.LOGIN_REQUEST});
  params.scopes = 'gs';

  return axios.post('/auth/sign-in/', params)
    .then(() => dispatch(getUser()))
    .then( user => {
      dispatch(setUsers(user));
      dispatch({
        type: actions.LOGIN_SUCCESS,
        id: user.id,
      })
      analyticsSetUser(user);
      logEvent('UserLoggedIn', {}, true)

      return user;
    })
    .catch( error => {
      dispatch({type: actions.LOGIN_FAILURE})

      throw error;
    });
};

export const signUp = (params) => (dispatch) => {
  jwtService.removeToken();
  params.scopes = 'gs'

  return axios.post('/auth/sign-up/', params)
    .then(() => dispatch(getUser()))
    .then( user => {
      window.dataLayer?.push({'event': 'sign-up'});
      dispatch(setUsers(user));
      dispatch({
        type: actions.SIGNUP_SUCCESS,
        id: user.id,
      });
      analyticsSetUser(user);

      return user;
    });
};

export const loginAs = (id) => (dispatch) => {
  return axios.get(`/account/team/${id}/loginAs`)
    .then(() => window.location.reload())
    .catch(() => window.location.reload())
}

export const logout = () => (dispatch) => {
  return axios.post('/auth/revoke')
    .then( response => {
      jwtService.removeToken();
      dispatch({type: actions.LOGOUT_SUCCESS})
      unsetUserId();
      logEvent('UserLoggedOut', {}, true)

      return response;
    })
};

export const getUser = () => (dispatch) => {
  return axios.get('/account')
    .then( user => {
      dispatch(setUsers(user));
      dispatch({
        type: actions.LOGIN_SUCCESS,
        id: user.id,
      });
      analyticsSetUser(user);
      if ( user.mailbox) {
        dispatch(setMailboxes(user.mailbox));
      }

      return user;
    })
    .catch( err => {
      dispatch({type: actions.LOGIN_FAILURE});
      throw err;
    })
}

export const waitForSubscription = ({maxAttempts = 10, interval = 3000} = {}) => (dispatch) => {
  let attempt = 1;

  const check = ({resolve, reject}) => {
    if (attempt > maxAttempts) {
      return reject();
    }
    attempt++;
    dispatch(getUser())
      .then(user => {
        if (user.subscription && [paddleStatuses.ACTIVE, paddleStatuses.TRIALING].includes(user.subscription.paddle_status)) {
          resolve();
        } else {
          if (attempt > maxAttempts) {
            return reject();
          }
          setTimeout(() => check({resolve, reject}), interval)
        }
      })
  }

  return new Promise((resolve, reject) => check({resolve, reject}))
}

export const resetPasswordRequest = (email) => (dispatch) => {
  return axios.post('/password/email', {email})
    .then( res => {

      return res;
    });
}

export const resetPassword = ({email, password, token}) => (dispatch) => {
  return axios.post('/password/reset', {
    email,
    password,
    password_confirmation: password,
    reset_token: token,
  })
    .then( res => {
      return dispatch(getUser());
    });
}

export const sendInvCode = (inv) => (dispatch, getState) => {
  return axios.put('/invitation-tokens/close', {inv})
    .then( res => {
      console.log('sendInvCode res', res);
      dispatch({
        type: actions.SET_ACCOUNT_STATUS,
        status: res.status,
        userId: getState().auth.id,
      })
    })
}
