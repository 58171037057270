import * as actions from './consts';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import { CONVERSATIONS_SET } from "../conversations/consts";

const entitySetter = (state, entities) => {
  const s = {...state};

  if (!Array.isArray(entities)) {
    entities = [entities];
  }

  entities.forEach( (entity) => {
    const generatedActivity = (s[entity.id] && s[entity.id].activity || [])
      .filter(a => a._generated);

    s[entity.id] = {
      ...s[entity.id],
      ...entity,
    }
    s[entity.id].activity = [...s[entity.id].activity, ...generatedActivity]
  });

  return s;
}

const ACTION_HANDLERS = {
  [CONVERSATIONS_SET]: (state, { conversations }) => entitySetter(state, conversations),
  [actions.CONTACTS_ADD_ACTIVITY]: (state, {leadId, activity}) => {
    if (!state[leadId]) return state;
    if (state[leadId].activity.find(theActivity => {
      return (
        theActivity.type === activity.type
        && Object.keys(activity.data).every(key => theActivity.data[key] == activity.data[key])
      )
    })) {
      // update
      return state;
    }

    const s = {...state};
    s[leadId] = {...s[leadId]};
    s[leadId].activity = [activity, ...s[leadId].activity];

    return s;
  },
  [actions.CONTACTS_CANCEL_ACTIVITY]: (state, {leadId, messageId}) => {
    if (!state[leadId]) return state;

    const s = {...state};
    s[leadId] = {...s[leadId]};
    s[leadId].activity = s[leadId].activity.filter(({data}) => data.message_id !== messageId);

    return s;
  },
  [actions.SEED_CONTACTS]: (state, { contacts }) => entitySetter(state, contacts),
  [actions.CONTACTS_DELETE_SUCCESS]: (state, { loadedSelection }) => {
    const s = {...state};

    for (const id of loadedSelection) {
      delete s[id];
    }

    return s;
  },
  [actions.CONTACTS_SET_LIST_SUCCESS]: (state, { ids, all, list, loadedIds }) => {
    const s = {...state};
    const idsObj = ids.reduce( (obj, id ) => { obj[id] = true; return obj}, {});
    loadedIds.forEach(id => {
      if (!s[id]) return;

      if ( all != !idsObj[id] ) return;

      s[id] = {
        ...s[id],
        list,
      }
    })

    return s;
  },
  [actions.CONTACTS_SET_STAGE_PLURAL_SUCCESS]: (state, { ids, all, stage, loadedIds }) => {
    const s = {...state};
    const idsObj = ids.reduce( (obj, id ) => { obj[id] = true; return obj}, {});
    loadedIds.forEach(id => {
      if (!s[id]) return;

      if ( all != !idsObj[id] ) return;

      s[id] = {
        ...s[id],
        stage,
      }
    })

    return s;
  },
  [actions.CONTACTS_SET_ASSIGN_PLURAL_SUCCESS]: (state, { ids, all, assign_to, loadedIds }) => {
    const s = {...state};
    const idsObj = ids.reduce( (obj, id ) => { obj[id] = true; return obj}, {});
    loadedIds.forEach(id => {
      if (!s[id]) return;

      if ( all != !idsObj[id] ) return;
      s[id] = {
        ...s[id],
        assign_to,
      }
    })

    return s;
  },
  [actions.CONTACTS_SET_TAGS_SUCCESS]: (state, { ids, all, tags, loadedIds }) => {
    const s = {...state};
// TODO: тут, конечно, можно сэкономить очень много спичек, но нах
    const idsObj = ids.reduce( (obj, id ) => { obj[id] = true; return obj}, {});
    loadedIds.forEach(id => {
      if (!s[id]) return;

      if ( all != !idsObj[id] ) return;

      s[id] = {
        ...s[id],
        tags: (s[id].tags.concat(tags)),
      }
    })

    return s;
  },
  [actions.CONTACTS_DELETE_TAGS_SUCCESS]: (state, { ids, all, tags, loadedIds }) => {
    const s = {...state};

    const idsObj = ids.reduce( (obj, id ) => { obj[id] = true; return obj}, {});
    loadedIds.forEach(id => {
      if (!s[id]) return;

      if ( all != !idsObj[id] ) return;

      s[id] = {
        ...s[id],
        tags: difference(s[id].tags, tags),
      }
    })

    return s;
  },
  [actions.CONTACTS_SET_STAGE_SUCCESS]: (state, {id, stage}) => ({
    ...state,
    [id]: {
      ...state[id],
      stage,
    },
  }),
  [actions.CONTACTS_ASSIGN_SUCCESS]: (state, {leadId, userId}) => ({
    ...state,
    [leadId]: {
      ...state[leadId],
      assigned_to: userId,
    },
  }),
  [actions.CONTACTS_SET_NETWORK_TYPE_SUCCESS]: (state, {leadId, networkType}) => ({
    ...state,
    [leadId]: {
      ...state[leadId],
      company: {
        ...state[leadId].company,
        network_type: networkType,
      },
    },
  }),
  [actions.CONTACTS_MESSAGES_READ]: (state, { leadId }) => ({
    ...state,
    [leadId]: {
      ...state[leadId],
      markers: {
        ...state[leadId].markers,
        dm_user_unread_count: 0,
      },
    },
  }),
  [actions.CONTACTS_MARK_UNREAD]: (state, { leadId }) => ({
    ...state,
    [leadId]: {
      ...state[leadId],
      markers: {
        ...state[leadId].markers,
        dm_user_unread_count: 1,
      },
    },
  }),
};

const initialState = {
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
