import React from 'react'
import Loadable from 'react-loadable';
import Loading from 'components/common/Loading'
import { Switch, Route } from 'react-router-dom'
import RouteWithSubRoutes from 'components/common/RouteWithSubRoutes/RouteWithSubRoutes'
import NotFoundRoute from "../../NotFound";
import path from "./path";
export { default as path } from "./path"

const EmailTemplatesDetailsPage = Loadable({
  loader: () => import(/* webpackChunkName:'EmailTemplatesDetailsPage' */ './Details/container'),
  loading: Loading,
});

const EmailTemplatesListPage = Loadable({
  loader: () => import(/* webpackChunkName:'EmailTemplatesListPage' */ './List/container'),
  loading: Loading,
});

export default () => (
  <Switch>
    <Route exact path={`${path}/:id`} component={EmailTemplatesDetailsPage} />
    <Route exact path={path} component={EmailTemplatesListPage} />

    <Route component={NotFoundRoute} /> {/* 404 */}
  </Switch>
)
