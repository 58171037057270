import React, { useState, useCallback } from 'react';
import find from 'lodash/find';
import cn from 'classnames';
import Icon from 'components/common/elements/Icon';
import Modal from 'components/common/Modal/Modal'
import nodeTypes, { getNodeType } from "store/entities/flows/model/nodeTypes";
import ChooseType from "./ChooseType";
import ActionTab from "./ActionTab";
import PayloadTab from "./PayloadTab";

import styles from './NodeForm.module.scss'

const tabs = {
  TYPE: 'TYPE',
  ACTION: 'ACTION',
  PAYLOAD: 'PAYLOAD',
}

const NodeFrom = ({ node, model, setFocusedNodeId, onSubmit, onDelete, description }) => {
  const nodeType = getNodeType(node);
  const serverNode = find(model.flow.nodes, {id: node.serverNodeId});
  const [tab, setTab] = useState(nodeType === nodeTypes.REAL ? tabs.PAYLOAD : tabs.TYPE);
  const [type, setType] = useState(serverNode && serverNode.type);
  const [action, setAction] = React.useState(serverNode && serverNode.action)

  const onTypeSelect = useCallback((type) => {
    setType(type);
    setTab(tabs.ACTION);
  }, []);

  const onActionSelect = useCallback((action) => {
    setTab(tabs.PAYLOAD);
    setAction(action);
  }, []);

  return (
    <div className={cn(styles.container, {
      [styles.hidden]: tab === tabs.PAYLOAD,
    })}
         onMouseDown={ (e) => e.stopPropagation() }
    >
      {tab !== tabs.PAYLOAD && (
        <div onClick={() => setFocusedNodeId(null)} className={styles.close}>
          <Icon icon="Cross" color="#425B76"/>
        </div>
      )}

      { tab === tabs.TYPE && <ChooseType setType={onTypeSelect}/> }
      { tab === tabs.ACTION && <ActionTab type={type} setAction={onActionSelect} description={description}/> }
      <Modal
        isOpen={tab === tabs.PAYLOAD}
        closeModal={() => setFocusedNodeId(null)}
        className="gs-modal"
        overlayClassName="gs-modal__overlay"
      >
        <PayloadTab
          type={type}
          uiNode={node}
          serverNode={serverNode}
          back={() => setTab(tabs.TYPE)}
          cancel={() => setFocusedNodeId(null)}
          onSubmit={onSubmit}
          onDelete={onDelete}
          action={action}
          setAction={setAction}
          model={model}
          description={description}
        />
      </Modal>

    </div>
  )
}

export default NodeFrom
