import React from 'react'
import PropTypes from 'prop-types'
import { fieldTypes } from "../../../../reducer/utils/description";

import DelayInput from './inputs/DelayInput'
import CheckInput from './inputs/CheckInput'
import SelectInput from './inputs/SelectInput'
import TextInput from './inputs/TextInput'
import NumberInput from './inputs/NumberInput'
import RadioInput from './inputs/RadioInput'
import SnippetInput from './inputs/Snippet'
import EmailInput from './inputs/EmailInput'
import AbInput from './inputs/AbInput'
import FiltersRule from './inputs/FiltersRule'
import QualificationInput from './inputs/QualificationInput'

const OptionInputFacade = (props) => {
  const { field, value, onChange, _payload, ...other } = props;
  const isViewMode = false;

  if (field.cond && !field.cond(_payload)) {
    return null;
  }

  switch (field.type) {
    case fieldTypes.DELAY: {
      return <DelayInput readOnly={isViewMode} {...props}/>
    }
    case fieldTypes.CHECK: {
      return <CheckInput readOnly={isViewMode} {...props}/>
    }
    case fieldTypes.SELECT: {
      return <SelectInput readOnly={isViewMode} {...props}/>
    }
    case fieldTypes.TEXT: {
      return <TextInput readOnly={isViewMode} className="gs-input" {...props}/>
    }
    case fieldTypes.NUMBER: {
      return <NumberInput readOnly={isViewMode} className="gs-input" {...props}/>
    }
    case fieldTypes.RADIO: {
      return <RadioInput readOnly={isViewMode} {...props}/>
    }
    case fieldTypes.SNIPPET: {
      return <SnippetInput readOnly={isViewMode} {...props} />
    }
    case fieldTypes.EMAIL: {
      return <EmailInput readOnly={isViewMode} {...props} />
    }
    case fieldTypes.AB: {
      return <AbInput readOnly={isViewMode} {...props}/>
    }
    case fieldTypes.FILTERS: {
      return <FiltersRule readOnly={isViewMode} {...props}/>
    }
    case fieldTypes.QUALIFICATION: {
      return <QualificationInput readOnly={isViewMode} {...props}/>
    }
    case fieldTypes.COMPOSITE: {
      return (
        <>
          { field.fields.map(nestedField => (
            <OptionInputFacade
              key={nestedField.key}
              field={nestedField}
              value={value?.[nestedField.key]}
              onChange={newValue => onChange({
                ...value,
                [nestedField.key]: newValue,
              })}
              _payload={_payload}
              {...other}
            />
          ))}
        </>
      )
    }
    default: {
      return 'unknown field type'
    }
  }

}

OptionInputFacade.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
}

export default OptionInputFacade
