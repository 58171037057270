import { useState, useEffect } from "react";

const start = +new Date();
const check = () => {
  const now = +new Date();

  if (now - start < 10000) {
    setTimeout(() => {
      if (window._isGsClientPluginColumn) {
        window.dataLayer.push({'event': 'chrome-extension-installed'});
        Object.values(subs).forEach(cb => cb())
        document.body.classList.add('gs-column-plugin')
      } else {
        check();
      }
    }, 250);
  }
};
const subs = {};
let nextId = 1;

check();
const useIsColumnPlugin = () => {
  const [isPlugin, setIsPlugin] = useState(!!window._isGsClientPluginColumn);

  useEffect(() => {
    if (isPlugin) return;

    const id = ++nextId;
    subs[id] = () => setIsPlugin(true);

    return () => delete subs[id];
  }, []);

  return isPlugin;
}

export default useIsColumnPlugin
