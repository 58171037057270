import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class OutsideClick extends PureComponent {
  constructor({ capture }) {
    super();

    this.capture = capture;
    this.handler = this.handler.bind(this);
    this.ref = React.createRef();
  }

  componentDidMount() {
    document.getElementById('root').addEventListener('click', this.handler, {capture: this.capture});
  }

  componentWillUnmount() {
    document.getElementById('root').removeEventListener('click', this.handler, {capture: this.capture});
  }

  handler(event) {
    const thisDomElem = this.ref.current;

    for (let element = event.target; element; element = element.parentNode) {
      if (element === thisDomElem) {
        return;
      }
    }

    this.props.outsideClick(event)
  }

  render() {
    const { children } = this.props;
    return children({ ref: this.ref });
  }
}

OutsideClick.propTypes = {
  outsideClick: PropTypes.func.isRequired,
  capture: PropTypes.bool,
};

export default OutsideClick
