import * as actions from "./consts";
import getAxios from 'services/axios'
import { createCachedFunction } from "../utils";

const axios = getAxios('contact');

export const loadFlowSources = (params) => (dispatch, getState) => {
  return axios.get(`/api/data-sources`, {params})
    .then( res => {
      dispatch({
        type: actions.FLOW_SOURCES_GET_SUCCESS,
        flowSources: res.data,
      })

      return res;
    })
};

export const loadFlowSource = (id) => (dispatch, getState) => {
  return axios.get(`/api/data-sources/${id}`)
    .then( flowSource => {
      dispatch({
        type: actions.FLOW_SOURCES_GET_SUCCESS,
        flowSources: [flowSource],
      })

      return flowSource;
    })
};

export const loadDataSourcesByList = (listId) => (dispatch) => {
  return axios.get(`/api/lists/${listId}/data-sources`)
    .then(res => {
      dispatch({
        type: actions.FLOW_SOURCES_GET_SUCCESS,
        flowSources: res,
      });

      return res;
    });
}

export const deleteFlowSource = (id) => (dispatch, getState) => {
  return axios.delete(`/api/data-sources/${id}`)
    .then( res => {
      const ds = getState().entities.flowSources[id];

      dispatch({
        type: actions.FLOW_SOURCES_DELETE_SUCCESS,
        id,
        listId: ds && ds.list_id,
      })

      return res;
    })
}

export const createFlowSource = (params) => (dispatch) => {
  return axios.post(`/api/data-sources`, params)
    .then( flowSource => {
      console.log('new flowSource', flowSource)
      dispatch({
        type: actions.FLOW_SOURCES_CREATE_SUCCESS,
        flowSource,
      })

      return flowSource;
    })
}

export const updateFlowSource = (id, params) => (dispatch) => {
  return axios.put(`/api/data-sources/${id}`, params)
    .then( flowSource => {
      console.log('update flowSource', flowSource)
      dispatch({
        type: actions.FLOW_SOURCES_CREATE_SUCCESS,
        flowSource,
      })

      return flowSource;
    })
}

export const switchFlowSource = (id, params) => (dispatch) => {
  return axios.put(`/api/data-sources/${id}/switch-status`, params)
    .then( flowSource => {
      console.log('switch flowSource', flowSource)
      dispatch({
        type: actions.FLOW_SOURCES_CREATE_SUCCESS,
        flowSource,
      })

      return flowSource;
    })
}



export const clearSearchUrls = (data) => {
  delete data.page;
  delete data.searchSessionId;
  delete data.preserveScrollPosition;
  delete data.doFetchHeroCard;
  delete data.logHistory;
  delete data.rsLogId;
  // delete data.companyTimeScope;
}
