import Loadable from "react-loadable";
import { injectReducer } from "store/reducers";
import store from "store/store";
import { Loading } from "components/common/Loading";
import Error404 from "components/routes/NotFound";

const LeadLists = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName:'LeadLists' */ './LeadLists');

    return import(/* webpackChunkName:'LeadLists' */ './reducer')
      .then( reducer => injectReducer(store, { key: reducer.name, reducer: reducer.default }) )
      .then( () => component )
      .catch(e => console.log('PAGE LOADING ERROR', e) || Error404)
  },
  loading: Loading,
  delay: 300, // 0.3 seconds
  timeout: 4000, // 4 seconds
});

export default LeadLists;
export {default as path} from './path'
