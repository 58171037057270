import * as actions from './consts'
import { entitySetter } from '../utils'
import { immuteDelete, immuteSet } from "services/immuteActions";

const ACTION_HANDLERS = {
  [actions.FLOWS_LOAD_SUCCESS]: (state, {flows = []}) => {
    return entitySetter(state, flows);
  },
  [actions.FLOW_UPDATE_SUCCESS]: (state, {flow}) => {
    return entitySetter(state, flow);
  },
  [actions.FLOW_LOAD_SUCCESS]: (state, {flow}) => {
    return entitySetter(state, flow);
  },
  [actions.FLOW_CREATE_SUCCESS]: (state, {flow}) => {
    return entitySetter(state, flow);
  },
  [actions.FLOW_STEP_NEW_SUCCESS]: (state, {flowId, step}) => {
    const s = {...state};
    s[flowId] = {...s[flowId]};
    s[flowId].nodes = {...s[flowId].nodes};
    s[flowId].nodes[step.id] = step

    return s;
  },
  [actions.FLOW_DELETE_SUCCESS]: (state, {flowId}) => immuteDelete(state, flowId.toString()),
  [actions.FLOW_STATUS_CHANGE_SUCCESS]: (state, { flowId, status }) => immuteSet(state, `${flowId}.status`, status),
}

const initialState = {
}

export default function flowReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
