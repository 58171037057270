import { combineReducers } from 'redux'

import contactsReducer from './contacts'
import emailTemplatesReducer from './emailTemplates/reducer'
import flowsReducer from './flows/reducer'
import { flowStatisticsReducer } from './flowStatistics/reducer'
import listsReducer from './lists/reducer'
import mailboxesReducer from './mailboxes/reducer'
import usersReducer from './users/reducer'
import integrationsReducer from './integrations/reducer'
import schedulesReducer from './schedules/reducer'
import flowFoldersReducer from './flowFolders/reducer'
import tagsReducer from './tags/reducer'
import hooksReducer from './hooks'
import segmentsReducer from './segments'
import cardsReducer from './cards'
import plansReducer from './plans'
import subscriptions from './subscriptions'
import snippets from './snippets'
import invites from './invites'
import flowSources from './flowSources'
import leadsStatuses from './leadsStatuses'
import messages from './messages'
import tasks from './tasks'
import conversations from './conversations'
import buyerPersonas from './buyerPersonas'
import emails from './emails'

// ------------------------------------
// В алфовитном порядке плеазе
// ------------------------------------
export default combineReducers({
  buyerPersonas,
  cards: cardsReducer,
  contacts: contactsReducer,
  conversations,
  emails,
  emailTemplates: emailTemplatesReducer,
  flowFolder: flowFoldersReducer,
  flows: flowsReducer,
  flowSources,
  flowStatistics: flowStatisticsReducer,
  hooks: hooksReducer,
  integrations: integrationsReducer,
  invites,
  leadsStatuses,
  lists: listsReducer,
  mailboxes: mailboxesReducer,
  messages,
  plans: plansReducer,
  schedules: schedulesReducer,
  segments: segmentsReducer,
  snippets,
  subscriptions,
  tags: tagsReducer,
  users: usersReducer,
  tasks,
});
