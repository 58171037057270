import * as consts from './consts'
import { entitySetter } from '../utils'
import { messageStatuses } from './model/enums'

const ACTION_HANDLERS = {
  [consts.MESSAGES_SET]: (state, { messages }) => {
    let s = {...state};

    s = entitySetter(s, messages);

    return s;
  },
  [consts.MESSAGE_RETRY]: (state, { messageId }) => {
    const s = {...state}

    s[messageId] = {
      ...s[messageId],
      status: messageStatuses.IN_PROGRESS,
    }

    return s;
  },
  [consts.MESSAGE_CANCEL]: (state, { messageId }) => {
    const s = {...state}

    s[messageId] = {
      ...s[messageId],
      status: messageStatuses.CANCELED,
    }

    return s;
  },
}

const initialState = {
}

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
