// import { injectReducer } from "store/reducers";
// import store from "store/store";
import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";

const AddCard = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName: 'AddCard' */ './container');

    return component
    // return import (/* webpackChunkName: 'AddCard' */ './reducer')
    //   .then( (reducer) => injectReducer(store, { key: reducer.stateName, reducer: reducer.default }) )
    //   .then( () =>  component)
      .catch(e => console.log('PAGE LOADING ERROR', e) || Error404)
  },
  loading: Loading,
})

export default AddCard

export const path = '/add-card'
