import find from 'lodash/find';
import recalculate from "../recalculate";


export default (model, nodeId, targetPlusNodeId) => {
  const node = find(model.nodes, {id: nodeId});
  const targetPlusNode = find(model.nodes, {id: targetPlusNodeId});
  const nodeAfterTargetPlus = find(model.nodes, {id: targetPlusNode.after[0]});
  const plusNodeBefore = find(model.nodes, {id: node.before[0]});
  const plusNodeAfter = find(model.nodes, {id: node.union || node.after[0]});
  const afterAfterNode = find(model.nodes, {id: plusNodeAfter.after[0]});

  nodeAfterTargetPlus.before = nodeAfterTargetPlus.before.map(id => {
    if (id === targetPlusNode.id) return  plusNodeAfter.id;
    if (id === plusNodeAfter.id) return  plusNodeBefore.id;

    return id;
  })
  if (afterAfterNode.id !== nodeAfterTargetPlus.id) {
    afterAfterNode.before = afterAfterNode.before.map(id => id === plusNodeAfter.id ? plusNodeBefore.id : id);
  }
  node.before = [targetPlusNode.id]
  plusNodeBefore.after = [...plusNodeAfter.after];
  plusNodeAfter.after = [...targetPlusNode.after];
  targetPlusNode.after = [node.id];

  recalculate(model);
}
