import React from 'react';
import WhoopsPage from '../Whoops';
import Preloader from 'components/common/elements/Preloader';
import { LongTime } from './LongTime';

const Loading = ((props) => {
  if (props.error) {
    return WhoopsPage;

  } else if (props.timedOut) {
    return <LongTime retry={ props.retry }/>;

  } else if (props.pastDelay) {
    return (
      <Preloader
        {...props}
      />
    );
  } else {
    return null;
  }
});

export {
  Loading as default,
  Loading
}
