import React from 'react'
import { Switch, Route } from 'react-router-dom'
import DetailsRoute from './Details'
import ListRoute from './List'
import PublicFlow, { subPath as publicRouteSubPath} from "./PublicFlow";
import path from './path'
import NotFoundRoute from "../../NotFound";
export { default as path } from "./path";

export default() => (
  <Switch>
    <Route exact path={`${path}`} component={ListRoute} />
    <Route exact path={`${path}/templates/:templateId?/public`} component={ListRoute} />
    <Route exact path={`${path}/:publicId${publicRouteSubPath}`} component={PublicFlow} />
    <Route exact path={`${path}/:id/:subRoute?`} component={DetailsRoute} />
    <Route component={NotFoundRoute} /> {/* 404 */}
  </Switch>
)
