import sse, { SSE } from "services/serverSideEvents";
import store from 'store/store'
import { ALL_ACTIVITIES } from "./consts"
import { addActivity } from "./actions";
import { MESSAGES_SET } from "../messages/consts";


const setupLeadsLiveEventHandler = () => {
  sse.listen((events => {
    events
      .filter((event) => event.type == SSE.eventTypes.MESSAGE)
      .map(event => ({
        message: event.message,
        leadId: event.message.lead_id,
        activity: {
          created_at: event.message.created_at,
          data: {
            conversation_id: event.message.social_conversation_id,
            message_id: event.message.id,
          },
          type: event.message.type === "inbox"
            ? ALL_ACTIVITIES.SOCIAL_DM_REPLIED
            : ALL_ACTIVITIES.SOCIAL_DM_SENT,
        }
      }))
      .forEach(({leadId, activity, message}) => {
        store.dispatch({
          type: MESSAGES_SET,
          messages: message,
        })
        store.dispatch(addActivity({leadId, activity}))
      })
  }));
}

export default setupLeadsLiveEventHandler;
