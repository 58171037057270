import Loadable from "react-loadable";
import Loading from 'components/common/Loading';

import Error404 from 'components/routes/NotFound';

const PluginTasks = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'PluginTasks' */ './PluginTasks')
      .catch( e => console.log('Page loading err', e) || Error404 );
  },
  loading: Loading,
});

export { default as path } from "./path";
export default PluginTasks;
