export const SUP_SIGNUP_REQUEST = 'SUP_SIGNUP_REQUEST';
export const SUP_SIGNUP_SUCCESS = 'SUP_SIGNUP_SUCCESS';
export const SUP_SIGNUP_FAILURE = 'SUP_SIGNUP_FAILURE';

export const SUP_SET_FIELD = 'SUP_SET_FIELD';

export const SUP_CHANGE_TAB = 'SUP_CHANGE_TAB';
export const SUP_CHANGE_TAB_FINISHED = 'SUP_CHANGE_TAB_FINISHED';

export const SUP_SET_SUBMITED = 'SUP_SET_SUBMITED';

export const TABS = {
  SIGN_UP: 'SIGN_UP',
  ABOUT_YOU: 'ABOUT_YOU',
  PLANS: 'PLANS',
}
