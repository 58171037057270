import { connect } from 'react-redux'
import Component from './Navigation'
import { isMailboxError, getCurrentUserServer } from "store/entities/users/selectors";

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return {
    isMailboxError: isMailboxError(state),
    currentUserServer: getCurrentUserServer(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
