import React from "react";
import cn from 'classnames';
import Icon from "../../elements/Icon";

import styles from './ModalHeader.module.scss'

const ModalHeader = ({header, onClose, className = ""}) => (
  <div className={cn(styles.container, className)}>
    <div className={styles.title}>
      { header }
    </div>

    <div
      onClick={onClose}
      className={styles.closeOuter}
    >
      <Icon
        icon="Cross"
        size={20}
        color="#516F90"
        className={styles.closeInner}
      />
    </div>
  </div>
)

export default ModalHeader
