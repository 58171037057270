import { __DEV__ } from "services/env";
import config from './config.json';

export const dev  = config.dev;
export const prod  = config.prod;
export const appConfig = config;
export const mobileWidth = 800;
export default (__DEV__ ? dev : prod);

export const firebaseConfig = {
    apiKey: "AIzaSyAkdBip4ZMISbenS4LK2q4LXZMP3g4vqXc",
    authDomain: "getsales-io-web.firebaseapp.com",
    projectId: "getsales-io-web",
    storageBucket: "getsales-io-web.appspot.com",
    messagingSenderId: "1043924910578",
    appId: "1:1043924910578:web:ee40390e5abb604a426b6b",
    measurementId: "G-YPT38Q920D"
  };
  