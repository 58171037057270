import React from 'react'
import Toggle from 'components/common/inputs/Toggle'

import styles from './CheckInput.module.scss'

const CheckInput = (props) => {
  const { field, value, onChange, readOnly } = props;

  const valuesMap = {
   true: field.trueValue ?? true,
   false: field.falseValue ?? false,
  }

  return (
    <label className={styles.container}>
      <Toggle onChange={(newValue) => onChange(valuesMap[newValue])} value={value === valuesMap.true} disabled={readOnly} />

      <span className={styles.label}> { field.label } </span>
    </label>
  )
}

export default CheckInput
