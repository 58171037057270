import * as actions from './consts'

import getAxios from 'services/axios'
import {createCachedFunction} from "../utils";

const axios = getAxios('flow');

const cacher = createCachedFunction((params, cancelToken) => (dispatch, getState) => {
  return axios.get('/api/schedules', {
    params,
    cancelToken,
  })
    .then( res => {
      dispatch(setSchedules(res.data))

      return res;
    })
}, {recursively: true})

export const setSchedules = (schedules) => {
  if (!(schedules instanceof Array)) {
    schedules = [schedules];
  }
  return {
    type: actions.SCHEDULES_SET,
    schedules,
  }
}

export const loadSchedules = cacher.cachedFunction;

export const loadSchedule = (id) => (dispatch, getState) => {
  return axios.get(`/api/schedules/${id}`)
    .then( schedule => {
      dispatch(setSchedules(schedule))

      return schedule;
    })
}

export const createSchedule = (params) => (dispatch, getState) => {
  return axios.post(`/api/schedules`, params)
  .then( schedule => {
    cacher.clearCache();
    dispatch(setSchedules(schedule))

    return schedule;
  })
}

export const updateSchedule = (id, params) => (dispatch, getState) => {
  return axios.put(`/api/schedules/${id}`, params)
    .then( schedule => {
      cacher.clearCache();
      dispatch(setSchedules(schedule))

      return schedule;
    })
}

export const deleteSchedule = (id) => (dispatch, getState) => {
  return axios.delete(`/api/schedules/${id}`)
    .then( res => {
      cacher.clearCache();
      dispatch({
        type: actions.SCHEDULES_DELETE_SUCCESS,
        id,
      })

      return res;
    })
}
