import React from "react";
import NodeStatistics from "../RealNode/NodeStatistics/NodeStatistics";

import styles from './CircleNode.module.scss'

const CircleNode = ({ color, node, model }) => {
  return <React.Fragment>
    { node && <React.Fragment>
      <div className={styles.name}>
        <span className={styles.orange}> #{node.serverNodeId} </span>
        End
        <NodeStatistics node={node} model={model}/>
      </div>

    </React.Fragment>}
    <div className={styles.symbol} style={{backgroundColor:color}}/>
  </React.Fragment>;
}

export default CircleNode;
