import { injectReducer } from "store/reducers";
import store from "store/store";
import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";


const LoginPage = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName: 'LoginPage' */ './container');

    return import(/* webpackChunkName: 'LoginPage' */ './reducer')
      .then( (reducer) => injectReducer(store, { key: 'loginPage', reducer: reducer.default }) )
      .then( () => component )
      .catch(e => console.log('PAGE LOADING ERROR', e) || Error404)
  },
  loading: Loading,
})

export default LoginPage
