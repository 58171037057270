import FieldPreview from "../FieldPreview";

const CompositeValue = ({field, payload}) => {
  return (
    <>
      { field.fields.map(subField => (
        <FieldPreview
          key={subField.key}
          field={subField}
          payload={payload[field.key]}
        />
      )) }
    </>
  );
}

export default CompositeValue
