import React, { PureComponent } from 'react'
import cn from 'classnames'
import Input from 'components/common/inputs/Input'

import styles from '../Styles.module.scss'

class TextRow extends PureComponent {
  constructor() {
    super();

    this.state = {
      dirty: false,
      touched: false,
    }

    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }
  onChange(e) {
    if (e.target.value && !this.state.dirty) {
      this.setState({dirty: true});
    }
    this.props.onChange(e);
  }
  onBlur() {
    const { onBlur } = this.props;
    const { dirty, touched } = this.state;

    if (dirty && !touched) {
      this.setState({touched: true});
    }

    if (onBlur) {
      onBlur();
    }
  }

  render() {
    let {
      rowClassName,
      style,
      label,
      labelDescription,
      type = "text",
      error,
      isSubmitted,
      required,
      ...other
    } = this.props;

    const { dirty, touched } = this.state;

    const isError = (isSubmitted || dirty && touched) && error;
    // надо сделать, чтобы можно было много ошибок проказывать
    // а еще надо, чтоб error мог быть функцией валидации, возвращающий массив ошибок
    if (Array.isArray(error)) {
      error = error[0];
    }

    return (
      <label className={cn(styles.gsFormRow, rowClassName)} style={style}>
        { label && (
          <div className={styles.label}>
            <span className={cn({[styles.required]: required})}>
              { label }
            </span>
            <p>{labelDescription}</p>
          </div>
        )}
        <Input
          type={type}
          {...other}
          onBlur={this.onBlur}
          onChange={this.onChange}
        />
        { isError &&
          // Здесь надо сделать массив ошибок, а не одну
          <span className="gs-text _error _c-danger gs-input--label__error">
            { error }
          </span>
        }
      </label>
    )
  }
}

TextRow.propTypes = {
};

export default TextRow;
