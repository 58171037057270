import axios from 'axios'
import history from 'services/browserHistory'
import appConfig from 'config';
import { path as addCardPath } from "components/routes/authGroup/AddCard";
import jwtService from './jwt'

const endPoints = appConfig.endPoints;

const interceptorSuccessCb = response => {
  // const newToken = response.headers.authorization

  // if (newToken) {
  //   jwtService.setToken(newToken);
  //   // localStorage.setItem('token', newToken);
  //   // axios.defaults.headers.common['Authorization'] = newToken;
  // }
  return response.data
}

let isRefreshing = false;
let refreshSubscribers = [];

function onRrefreshed(newToken) {
  refreshSubscribers.map(cb => cb(newToken));
  refreshSubscribers = [];
}

const interceptorFailureCb = err => {
  if (!err.response) {
    return Promise.reject(err);
  }

  const originalRequest = err.config;

  switch (err.response.status) {
    case 401:
      console.log('401', err.response.data)
      if (err.response.data && (err.response.data.error.reason == 'token_expired') ) {
        return new Promise((resolve, reject) => {
          if (!isRefreshing) {
            isRefreshing = true;
            jwtService.refreshToken()
              .then( (newToken) => {
                isRefreshing = false;
                onRrefreshed(newToken);
              })
              .catch( () => reject(err.response.data));
          }

          refreshSubscribers.push( (token) => {
            originalRequest.headers['Authorization'] = token;
            resolve(axios(originalRequest).then( r => r.data ));
          });
        });
      } else {
        // MainLayout запушит на логин если надо
        // history.push('/login')
        jwtService.removeToken();
        // localStorage.removeItem('token');
      }
      break;
    case 402:
      // need payment
      history.push(addCardPath);
      return Promise.reject(err.response);
      break;
    case 403:
      break;
  }


  if(typeof err.response.data === 'string') {
    return Promise.reject(err.response.data)
  }

  err.response.data = {
    responseCode: err.response.status,
    data: err.response.data.error,
  }
  return Promise.reject(err.response.data);
}

export const createInstanceGetter = () => {
  const instances = {};

  const createAxiosInstance = (type) => {
    const axiosInstance = axios.create({
      baseURL: endPoints[type],
      withCredentials: true,
      crossDomain: true,
    });

    //axiosInstance.defaults.headers.common['Authorization'] = jwtService.getToken();//localStorage.getItem('token');
    axiosInstance.interceptors.response.use(interceptorSuccessCb, interceptorFailureCb);
    //jwtService.subscribe( newToken => axiosInstance.defaults.headers.common['Authorization'] = newToken )

    return axiosInstance;
  }

  return (type) => {
    if (!instances[type]) {
      instances[type] = createAxiosInstance(type);
    }

    return instances[type];
  }
}

export default createInstanceGetter()

export const createSource = () => axios.CancelToken.source()
