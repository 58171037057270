import * as constants from './consts'

const ACTION_HANDLERS = {
  [constants.S_SOCPROF_LEAVE_PAGE]: () => initialState,
  [constants.S_SOCPROF_SET_PROFILE]: (state, { profile }) => ({
    ...state,
    profile,
  }),
  [constants.S_SOCPROF_GET_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [constants.S_SOCPROF_GET_SUCCSES]: (state, { profile }) => ({
    ...state,
    profile,
    isLoading: false,
    error: null,
  }),
  [constants.S_SOCPROF_GET_FAILURE]: (state, error) => ({
    ...state,
    error,
    isLoading: false,
  }),
  [constants.S_SOCPROF_SUBMIT]: (state) => ({
    ...state,
    isSubmitted: true,
  }),
  [constants.S_SOVPROF_SET_SERVER]: (state, {server}) => ({
    ...state,
    server,
  }),
  [constants.S_SOVPROF_NO_SERVER]: (state) => ({
    ...state,
    isServerAvailable: false,
  }),
  [constants.S_SOVPROF_DISCONNECT_LN]: (state) => ({
    ...state,
    profile: null,
    server: null,
  }),
};

const initialState = {
  error: '', // TODO: толком не используется
  isLoading: true,
  isSubmitted: false,
  profile: null,
  server: null,
  isServerAvailable: true,
};

export default (state = initialState, action) =>  {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}

export const key = 'socialProfilePage'
