import React, { PureComponent } from 'react'
import cn from 'classnames'
import SelectBox from 'components/common/inputs/SelectBox'

import styles from '../Styles.module.scss'

class SelectRow extends PureComponent {
  componentDidMount(){
    const { value, options, disableAutoFill, onChange, simpleValue } = this.props;

    if (value == null && !disableAutoFill && options && options[0]) {
      let val = this.props.options[0];

      // simpleValue dont work in react-select 2
      // if (simpleValue) val = val.value;

      onChange(val)
    }
  }

  render() {
    const {
      rowClassName,
      label,
      extra,
      error,
      required,
      withoutErrorBLock,
      ...other
    } = this.props;

    return (
      <div className={cn(styles.gsFormRow, {[rowClassName]: rowClassName})}>
        <div className={styles.labelRow}>
          { label && (
            <label className={cn({[styles.required]: required})}>
              { label }
            </label>
          )}
          { extra }
        </div>
        <SelectBox {...other} />
        { error && (
            <label className="gs-text _error _c-danger gs-input--label__error">
              { error }
            </label>
        )}
        { (!error && !withoutErrorBLock) && <label className="gs-input--label__error"/> }
      </div>
    )
  }
}

SelectRow.propTypes = {
};

export {
  SelectRow as default,
  SelectRow
};
