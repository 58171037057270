import * as actions from './consts'
import { entitySetter } from '../utils'

const ACTION_HANDLERS = {
  [actions.FLOW_FOLDER_SET]: (state, { flowFolders }) => entitySetter(state, flowFolders),
}

const initialState = {};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
