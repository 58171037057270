import store from 'store/store'
import { injectReducer } from 'store/reducers'
import Loadable from 'react-loadable';
import Loading from "components/common/Loading";
import Error404 from 'components/routes/NotFound'

const ScheduleDetailsPage = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName: 'ScheduleDetailsPage' */ './container');

    return import(/* webpackChunkName: 'ScheduleDetailsPage' */ './reducer')
      .then( reducer => injectReducer(store, { key: 'scheduleDetails', reducer: reducer.default }) )
      .then( () => component )
      .catch( e => console.log('Page loading err', e) || Error404 )
  },
  loading: Loading,
});

export default ScheduleDetailsPage;
