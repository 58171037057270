import React, { useRef, useEffect } from "react";
import cn from 'classnames';
import { cutHtmlTags, htmlDecode, escapeHTML } from "services/utils";
import { tabs, varsTemplate } from "../../inputs/TextEditor/Toolbar/VarsController/VarsController";

import styles from './HighlightText.module.scss'

function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
const allVars = [varsTemplate[tabs.LEAD], varsTemplate[tabs.COMPANY]]
  .flat()
  .map(theVar => theVar.value)
  .flat()
  .map(escapeRegExp)
  .join('|')

const highlight = (html) => html
  .replace(
    /(\[\[.+?]])/g,
    '<span style="background-color: #F5C26B">$1</span>',
  )
  .replace(
    RegExp(`(\\{\\{(${allVars})}})`, 'g'),
    '<span style="background-color: #DFE3EB">$1</span>',
  )

const HighlightText = ({
  value,
  onChange,
  handleSideChanges,
  onBlur,
  className,
  placeholder = 'Type to send message…',
}) => {
  const fakeInputRef = useRef();
  const textareaRef = useRef();

  useEffect(() => {
    if (value?.replace(/\s/g, '') !== textareaRef.current.textContent.replace(/\s/g, '')) {
      console.log('update content editable');
      console.log('value prop', value);
      console.log('inner html', textareaRef.current.innerHTML)
      textareaRef.current.textContent = value;
      fakeInputRef.current.innerHTML = highlight(escapeHTML(value));
    }
  }, [value]);
  // можно и по красивее решить
  useEffect(() => {
    if (!handleSideChanges) return;

    handleSideChanges.current = handleInput;
  })

  const handlePaste = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text');
    // const pastedData = cutHtmlTags(clipboardData.getData('text/html')) || clipboardData.getData('text');
    // const pastedData = cutHtmlTags(clipboardTextData);

    const selection = window.getSelection();
    if (!selection.rangeCount) return false;
    selection.deleteFromDocument();
    const textNode = document.createTextNode(pastedData);
    selection.getRangeAt(0).insertNode(textNode);
    // onChange(cutHtmlTags(textareaRef.current.innerHTML));
    onChange(htmlDecode(cutHtmlTags(textareaRef.current.innerHTML)));
    window.getSelection().collapse(textNode, pastedData.length);

    fakeInputRef.current.innerHTML = highlight(textareaRef.current.innerHTML)
  }
  const handleInput = (e) => {
    const html = textareaRef.current.innerHTML;
    const text = textareaRef.current.textContent; // не содержит переносы строк!!
    onChange(htmlDecode(cutHtmlTags(html)));
    // onChange(text);

    fakeInputRef.current.innerHTML = highlight(html)
  }

  return (
    <div className={cn(styles.textareaContainer, className)}>
      <div className={styles.fakeInput} ref={fakeInputRef} />
      <div
        onPaste={handlePaste}
        onInput={handleInput}
        contentEditable
        ref={textareaRef}
        className={styles.textarea}
        placeholder={placeholder}
        onScroll={e => fakeInputRef.current.scrollTo(0, e.target.scrollTop)}
        onBlur={onBlur}
      />
    </div>
  )
}

export {
  HighlightText as default,
  HighlightText,
};
