import { Redirect, Route, Switch } from 'react-router-dom'

import Navigation from './Navigation'
import User, { path as userPath} from './User'
import Integrations, { path as integrationsPath} from './Integrations'
import Team, { path as teamPath} from './Team'
import Schedules, { path as schedulesPath } from './Schedules'
import SocialProfile, { path as socialPath } from './SocialProfile'
import { SubscriptionsPage, path as subscriptionsPath } from './Subscriptions'
import ChangePassword, { path as changePasswordPath } from './ChangePassword'
// import EmailAccountPage, { path as emailAccountPagePath} from './EmailAccount'
import { path as mailboxesPath, MailboxesPage } from './Mailboxes';
import NotFoundRoute from "../NotFound";
import rootPath from './path';
import styles from "./Styles.module.scss"

export { default as path } from "./path"

export default () => (
  <div className={styles.Page}>
    <Route path="/settings" component={Navigation} />

    <div className={styles.TabContent}>
      <Switch>
        <Route exact path={rootPath}>
          <Redirect to={userPath} />
        </Route>
        <Route exact path={userPath} component={User} />
        <Route path={socialPath} component={SocialProfile} />
        <Route path={subscriptionsPath} component={SubscriptionsPage} />
        <Route path={changePasswordPath} component={ChangePassword} />
        <Route path={schedulesPath} component={Schedules} />
        {/*<Route path={connectPath} component={Connect} />*/}
        <Route path={integrationsPath} component={Integrations} />
        <Route path={teamPath} component={Team} />
        <Route path={mailboxesPath} component={MailboxesPage} />
        {/* <Route path={emailAccountPagePath} component={EmailAccountPage} /> */}
        <Route component={NotFoundRoute} /> {/* 404 */}
      </Switch>
    </div>
  </div>
);

export const settingsMiddleMenu = {
  title: { label: 'Navigation', icon: 'gear', color: '#253342' },
  items: [
    {label: 'Profile', path: userPath}, //
    {label: 'Social Profile', path: socialPath}, //
    {label: 'Billing', path: subscriptionsPath}, //
    {label: 'Email Account', path: mailboxesPath}, //
    {label: 'Schedules', path: schedulesPath}, //
    {label: 'Change Password', path: changePasswordPath}, //
    {label: 'Integrations', path: integrationsPath}, //
    {label: 'Team', path: teamPath}, //
  ]
};
