import { useState, useEffect, useRef } from 'react';
import { Navigation } from './Nav';
import { AboutTemplate } from './AboutTemplate';
import { useDispatch, useSelector } from "react-redux";
import { loadPublicFlow } from "store/entities/flows/actions";
import { copyFlow } from 'store/entities/flows/actions'
import { useParams, useHistory } from 'react-router-dom';
import createModal from "components/hocs/ModalTrigger";
import Icon from 'components/common/elements/Icon'
import Button from "components/common/buttons/Button"
import Preloader from "components/common/elements/Preloader";
import FlowConstructor from 'components/routes/FlowConstructor/FlowConstructor'
import { showError, showSuccess, copyToClipboard } from "services/utils";
import { userStatuses } from "store/auth";
import { getAuthUserStatus } from 'store/auth/selectors';
import description from '../../Details/reducer/utils/description';
import { IChooseTemplateProps } from '../interfaces';
import { default as flowsPath } from '../../path';

import styles from './Modal.module.scss';

type TParams = {
  templateId: string;
}

export const ChooseTemplateContent = ({onRequestClose, onConstructorHover}: IChooseTemplateProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const flowContainer = useRef<HTMLDivElement>(null);
  const [flow, setFlow] = useState<any>(null);
  const { templateId } = useParams<TParams>();
  const [error, setError] = useState<Error | boolean>(false);
  const userStatus = useSelector(getAuthUserStatus);
  const isUnauthStatus = userStatus === userStatuses.UNAUTH;

  useEffect(() => {
    dispatch(loadPublicFlow(templateId))
      // @ts-ignore
      .then((flow) => {
        setError(false);
        setFlow(flow)
      })
      .catch((e: Error) => {
        setFlow(false);
        setError(e);
      });
  }, [templateId]);

  const handleUseTemplate = () => {
    const nodes = flow.nodes;
    const name = flow.name;

    if (flow) {
      dispatch(copyFlow({name, nodes}))
        // @ts-ignore
        .then((flow) => {
          history.replace(`${flowsPath}/${flow.id}`);
        })
        .catch(() => showError('Failed to create flow template'))
    }
  }

  const handleCopyLinkToShare = () => {
    copyToClipboard(`${window.location.origin}/flows/${templateId}/public`);
    showSuccess('Link has been copied');
  }

  const redirectToLogin = () => {
    return history.replace(`/`);
  }

  return (
    <div className={styles.Modal}>
      <div className={styles.ModalLeft}>
        <Navigation/>
      </div>
      <div className={styles.ModalRight}>
        <div
          className={styles.ModalCloseBtn}
          onClick={() => onRequestClose()}
        >
          <Icon icon="Cross" color='#516F90' size={20}/>
        </div>
        <div className={styles.ModalTopRow}>
          <Button
            onClick={isUnauthStatus ? redirectToLogin : handleUseTemplate}
          >
            Use Template
          </Button>
          <Button
            transparent
            className={styles.ModalShareTemplateBtn}
            onClick={isUnauthStatus ? redirectToLogin :handleCopyLinkToShare}
          >
            <Icon icon="chain" color='#253342' style={{marginRight: '7px'}} size={20}/>
            <span>Copy link to share</span>
          </Button>
        </div>
        <div
          onMouseEnter={() => onConstructorHover?.(true)}
          onMouseLeave={() => onConstructorHover?.(false)}
          className={styles.ModalFlow}
          ref={flowContainer}>
          {error && <p>Not found</p>}
          {!flow && !error && <Preloader />}
          {flow && (
            <FlowConstructor
              key={flow.name}
              withCopyBar={false}
              controlClass={styles.ModalFlowControls}
              scalableContainer={flowContainer.current}
              flow={flow}
              name={flow.name}
              // @ts-ignore
              description={description}
            />
          )}
        </div>
        <AboutTemplate/>
      </div>
    </div>
  )
}

export const ModalChooseTemplate = createModal(ChooseTemplateContent);

export const ModalChooseTemplateContainer = () => {
  const [hoverMode, setHoverMode] = useState<boolean>(false);
  const history = useHistory();
  let { templateId } = useParams<TParams>();

  const handleClose = () => {
    history.replace(flowsPath);
  }


  return (
    <ModalChooseTemplate
      modalClassName={styles.ModalWrap}
      onConstructorHover={setHoverMode}
      isOpen={templateId}
      onClose={handleClose}
      modalCustomStyles={{
        overlay: {
          overflow: hoverMode ? 'hidden' : '',
        }
      }}
    />
  )
}

