export default (allParents) => {
  const allMatches = allParents.map(() => []);

  for (const index in allParents) {
    const parent = allParents[index];
    for (const nodeIndex in parent) {
      if (allMatches[index].length) break;
      const nodeId = parent[nodeIndex];

      for (const anotherIndex in allParents) {
        if (anotherIndex === index) continue;

        const anotherParent = allParents[anotherIndex];
        const match = anotherParent.indexOf(nodeId);
        if (match !== -1) {
          allMatches[index].push({
            withParentIndex: +anotherIndex,
            nodeIndexHere: +nodeIndex,
            nodeIndexThere: match,
          })
        }
      }
    }
  }

  let unionIndexes;
  let root;
  main:
  for (let matchesIndex in allMatches) {
    matchesIndex = +matchesIndex;
    const matches = allMatches[matchesIndex];
    if (!matches.length) continue;
    unionIndexes = [matchesIndex];
    root = allParents[matchesIndex][matches[0].nodeIndexHere]

    for (const match of matches) {
      if (allMatches[match.withParentIndex][0].nodeIndexHere < match.nodeIndexThere) {
        continue main;
      }
      unionIndexes.push(match.withParentIndex)
    }
    break;
  }


  return {
    unionIndexes,
    root,
  }
}
