import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { serverStatuses } from "components/routes/SettingsGroup/SocialProfile/reducer/consts";
import { isMailboxError, getCurrentUserServer } from "store/entities/users/selectors";
import { useMobileDetector } from 'components/common/hooks/useMobileDetector';
import { MobileContext } from 'components/common/contexts/MobileContext';
import Icon from 'components/common/elements/Icon';
import { menuItems } from './items';

import styles from './Menu.module.scss'

const Menu = (props) => {
  const globalState = useSelector(s => s.global);
  const isMbError = useSelector(isMailboxError);
  const currentUserServer = useSelector(getCurrentUserServer);

  const {
    existMiddleMenu,
    // openMobileMiddlemenu,
    // setOpenMobileMiddlemenu
  } = props;

  const [navigationClosed, setNavigationClosed] = useState(true);
  const isMobile = useMobileDetector(MobileContext);

  const isPlugin = !!window._isGsClientPluginColumn;

  const mobileClickHandler = useCallback(() => {
    if (!isMobile) {
      return;
    }
    setNavigationClosed((prevValue) => !prevValue);
  }, [isMobile, setNavigationClosed]);

  // const openMobileMiddleMenuHandler = useCallback(() => {
  //   if (!isMobile) {
  //     return;
  //   }

  //   setOpenMobileMiddlemenu((prevValue) => !prevValue);
  // }, [isMobile, setOpenMobileMiddlemenu]);

  const items = React.useMemo(() => {
    if (!isMobile) {
      return menuItems;
    }

    return menuItems.filter((item) => item.showOnplugin)
  }, [isMobile]);

  const logoBtn = (
    <div className={styles.navigationBtn}>
      <Icon
        icon="logo_circle"
        className={cn(styles.Logo, styles.LogoSmall)}
        width={isMobile ? 36 : 60}
        height={isMobile ? 36 : 60}
      />
    </div>
  );

  return (
    <div className={styles.navigation}>

      {!isMobile && logoBtn}
      {(!existMiddleMenu && isMobile) && logoBtn}

      {/*{(existMiddleMenu && isMobile) && (*/}
      {/*  <div*/}
      {/*    className={cn(styles.navigationBtn, styles.menuBtn)}*/}
      {/*    onClick={openMobileMiddleMenuHandler}*/}
      {/*    role='button'*/}
      {/*  >*/}
      {/*    <Icon*/}
      {/*      icon="Menu"*/}
      {/*      className={cn(styles.menuBtnIcon)}*/}
      {/*      width={30}*/}
      {/*      height={30}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}

      { !isPlugin && (
        <div
          className={cn(styles.navigationList, {[styles.navigationClosed]: navigationClosed })}
          onClick={mobileClickHandler}
        >
          { items.map(({ path, label, icon, color, badge, badgeStyle }) => {
            let theBadge = badge && globalState[badge];
            let theBadgeStyle = styles[badgeStyle];
            if (label === 'Settings') {
              if (!currentUserServer) {
                theBadgeStyle = styles.light;
                theBadge = '!';
              } else if (currentUserServer.status !== serverStatuses.BUSY) {
                theBadge = '!';
                theBadgeStyle = null;
              }
              if (isMbError) {
                theBadge = '!';
                theBadgeStyle = null;
              }
            }

            return (
              <NavLink
                key={path}
                to={path}
                activeClassName={styles.activeNav}
                className={cn(styles.navItem)}
              >
                <div className={styles.navItemIcon}>
                  <Icon
                    icon={icon}
                    size={isMobile ? 18 : 30}
                    color={color}
                  />
                  { !!theBadge && <div className={cn(styles.badge, theBadgeStyle)}> { theBadge } </div> }
                </div>
                <div className={cn(styles.navItemLabel, styles.navItemFullShow )}>
                  { label }
                </div>
              </NavLink>
            )
          })}
        </div>
      )}
    </div>
  );
}

Menu.propTypes = {
  existMiddleMenu: PropTypes.bool,
};

export {
  Menu
};
