export const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ig;
export const phoneNumberPattern = /^\+?[0-9\-\(\)\s]+$/;

export const composeValidators = (...validators) => (value, options) => {
  for ( const validator of validators) {
    const res = validator(value, options);
    if (res) return res;
  }

  return false;
}

export const required = (value, { fieldName = 'Field' } = {}) => {
  if (value == null || value === '') return `${fieldName} is required`
  return false;
}

export const validatePosInt = (value) => {
  if (!value.match(/^\d+$/)) return 'It is not valid number';
  return false;
}

export const requiredPosInt = (value) => composeValidators(required, validatePosInt)(value)

export const validateEmail = (email) => {
  if (!email.match(emailPattern)) return 'Email is not valid';
  return false;
}

export const requiredEmail = (value) => composeValidators(required, validateEmail)(value.trim(), {fieldName: 'Email'})

export const validatePassword = (password) => {
  const length = 8;
  if (password.length < length) return `The password must be at least ${length} characters`;
  return false;
}

export const requiredPassword = (value) => composeValidators(required, validatePassword)(value, {fieldName: 'Password'})

export const validateConfirmPassword = (password, confirmPassword) => {
  if (!confirmPassword) return 'Field is required';
  if (password !== confirmPassword) return "Password does not match the confirm password";
  return false;
}

export const validatePhoneNumber = (phone) => {
  if (!phone.match(phoneNumberPattern)) return "Phone is not valid";
  return false;
}

export const requiredPhone = (value) => composeValidators(required, validatePhoneNumber)(value, {fieldName: 'Phone'})
