import React from 'react'
import find from 'lodash/find'
import store from 'store/store'
import { loadTags, createTag } from "store/entities/tags/actions";
import { stepTypes } from "../flowsGroup/Flow/Details/reducer/utils/consts";

import { icons } from 'components/routes/flowsGroup/Flow/Details/visualConsts'


export const fieldTypes = {
  SELECT: 'SELECT',
  DELAY: 'DELAY',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  RADIO: 'RADIO',
  CHECK: 'CHECK',
  SNIPPET: 'SNIPPET',
  EMAIL: 'EMAIL',
  AB: 'AB',
  FILTERS: 'FILTERS',
  QUALIFICATION: 'QUALIFICATION',
  // CONDITION: 'CONDITION',
}

const serverActionKeys = {
  SEND_EMAIL: 'email',
  MAKE_PHONE_CALL: 'phone_call',
  ADD_CUSTOM_TASK: 'custom_task',
  ADD_SOCIAL_TASK: 'social_task',
  SEND_LEAD_TO_OTHER_FLOW: 'send_to_flow',
  ADD_TO_LIST: 'add_to_list',
  REMOVE_FROM_LIST: 'remove_from_list',
  ASSIGN_TO: 'assign_to_user',
  CHANGE_FIELD_VALUE: 'change_field_value',
  ADD_TAG: 'add_tag',
  REMOVE_TAG: 'remove_tag',
  WITHDRAW: 'social_withdraw_connect',
  CRM: 'export_to_crm',

  // SOCIAL_WRITE_COMMENT: 'social_comment',
  // SOCIAL_LIKE: 'social_like',
  SOCIAL_ACTIVITY: 'social_activity',
  SOCIAL_CONNECT: 'social_connect',
  SOCIAL_SEND_DM: 'social_dm',
  SOCIAL_ENDORSE: 'social_endorse_skills',
  SOCIAL_COMMENT: 'social_comment',
}
const serverTriggerKeys = {
  CHECK_DM_REPLIED: 'check_dm_replied',
  SOCIAL_CHECK_CONNECTED: 'social_check_connected',
  EMAIL_LINK_CLICKED: 'email_link_clicked',
  EMAIL_OPENED: 'email_opened',
  EMAIL_FORWARDED: 'email_forwarded',
  EMAIL_REPLIED: 'email_replied',
  FILE_DOWNLOADED: 'file_downloaded',
  PAGE_VISITED: 'page_visited',
};
export const serverRuleKeys = {
  A_B: 'ab_test',
  FILTERS: 'filter',
  QUALIFICATION: 'router',
};
export const serverUtilKeys = {
  TIMER: 'timer',
}

export function getFirstServerNodeId(model, startUiNodeId) {
  let uiNode = find(model.nodes, {id: startUiNodeId});

  while (!uiNode.serverNodeId) {
    uiNode = find(model.nodes, {id: uiNode.after[0]})
  }

  return uiNode.serverNodeId;
}

const getCrmOptions = () => Promise.resolve([
  {
    value: 'amo',
    label: 'AmoCRM',
  }, {
    value: 'bitrix',
    label: 'Bitrix',
  }, {
    value: 'hubspot',
    label: 'HubSpot',
  }, {
    value: 'zoho',
    label: 'Zoho',
  },
]);

const getEmailTemplatesOptions = ({q = ''}) => Promise.resolve([
  {
    value: 1,
    label: 'Greeting',
    snippet: "Hello my dear friend. Buy my stuff!",
    subject: "Greeting",
    attachments: [],
  },
  {
    value: 1,
    label: 'Offer',
    snippet: "Hi {{ lead.first_name }}! Give me your money for my good",
    subject: "Offer",
    attachments: [],
  },
]);
const getSnippetsOptions = ({q = ''}) => Promise.resolve([
  {
    value: 1,
    label: 'Greeting',
    snippet: "Hello my dear friend. Buy my stuff!",
    subject: "Greeting",
    attachments: [],
  },
  {
    value: 1,
    label: 'Offer',
    snippet: "Hi {{ lead.first_name }}! Give me your money for my good",
    subject: "Offer",
    attachments: [],
  },
]);

const automationOptions = () => Promise.resolve([
  {
    value: 'auto',
    label: 'Auto',
  }, {
    value: 'manual',
    label: 'Manual',
  }
]);

const getFlowsOptions = ({q = ''}) => Promise.resolve([
  {
    value: 1,
    label: 'Hot Flow',
  },
  {
    value: 2,
    label: 'Cold Flow',
  },
]);

const getListsOptions = ({q = '', leadId}) => Promise.resolve([
  {
    value: 1,
    label: 'C Level',
  },
  {
    value: 2,
    label: 'New York',
  },
]);

const getTagsOptions = ({q = ''}) => Promise.resolve([
  {
    value: 'Netflix',
    label: 'Netflix',
  },
  {
    value: 'Amazon',
    label: 'Amazon',
  },
  {
    value: 'Meta',
    label: 'Meta',
  },
]);

const getUsersOptions = () => Promise.resolve([
  {
    value: 1,
    label: 'Bill Gates',
  },
  {
    value: 2,
    label: 'Rick Sanches',
  },
]);

const snippetField = {
  label: 'Message',
  key: 'snippet',
  type: fieldTypes.SNIPPET,
  options: getSnippetsOptions,
};

const stopOnReplyTriggerLevels = {
  ALWAYS: 'always',
  NODE: 'flow_node',
  TIMEOUT: 'time_shift',
  NEVER: 'never',
}
const automationField = {
  label: 'Automation',
  key: 'mode',
  type: fieldTypes.RADIO,
  options: automationOptions,
  autoSelect: true,
  default: 'auto',
  cond: () => false,
};
const noteField = {
  label: 'Note',
  key: 'note',
  type: fieldTypes.TEXT,
  cond: (payload) => payload.mode === "manual",
};

const description = {
  [stepTypes.UTIL]: {
    [serverUtilKeys.TIMER]: {
      name: "Timer",
      icon: "clock",
      fields: [
        {
          label: 'Wait',
          key: 'delay',
          type: fieldTypes.DELAY,
          default: 60*60*24,
        },
      ],
    },
  },
  [stepTypes.ACTION]: {
    [serverActionKeys.SOCIAL_CONNECT]: {
      name: "Connect Person",
      icon: icons.CONNECT,
      fields: [
        automationField,
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
        {
          ...snippetField,
          maxLength: 280,
          withoutAttachments: true,
        },
      ],
    },
    [serverActionKeys.SOCIAL_SEND_DM]: {
      name: "Send Direct Message",
      icon: icons.CHAT,
      fields: [
        automationField,
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
        snippetField,
      ],
      triggers: [
        {
          key: 'stop_on_reply',
          fields: [
            {
              key: 'level',
              label: 'Stop on reply',
              type: fieldTypes.SELECT,
              options: () => Promise.resolve([
                {
                  value: stopOnReplyTriggerLevels.ALWAYS,
                  label: 'Always',
                },
                {
                  value: stopOnReplyTriggerLevels.NODE,
                  label: 'Message Node',
                },
                {
                  value: stopOnReplyTriggerLevels.TIMEOUT,
                  label: 'Time Shift',
                },
                {
                  value: stopOnReplyTriggerLevels.NEVER,
                  label: 'Never',
                },
              ]),
              default: stopOnReplyTriggerLevels.ALWAYS,
            },
            {
              key: 'days',
              label: 'Days',
              type: fieldTypes.NUMBER,
              cond: (payload) => payload.triggers.stop_on_reply.level === stopOnReplyTriggerLevels.TIMEOUT,
              default: 7,
            },
          ],
        },
      ],
    },
    [serverActionKeys.ADD_CUSTOM_TASK]: {
      name: "Visit Page",
      icon: icons.CUSTOM_TASK,
      fields: [
        automationField,
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.SOCIAL_ACTIVITY]: {
      name: "Social Activity",
      icon: icons.LIKE,
      fields: [
        automationField,
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
        // snippetField,
      ],
    },
    [serverActionKeys.SOCIAL_ENDORSE]: {
      name: "Endorse Skills",
      icon: 'endorse',
      fields: [
        automationField,
        noteField,
        {
          label: "Skills count",
          key: 'amount',
          type: fieldTypes.NUMBER,
        },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.SOCIAL_COMMENT]: {
      name: "Comment Post",
      icon: "comment_li",
      fields: [
        automationField,
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
        {
          ...snippetField,
          withoutAttachments: true,
        },
      ],
    },
    [serverActionKeys.WITHDRAW]: {
      name: "Withdraw Connection",
      icon: icons.CONNECT,
      fields: [
        {
          label: 'Automation',
          key: 'mode',
          type: fieldTypes.RADIO,
          options: () => Promise.resolve([ {
            value: 'auto',
            label: 'Auto',
          }
          ]),
          autoSelect: true,
          default: 'auto',
        },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.SEND_EMAIL]: {
      name: "Send Email",
      icon: icons.SEND_EMAIL,
      fields: [
        automationField,
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
        {
          label: 'Template',
          key: 'template',
          type: fieldTypes.EMAIL,
          options: getEmailTemplatesOptions,
        },
        {
          label: "Attach email history",
          key: "add_history",
          type: fieldTypes.CHECK,
          defaultValue: true,
        },
      ],
      triggers: [
        {
          key: 'stop_on_reply',
          fields: [
            {
              key: 'level',
              label: 'Stop on reply',
              type: fieldTypes.SELECT,
              options: () => Promise.resolve([
                {
                  value: stopOnReplyTriggerLevels.ALWAYS,
                  label: 'Always',
                },
                {
                  value: stopOnReplyTriggerLevels.NODE,
                  label: 'Message Node',
                },
                {
                  value: stopOnReplyTriggerLevels.TIMEOUT,
                  label: 'Time Shift',
                },
                {
                  value: stopOnReplyTriggerLevels.NEVER,
                  label: 'Never',
                },
              ]),
              default: stopOnReplyTriggerLevels.ALWAYS,
            },
            {
              key: 'days',
              label: 'Days',
              type: fieldTypes.NUMBER,
              cond: (payload) => payload.triggers.stop_on_reply.level === stopOnReplyTriggerLevels.TIMEOUT,
              default: 7,
            },
          ],
        },
      ],
    },
    [serverActionKeys.CRM]: {
      name: "Export to CRM",
      icon: 'link',
      fields: [
        {
          label: 'CRM',
          key: 'integration_type',
          type: fieldTypes.SELECT,
          options: getCrmOptions,
        },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.ADD_TAG]: {
      name: "Add Tag",
      icon: icons.ADD_TO_LIST,
      fields: [
        {
          label: 'Tag',
          key: 'tag',
          type: fieldTypes.SELECT,
          options: getTagsOptions,
          getValueLabel: tag => Promise.resolve(tag),
          creatable: true,
          createFn: (name) => Promise.resolve(name),
        },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.ASSIGN_TO]: {
      name: "Assign To",
      icon: icons.ASSIGN_TO,
      fields: [
        {
          label: 'User',
          key: "user_id",
          type: fieldTypes.SELECT,
          options: getUsersOptions,
        },
        {
          label: 'List',
          key: "list_id",
          type: fieldTypes.SELECT,
          options: ({ payload }) => getListsOptions({leadId: payload.user_id}),
          cond: (paylaod) => paylaod.user_id,
        },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.SEND_LEAD_TO_OTHER_FLOW]: {
      name: "Send Lead To New Flow",
      icon: icons.SEND_CONTACT_IN_NEW_FLOW,
      fields: [
        {
          label: 'New Flow',
          key: 'flow_id',
          type: fieldTypes.SELECT,
          options: getFlowsOptions,
        },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.MAKE_PHONE_CALL]: {
      name: "Phone Call",
      icon: icons.PHONE_CALL,
      fields: [
        {
          label: 'Automation',
          key: 'mode',
          type: fieldTypes.RADIO,
          options: () => Promise.resolve([ {
              value: 'manual',
              label: 'Manual',
            }
          ]),
          autoSelect: true,
          default: 'manual',
        },
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
  },
  [stepTypes.RULE]: {
    [serverRuleKeys.FILTERS]: {
      name: "Filter",
      icon: 'filter',
      fields: [
        {
          label: "Filters",
          key: 'branches',
          type: fieldTypes.FILTERS,
        },
      ],
    },
    [serverRuleKeys.A_B]: {
      name: "A/B  Testing",
      icon: 'ab',
      fields: [
        {
          label: 'Branches',
          key: 'branches',
          type: fieldTypes.AB,
        },
      ],
    },
    [serverRuleKeys.QUALIFICATION]: {
      name: 'Manual Router',
      icon: 'pointer',
      fields: [
        {
          label: 'Branches',
          key: 'branches',
          type: fieldTypes.QUALIFICATION,
        },
      ],
    },
  },
  [stepTypes.TRIGGER]: {
    [serverTriggerKeys.SOCIAL_CHECK_CONNECTED]: {
      name: "Check Connection",
      icon: icons.CHECK_CONNECTION,
      fields: [
        {
          label: 'Wait for LinkedIn Connect',
          key: 'delay',
          type: fieldTypes.DELAY,
          default: 86400,
        },
      ],
      branchLabels: ['No Connect', 'Connected'],
    },
    [serverTriggerKeys.CHECK_DM_REPLIED]: {
      name: "Check Direct Message Replied",
      icon: icons.CHAT,
      fields: [
        {
          label: 'Wait for LinkedIn Reply',
          key: 'delay',
          type: fieldTypes.DELAY,
          default: 86400,
        },
      ],
      branchLabels: ['No Reply', 'Replied'],
    },
    [serverTriggerKeys.EMAIL_REPLIED]: {
      name: "Email Replied",
      icon: icons.EMAIL_LINK_CLICK,
      fields: [
        // {
        //   label: 'Node',
        //   key: "node_id",
        //   type: fieldTypes.SELECT,
        //   options: getSendEmailSteps,
        // },
        {
          label: 'Wait for Email Reply',
          key: 'delay',
          type: fieldTypes.DELAY,
          default: 86400,
        },
      ],
      branchLabels: ['No Reply', 'Replied'],
    },
  },
  [stepTypes.END]: {},
}

for (const nodeType of Object.values(description)) {
  for (const id in nodeType) {
    nodeType[id].id = id;
  }
}

export {
  description as default,
  description,
  serverActionKeys
}
