import React from 'react'
import 'react-dates/initialize';
import { Switch, Router, Route } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from 'react-tooltip'
import { ToastContainer } from 'react-toastify'
import { getUser } from 'store/auth/actions'
import { userStatuses } from "store/auth";
import { getAuthUserStatus } from 'store/auth/selectors';
import sse, { SSE } from "services/serverSideEvents";

import history from 'services/browserHistory'

import ErrorBoundary from './ErrorBoundary'
import MainLayout from "./layouts/MainLayout/MainLayout";
import FakeLayout from "./layouts/FakeLayout";
import UnauthLayout from "./layouts/UnauthLayout/UnauthLayout";

import AddCard, {path as addCardPath} from "./routes/authGroup/AddCard";
import Login from "./routes/authGroup/Login";
import Signup from "./routes/authGroup/Signup";
import ResetPassword from "./routes/authGroup/ResetPassword";

import { getSocialProfile } from "./routes/SettingsGroup/SocialProfile/reducer/actions";
import Home from './routes/Home'
import FlowsGroupRoutes, { path as flowsGroupPath } from './routes/flowsGroup'
import FlowConstructorRoute, { path as flowConstructorPath } from './routes/FlowConstructor'
import TemplatesGroupRoutes, { path as templatesPath } from './routes/TemplatesGroup'
import SettingsGroupRoutes, { path as settingsGroupPath } from  './routes/SettingsGroup'
import VisitorsRoutes, { path as visitorsPath } from './routes/Visitors';
import PluginGroupRoute, { path as pluginPath } from './routes/PluginGroup';
import MessagesRoute, { path as messagesPath } from './routes/Messages/routing';
import DashboardPage, { path as dashboardPath } from './routes/dashboard';
import TasksRoute, { path as tasksPath } from './routes/Tasks/routing';
import { ModalChooseTemplateContainer } from 'components/routes/flowsGroup/Flow/Templates/Modal';
import NotFoundRoute from  './routes/NotFound'

import { useMatchMedia } from './common/hooks/useMatchMedia';
import { firebaseConfig, mobileWidth } from '../config';
import { MobileContext } from './common/contexts/MobileContext';

import * as firebase from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// import StyleGuideRoutes from  './routes/StyleGuide'

import appStyles from './App.module.scss'
import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
  const dispatch = useDispatch();
  const userStatus = useSelector(getAuthUserStatus);
  const isMobile = useMatchMedia(mobileWidth);

  React.useEffect(() => {
    dispatch(getUser());

    dispatch(getSocialProfile());
    sse.listen((events => {
      if (events.some(event => event.type == SSE.eventTypes.MESSAGE)) {
        dispatch(getSocialProfile());
      }
    }))

    const apps = firebase.getApps();
    let app;

    if (!apps.length) {
      app = firebase.initializeApp(firebaseConfig);
    } else {
      app = apps[0];
    }

    getAnalytics(app);
  }, []);

  // отключить браузер Safari
  const ua = navigator.userAgent.toLowerCase();
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !/ crios\/\d/.test(ua)) {
    return (
      <div style={{display: 'flex', height: '100vh'}}>
        <div style={{ margin: 'auto', fontSize: 18, width: 400, textAlign: 'center'}}>
          Unfortunately we do not support the Safari browser yet. Please use
          <a style={{fontSize: 18}} href="https://www.google.com/intl/en_us/chrome/"> Google Chrome </a>
        </div>
      </div>
    )
  }

  return (
    <ErrorBoundary>
      <MobileContext.Provider value={isMobile} >
        <ReactTooltip effect="solid" place="bottom"/>
        <ToastContainer />

        <div className={appStyles.rootWrapper}>
          <Router history={history}>
            <Switch>
              {userStatus === userStatuses.UNAUTH && (
                <Route path={[`${flowsGroupPath}/templates/:templateId`]}>
                  <FakeLayout>
                    <Switch>
                      <Route exact
                        path={`${flowsGroupPath}/templates/:templateId`}
                        component={ModalChooseTemplateContainer}
                      />
                    </Switch>
                  </FakeLayout>
                </Route>
              )}

              <Route path={[addCardPath, "/login", "/signup", "/restore/:token"]}>
                <UnauthLayout>
                  <Switch>
                    <Route exact path={addCardPath} component={AddCard} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/signup" component={Signup} />
                    <Route exact path="/restore/:token" component={ResetPassword} />
                    <Route component={Home} />
                  </Switch>
                </UnauthLayout>
              </Route>

              <Route path={flowConstructorPath} component={FlowConstructorRoute} />

              <Route>
                <MainLayout>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path={settingsGroupPath} component={SettingsGroupRoutes}/>
                    <Route path={flowsGroupPath} component={FlowsGroupRoutes}/>
                    <Route path={visitorsPath} component={VisitorsRoutes} />
                    <Route path={templatesPath} component={TemplatesGroupRoutes} />
                    <Route path={pluginPath} component={PluginGroupRoute} />
                    <Route path={messagesPath} component={MessagesRoute} />
                    {/* <Route path={tasksPath} component={TasksRoute} /> */}
                    <Route path={dashboardPath} component={DashboardPage} />
                    <Route component={NotFoundRoute} /> {/* 404 */}
                  </Switch>
                </MainLayout>
              </Route>
            </Switch>
          </Router>
        </div>
      </MobileContext.Provider>
    </ErrorBoundary>
  );
}

export {
  App
}
