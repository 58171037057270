import * as actions from './consts'

const ACTION_HANDLERS = {
  [actions.INTEGRATION_SET_MAPPING]: (state, { slug, mapping}) => ({
    ...state,
    [slug]: {
      ...state[slug],
      mapping,
    },
  }),
  [actions.INTEGRATIONS_DELETE_SUCCESS]: (state, { slug }) => {
    let s = state;

    delete s[slug];

    return s;
  },
  [actions.INTEGRATIONS_SET]: (state, { integrations }) => {
    const s = {...state};

    if (!Array.isArray(integrations)) {
      integrations = [integrations];
    }

    for (const int of integrations) {
      s[int.type] = int;
    }

    return s;
  },
  [actions.INTEGRATION_TOGGLE]: (state, { slug }) => {
    if (!state[slug]) return state;

    const s = {...state};

    s[slug] = {
      ...s[slug],
      is_active: !s[slug].is_active,
    }

    return s;
  }
}

const initialState = {
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
