import React, { useCallback, useRef } from 'react'
import cn from 'classnames'
import Select, { Creatable, Async, AsyncCreatable } from 'react-select'
import * as Animated from 'react-select/lib/animated'

import { actionColor, disabledColor } from 'styles/_variables.module.scss'

import styles from '../Styles.module.scss'

const CREATE_KEY = Symbol('CREATE_KEY');
function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const CustomDropdownIndicator = ({
  innerProps,
   // ...other
}) => {
  return (
    <div {...innerProps} style={{padding: '8px'}}>
      <div style={{
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 4.5px 5px 4.5px',
        borderColor: 'transparent transparent #7c98b6 transparent',
        marginBottom: '6px'
      }}/>

      <div style={{
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '5px 4.5px 0 4.5px',
        borderColor: '#7c98b6 transparent transparent transparent',
      }}/>
    </div>
  )
}

const getValueColor = (isGhost, isDisabled, isSelected, isFocused) => {
  if (isDisabled) return disabledColor;
  if (isSelected) return actionColor
  if (isFocused) return 'white';
  if (isGhost) return '#516F90';

  return '#253342';
}

const SelectBox = (props) => {
  const {
    additionalInfo,
    components,
    className = '',
    isError,
    creatable,
    loadOptions,
    isShortInput,
    createOnBlur,
    onBlur = ()=>{},
    onChange,
    placeholder,
    ...other
  } = props;

  let { filterOption } = props;
  const selectRef = useRef();

  const colourStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      minHeight: 34,
      backgroundColor: 'white',
      overflow: 'hidden',
      borderRadius: 8,
      borderColor: isFocused ? '#425B76' : '#99ACC2',
      boxShadow: isFocused ? `0 0 1px ${actionColor}` : 'none'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {

      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected ? data.color : isFocused ? '#425B76' : null,
        color: getValueColor(data.ghost, isDisabled, isSelected, isFocused),
        cursor: isDisabled ? 'not-allowed' : 'pointer'
      };
    },
    menu: styles => ({...styles, zIndex: 999999}),
    menuPortal: styles => ({...styles, zIndex: 1002}),
    valueContainer: styles => ({ ...styles, overflow: 'hidden' }),
    input: styles => ({ ...styles, overflow: 'hidden' }),
    placeholder: styles => ({ ...styles, opacity: 0.5, color: '#253342', overflow: 'hidden' }),
    singleValue: (styles, {data}) => ({ ...styles, color: data.ghost ? '#516F90' : '#253342', overflow: 'hidden' }),
    multiValue: styles => ({ ...styles, backgroundColor: actionColor }),
    multiValueLabel: styles => ({ ...styles, color: '#253342', fontWeight: 400 }),
    multiValueRemove: styles => ({
      ...styles,
      color: disabledColor,
      ':hover': {
        color: '#253342',
      },
    }),
  };

  let SelectComponent;
  if (loadOptions) {
    SelectComponent = creatable ? AsyncCreatable : Async;
    filterOption = filterOption || ( (v, str) => v.label.match(new RegExp(escapeRegExp(str), 'i')) );
  } else {
    SelectComponent = creatable ? Creatable : Select;
  }

  const wrappedLoadOptions = useCallback((...args) => {
    return Promise.resolve(loadOptions(...args))
      .then(options => creatable ? [
        ...options,
        {
          value: null,
          label: "Create New",
          ghost: true,
          [CREATE_KEY]: true,
        }
      ] : options)
  }, [loadOptions])
  const wrappedOnChange = useCallback((selectedValue) => {
    if (selectedValue[CREATE_KEY]) {
      onChange({
        value: selectedValue.label,
        label: selectedValue.label,
        __isNew__: false,
      });
      setTimeout(() => selectRef.current.focus());
      return
    }
    if (Array.isArray(selectedValue) && selectedValue.some(v => v[CREATE_KEY])) {
      onChange(selectedValue.filter(v => !v[CREATE_KEY]));
      setTimeout(() => selectRef.current.focus());
      return;
    }

    onChange(selectedValue);
  }, [onChange])

  const handleBlur = useCallback((e) => {
    if (!creatable) return;
    if (!createOnBlur) return;
    const inputValue = e.target.value;

    if (inputValue) {
      onChange({
        value: inputValue,
        label: inputValue,
        __isNew__: true,
      })
    }

    onBlur(e);
  }, [onBlur, createOnBlur, creatable]);

  return (
    <div
      className={cn(styles.inputWrapper, {
          [styles.AdditionalInfo]: additionalInfo,
          [styles.Error]: isError,
          [styles.ShortInput]: isShortInput,
          [className]: true,
        })}
      data-additional-info={additionalInfo}
    >
      <SelectComponent
        ref={selectRef}
        className={styles.Select}
        openOnFocus
        styles={colourStyles}
        components={{
          Animated,
          DropdownIndicator: CustomDropdownIndicator,
          IndicatorSeparator: () => null,
          ...components,
        }}
        loadOptions={wrappedLoadOptions}
        defaultOptions
        filterOption={filterOption}
        noOptionsMessage={() => creatable ? 'Type to create' : undefined}
        onBlur={handleBlur}
        onChange={wrappedOnChange}
        placeholder={placeholder || (creatable ? 'Type to create...' : 'Select...')}
        {...other}
      />
    </div>
  )
}

export default SelectBox
