import React from 'react'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { serializeFilters } from "components/common/Filters";
import leadsPath from 'components/routes/Visitors/pathname'
import { getFlowNodesAsObject } from "store/entities/flowStatistics/selectors";
import { flowDetailsModeSelector} from "../../../../../reducer/selectors";

const statsMapping = [
  {
    key: 'new',
    label: 'In Progress',
  }, {
    key: 'closed',
    label: 'Done',
  }, {
    key: 'cancelled',
    label: 'Canceled',
  }, {
    key: 'error',
    label: 'Error',
    color: "#F2545B",
    percent: true,
  },
]

const conversationsActivityMap = {
  replied: 'social_dm_replied',
  connected: 'social_connected',
  opened: 'email_opened',
  unsubscribed: 'lead_unsubscribed',
  bounced: 'email_bounced',
}

const NodeStatistics = ({ node, model }) => {
  const nodesObject = useSelector((state) => getFlowNodesAsObject(state, model.flow.id));
  const stepStatistics = node && nodesObject[node.serverNodeId];

  const getPercent = (value, total = stepStatistics.total) =>  stepStatistics ? Number(value * 100 / total).toFixed(0) : 0;
  const getCountText = (count, percent) => `${count} Lead${count > 1 ? 's' : ''}` +  (percent ? ` (${getPercent(count, percent)}%)` : '');
  const getLink = ({status, ...other}) => `${leadsPath}?filters=${serializeFilters({
    flow_node_id: {has: [{id: node.serverNodeId, status}]},
    ...other,
  })}`

  return (
    <div>
      { stepStatistics && <React.Fragment>
        { statsMapping.map(
          ({key, label, color, percent}) => (stepStatistics.states[key])
            ? <div key={key}>
                <span className="gs-text _sm" style={{color}}> { label }: </span>
                <Link to={getLink({status: key})} target="_blank" className="gs-text _blue _sm _thin">
                  { getCountText(stepStatistics.states[key], percent) }
                </Link>
            </div>
            : null
        )}
        { stepStatistics && stepStatistics.conversions && Object.keys(stepStatistics.conversions).map(key => (
          <div key={key}>
            <span className="gs-text _sm" style={{textTransform: 'capitalize'}}> { key }: </span>
            { console.log('key', key)}
            <Link
              to={getLink({activity: {has: [{type: conversationsActivityMap[key]}]}})}
              target="_blank"
              className="gs-text _blue _sm _thin"
            >
              { getCountText(stepStatistics.conversions[key], stepStatistics.states.closed) }
            </Link>
          </div>
        )) }
      </React.Fragment>}
    </div>
  )
}

export default React.memo(NodeStatistics)
