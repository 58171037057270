import * as actions from './consts'
import { entitySetter } from '../utils'

const ACTION_HANDLERS = {
  [actions.FLOW_STATISTICS_SET]: (state, { flowStatistics }) => entitySetter(state, flowStatistics),
}

const initialState = {};

const flowStatisticsReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

export {
  flowStatisticsReducer
};
