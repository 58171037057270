export const nodeTypes = {
  START: 'START',
  PLUS: 'PLUS',
  END: 'END',
  REAL: 'REAL',
}
export default nodeTypes
// это константы сервера. не надо их тут менять
export const stepTypes = {
  ACTION: 'action',
  TRIGGER: 'trigger',
  RULE: 'rule',
  UTIL: 'util',
  END: 'end',
};

export const ONE_SIDE_WIDTH = 2


export const getNodeType = (node) => {
  if (!node.before.length) {
    return nodeTypes.START;
  }
  if (!node.after.length) {
    return nodeTypes.END;
  }
  if (node.serverNodeId) {
    return nodeTypes.REAL;
  }

  return nodeTypes.PLUS;
}
