import find from "lodash/find";

export default (model, startUiNodeId) => {
  if (!startUiNodeId) throw new Error('empty startUiNodeId');

  const res = [];
  let nextUiId = startUiNodeId;

  main:
  while(true) {
    const uiNode = find(model.nodes, {id: nextUiId});
    if (uiNode.before.length > 1) break;

    res.push(nextUiId);

    switch (uiNode.after.length) {
      case 0: {
        break main;
      }
      case 1: {
        nextUiId = uiNode.after[0];
        break;
      }
      default: {
        if (!uiNode.union) break main;
        const unionPlusNode = find(model.nodes, {id: uiNode.union});
        nextUiId = unionPlusNode.after[0];
        break;
      }
    }
  }

  return res
}
