import React from 'react'
import cn from 'classnames'

import Spinner from 'components/common/elements/Spinner'

import styles from '../Styles.module.scss'

const Button = (props) => {
  const {
    children,
    reverse,
    mini,
    rounded,
    transparent,
    micro,
    text,
    active,
    action,
    cancelType,
    darkType,
    indigoType,
    className,
    ...other
  } = props;

  return (
    <button
      className={cn(styles.Button, {
        [className]: className,
        [styles.Reverse]: reverse,
        [styles.Mini]: mini,
        [styles.Rounded]: rounded,
        [styles.Micro]: micro,
        [styles.Transparent]: transparent,
        [styles.CancelType]: cancelType,
        [styles.DarkType]: darkType,
        [styles.IndigoType]: indigoType
      })}
      disabled={active}
      role='button'
      {...other}
    >
      { children || text }
      { active && (
        <Spinner size={mini ? 20 : 36}/>
      )}
    </button>
  )
};

export {
  Button as default,
  Button
};
