import React, { useState, useEffect, Fragment as F } from 'react'
import { useDispatch } from 'react-redux'
import TextRow from 'components/common/inputs/FormRows/TextRow'
import CheckBox from 'components/common/inputs/CheckBox'
import AsyncButton from 'components/common/buttons/AsyncActionButton'
import Modal from 'components/common/Modal/Modal'
import { getAvailableSeats } from "store/entities/subscriptions/actions";
import { getNextPlan } from "store/entities/plans/actions";
import { createUserForTeam } from "store/entities/users/actions";
import { validateEmail } from "services/validators";
import { showError, getError } from "services/utils";

import styles from './NewInvite.module.scss'

const noop = () => {};

const NewInvite = ({ onRequestClose, onAdd=noop }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nextPlan, setNextPlan] = useState('');
  const [planError, setPlanError] = useState('');
  const [isMaxUsers, setIsMaxUsers] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const dispatch = useDispatch();
  const emailError = validateEmail(email);
  const error = planError || emailError || !firstName || !lastName || isMaxUsers === null || (isMaxUsers === true && !isConfirmed);

  useEffect(() => {
    dispatch(getAvailableSeats())
      .then((availableSeats) => {
        if (availableSeats <= 0) {
          setIsMaxUsers(true);
          dispatch(getNextPlan())
            .then(setNextPlan)
            .catch(e => setPlanError(getError(e, 'Something went wrong')))
        } else {
          setIsMaxUsers(false);
        }
      })
  }, [])

  const onSubmit = (e) => {
    e && e.preventDefault();

    return dispatch(createUserForTeam({
      email,
      password,
      plan_id: nextPlan && nextPlan.plan_id,
      first_name: firstName,
      last_name: lastName,
      confirm_payment: isConfirmed,
    }))
      .then(onAdd)
      .then(onRequestClose)
      .catch(er => showError(er, "Failed tp send Invite. Please try again later"))
  }

  return (
    <Modal.Container className={styles.container}>
      <Modal.Header
        header="Invite New Team Member"
        onClose={onRequestClose}
      />

      <div className={styles.doubleInputRow}>
        <TextRow
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          label="First Name"
          placeholder="Enter First Name"
          error={!firstName && 'Required'}
          required
          isShortInput
        />

        <TextRow
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          label="LastName"
          placeholder="Enter Last Name"
          error={!lastName && 'Required'}
          required
          isShortInput
        />
      </div>

      <TextRow
        value={email}
        onChange={e => setEmail(e.target.value)}
        label="Email"
        placeholder="Enter Email of Team Member"
        error={emailError}
        required
      />

      <TextRow
        rowClassName={styles.mt15}
        value={password}
        onChange={e => setPassword(e.target.value)}
        label={<F>Password <span className={styles.passwordTip}>(will be generated if empty)</span></F>}
        placeholder="Enter Email of Team Member"
        error={emailError}
      />

      { isMaxUsers && !planError && (
        <F>
          <div className={styles.checkRow}>
            <CheckBox checked={isConfirmed} onChange={() => setIsConfirmed(!isConfirmed)} />
            <span> Confirm upgrade of your subscription </span>
          </div>
          <div className={styles.upText}>
            You have reached your subscription places limit!
            <br/>
            By adding new team user you will be upgraded to <strong>{nextPlan && nextPlan.name}</strong> subscription.
          </div>
        </F>
      )}
      { planError && (
        <div className={styles.upText} style={{marginTop: 20}}>
          { planError }
        </div>
      )}

      <div className={styles.footer}>
        <div className={styles.cancel}>
          Cancel
        </div>

        <AsyncButton
          rounded
          action={onSubmit}
          disabled={error}
        >
          Add Team Member
        </AsyncButton>
      </div>
    </Modal.Container>
  )
}

export default NewInvite;
