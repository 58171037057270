import React from 'react';
import simpleValue from '../../hocs/simpleValue'
import Icon from "../elements/Icon";
import TextRow from '../inputs/FormRows/TextRow'
import SelectRow from '../inputs/FormRows/SelectRow'
import InputDateTime from "../inputs/DateTime"
import { typeOperators, operatorInputs, fieldTypes } from './description'

import styles from "./Filters.module.scss"

const SimpleSelect = simpleValue(SelectRow);

const CurrentFilter = ({ filters, field, onChange }) => {
  if (!filters) return <div className={styles.current}/>

  const getOperator = operatorKey => Object.values(field.customOperators || typeOperators[field.type]).find(operator => operator.key == operatorKey)

  const handleChange = (operatorKey, index, value, needDebounce) => {
    const newFilters = {
      ...filters,
      [operatorKey]: [...filters[operatorKey]],
    };
    newFilters[operatorKey][index] = value;

    onChange(newFilters, needDebounce);
  }
  const handleDelete = (operatorKey, index) => {
    const newFilters = {
      ...filters,
      [operatorKey]: [...filters[operatorKey]],
    };
    newFilters[operatorKey].splice(index, 1)
    if (!Object.keys(newFilters[operatorKey]).length) {
      delete newFilters[operatorKey];
    }

    onChange(newFilters);
  };

  return (
    <div className={styles.current}>
      { Object.keys(filters).map(operatorKey => (
        <div key={operatorKey}>
          <div> { getOperator(operatorKey).label } </div>

          <ul>
            { filters[operatorKey].map( (value, index) => (
              <div
                key={index}
                className={styles.inputRow}
              >
                <ValueInput
                  value={value}
                  onChange={(newValue, needDebounce) => handleChange(operatorKey, index, newValue, needDebounce)}
                  field={field}
                  operator={getOperator(operatorKey)}
                />
                <Icon
                  onClick={() => handleDelete(operatorKey, index)}
                  icon="Cross"
                  size={20}
                  className={styles.removeValue}
                />
              </div>
            )) }
          </ul>
        </div>
      )) }
    </div>
  )
}

export default CurrentFilter;

const ValueInput = ({ operator = {}, field, value, onChange, sub }) => {
  const isDouble = operator.input == operatorInputs.DOUBLE;

  switch (field.type) {
    case fieldTypes.STRING: {
      return (
        <TextRow
          value={value}
          onChange={e => onChange(e.target.value, true)}
          autoFocus
        />
      );
    }
    case fieldTypes.SELECT: {
      return (
        <SimpleSelect
          value={value}
          onChange={({ value }) => onChange(value)}
          loadOptions={field.getOptions}
          getLabel={field.getLabel}
          creatable={field.creatable}
          placeholder={field.placeholder}
          disableAutoFill
          rowClassName={styles.selectRow}
          menuPortalTarget={document.querySelector('body')}
          autoFocus
        />
      )
    }
    case fieldTypes.NUMBER: {
      const handler = (e) => {
        let newValue = e.target.value
          .replace(/\D/g, '')
          .replace(/^0+(.+)/, '$1')

        newValue = parseInt(newValue) || '';

        return newValue;
      }
      const onBlur = (val) => {
        if (val === '') return val;

        if (field.min) {
          val = Math.max(field.min, val)
        }
        if (field.max) {
          val = Math.min(field.max, val)
        }

        return val;
      }
      if (isDouble) {
        value = value || [];
        return (
          <React.Fragment>
            <TextRow
              value={value[0] || ''}
              onChange={e => onChange([handler(e), value[1]])}
              onBlur={() => onChange([onBlur(value[0]), value[1]])}
              className={styles.smallInput}
              autoFocus
            />
            <TextRow
              value={value[1] || ''}
              onChange={e => onChange([value[0], handler(e)])}
              onBlur={() => onChange([value[0], onBlur(value[1])])}
              className={styles.smallInput}
            />
          </React.Fragment>
        )
      } else {
        return (
          <TextRow
            value={value}
            onChange={e => onChange(handler(e))}
            onBlur={() => onChange(onBlur(value))}
            autoFocus
          />
        )
      }
    }
    case fieldTypes.DATE: {
      const handler = (v) => {
        try {
          return v.format('YYYY-MM-DD');
        } catch (e) {}
      }
      if (isDouble) {
        value = value || [];
        return (
          <React.Fragment>
            <InputDateTime
              value={value[0]}
              onChange={(v) => onChange([handler(v), value[1]])}
              timeFormat={false}
              className={styles.smallInput}
              autoFocus
            />
            <InputDateTime
              value={value[1]}
              onChange={(v) => onChange([value[0], handler(v)])}
              timeFormat={false}
              className={styles.smallInput}
            />
          </React.Fragment>
        )
      } else {
        return (
          <InputDateTime
            value={value}
            onChange={handler}
            timeFormat={false}
            autoFocus
          />
        )
      }
    }
    case fieldTypes.COMBO:
      value = value || {};
      return <div style={{display: 'flex', width: '100%'}}>
        { field.fields.map(subField => (
          <ValueInput
            key={field.name + '-' + subField.name}
            field={subField}
            sub={true}
            value={value[subField.name]}
            onChange={subValue => onChange({
              ...value,
              [subField.name]: subValue,
            })}
          />
        ))}
      </div>
  }
}
