import React from "react";

const simpleValue = (SelectComponent) => {

  return ({ value, getLabel, options, ...props }) => {
    const [theValue, setTheValue] = React.useState(value || null);
    React.useEffect(() => setTheValue(value), [value, options])

    if (typeof theValue !== 'object') {
      if (options) {
        const opt = options.find(opt => opt.value == value);
        if (opt != null) {
          setTheValue(opt)
        }
      }
      if (getLabel) {
        Promise.resolve(getLabel(value))
          .then(label => label &&  setTheValue({label, value}))
      }
    }

    return <SelectComponent value={theValue} options={options} getLabel={getLabel} {...props}/>
  }
}

export default simpleValue
