import * as actions from './consts'

import getAxios from 'services/axios'
import { createCachedFunction } from "../utils";
import {MAILBOXES_RESET_DAILY_LIMITS} from "./consts";

const axios = getAxios('mailbox');

export const getOutbox = (id) => (dispatch) => {
  return axios.get(`/api/outboxes/${id}`)
}

export const setMailboxes = (mailboxes) => {
  if (!(mailboxes instanceof Array)) {
    mailboxes = [mailboxes];
  }
  return {
    type: actions.MAILBOXES_SET,
    mailboxes,
  }
}

export const getMailboxErrors = (mailboxId, type) => (dispatch) => {
  return axios.get(`/api/mailbox-errors/${mailboxId}/${type}`)
}

export const testMailbox = (data) => (dispatch) => axios.post('/api/mailboxes/test', data)

const cacher = createCachedFunction((params, cancelToken) => (dispatch) => {
  return axios.get('/api/mailboxes', {
    params,
    cancelToken,
  })
    .then( mailboxes => {
      dispatch(setMailboxes(mailboxes))

      return mailboxes;
    })
}, {recursively: true})

export const loadMailboxes = cacher.cachedFunction;

// стоит кэшировать или нет такие функции?
// ЕСЛИ БЫ сервер умел оповещать об изменеиях сделанных другими пользователями,
// то ответ однозначно ДА...
export const loadMailbox = (id) => (dispatch, getState) => {
  return axios.get(`/api/mailboxes/${id}`)
    .then( mailbox => {
      dispatch(setMailboxes(mailbox))

      return mailbox;
    })
}
export const clearMailboxCache = cacher.clearCache;

export const checkMailbox = (id) => (dispatch) => {
  return axios.put(`/api/mailboxes/${id}/check`)
    .then(res => {
      dispatch({
        type: actions.MAILBOXES_RESET_CONNECTION,
        id,
      })
      return res;
    })
}

export const resetMailboxLimits = (id) => (dispatch) => {
  return axios.put(`/api/mailboxes/${id}/reset-limits`)
    .then(r => dispatch({
      type: actions.MAILBOXES_RESET_DAILY_LIMITS,
      id,
    }))
}

export const createMailbox = (params) => (dispatch, getState) => {
  return axios.post(`/api/mailboxes`, params)
    .then( mailbox => {
      cacher.clearCache()
      dispatch(setMailboxes(mailbox))

      return mailbox;
    })
}

export const updateMailbox = (id, params) => (dispatch, getState) => {
  return axios.put(`/api/mailboxes/${id}`, params)
    .then( mailbox => {
      cacher.clearCache()
      dispatch(setMailboxes(mailbox))

      return mailbox;
    })
}

export const deleteMailbox = (id) => (dispatch, getState) => {
  return axios.delete(`/api/mailboxes/${id}`)
    .then( res => {
      cacher.clearCache()
      dispatch({
        type: actions.MAILBOXES_DELETE_SUCCESS,
        id,
      })

      return res;
    })
}

export const toggleMailbox = (id) => (dispatch, getState) => {
  return axios.put(`/api/mailboxes/${id}/toggle-active`)
    .then( mailbox => {
      cacher.clearCache()
      dispatch(setMailboxes(mailbox))

      return mailbox;
    })
}

export const sendEmail = ({body, subject, email, lead_id, attachments, template_id, taskId = null}) => (dispatch) => {
  return axios.post('/api/outboxes', {
    body, subject, email, lead_id, attachments, template_id, taskId
  })
  .then(emails => {
    dispatch({
      type: actions.EMAILS_SET,
      emails,
    })

    window.dataLayer.push({'event': 'inner-message-sent'});
    return emails;
  })
}
