import * as actions from './consts'
import { entitySetter } from '../utils'

const ACTION_HANDLERS = {
  [actions.EMAILS_SET]: (state, { emails }) => entitySetter(state, emails)
}

const initialState = {
}

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
