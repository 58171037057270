import basePath from '../path';

const mailboxesPath = `${basePath}/mailboxes`;

export const createMailboxPaths = {
  gmail : `${mailboxesPath}/gmail`,
  outlook : `${mailboxesPath}/outlook`,
  other : `${mailboxesPath}/other`
}

export default mailboxesPath;
