import * as actions from './consts'
import { entitySetter } from '../utils'

const ACTION_HANDLERS = {
  [actions.MAILBOXES_DELETE_SUCCESS]: (state, { id }) => {
    const s = {...state};

    delete s[id];

    return s;
  },
  [actions.MAILBOXES_SET]: (state, { mailboxes }) => {
    let s = state;

    mailboxes.forEach( mb => s = entitySetter(s, mb) );

    return s;
  },
  [actions.MAILBOXES_RESET_DAILY_LIMITS]: (state, { id }) => ({
    ...state,
    [id]: {
      ...state[id],
      sent_count: 0,
      hold_till: null,
    },
  }),
  [actions.MAILBOXES_RESET_CONNECTION]: (state, { id }) => ({
    ...state,
    [id]: {
      ...state[id],
      sync_status: 'active',
      send_status: 'active',
    },
  }),
  [actions.EMAILS_SET]: (state, { emails }) => {
    let s = {...state};

    s = entitySetter(s, emails);

    return s;
  },
}

const initialState = {
}

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
