import React from 'react'
import find from 'lodash/find'
import { stepTypes } from "./consts";
import { loadEmailTemplates, loadEmailTemplate } from "store/entities/emailTemplates/actions";
import { loadIntegrations } from 'store/entities/integrations/actions'
import { loadSnippets } from "store/entities/snippets/actions";
import { loadFlows, loadFlow, loadNodes } from "store/entities/flows/actions";
import { loadMailboxes } from "store/entities/mailboxes/actions";
import { loadLists } from "store/entities/lists/actions";
import { loadTags, createTag } from "store/entities/tags/actions";
import { getTeamOptions } from "store/entities/users/actions";
import { icons } from 'components/routes/flowsGroup/Flow/Details/visualConsts'
import store from 'store/store'
import { checkPeriodTypes } from "./consts";

export const fieldTypes = {
  COMPOSITE: 'COMPOSITE',
  SELECT: 'SELECT',
  DELAY: 'DELAY',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  RADIO: 'RADIO',
  CHECK: 'CHECK',
  SNIPPET: 'SNIPPET',
  EMAIL: 'EMAIL',
  AB: 'AB',
  FILTERS: 'FILTERS',
  QUALIFICATION: 'QUALIFICATION',
  // CONDITION: 'CONDITION',
}

const serverActionKeys = {
  SEND_EMAIL: 'email',
  MAKE_PHONE_CALL: 'phone_call',
  ADD_CUSTOM_TASK: 'custom_task',
  ADD_SOCIAL_TASK: 'social_task',
  SEND_LEAD_TO_OTHER_FLOW: 'send_to_flow',
  ADD_TO_LIST: 'add_to_list',
  REMOVE_FROM_LIST: 'remove_from_list',
  ASSIGN_TO: 'assign_to_user',
  CHANGE_FIELD_VALUE: 'change_field_value',
  ADD_TAG: 'add_tag',
  REMOVE_TAG: 'remove_tag',
  WITHDRAW: 'social_withdraw_connect',
  CRM: 'export_to_crm',

  // SOCIAL_WRITE_COMMENT: 'social_comment',
  // SOCIAL_LIKE: 'social_like',
  SOCIAL_ACTIVITY: 'social_activity',
  SOCIAL_CONNECT: 'social_connect',
  SOCIAL_SEND_DM: 'social_dm',
  SOCIAL_ENDORSE: 'social_endorse_skills',
  SOCIAL_COMMENT: 'social_comment',
}
const serverTriggerKeys = {
  CHECK_REPLIED: 'check_replied',
  CHECK_DM_REPLIED: 'check_dm_replied',
  EMAIL_REPLIED: 'email_replied',
  SOCIAL_CHECK_CONNECTED: 'social_check_connected',
  EMAIL_LINK_CLICKED: 'email_link_clicked',
  EMAIL_OPENED: 'email_opened',
  EMAIL_FORWARDED: 'email_forwarded',
  FILE_DOWNLOADED: 'file_downloaded',
  PAGE_VISITED: 'page_visited',
};
export const serverRuleKeys = {
  A_B: 'ab_test',
  FILTERS: 'filter',
  QUALIFICATION: 'router',
};
export const serverUtilKeys = {
  TIMER: 'timer',
}

export function getFirstServerNodeId(model, startUiNodeId) {
  let uiNode = find(model.nodes, {id: startUiNodeId});

  while (!uiNode.serverNodeId) {
    uiNode = find(model.nodes, {id: uiNode.after[0]})
  }

  return uiNode.serverNodeId;
}

const getCrmOptions = () => store.dispatch(loadIntegrations()).then(res => res.data.map(({ type }) => ({
  value: type,
  label: type,
})))

const getEmailTemplatesOptions = ({q = ''}) => store.dispatch(loadEmailTemplates({q}))
  .then(r => r.map( template => ({
    value: template.id,
    label: template.name,
    snippet: template.body,
    subject: template.subject,
    attachments: template.attachments,
    design: template.design,
  })))
const getSnippetsOptions = ({q = ''}) => store.dispatch(loadSnippets({q}))
  .then(r => r.data.map( snippet => ({
    value: snippet.id,
    label: snippet.name,
    snippet: snippet.body,
    snippet_dirty: snippet.body_dirty,
  })))

const automationOptions = () => Promise.resolve([
  {
    value: 'auto',
    label: 'Auto',
  }, {
    value: 'manual',
    label: 'Manual',
  }
]);

const getMailboxOptions = ({q = ''}) => store.dispatch(loadMailboxes({q}))
  .then(r => r.map( mailbox => ({
    value: mailbox.id,
    label: mailbox.name || mailbox.email,
  })))

const getEntityValue = (entityId, entitiesName, loader, labelField = 'name') => {
  const entity = store.getState().entities[entitiesName][entityId];
  if (entity) {
    return Promise.resolve({
      value: entity.id,
      label: entity[labelField],
      snippet: entity.body,
    })
  }

  return store.dispatch(loader(entityId))
    .then (entity => ({
      value: entity.id,
      label: entity[labelField],
    }))
}

const getFlowsOptions = ({q = ''}) => store.dispatch(loadFlows({q}))
  .then(r => r.data.map( template => ({
    value: template.id,
    label: template.name,
  })))

const getListsOptions = ({q = '', leadId}) => store.dispatch(loadLists(leadId))
  .then(lists => lists.map( list => ({
    value: list.id,
    label: list.name,
  })))

const getTagsOptions = ({q = ''}) => store.dispatch(loadTags())
  .then(tags => tags.map( tag => ({
    value: tag.name,
    label: tag.name,
  })))

const getUsersOptions = ({q = ''}) => getTeamOptions({withoutCurrent: true});


const getSendEmailSteps = ({nodeId}) => {
  const model = store.getState().flowDetails.workTable;

  const sendEmailSteps = []
  const loadingIds = []
  const loadingPromises = []

  const recursiveCheck = (nodeId) => {
    const curNode = find(model.nodes, {id: nodeId});

    for (const id of curNode.before) {
      recursiveCheck(id);
    }

    if (!curNode.serverNodeId) return;

    const curStep = find(model.flow.nodes, {id: curNode.serverNodeId});

    if (curStep.action !== serverActionKeys.SEND_EMAIL) return;

    const templateId = curStep.payload.template_id;
    const template = store.getState().entities.emailTemplates[templateId];

    if (template) {
      sendEmailSteps.push({
        value: curStep.id,
        label: template.name,
      })
    } else if (loadingIds.indexOf(templateId) === -1) {
      loadingIds.push(templateId)
      loadingPromises.push(
        store.dispatch(loadEmailTemplate(templateId))
          .then( template => sendEmailSteps.push({
            value: curStep.id,
            label: template.name,
          }))
      )
    }
  };

  recursiveCheck(nodeId);

  return Promise.all(loadingPromises).then( () => sendEmailSteps )
}
const getPreviousNodesOfTypes = (actionTypes) => ({nodeId}) => {
  const model = store.getState().flowDetails.workTable;

  const sendMessageSteps = [];
  const loadingPromises = [];
  const foundedIds = {};

  const recursiveCheck = (nodeId) => {
    const curNode = find(model.nodes, {id: nodeId});

    for (const id of curNode.before) {
      recursiveCheck(id);
    }

    if (!curNode.serverNodeId) return;

    const curStep = find(model.flow.nodes, {id: curNode.serverNodeId});

    if (actionTypes.indexOf(curStep.action) == -1) return;

    if (!foundedIds[curStep.id]) {
      foundedIds[curStep.id] = true;

      sendMessageSteps.push({
        value: curStep.id,
        label: curStep.id,
      });
    }
  };

  recursiveCheck(nodeId);

  return Promise.all(loadingPromises).then( () => sendMessageSteps )
}


export const getNodeOptions = () => store.dispatch(loadNodes())
  .then(nodes => console.log(nodes) || nodes.map(node => {
    const nodeType = description[node.type][node.action];
    const label = nodeType ? nodeType.name : 'End';
    return {
      value: node.id,
      label: `#${node.id} - ${label}`,
    }
  }))

const snippetField = {
  label: 'Message',
  key: 'snippet',
  type: fieldTypes.SNIPPET,
  options: getSnippetsOptions,
};

const stopOnReplyTriggerLevels = {
  ALWAYS: 'always',
  NODE: 'flow_node',
  TIMEOUT: 'time_shift',
  NEVER: 'never',
}
const automationField = {
  label: 'Automation',
  key: 'mode',
  type: fieldTypes.RADIO,
  options: automationOptions,
  autoSelect: true,
  default: 'auto',
  cond: () => false,
};
const noteField = {
  label: 'Note',
  key: 'note',
  type: fieldTypes.TEXT,
  cond: (payload) => payload.mode === "manual",
};

const stopOnReplyTrigger = {
    key: 'stop_on_reply',
    fields: [
      {
        label: "Send always, even if lead replied",
        key: "level",
        type: fieldTypes.CHECK,
        default: stopOnReplyTriggerLevels.ALWAYS,
        trueValue: stopOnReplyTriggerLevels.NEVER,
        falseValue: stopOnReplyTriggerLevels.ALWAYS,
      },
    ],
  };

const description = {
  [stepTypes.UTIL]: {
    [serverUtilKeys.TIMER]: {
      name: "Timer",
      icon: "clock",
      fields: [
        {
          label: 'Wait',
          key: 'delay',
          type: fieldTypes.DELAY,
          default: 60*60*24,
        },
      ],
    },
  },
  [stepTypes.ACTION]: {
    [serverActionKeys.SOCIAL_CONNECT]: {
      name: "Connect Person",
      icon: icons.CONNECT,
      fields: [
        automationField,
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
        {
          ...snippetField,
          maxLength: 280,
          withoutAttachments: true,
        },
      ],
    },
    [serverActionKeys.SOCIAL_SEND_DM]: {
      name: "Send Direct Message",
      icon: icons.CHAT,
      fields: [
        automationField,
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
        snippetField,
      ],
      triggers: [
        stopOnReplyTrigger,
        // {
        //   key: 'stop_on_reply',
        //   fields: [
        //     {
        //       key: 'level',
        //       label: 'Stop on reply',
        //       type: fieldTypes.SELECT,
        //       options: () => Promise.resolve([
        //         {
        //           value: stopOnReplyTriggerLevels.ALWAYS,
        //           label: 'Always',
        //         },
        //         {
        //           value: stopOnReplyTriggerLevels.NODE,
        //           label: 'Message Node',
        //         },
        //         {
        //           value: stopOnReplyTriggerLevels.TIMEOUT,
        //           label: 'Time Shift',
        //         },
        //         {
        //           value: stopOnReplyTriggerLevels.NEVER,
        //           label: 'Never',
        //         },
        //       ]),
        //       default: stopOnReplyTriggerLevels.ALWAYS,
        //     },
        //     {
        //       key: 'flow_node_id',
        //       label: 'Message Node',
        //       type: fieldTypes.SELECT,
        //       options: getPreviousNodesOfTypes([serverActionKeys.SOCIAL_SEND_DM]),
        //       cond: (payload) => payload.triggers.stop_on_reply.level === stopOnReplyTriggerLevels.NODE,
        //     },
        //     {
        //       key: 'days',
        //       label: 'Days',
        //       type: fieldTypes.NUMBER,
        //       cond: (payload) => payload.triggers.stop_on_reply.level === stopOnReplyTriggerLevels.TIMEOUT,
        //       default: 7,
        //     },
        //   ],
        // },
      ],
    },
    [serverActionKeys.ADD_CUSTOM_TASK]: {
      name: "Visit Page",
      icon: icons.CUSTOM_TASK,
      fields: [
        automationField,
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.SOCIAL_ACTIVITY]: {
      name: "Social Activity",
      icon: icons.LIKE,
      fields: [
        automationField,
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
        // snippetField,
      ],
    },
    [serverActionKeys.SOCIAL_ENDORSE]: {
      name: "Endorse Skills",
      icon: 'endorse',
      fields: [
        automationField,
        noteField,
        {
          label: "Skills count",
          key: 'amount',
          type: fieldTypes.NUMBER,
        },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.SOCIAL_COMMENT]: {
      name: "Comment Post",
      icon: "comment_li",
      fields: [
        automationField,
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
        {
          ...snippetField,
          withoutAttachments: true,
        },
      ],
    },
    [serverActionKeys.WITHDRAW]: {
      name: "Withdraw Connection",
      icon: icons.CONNECT,
      fields: [
        {
          label: 'Automation',
          key: 'mode',
          type: fieldTypes.RADIO,
          options: () => Promise.resolve([ {
            value: 'auto',
            label: 'Auto',
          }
          ]),
          autoSelect: true,
          default: 'auto',
        },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.SEND_EMAIL]: {
      name: "Send Email",
      icon: icons.SEND_EMAIL,
      fields: [
        automationField,
        noteField,
        // {
        //   label: 'Mailbox',
        //   key: 'mailbox_id',
        //   type: fieldTypes.SELECT,
        //   options: getMailboxOptions,
        //   getValueLabel: (id) => getEntityValue(id, 'mailboxes', loadMailbox, 'email'),
        // },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
        {
          label: 'Template',
          key: 'template',
          type: fieldTypes.EMAIL,
          options: getEmailTemplatesOptions,
          // label: 'Email Template',
          // key: 'template_id',
          // type: fieldTypes.SELECT,
          // options: getEmailTemplatesOptions,
          // getValueLabel: (id) => getEntityValue(id, 'emailTemplates', loadEmailTemplate),
          // creatable: true,
          // CreateComponent: ({...params}) => (
          //   <EmailTemplateDetails
          //     isNew
          //     createEmailTemplate={(theParams) => store.dispatch(createEmailTemplate(theParams))}
          //     loadEmailTemplate={ () => store.dispatch(loadEmailTemplate(params.id))}
          //     updateEmailTemplate={ (theParams) => store.dispatch(updateEmailTemplate(params.id, theParams)) }
          //     onlyForm
          //     {...params}
          //   />
          // ),
          // createParam: (newVal) => ({name: newVal}),
        },
        {
          label: "Attach email history",
          key: "add_history",
          type: fieldTypes.CHECK,
          default: true,
        },
      ],
      triggers: [
        stopOnReplyTrigger,
        // {
        //   key: 'stop_on_reply',
        //   fields: [
        //     {
        //       key: 'level',
        //       label: 'Stop on reply',
        //       type: fieldTypes.SELECT,
        //       options: () => Promise.resolve([
        //         {
        //           value: stopOnReplyTriggerLevels.ALWAYS,
        //           label: 'Always',
        //         },
        //         {
        //           value: stopOnReplyTriggerLevels.NODE,
        //           label: 'Message Node',
        //         },
        //         {
        //           value: stopOnReplyTriggerLevels.TIMEOUT,
        //           label: 'Time Shift',
        //         },
        //         {
        //           value: stopOnReplyTriggerLevels.NEVER,
        //           label: 'Never',
        //         },
        //       ]),
        //       default: stopOnReplyTriggerLevels.ALWAYS,
        //     },
        //     {
        //       key: 'flow_node_id',
        //       label: 'Message Node',
        //       type: fieldTypes.SELECT,
        //       options: getPreviousNodesOfTypes([serverActionKeys.SEND_EMAIL]),
        //       cond: (payload) => payload.triggers.stop_on_reply.level === stopOnReplyTriggerLevels.NODE,
        //     },
        //     {
        //       key: 'days',
        //       label: 'Days',
        //       type: fieldTypes.NUMBER,
        //       cond: (payload) => payload.triggers.stop_on_reply.level === stopOnReplyTriggerLevels.TIMEOUT,
        //       default: 7,
        //     },
        //   ],
        // },
      ],
    },
    [serverActionKeys.CRM]: {
      name: "Export to CRM",
      icon: 'link',
      fields: [
        {
          label: 'CRM',
          key: 'integration_type',
          type: fieldTypes.SELECT,
          options: getCrmOptions,
        },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.ADD_TAG]: {
      name: "Add Tag",
      icon: icons.ADD_TO_LIST,
      fields: [
        {
          label: 'Tag',
          key: 'tag',
          type: fieldTypes.SELECT,
          options: getTagsOptions,
          getValueLabel: tag => Promise.resolve(tag),
          creatable: true,
          createFn: (name) => store.dispatch(createTag({name})).then(tag => tag.name),
        },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.ASSIGN_TO]: {
      name: "Assign To",
      icon: icons.ASSIGN_TO,
      fields: [
        {
          label: 'User',
          key: "user_id",
          type: fieldTypes.SELECT,
          options: getUsersOptions,
        },
        {
          label: 'List',
          key: "list_id",
          type: fieldTypes.SELECT,
          options: ({ payload }) => getListsOptions({leadId: payload.user_id}),
          cond: (paylaod) => paylaod.user_id,
        },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.SEND_LEAD_TO_OTHER_FLOW]: {
      name: "Send Lead To New Flow",
      icon: icons.SEND_CONTACT_IN_NEW_FLOW,
      fields: [
        {
          label: 'New Flow',
          key: 'flow_id',
          type: fieldTypes.SELECT,
          options: getFlowsOptions,
          getValueLabel: (id) => getEntityValue(id, 'flows', loadFlow),
        },
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    [serverActionKeys.MAKE_PHONE_CALL]: {
      name: "Phone Call",
      icon: icons.PHONE_CALL,
      fields: [
        {
          label: 'Automation',
          key: 'mode',
          type: fieldTypes.RADIO,
          options: () => Promise.resolve([ {
              value: 'manual',
              label: 'Manual',
            }
          ]),
          autoSelect: true,
          default: 'manual',
        },
        noteField,
        {
          label: 'Delay',
          key: 'delay',
          type: fieldTypes.DELAY,
        },
      ],
    },
    // [serverActionKeys.ADD_TO_LIST]: {
    //   name: "Add To List",
    //   icon: icons.ADD_TO_LIST,
    //   fields: [
    //     {
    //       label: 'List',
    //       key: 'list_id',
    //       type: fieldTypes.SELECT,
    //       options: getListsOptions,
    //     },
    //     {
    //       label: 'Delay',
    //       key: 'delay',
    //       type: fieldTypes.DELAY,
    //     },
    //   ],
    // },
    // [serverActionKeys.REMOVE_FROM_LIST]: {
    //   name: "Remove From List",
    //   icon: icons.REMOVW_FROM_LIST,
    //   fields: [
    //     {
    //       label: 'List',
    //       key: 'list_id',
    //       type: fieldTypes.SELECT,
    //       options: getListsOptions,
    //     },
    //     {
    //       label: 'Delay',
    //       key: 'delay',
    //       type: fieldTypes.DELAY,
    //     },
    //   ],
    // },
  },
  [stepTypes.RULE]: {
    [serverRuleKeys.FILTERS]: {
      name: "Filter",
      icon: 'filter',
      fields: [
        {
          label: "Filters",
          key: 'branches',
          type: fieldTypes.FILTERS,
        },
      ],
    },
    [serverRuleKeys.A_B]: {
      name: "A/B  Testing",
      icon: 'ab',
      fields: [
        {
          label: 'Branches',
          key: 'branches',
          type: fieldTypes.AB,
        },
      ],
    },
    [serverRuleKeys.QUALIFICATION]: {
      name: 'Manual Router',
      icon: 'pointer',
      fields: [
        {
          label: 'Branches',
          key: 'branches',
          type: fieldTypes.QUALIFICATION,
        },
      ],
    },
  },
  [stepTypes.TRIGGER]: {
    [serverTriggerKeys.SOCIAL_CHECK_CONNECTED]: {
      name: "Check Connection",
      icon: icons.CHECK_CONNECTION,
      fields: [
        {
          label: 'Wait for LinkedIn Connect',
          key: 'delay',
          type: fieldTypes.DELAY,
          default: 86400,
        },
      ],
      branchLabels: ['No Connect', 'Connected'],
    },
    [serverTriggerKeys.CHECK_REPLIED]: {
      name: "Check Replied",
      icon: icons.CHAT,
      fields: [
        {
          label: 'Wait for LinkedIn Reply',
          key: 'delay',
          type: fieldTypes.DELAY,
          default: 86400,
        },
        {
          key: 'check_period',
          label: 'Check Period',
          type: fieldTypes.COMPOSITE,
          default: {
            type: checkPeriodTypes.FLOW,
            days: 7,
          },
          PayloadComponent: ({ field, payload }) => {
            const labelsMap = {
              [checkPeriodTypes.FLOW]: 'While in this Flow',
              [checkPeriodTypes.DAYS]: `${payload[field.key]?.days} Days`,
              [checkPeriodTypes.ALL_TIME]: `All Time`,
            }
            return labelsMap[payload[field.key]?.type] || 'error';
          },
          fields: [
            {
              label: 'Check Period',
              key: 'type',
              type: fieldTypes.RADIO,
              options: () => Promise.resolve([
                {
                  value: checkPeriodTypes.FLOW,
                  label: 'In this Flow',
                },
                {
                  value: checkPeriodTypes.DAYS,
                  label: 'X Days',
                },
                {
                  value: checkPeriodTypes.ALL_TIME,
                  label: 'All Time',
                },
              ]),
              default: checkPeriodTypes.FLOW,
            },
            {
              key: 'days',
              label: 'Days',
              type: fieldTypes.NUMBER,
              cond: (payload) => payload?.check_period?.type === checkPeriodTypes.DAYS,
              default: 7,
            },
          ],
        },
      ],
      branchLabels: ['No Reply', 'Replied'],
    },
    [serverTriggerKeys.CHECK_DM_REPLIED]: {
      name: "Check Direct Message Replied",
      icon: icons.CHAT,
      isHidden: true,
      fields: [
        {
          label: 'Wait for Reply',
          key: 'delay',
          type: fieldTypes.DELAY,
          default: 86400,
        },
      ],
      branchLabels: ['No Reply', 'Replied'],
    },
    // [serverTriggerKeys.EMAIL_LINK_CLICKED]: {
    //   name: "Email Link Click",
    //   icon: icons.EMAIL_LINK_CLICK,
    //   fields: [
    //     {
    //       label: 'Node',
    //       key: "node_id",
    //       type: fieldTypes.SELECT,
    //       options: getSendEmailSteps,
    //     },
    //     {
    //       label: 'Delay',
    //       key: 'delay',
    //       type: fieldTypes.DELAY,
    //       default: 86400,
    //     },
    //   ],
    // },
    [serverTriggerKeys.EMAIL_REPLIED]: {
      name: "Email Replied",
      icon: icons.EMAIL_LINK_CLICK,
      isHidden: true,
      fields: [
        // {
        //   label: 'Node',
        //   key: "node_id",
        //   type: fieldTypes.SELECT,
        //   options: getSendEmailSteps,
        // },
        {
          label: 'Wait for Email Reply',
          key: 'delay',
          type: fieldTypes.DELAY,
          default: 86400,
        },
      ],
      branchLabels: ['No Reply', 'Replied'],
    },
    // [serverTriggerKeys.EMAIL_OPENED]: {
    //   name: "Email Open",
    //   icon: icons.EMAIL_OPEN,
    //   fields: [
    //     {
    //       label: 'Node',
    //       key: "node_id",
    //       type: fieldTypes.SELECT,
    //       options: getSendEmailSteps,
    //     },
    //     {
    //       label: 'Delay',
    //       key: 'delay',
    //       type: fieldTypes.DELAY,
    //       default: 86400,
    //     },
    //   ],
    // },
    // [serverTriggerKeys.EMAIL_FORWARDED]: {
    //   name: "Email Forwarded",
    //   icon: icons.EMAIL_FORWARDED,
    //   fields: [
    //     {
    //       label: 'Node',
    //       key: "node_id",
    //       type: fieldTypes.SELECT,
    //       options: getSendEmailSteps,
    //     },
    //     {
    //       label: 'Delay',
    //       key: 'delay',
    //       type: fieldTypes.DELAY,
    //       default: 86400,
    //     },
    //   ],
    // },
    // [serverTriggerKeys.FILE_DOWNLOADED]: {
    //   name: "File Download",
    //   icon: icons.FILE_DOWNLOAD,
    //   fields: [
    //     {
    //       label: 'Node',
    //       key: "node_id",
    //       type: fieldTypes.SELECT,
    //       options: getSendEmailSteps,
    //     },
    //     {
    //       label: 'Delay',
    //       key: 'delay',
    //       type: fieldTypes.DELAY,
    //       default: 86400,
    //     },
    //   ],
    // },
    // [serverTriggerKeys.PAGE_VISITED]: {
    //   name: "Visit Page",
    //   icon: icons.VISIT_PAGE,
    //   fields: [
    //     {
    //       label: 'URL Wildcard',
    //       key: 'page',
    //       type: fieldTypes.TEXT,
    //       placeholder: `https://your.website.com/shop/*/buy`,
    //     },
    //     {
    //       label: 'Delay',
    //       key: 'delay',
    //       type: fieldTypes.DELAY,
    //       default: 86400,
    //     },
    //   ],
    // },
  },
  [stepTypes.END]: {},
}

for (const nodeType of Object.values(description)) {
  for (const id in nodeType) {
    nodeType[id].id = id;
  }
}

export {
  description as default,
  description,
  serverActionKeys
}
