import React from "react";
import valueComponents from "./valueComponents";
import DefaultValue from "./valueComponents/DefaultValue";

import styles from './FieldPreview.module.scss'


const FieldPreview = ({field, payload}) => {
  const ValueComponent = field.PayloadComponent || valueComponents[field.type] || DefaultValue;

  return (
    <div className={styles.container}>
      {field.label && <span className={styles.label}> { field.label }: </span> }
      <ValueComponent field={field} payload={payload} />
    </div>
  )
}

export default FieldPreview;
