import { useState, useEffect } from 'react';

const windowMatchMedia = (size) => window.matchMedia(`(max-width: ${size}px)`);

// 'example (max-width: 600px)'
function useMatchMedia(size) {
  const query = () => windowMatchMedia(size).matches;
  // Состояние и сеттер для отложенного значения
  const [matched, setMatched] = useState(query);

  useEffect(
    () => {
      const resizeFunc = () => setMatched(query);

      resizeFunc();
      window.addEventListener('resize', resizeFunc);

      return () => window.removeEventListener('resize', resizeFunc);
    },
    [query]);

  return matched;
}

export {
  windowMatchMedia,
  useMatchMedia
}
