import * as actions from './consts'

import getAxios from 'services/axios'
import { createCachedFunction } from "../utils";

const axios = getAxios('flow');

export const setTemplates = (templates) => {
  if (!(templates instanceof Array)) {
    templates = [templates];
  }
  return {
    type: actions.EMAIL_TEMPLATES_SET,
    templates,
  }
}


const cacher = createCachedFunction((params, cancelToken) => (dispatch) => {
  return axios.get('/api/email-templates', {
    cancelToken,
    params,
  })
    .then( temps => {
      dispatch(setTemplates(temps))

      return temps;
    })
}, {recursively: true})

export const loadEmailTemplates = cacher.cachedFunction;

export const loadEmailTemplatesWithPagination = (params, cancelToken) => (dispatch) => {
  return axios.get('/api/email-templates/list', {
    cancelToken,
    params,
  })
    .then( res => {
      dispatch(setTemplates(res.data))

      return res;
    })
}

export const loadEmailTemplate = (id) => (dispatch, getState) => {
  return axios.get(`/api/email-templates/${id}`)
  .then( template => {
    dispatch(setTemplates(template))

    return template;
  })
}

export const createEmailTemplate = (params) => (dispatch, getState) => {
  return axios.post(`/api/email-templates`, params)
  .then( template => {
    cacher.clearCache()
    dispatch(setTemplates(template))

    return template;
  })
}

export const updateEmailTemplate = (id, params) => (dispatch, getState) => {
  return axios.put(`/api/email-templates/${id}`, params)
  .then( template => {
    cacher.clearCache()
    dispatch(setTemplates(template))

    return template;
  })
}

export const deleteEmailTemplate = (id) => (dispatch, getState) => {
  return axios.delete(`/api/email-templates/${id}`)
  .then( res => {
    cacher.clearCache()
    dispatch({
      type: actions.EMAIL_TEMPLATE_DELETE_SUCCESS,
      id,
    })

    return res;
  })
}
