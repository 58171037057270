import createModel from "./handlers/createModel";
import createRealNodesAndSetParents from "./handlers/createRealNodesAndSetParents";
import createPlusNodes from "./handlers/createPlusNodes";
import recalculate from "./recalculate";

export default function (flow) {
  const model = createModel(flow);
  createRealNodesAndSetParents(model);
  createPlusNodes(model);
  recalculate(model);

  return model;
}
