export const messageStatuses = {
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  DONE: 'done',
  FAILED: 'failed',
  CANCELED: 'canceled',
  DELEGATED: 'delegated'
}

export const messageTypes = {
  INBOX: 'inbox',
  OUTBOX: 'outbox',
}

export const messageAutomations = {
  AUTO: 'auto',
  NATIVE: 'synced',
  MANUAL: 'manual',
  CONNECT: 'connect',
  AMO: 'amo_synced',
}
