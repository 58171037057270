import find from 'lodash/find';
import nodeTypes from "../nodeTypes";
import createNode from './createNode';
import mergeParents from './mergeParents';

export default (model) => {
  for (const serverNode of model.flow.nodes) {
    createUiNodeRecursively(model, serverNode);
  }


  return model;
}

function createUiNodeRecursively(model, serverNode) {
  let uiNode = find(model.nodes, {serverNodeId: serverNode.id});
  if (uiNode) return uiNode;

  uiNode = createNode(model, {
    serverNodeId: serverNode.id,
    union: null,
    before: [],
    after: [],
  });

  const uiParentNodes = serverNode.before
    .map(before => find(model.flow.nodes, {id: before.node_id}))
    .map(theServerNode => createUiNodeRecursively(model, theServerNode))
    .map(uiNode => [uiNode.serverNodeId, ...uiNode.parents]);

  uiNode.parents = mergeParents(uiParentNodes)
  return uiNode;
}
