import find from 'lodash/find'

export function getNextServerNodeId(model, plusId) {
  let uiNode = find(model.nodes, {id: plusId});

  while (true) {
    if (uiNode.serverNodeId) {
      return uiNode.serverNodeId;
    }
    uiNode = find(model.nodes, {id: uiNode.after[0]});
  }
}

export function getBeforeOfPlus(model, plusNodeId) {
  const res = [];

  const plusNode = find(model.nodes, {id: plusNodeId});
  for (const id of plusNode.before) {
    rec(id, res, plusNodeId);
  }

  function rec(uiNodeId, res, fromUiId) {
    const uiNode = find(model.nodes, {id: uiNodeId});
    if (uiNode.serverNodeId) {
      const serverNode = find(model.flow.nodes, {id: uiNode.serverNodeId});
      const index = uiNode.after.indexOf(fromUiId);
      res.push({
        node_id: serverNode.id,
        branch_id: serverNode.after[index].branch_id,
      });
    } else {
      for (const id of uiNode.before) {
        rec(id, res, uiNodeId);
      }
    }
  }

  return res;
}
