import { createSelector } from "reselect";
import store from 'store/store'
import { getAuthUserId } from "../../auth/selectors";
import { getTeam } from "./actions";

const sliceSelector = (state) => state.entities.users || {};

const getUserById = (state, id) => {
  const user = sliceSelector(state)[id];

  if (!user) {
    store.dispatch(getTeam());
  }

  return user;
}

const userIdsSelector = createSelector(
  [sliceSelector], (users) => Object.keys(users)
);

const usersArraySelector = createSelector(
  [sliceSelector], (users) => Object.values(users)
);

const getCurrentUser = (state) => {
  return state.entities.users[getAuthUserId(state)];
};
const isMailboxError = createSelector(
  [getCurrentUser],
  (user) => {
    const mailbox = user && user.mailbox;
    if (mailbox && (mailbox.send_status === 'error' || mailbox.sync_status === 'error')) {
      return  true;
    }

    return false
  }
)
const getCurrentUserServer = (state) => {
  return state.entities.users[getAuthUserId(state)].server;
};

export {
  sliceSelector as usersSelector,
  getUserById,
  usersArraySelector,
  userIdsSelector,
  getCurrentUser,
  isMailboxError,
  getCurrentUserServer,
};

