import * as consts from './consts'
import { entitySetter } from "../entities/utils";

const initialState = {
  servers: {},
  selectedId: null,
  status: consts.proxyStatuses.NEUTRAL,
}

const ACTION_HANDLERS = {
  [consts.PROXY_SELECT]: (state, {id}) => ({
    ...state,
    selectedId: id,
  }),
  [consts.PROXY_CLEAR]: (state) => ({
    ...state,
    selectedId: null,
  }),
  [consts.PROXY_SET_STATUS]: (state, { status }) => ({
    ...state,
    status,
  }),
  [consts.PROXY_SET_ENTITIES]: (state, {servers}) => ({
    ...state,
    servers: entitySetter(state.servers, servers),
  }),
};

export default function proxyReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
