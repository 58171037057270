import React from 'react'
import PropTypes from 'prop-types'

import Textarea from 'components/common/inputs/Textarea'

import styles from "./Styles.module.scss"
import formStyles from "components/common/inputs/FormRows/Styles.module.scss"

const TextInput = ({ value, onChange, field, readOnly }) => (
  <label className={styles.container + ' ' + formStyles.gsFormRow}>
    <div className={formStyles.label}> { field.label } </div>
    <Textarea value={value}
              onChange={ e => onChange(e.target.value) }
              style={{resize: 'none'}}
              placeholder={field.placeholder}
              readOnly={readOnly}
    />
  </label>
)

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TextInput
