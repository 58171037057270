import bellsoftPath from 'assets/companies/bellsoft.png';
import buzzGuruPath from 'assets/companies/BuzzGuru.png';
import cofixPath from 'assets/companies/cofix.png';
import crocPath from 'assets/companies/croc.svg';
import displayforcePath from 'assets/companies/displayforce.png';
import itglobalPath from 'assets/companies/itglobal.png';
import ivideonPath from 'assets/companies/ivideon.svg';
import kasperskyPath from 'assets/companies/kaspersky.svg';
import kbPath from 'assets/companies/kb-12.png';
import mrgPath from 'assets/companies/mrg.svg';
import ocoPath from 'assets/companies/oco.svg';
import selectyPath from 'assets/companies/selecty.png';
import ttPath from 'assets/companies/tt.png';
import yvaPath from 'assets/companies/Yva.png';


export const companiesList = [
  {
    logoPath: kasperskyPath
  },
  {
    logoPath: mrgPath
  },
  {
    logoPath: cofixPath
  },
  {
    logoPath: crocPath
  },
  {
    logoPath: displayforcePath
  },
  {
    logoPath: ivideonPath
  },
  {
    logoPath: itglobalPath
  },
  {
    logoPath: yvaPath
  },
  {
    logoPath: ocoPath
  },
  {
    logoPath: selectyPath
  },

  {
    logoPath: buzzGuruPath
  },
  {
    logoPath: bellsoftPath
  },
  {
    logoPath: kbPath
  },


  {
    logoPath: ttPath
  },

]
