import React, { useEffect, useState, memo } from 'react'
import { useSelector } from 'react-redux'
import getAxios from "services/axios";
import createPopover from "components/hocs/createPopover";
import { getCurrentUser } from "store/entities/users/selectors";
import TeamPopoverContent from "./TeamPopoverContent";

import style from './UserMenu.module.scss'

const AvaPop = createPopover(TeamPopoverContent)

export const UserMenu = memo(() => {
  const currentUser = useSelector(getCurrentUser);
  const firstNameLatter = currentUser.first_name[0];
  const lastNameLatter = currentUser.last_name[0];
  const userColor = currentUser.color;
  const [totalEvents, setTotalEvents] = useState(0);
  const [isPersistent, setIsPersistent] = useState(false);

  useEffect(() => {
    getAxios('flow')
      .get('/api/social-profiles')
      .then(profile => {
        let events = 0;
        for (let userId of Object.keys(profile.aggs.users)) {
          if (userId == currentUser.id) continue;

          events += (profile.aggs.users[userId].unread_chats_count || 0)
        }

        setTotalEvents(events);
      })
  })

  return (
    <div className={style.userMenu}>
      <AvaPop preferPlace="right" persistent={isPersistent} setIsPersistent={setIsPersistent}>
        <div className={style.ava}  style={{backgroundColor: userColor || '#6A78D1'}}>
          { totalEvents > 0 && <div className={style.badge}> {totalEvents }</div> }
          { firstNameLatter }{ lastNameLatter }
        </div>
      </AvaPop>

      <div className={style.userMenu__item}>
        {currentUser.first_name} {currentUser.last_name}
      </div>
    </div>
  )
})
