import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import commonStyles from '../Styles.module.scss'
import styles from './Radio.module.scss'

const Radio = ({ additionalInfo, isError, label, orange, reversed, ...other}) => (
  <div
    className={cn(styles.wrapper, {[commonStyles.AdditionalInfo]: additionalInfo, [commonStyles.Error]: isError})}
    data-additional-info={additionalInfo}
  >
    <label className={styles.label}>
      { !reversed && label }
      <input
        className={styles.input}
        type="radio"
        readOnly
        {...other}
      />
      <span
        className={cn(styles.btn, {
          [styles.orange]: orange,
          [styles.reversed]: reversed,
        })}
        role="button"
      />
      { reversed && (
        <span style={{marginLeft: '1em'}}>
          { label }
        </span>
      ) }
    </label>
  </div>
)

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  label: PropTypes.string,
  reversed: PropTypes.bool,
  orange: PropTypes.bool,
};

export {
  Radio as default,
  Radio,
}
