import store from 'store/store';
import { injectReducer } from 'store/reducers';
import Loadable from 'react-loadable';
import Loading from "components/common/Loading";

import Error404 from 'components/routes/NotFound'
import { initAction } from './reducer/actions';

export { default as path } from './path'

const DashboardPage = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName:'DashboardPage' */ './DashboardPage');

    return import(/* webpackChunkName:'DashboardPage' */ './reducer/reducer')
      .then( reducer => injectReducer(store, { key: reducer.key, reducer: reducer.default }) )
      .then( () => {
        store.dispatch(initAction());
        return component;
      })
      .catch( e => console.log('Page loading err', e) || Error404 )
  },
  loading: Loading,
});

export default DashboardPage;
