import Loadable from 'react-loadable';
import Loading from "components/common/Loading";
import Error404 from 'components/routes/NotFound'
import { injectReducer } from "store/reducers";
import store from "store/store";
export { default as path } from './path'

const FlowConstructorPage = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName: 'SignupPage' */ './FlowConstructor');

    return import (/* webpackChunkName: 'SignupPage' */ '../authGroup/Signup/reducer')
      .then( (reducer) => injectReducer(store, { key: 'signupPage', reducer: reducer.default }) )
      .then( () =>  component)
      .catch(e => console.log('PAGE LOADING ERROR', e) || Error404)
  },
  loading: Loading,
});

export default FlowConstructorPage;
