import React from 'react'
import PropTypes from 'prop-types'
import Icon from "components/common/elements/Icon";
import Input from 'components/common/inputs/Input'

import BranchTo from "./BranchTo";

import styles from './Styles.module.scss'


const AbInput = ({ readOnly, value, onChange, nodeId, model, description }) => {
  const [nextBranchId, setNextBranchId] = React.useState(value ? Math.max(...value.map(v => v.branch_id)) + 1 : 3)
  const branches = value || [];

  function onChangeRow(index, value) {
    value = value.replace(/\D/g, '');
    value = parseInt(value);
    value = Math.min(value, 100);
    value = value || '';

    const newBranches = [...branches];
    newBranches[index] = {
      ...newBranches[index],
      value,
    };

    onChange(newBranches);
  }
  function addBranch() {
    onChange([
      ...branches,
      {
        branch_id: nextBranchId,
        value: 0,
      }
    ]);
    setNextBranchId(nextBranchId + 1);
  }
  function removeBranch(index) {
    const newBranches = [...branches];
    newBranches.splice(index, 1);
    onChange(newBranches);
  }

  return (
    <div className={styles.container}>
      <div className={styles.branchTitle}>
        <label> Branches </label>

        { !readOnly && <span onClick={addBranch} className={styles.addBranch}> Add Branch </span> }
      </div>

      { branches.map(({branch_id, value}, index) => (
        <div key={branch_id} className={styles.abItem}>
          <Input value={value} onChange={e => onChangeRow(index, e.target.value)} readOnly={readOnly} className={styles.abInput}/>
          <span style={{marginLeft: '6px'}}> % </span>
          <BranchTo uiNodeId={nodeId} branchId={branch_id} model={model} description={description}/>
          { !readOnly && branches.length > 2 && <Icon icon="Cross" onClick={() => removeBranch(index)} size={20} className={styles.abCross}/>}
        </div>
      ))}
    </div>
  )
}

AbInput.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export {
  AbInput as default,
  AbInput,
}
