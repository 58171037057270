import { createSelector } from "reselect";

export const createSelectionActions = ({
  type, // тип редаксовского экшена, который будет диспатчиться
  getSelectionState, // функция. получает функцию getState. возвращает состаяние с полями all:bool и ids:object
  getIdsOfLoaded, // функция. получает функцию getState. возвращает массив ид загруженных сущностей
}) => ({
  toggleOne: (id) => (dispatch, getState) => {
    const selection = getSelectionState(getState);
    const ids = {...selection.ids};

    if (ids[id]) {
      delete ids[id];
    } else {
      ids[id] = true;
    }

    dispatch({
      type,
      ids,
      all: selection.all,
    })
  },
  togglePage: () => (dispatch, getState) => {
    const loadedIds = getIdsOfLoaded(getState);
    const selection = getSelectionState(getState);
    const selectionIdsAsArr = Object.keys(selection.ids)

    let all, ids;
    if (selection.all && selectionIdsAsArr.length) {
      all = true;
      ids = {};
    }
    else if (selection.all && !selectionIdsAsArr.length) {
      all = false;
      ids = {};
    }
    // selection.all === false
    else if (selectionIdsAsArr.length < loadedIds.length) {
      all = false;
      ids = loadedIds.reduce( (res, id) => {
        res[id] = true;
        return res;
      }, {});
    } else {
      all = false;
      ids = {};
    }

    dispatch({
      type,
      all,
      ids,
    })
  },
  selectAll: () => ({
    type,
    all: true,
    ids: {},
  }),
})


export const getSelectionCountersGetters = ({
  selection,
  total,
  loadedCount,
}) => ({
  getSelectedCount: createSelector(
    [selection, total],
    (selection, total) => {
      const idsCount = Object.keys(selection.ids).length;

      if (selection.all) {
        return total - idsCount
      }

      return idsCount;
    }
  ),
  getShowSelectAll: createSelector(
    [selection, total, loadedCount],
    (selection, total, loadedCount) => {
      if (selection.all) return false;
      if (loadedCount == total) return false;
      if (Object.keys(selection.ids).length == loadedCount) return true;
      return false;
    }
  ),
})

export const getIsSelected = ({
  selection,
  id,
}) => createSelector(
  [selection, id],
  (selection, id) => {
    const { all, ids } = selection;

    if (!ids[id] == all) return true;
    return false;
  }
)

export const getTripleSelectionState = ({
  selection,
  loadedIds,
  total,
}) => createSelector(
  [selection, loadedIds, total],
  (selection, loadedIds, total) => {
    if (!total) {
      return {
        any: false,
        all: false,
      }
    }

    const res = {}
    const { all, ids } = selection;
    const selectionIds = Object.keys(ids);

    if (all) {
      res.all = selectionIds.length == 0;
      res.any = selectionIds.length < total;
    } else {
      res.all = selectionIds.length == loadedIds.length;
      res.any = selectionIds.length > 0;
    }

    return res;
  }
)
