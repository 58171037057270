import * as actions from "./consts";
import getAxios from 'services/axios'
import { createCachedFunction } from "../utils";
import store from "store/store";
import { getAuthUserId } from "store/auth/selectors";

const axios = getAxios('contact');

const cacher = createCachedFunction((userId) => (dispatch, getState) => {
  return axios.get(`/api/lists`, {params: {user_id: userId}})
    .then( lists => {
      dispatch({
        type: actions.LISTS_GET_SUCCESS,
        lists,
      })

      return lists;
    })
}, {recursively: true})

export const loadLists = cacher.cachedFunction;

export const loadListsWithPagination = ({limit, offset}) => (dispatch) => {
  return axios.get(`/api/lists/search`, {params: {limit, offset}})
    .then(res => {
      dispatch({
        type: actions.LISTS_GET_SUCCESS,
        lists: res.data,
      })

      return res;
    })
}

export const deleteList = (id) => (dispatch, getState) => {
  return axios.delete(`/api/lists/${id}`)
    .then( res => {
      cacher.clearCache(); // надо ли??
      dispatch({
        type: actions.LIST_DELETE_SUCCESS,
        ids: [id],
      })

      return res;
    })
}

export const updateList = (id, data) => (dispatch, getState) => {
  return axios.put(`/api/lists/${id}`, data)
    .then( list => {
      cacher.clearCache();
      dispatch({
        type: actions.LISTS_GET_SUCCESS,
        lists: [list],
      })

      return list;
    })
}

export const createList = (name, { flow_id, user_id } = {}) => (dispatch, getState) => {
  return axios.post('/api/lists', {name, flow_id, user_id})
    .then(list => {
      cacher.clearCache();
      dispatch({
        type: actions.LISTS_GET_SUCCESS,
        lists: [list],
        isNew: user_id === getAuthUserId(getState()),
      });

      return list;
    })
}

export const getListOptions = (query, userId) => store.dispatch(loadLists(userId))
  .then(lists => lists.reduce((res, list) => {
    if (RegExp(query, 'i').test(list.name)) {
      res.push({value: list.id, label: list.name});
    }

    return res;
  }, []));
