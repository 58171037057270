import * as actions from './consts'

export const userStatuses = {
  LOADING: 'LOADING',
  AUTH: 'AUTH',
  UNAUTH: 'UNAUTH',
}

const ACTION_HANDLERS = {
  [actions.LOGIN_REQUEST]: (state) => ({
    ...state,
    status: userStatuses.LOADING,

  }),
  [actions.LOGIN_SUCCESS]: (state, { id }) => ({
    ...state,
    status: userStatuses.AUTH,
    id,
  }),
  [actions.LOGIN_FAILURE]: (state) => ({
    ...state,
    status: userStatuses.UNAUTH,
  }),
  [actions.SIGNUP_SUCCESS]: (state, { id }) => ({
    ...state,
    status: userStatuses.AUTH,
    id,
  }),
  [actions.LOGOUT_SUCCESS]: (state) => ({
    ...state,
    status: userStatuses.UNAUTH,
    id: null,
  }),
};

let initialState = {
  status: userStatuses.LOADING,
  id: null,
};


export default function userReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
