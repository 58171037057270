import * as actions from './consts'

import getAxios from 'services/axios'
import { createCachedFunction } from "../utils";

const axios = getAxios('flow');

export const setSnippets = (snippets) => {
  if (!(snippets instanceof Array)) {
    snippets = [snippets];
  }
  return {
    type: actions.SNIPPETS_SET,
    snippets,
  }
}


const cacher = createCachedFunction((params, cancelToken) => (dispatch) => {
  return axios.get('/api/snippets', {
    cancelToken,
    params,
  })
    .then( res => {
      dispatch(setSnippets(res.data))

      return res;
    })
}, {recursively: true})

export const loadSnippets = cacher.cachedFunction;

export const loadSnippet = (id) => (dispatch, getState) => {
  return axios.get(`/api/snippets/${id}`)
  .then( snippet => {
    dispatch(setSnippets(snippet))

    return snippet;
  })
}

export const createSnippet = (params) => (dispatch, getState) => {
  return axios.post(`/api/snippets`, params)
  .then( snippet => {
    cacher.clearCache()
    dispatch(setSnippets(snippet))

    return snippet;
  })
}

export const updateSnippet = (id, params) => (dispatch, getState) => {
  return axios.put(`/api/snippets/${id}`, params)
  .then( snippet => {
    cacher.clearCache()
    dispatch(setSnippets(snippet))

    return snippet;
  })
}

export const deleteSnippet = (id) => (dispatch, getState) => {
  return axios.delete(`/api/snippets/${id}`)
  .then( res => {
    cacher.clearCache()
    dispatch({
      type: actions.SNIPPETS_DELETE_SUCCESS,
      id,
    })

    return res;
  })
}
