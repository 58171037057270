import React, { useState, useMemo } from "react";
import { createPortal } from "react-dom";

export const MIN_FRAME_HEIGHT = 150;

export function resizeIFrameToFitContent(iFrame, {max = Number.MAX_SAFE_INTEGER} = {}) {
  const body = iFrame.contentWindow.document.body;
  const margin = Number.parseFloat(window.getComputedStyle(body).marginTop);
  const height = body.lastElementChild?.getBoundingClientRect().bottom;

  if (height) {
    iFrame.height = Math.min(height + (margin), max);
  }
}

export const IFrame = ({
  children,
  iframeRef = {},
  ...props
}) => {
  const [contentRef, setContentRef] = useState(null)
  const mountNode = contentRef && contentRef.contentWindow && contentRef.contentWindow.document.body

  return (
    <iframe {...props} ref={theRef => {
      setContentRef(theRef);
      if (typeof iframeRef === 'function') {
        iframeRef(theRef);
      } else {
        iframeRef.current = theRef;
      }
    }}>
      { mountNode && createPortal(children, mountNode) }
    </iframe>
  )
}

export const InnerContent = (props) => {
  const { innerHtml, onLoaded, onSeeMore = null, className } = props
  const wrapRef = React.useRef();
  const [showMore, setShowMore] = useState(false)
  const resizeObserver = useMemo(() => new ResizeObserver(onLoaded), [onLoaded]);
  const parsedMessage = onSeeMore ? parseMessageContent(innerHtml) : {
    message: innerHtml
  };

  React.useEffect(() => {
    onLoaded();

    resizeObserver.observe(wrapRef.current, {
      childList: true,
      attributes: true,
      characterData: true
    });

    return () => {
      resizeObserver.disconnect();
    }
  }, [])

  React.useEffect(() => {
    if (onSeeMore) {
      onSeeMore();
    }
  }, [showMore])

  const handleSeeMore = () => {
    resizeObserver.disconnect();
    setShowMore((prev) => !prev);
  }

  return (
    <>
      <FrameStyles/>
      <div
        ref={wrapRef}
        className={className}
        dangerouslySetInnerHTML={{
          __html: parsedMessage.message.replaceAll('height', '')
        }}
      />
      {parsedMessage.replyHistory && (
        <>
          {!showMore && (
            <button
              className="more-btn"
              onClick={handleSeeMore}
            >
              see more
            </button>
          )}
          <div
            style={showMore ? {} : {display: 'none'}}
            dangerouslySetInnerHTML={{
              __html: parsedMessage.replyHistory.replaceAll('height', '')
            }}/>
        </>
      )}
    </>
  )
}

const FrameStyles = () => {
  return (
    <style dangerouslySetInnerHTML={{__html: `
      body{
        margin-left: 0px;
        margin-right: 0px;
        font: 500 14px/1.4 'Lucida Grande', arial;
      }
      * {
        font: 500 14px/1.4 'Lucida Grande', arial;
      }
      html, body, div, span, applet, object,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre, a,
      abbr, acronym, address, big, cite, code, del,
      dfn, em, img, ins, kbd, q, s, samp, small, strike,
      strong, sub, sup, tt, var, b, u, i, center, dl, dt,
      dd, ol, ul, li, fieldset, form, label, legend, table,
      caption, tbody, tfoot, thead, tr, th, td, article, aside,
      canvas, details, embed,  figure, figcaption, footer,
      header, hgroup,  menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }
      blockquote {
        margin: 0px 0px 0px 0.8ex;
        border-left: 1px solid rgb(204,204,204);
        padding-left: 1ex;
      }
      .more-btn {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0px;
        color: #00A4BD;
      }
    `
    }}/>
)}


const parseMessageContent = (content) => {
  const FIRST_SEARCH_TERM = `<blockquote`;
  const firstIndex = content.indexOf(FIRST_SEARCH_TERM) || 0;

  return {
    message: content.slice(0, firstIndex),
    replyHistory: (firstIndex > 0) ? content.slice(firstIndex) : null
  }
}
