import React from 'react'
import Loadable from 'react-loadable';
// import store from 'store/store'
// import { injectReducer } from 'store/reducers'
import Loading from "components/common/Loading";
import Error404 from 'components/routes/NotFound'
export { default as path } from "./path";

export default Loadable({
  loader: () => {
    const component = import(/* webpackChunkName:'ChangePasswordPage' */ './container');

    return  component.catch( e => console.log('Page loading err', e) || Error404 );

    // return import(/* webpackChunkName:'ChangePasswordPage' */ './reducer')
    //   .then( reducer => injectReducer(store, { key: reducer.key, reducer: reducer.default }) )
    //   .then( () => component )
    //   .catch( e => console.log('Page loading err', e) || Error404 )
  },
  loading: Loading,
});
