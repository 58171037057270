const sliceSelector = (state) => state.auth || {};

const getAuthUserId = (state) => sliceSelector(state).id;
const getAuthUserStatus = (state) => sliceSelector(state).status;

export {
    sliceSelector as authUserSelector,
    getAuthUserId,
    getAuthUserStatus
  };
