import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { MiddleMenu } from "components/layouts/MainLayout/MiddleMenu";
import EmailTemplatesRoutes, { path as emailTemplatesPath } from "./EmailTemplates";
import Snippets, { path as snippetsPath } from './Snippets'
import NotFoundRoute from "../NotFound";
import basePath from './path';
export { default as path } from "./path";

export const templatesMiddleMenu = {
  title: { label: 'Templates', icon: 'snippets', color: '#253342' },
  items: [
    {label: 'Social Templates', path: snippetsPath},
    {label: 'Email Templates', path: emailTemplatesPath},
  ]
};

export default () => (
  <>
    <MiddleMenu{...templatesMiddleMenu} />
    <div style={{flexGrow: 1, height: 'inherit', overflow: 'auto'}}>
      <Switch>
        <Route path={emailTemplatesPath} component={EmailTemplatesRoutes} />
        <Route path={snippetsPath} component={Snippets} />
        <Route exact path={basePath}>
          <Redirect to={snippetsPath} />
        </Route>
        <Route component={NotFoundRoute} /> {/* 404 */}
      </Switch>
    </div>
  </>
)




