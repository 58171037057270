import React from 'react'
import SnippetsDetails from './Details'
import SnippetsList from './List'
import { Switch, Route } from 'react-router-dom'
import NotFoundRoute from "../../NotFound";
import path from "./path";
export { default as path } from "./path"

export default () => (
  <Switch>
    <Route exact path={`${path}/:id`} component={SnippetsDetails} />
    <Route exact path={path} component={SnippetsList} />

    <Route component={NotFoundRoute} /> {/* 404 */}
  </Switch>
)
