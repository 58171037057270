import React from 'react';
import { Link } from 'react-router-dom'
// import leadsPathname from "../Visitors/pathname";

import styles from './Styles.module.scss'

const NotFoundPage = () => (
  <div className={styles.NotFoundPage}>
    <h1 className={styles.Label}>
      <span>4</span>
      <span>0</span>
      <span>4</span>
    </h1>
    <p className={styles.Message}>Page not found!</p>
    <p className={styles.Message}>Nothing to see here - move on</p>

    <Link to="/" className="gs-text _link _exlg">Go to home page?</Link>
  </div>
);

export default NotFoundPage;
