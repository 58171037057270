import React from 'react';
import { fieldTypes } from '../../../../../../reducer/utils/description'
import SnippetValue from "./SnippetValue";
import FilterValue from "./FilterValue";
import DelayValue from "./DelayValue";
import AbValue from "./AbValue";
import CompositeValue from "./CompositeValue";


export default {
  [fieldTypes.SNIPPET]: (props) => <SnippetValue {...props} isPlain/>,
  [fieldTypes.FILTERS]: FilterValue,
  [fieldTypes.EMAIL]: SnippetValue,
  [fieldTypes.DELAY]: DelayValue,
  [fieldTypes.AB]: AbValue,
  [fieldTypes.COMPOSITE]: CompositeValue,
}
