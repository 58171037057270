import React, { useRef, useState, useEffect } from "react";
import cn from "classnames";
import { escapeHTML } from 'services/utils'
import useThrottleValue from "components/common/hooks/useThrottleValue";
import Icon from "components/common/elements/Icon";
import { IFrame, resizeIFrameToFitContent } from "components/common/IFrame";

import styles from './SnippetValue.module.scss'



const SnippetValue = ({field, payload, isPlain}) => {
  const [scroll, setScroll] = useState(0);
  const throttledScroll = useThrottleValue(scroll, 120);
  const [isTopScrollable, setIsTopScrollable] = useState(false);
  const [isBottomScrollable, setIsBottomScrollable] = useState(false);
  const value = payload[field.key] || '';
  const templateRef = useRef();
  const [iframe, setIframe] = useState();

  useEffect(
    () => {
      if (!templateRef.current) return;

      const { scrollTop, scrollHeight, offsetHeight} = templateRef.current;
      setIsTopScrollable(!!scrollTop);
      setIsBottomScrollable(scrollHeight - offsetHeight - scrollTop > 2 );
    },
    [throttledScroll, templateRef.current],
  );
  useEffect(() => {
    if (!iframe) return;
    resizeIFrameToFitContent(iframe, {max: 150});
  }, [iframe]);

  if (!value.trim()) return <span className={styles.none}> None </span>

  return (
    <>
      <div
        className={cn(styles.container)}
        onClick={e => e.preventDefault()}
      >
        <IFrame style={{width: '100%'}}  iframeRef={setIframe}>
          <div
            ref={templateRef}
            onScroll={e => setScroll(e.target.scrollTop)}
            className={cn({
              // "gs-ignore-scale-scroll": templateRef.current && (templateRef.current.offsetHeight + 1 < templateRef.current.scrollHeight),
            })}
            style={{
              whiteSpace: isPlain ? 'pre-wrap' : null,
            }}
            dangerouslySetInnerHTML={{ __html: isPlain ? escapeHTML(value) : value }}
          />
        </IFrame>

        { isBottomScrollable && (
          <svg className={styles.bottomArrow} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6 0L6 4.97297L1.4 0L0 1.51351L6 8L12 1.51351L10.6 0Z" fill="#99ACC2"/>
          </svg>
        )}
        { isTopScrollable && (
          <svg className={styles.topArrow} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6 0L6 4.97297L1.4 0L0 1.51351L6 8L12 1.51351L10.6 0Z" fill="#99ACC2"/>
          </svg>
        )}
      </div>

      { !!payload.attachments && (
        <div className={styles.attachments}>
          <Icon size={12} icon="attach" />
          { payload.attachments.length } attachment{payload.attachments.length > 1 ? 's' : ''}
        </div>
      )}
    </>
  )
}

export default SnippetValue;
