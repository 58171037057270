export const nodeTypes = {
  START: 'START',
  PLUS: 'PLUS',
  END: 'END',
  REAL: 'REAL',
}

export default nodeTypes;

export const getNodeType = (node) => {
  if (!node.before.length) {
    return nodeTypes.START;
  }
  if (!node.after.length) {
    return nodeTypes.END;
  }
  if (node.serverNodeId) {
    return nodeTypes.REAL;
  }

  return nodeTypes.PLUS;
}
