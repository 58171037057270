import Loadable from 'react-loadable';
import { Loading } from "components/common/Loading";
export { subPath } from './path'

const FlowDetailsPage = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName:'FlowDetailsPage' */ './PublicFlow');

    return component;
    // return import(/* webpackChunkName:'FlowDetailsPage' */ './reducer/reducer')
    //   .then( reducer => injectReducer(store, { key: 'flowDetails', reducer: reducer.default }) )
    //   .then( () => component )
    //   .catch( e => console.log('Page loading err', e) || Error404 )
  },
  loading: Loading,
});

export default FlowDetailsPage;
