import { Switch, Route } from 'react-router-dom'
import ListRoute from './List'
import NotFoundRoute from "components/routes/NotFound";
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import pathname from "./pathname";
import LeadLists, {path as leadListsPath} from "./LeadLists";

export default () => (
  <Switch>
    <Route path={leadListsPath} component={LeadLists} />
    <Route path={`${pathname}/:id?/:detailTab?`} component={ListRoute} />
    <Route component={NotFoundRoute} /> {/* 404 */}
  </Switch>
)

export { default as path } from "./pathname";
