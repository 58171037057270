import React from "react";
import cn from "classnames";
import find from 'lodash/find'
import Symbol from "../../Symbol/Symbol";
import { stepTypes } from "store/entities/flows/model/nodeTypes";

import styles from './ActionOption.module.scss'

const ActionOption = ({ innerProps, options, value, label, getValue }) => {
  let option = find(options, {value});
  let isValue = false;
  if (!option) {
    isValue = true;
    option = find(options, {value: getValue()[0].value});
  }

  if (!option) {
    option = getValue()[0];
    // todo: remove hardcode
    option.icon = 'FlowSocialMessage';
    option.type = 'trigger';
  }

  return (
    <div
      className={cn(styles.container, {
        [styles.option]: !isValue,
      })}
      {...innerProps}
    >
      { option.type && <div className={cn(styles.symbolContainer)}>
        <Symbol type={option.type} icon={option.icon}/>
      </div>}
      <span style={{fontSize: '12px'}}> { option.label } </span>
    </div>
  )
}

export default ActionOption

