import * as actions from './consts'
import { createSource } from "services/axios";
import { getTasks } from "store/entities/tasks/actions";
import { createSelectionActions } from "services/helpers/selectionHelper";
import { key } from "./reducer";
import browserHistory from 'services/browserHistory';
import { sliceSelector as leadEntities } from 'store/entities/contacts/selectors'
import { loadContacts } from '../../../../store/entities/contacts/actions';
import { SEED_CONTACTS } from '../../../../store/entities/contacts/consts';
import { tasksByIds }  from '../../../../store/entities/tasks/selectors';
import { getLinkedInProfile } from '../../../../services/helpers/socialProfiles';
import { pluginResponseEvents, sendMessageToPlugin } from '../../PluginGroup/pluginEvents';
import {
  executeTask,
  getNextTask,
  getTaskForLead,
} from '../../../../store/entities/tasks/actions';
import { WITH_SNIPPET, NO_SNIPPET } from '../../../../store/entities/tasks/consts';
import { toBinary, fromBinary } from "components/common/Filters";
import taksPath from '../../Tasks/path';
import { topFilterActionSelector } from './selectors';

let loadMoreSource;

const loadMoreTasks = () => (dispatch, getState) => {
  const state = getState();
  const taskState = state[key];

  if (taskState.isLoading) {
    return Promise.resolve();
  }

  dispatch({
    type: actions.TASKS_LOAD_REQUEST,
  });

  loadMoreSource = createSource();

  const params = {
    offset: taskState.taskIds.length,
    // status: 'in_progress',
    limit: 20,
  };

  const action = topFilterActionSelector(state);

  if (action !== 0) {
    params.action = action;
  }

  if (taskState.topFilters.taskType) {
    params.automation = [taskState.topFilters.taskType];
  }

  return dispatch(getTasks(params, loadMoreSource.token))
    .then((res) => {
      const { has_more, aggs } = res;
      let { data } = res;

      const requiredLeads = [];

      const loadedLeads = leadEntities(getState());
      const leads = [];

      if (!Array.isArray(data)) {
        data = Object.values(data);
      }

      const taskIds = data.map((task) => {
        if (!task.lead && !loadedLeads[task.lead_id] && !requiredLeads.includes(task.lead_id)) {
          requiredLeads.push(task.lead_id);
        }

        if (task.lead) {
          leads.push(task.lead);
        }

        return task.id;
      }).filter(Boolean);

      const promises = [
        dispatch({
          type: SEED_CONTACTS,
          contacts: leads,
        })
      ];

      if (requiredLeads.length) {
        const params = {
          query: {
            id: {
              is: requiredLeads,
            }
          }
        };
        promises.push(dispatch(loadContacts(params)));
      }

      const stateTaskIds = tasksByIds(state, { tasksIds: taskState.taskIds });

      let total = stateTaskIds.length + taskIds.length;

      if (has_more && data.length !== 0) {
        total++;
      }

      Promise.all(promises).then(() => {
        dispatch({
          type: actions.TASKS_LOAD_SUCCESS,
          taskIds,
          total,
          aggs,
        });
      });
    })
    .catch(err => {
      dispatch({
        type: actions.TASKS_LOAD_FAILURE,
      })
      console.log('err', err);

      throw err;
    })
}

const leavePage = () => {
  if (loadMoreSource) {
    loadMoreSource.cancel();
  }

  return {
    type: actions.TASKS_LEAVE,
  }
};

const getTasksForLead = (id) => (dispatch, getState) => {
  const state = getState();
  const taskState = state[key];

  if (taskState.isLeadTasksLoading || taskState.activeleadID === id) {

    return Promise.resolve();
  }

  dispatch({
    type: actions.TASKS_FOR_LEAD_LOAD_REQUEST,
    id,
  });

  loadMoreSource = createSource();

  return dispatch(getTaskForLead(id))
    .then(() => {
      dispatch({
        type: actions.TASKS_FOR_LEAD_LOAD_SUCCESS,
        id,
      });
    })
    .catch(err => {
      dispatch({
        type: actions.TASKS_FOR_LEAD_LOAD_FAILURE,
        id,
      })

      throw err;
    })
};

const selectionActions = createSelectionActions({
  type: actions.TASKS_SELECTION_TOGGLE_PLURAL,
  getSelectionState: (getState) => getState()[key].selection,
  getIdsOfLoaded: (getState) => getState()[key].taskIds,
});

const toggleOne = selectionActions.toggleOne;

const togglePage = selectionActions.togglePage;

const selectAll = selectionActions.selectAll;

const startTasks = () => (dispatch) => {
  return dispatch(getNextTask())
  .then((nextTask) => {
    dispatch({
      type: actions.TASKS_SET_EXECUTE,
      task: nextTask,
    });

    const linkedIn = getLinkedInProfile(nextTask.lead.social_profiles);
    const leadId = nextTask.lead.id;

    browserHistory.push(`${taksPath}/${leadId}/task/${nextTask.id}`);

    if (!linkedIn) {
      return;
    }

    let addSharesToUrl = '';
    if (nextTask.action === WITH_SNIPPET.SOCIAL_COMMENT || nextTask.action === NO_SNIPPET.SOCIAL_ACTIVITY) {
      if (linkedIn.url[linkedIn.url.length - 1] !== '/') {
        addSharesToUrl = '/';
      }

      addSharesToUrl += 'detail/recent-activity/shares/';
    }

    sendMessageToPlugin(
      pluginResponseEvents.START_TASKS,
      {
        url: `${linkedIn.url}${addSharesToUrl}`,
      }
    );
  });
};

const finishTasks = () => (dispatch) => {
  return dispatch({
    type: actions.TASKS_FINISH,
  });
};

const completeAndNext = (taskId, branchId) => (dispatch) => {
  return dispatch(executeTask(taskId, branchId))
  .then(() => {
    dispatch(getNextTask())
    .then((nextTask) => {
      if (!nextTask) {
        dispatch({
          type: actions.TASKS_FINISH,
        });

        sendMessageToPlugin(pluginResponseEvents.UI_LOGIN_SUCCESS);

        return;
      }

      dispatch({
        type: actions.TASKS_SET_CURRENT_TASK,
        task: nextTask,
      });

      const linkedIn = getLinkedInProfile(nextTask.lead.social_profiles);
      const leadId = nextTask.lead.id;

      browserHistory.push(`${taksPath}/${leadId}/task/${nextTask.id}`);

      if (!linkedIn) {
        return;
      }

      sendMessageToPlugin(
        pluginResponseEvents.SET_URL,
        {
          url: linkedIn.url,
        }
      );
      dispatch(loadMoreTasks())
    });
  });
};

const applyUrlFilters = (topFilters) => ({
  type: actions.TASKS_SET_URL_FILTERS,
  topFilters,
})

const serializeMessagesFilters = (topFilters) => btoa(toBinary(JSON.stringify(topFilters)));
const deserializeMessagesFilters = (serialized) => {
  try {
    return JSON.parse(fromBinary(atob(serialized)))
  } catch (e) {
    return '';
  }
};

function pushFilterUrl(getState) {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  searchParams.set('topFilters', serializeMessagesFilters(getState()[key].topFilters));
  url.search = searchParams.toString();

  browserHistory.push(url.pathname + url.search)
}

const setTopFilter = (topFilters) => (dispatch, getState) =>  {
  dispatch(dropSearch());

  console.log('filters', topFilters);

  dispatch({
    type: actions.TASKS_SET_TOP_FILTER,
    topFilters,
  });

  pushFilterUrl(getState);
}

const dropSearch = () => {
  if (loadMoreSource) {
    loadMoreSource.cancel();
  }

  return {
    type: actions.TASKS_DROP_SEARCH,
  }
}


export {
  loadMoreTasks,
  leavePage,
  getTasksForLead,
  toggleOne,
  togglePage,
  selectAll,
  startTasks,
  finishTasks,
  completeAndNext,
  applyUrlFilters,
  deserializeMessagesFilters,
  setTopFilter,
  dropSearch,
};
