import React, { Fragment, useEffect, useRef } from 'react'
import panzoom from 'panzoom'

import Icon from 'components/common/elements/Icon'


import styles from './Styles.module.scss'
import { actionColor } from 'styles/_variables.module.scss'


const maxZoom = 1;
const minZoom = 0.3;

const Scalable = ({
    children,
    scalableContainer = document.body,
    controlClass = styles.Control,
   }) => {
  const contRef = useRef();
  const instanceRef = useRef();
  useEffect(() => {
    const sideBarWidth = 70;
    let initialZoom = Math.min(
      1,
      scalableContainer.offsetHeight / (contRef.current.offsetHeight + 90),
      (scalableContainer.offsetWidth - sideBarWidth) / contRef.current.offsetWidth,
    )
    initialZoom = Math.min(initialZoom, maxZoom);
    initialZoom = Math.max(initialZoom, minZoom);

    const widthDiff = initialZoom*contRef.current.offsetWidth - (scalableContainer.offsetWidth - sideBarWidth);

    instanceRef.current = panzoom(contRef.current, {
      initialZoom,
      // bounds: true,
      // boundsPadding: 0.5,
      maxZoom,
      minZoom,
      beforeWheel: e => {
        if (e.ctrlKey) return false;
        if (e.target.closest('.gs-ignore-scale-scroll')) return true;

        const curTransform = instanceRef.current.getTransform()
        instanceRef.current.moveTo(
          curTransform.x - e.deltaX,
          curTransform.y - e.deltaY,
        );

        return true
      },
      beforeMouseDown: e => {
        if (e.target.closest('.gs-scale-dnd')) return true
        return false;
      },
      zoomDoubleClickSpeed: 1,
    });

    if (widthDiff < 0) {
      const curTransform = instanceRef.current.getTransform()
      instanceRef.current.moveTo(
        curTransform.x - widthDiff / 2,
        curTransform.y,
      );
    }
  }, []);
  const zoom = (scale) => {
    instanceRef.current.smoothZoom(contRef.current.offsetWidth / 2, 200, scale);
  }

  return (
    <Fragment>
      <div ref={contRef} style={{width: '100%', columns: '100%'}}>
        { children }
      </div>

      <div className={controlClass}>
        <span onClick={ () => zoom(0.8)}
              className={styles.ControlBtn}>
          <Icon icon="minus-in-square" size={25} color={actionColor} />
        </span>
        <span onClick={ () => zoom(1.2) }
              className={styles.ControlBtn}>
          <Icon icon="plus-in-square" size={25} color={actionColor} />
        </span>
      </div>
    </Fragment>
  )
}

export default Scalable
