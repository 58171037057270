import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from '../Styles.module.scss'

const defaultOnChange = () => {};

const Toggle = ({ value, reversed, onChange, label, disabled, stopProp, stopPropagation, ...other }) => {
  const { big, small, green, blue, red } = other;

  return (
    <div
      onClick={(e) => {
        if (stopPropagation) e.stopPropagation();
        !disabled && onChange(!value);
      }}
      className={styles.toggleInput}
    >
      { reversed && label && <span className="gs-label" style={{marginRight: 10}}> { label } </span>}
      <input
        className={cn(styles.toggleInputBtn, {
          [styles.Big]: big,
          [styles.Small]: small,
          [styles.Green]: green,
          [styles.Blue]: blue,
          [styles.Red]: red,
        })}
        checked={value}
        onChange={defaultOnChange}  // Чтобы не было ворнингов в консоли
        type="checkbox"
      />
      <label/>
      { !reversed && label && <span> { label } </span>}
    </div>
  )
}

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  stopPropagation: PropTypes.bool,
};

export {
  Toggle as default,
  Toggle,
}
