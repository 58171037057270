import find from 'lodash/find'
import difference from 'lodash/difference'
import createNode from './createNode';
import selectPlusNodesToUnion from "./selectPlusNodesToUnion";
import mergeParents from "./mergeParents";
import nodeTypes from "../nodeTypes";

const getFirstServerNode = (model) => {
  for (const serverNode of model.flow.nodes) {
    if (!serverNode.before.length) return serverNode
  }
}

export default function (model) {
  const firstServerNode = getFirstServerNode(model);
  const uiNodeOfFirstServerNode = find(model.nodes, {serverNodeId: firstServerNode.id});

  const uiStartNode = createNode(model, {
    serverNodeId: null,
    parents: null,
    type: nodeTypes.START,
    before: [],
    union: null,
  })

  const uiFirstPlusNode = createNode(model, {
    serverNodeId: null,
    parents: null,
    type: nodeTypes.PLUS,
    before: [uiStartNode.id],
    after: [uiNodeOfFirstServerNode.id],
    union: null,
  })
  uiStartNode.after = [uiFirstPlusNode.id];
  uiNodeOfFirstServerNode.before = [uiFirstPlusNode.id]

  const getUiNodeOfServerNodeWithMultipleBefores = () => model.nodes
    .filter(node => node.serverNodeId)
    .filter(node => node.before.length > 1)[0];

  for (const serverNode of model.flow.nodes) {
    const uiNodeOfServerFromNode = find(model.nodes, {serverNodeId: serverNode.id});

    for (const toServerNodeId of serverNode.after.map(v => v.node_id)) {
      const uiNodeOfServerToNode = find(model.nodes, {serverNodeId: toServerNodeId});

      const uiNode = createNode(model, {
        serverNodeId: null,
        parents: [uiNodeOfServerFromNode.serverNodeId, ...uiNodeOfServerFromNode.parents],
        type: nodeTypes.PLUS,
        before: [uiNodeOfServerFromNode.id],
        after: [uiNodeOfServerToNode.id],
        union: null,
      })

      uiNodeOfServerFromNode.after.push(uiNode.id);
      uiNodeOfServerToNode.before.push(uiNode.id);
    }
  }

  let uiNodeOfServerNodeWithMultipleBefores = getUiNodeOfServerNodeWithMultipleBefores();

  while (uiNodeOfServerNodeWithMultipleBefores) {
    const arrayOfParents = uiNodeOfServerNodeWithMultipleBefores.before.map(id => find(model.nodes, {id}).parents)
    const selectedToUnion = selectPlusNodesToUnion(arrayOfParents);
    const unionIds = selectedToUnion.unionIndexes.map(index => uiNodeOfServerNodeWithMultipleBefores.before[index]);
    const uiNodeRoot = find(model.nodes, {serverNodeId: selectedToUnion.root})

    const unionPlusNode = createNode(model, {
      serverNodeId: null,
      parents: mergeParents(unionIds.map(id => find(model.nodes, {id}).parents)),
      type: nodeTypes.PLUS,
      before: unionIds,
      after: [uiNodeOfServerNodeWithMultipleBefores.id],
      union: null,
      root: uiNodeRoot.id,
    })
    find(model.nodes, {id: unionPlusNode.root}).union = unionPlusNode.id;

    uiNodeOfServerNodeWithMultipleBefores.before = [
      ...difference(uiNodeOfServerNodeWithMultipleBefores.before, unionIds),
      unionPlusNode.id,
    ];
    unionIds.forEach(id => {
      find(model.nodes, {id}).after = [unionPlusNode.id]
    })

    uiNodeOfServerNodeWithMultipleBefores =  getUiNodeOfServerNodeWithMultipleBefores();
  }

  return model;
}
