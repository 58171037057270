import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import leadsPath from "components/routes/Visitors/pathname";
import {serializeFilters} from "components/common/Filters";

import styles from './InnerStats.module.scss'

const InnerStats = ({ states = {}, serverNodeId}) => {
  const getLink = (status) => {
    return `${leadsPath}?filters=${serializeFilters({flow_node_id: {has: [{id: serverNodeId, status}]}})}`
  }
  return (
    <div className={styles.wrapper} onClick={e => e.stopPropagation()}>
      <Link className={styles.block} to={getLink('new')} target="_blank">
        <span className={styles.label}> Active: </span>
        <span className={cn(styles.value, styles.green)}> { states.new || 0 } </span>
      </Link>
      { states.error && (
        <Link className={styles.block} to={getLink('error')} target="_blank">
          <span className={styles.label}> Error: </span>
          <span className={cn(styles.value, styles.red)}> { states.error } </span>
        </Link>
      )}
      { states.cancelled && (
        <Link className={styles.block} to={getLink('cancelled')} target="_blank">
          <span className={styles.label}> Cancelled: </span>
          <span className={cn(styles.value)}> { states.cancelled } </span>
        </Link>
      )}
      {states.bounced && (
        <Link className={styles.block} to={getLink('bounced')} target="_blank">
          <span className={styles.label}> Bounced: </span>
          <span className={cn(styles.value, styles.red)}> { states.bounced } </span>
        </Link>
      )}
    </div>
  )
}

export default InnerStats
