import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

class AsyncActionButton extends PureComponent {
  constructor() {
    super();
    this.state = {
      isAction: false,
    };

    this.action = this.action.bind(this);
  }

  componentWillUnmount() {
    this._isUnmounted = true;
  }

  action(e) {
    e.preventDefault();
    const { isAction } = this.state;
    const { action } = this.props;

    if (isAction) {
      return;
    }

    this.setState({isAction: true});

    const endAction = () => {
      if (!this._isUnmounted) {
        this.setState({isAction: false});
      }
    };

    Promise.resolve(action(e))
      .then(endAction)
      .catch(endAction);
  }

  render() {
    const { children, action, isLoading, ...other } = this.props;
    const { isAction } = this.state;

    return (
      <Button
        onClick={this.action}
        active={isAction || isLoading}
        {...other}
      >
        { children }
      </Button>
    )
  }
}

AsyncActionButton.propTypes = {
  action: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export {
  AsyncActionButton as default,
  AsyncActionButton,
}
