import * as consts from './consts'
import { entitySetter } from '../utils'

const ACTION_HANDLERS = {
  [consts.TASKS_SET]: (state, { payload: tasks }) => {
    if (!Array.isArray(tasks)) {
      tasks = Object.values(tasks);
    }

    let s = {...state};

    s = entitySetter(s, tasks);

    return s;
  },
  [consts.TASKS_SET_EXECUTE]: (state, { payload }) => {
    const currentTask = state[payload];

    if (!currentTask) {
      return state;
    }

    currentTask.status = 'closed';

    return {
      ...state,
      [payload]: currentTask
    };
  },
  [consts.TASKS_SET_UPDATE]: (state, { payload }) => {
    const currentTask = state[payload.id];

    if (!currentTask) {
      return state;
    }

    return {
      ...state,
      [payload.id]: payload
    };
  }
}

const initialState = {};

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
