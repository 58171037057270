import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from '../Styles.module.scss'

const Input = ({ additionalInfo, isError, areaClassName, type='text', ...other}) =>(
 <div className={cn(styles.inputWrapper, {[styles.AdditionalInfo]: additionalInfo, [styles.Error]: isError})}
      data-additional-info={additionalInfo}>
    <textarea className={cn(styles.Textarea, {[areaClassName]: areaClassName})}
              type={type}
              {...other}
    />
  </div>
)

Input.propTypes = {
};

export default Input
