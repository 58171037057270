import React from "react";
import styles from './VarsInfo.module.scss'

export const VarsInfo = ({ className }) => (
  <div className={className}>
    <div className={styles.headline}>
      <b className={styles.title}> Variables </b>
      <span className={styles.description}> Please use this variables to personalize your email’s subject and body. </span>
    </div>
    <div className={styles.vars}>
      <div className={styles.var} style={{gridColumn: 1}}> {`{{ lead.first_name }} - First Name`} </div>
      <div className={styles.var} style={{gridColumn: 2}}> {`{{ lead.position }} - Position in Current Company`} </div>
      <div className={styles.var} style={{gridColumn: 3}}> {`{{ unsubscribe_link }} - Unsubscribe Email Link`} </div>

      <div className={styles.var} style={{gridColumn: 1}}> {`{{ lead.last_name }} - Last Name`} </div>
      <div className={styles.var} style={{gridColumn: 2}}> {`{{ company.name }} - Company Name`} </div>

      <div className={styles.var} style={{gridColumn: 1}}> {`{{ lead.first_name.ru }} - First Name (Russian Equivalent)`} </div>
      <div className={styles.var} style={{gridColumn: 2}}> {`{{ company.industry }} - Company Industry`} </div>
    </div>
  </div>
)
