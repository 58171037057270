import * as actions from './consts'
import { entitySetter } from '../utils'

const ACTION_HANDLERS = {
  [actions.PLANS_GET_SUCCESS]: (state, {plans}) => entitySetter(state, plans),
}

const initialState = {}

export default function plansReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
