export const providers = {
  CUSTOM: "custom",
  GMAIL: "gmail",
  OFFICE: "office",
  SMTP: "smtp",
}

export const MAILBOXES_SET = 'MAILBOXES_SET'
export const MAILBOXES_DELETE_SUCCESS = 'MAILBOXES_DELETE_SUCCESS'
export const MAILBOXES_RESET_DAILY_LIMITS = 'MAILBOXES_RESET_DAILY_LIMITS'
export const MAILBOXES_RESET_CONNECTION = 'MAILBOXES_RESET_CONNECTION'

export const EMAILS_SET = 'EMAILS_SET';

