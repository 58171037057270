import * as constants from './consts'
import getAxios, { createSource } from "services/axios";
import { getError } from "services/utils";
import { changeMessagesCount } from "store/global";
import { setUserServer } from "store/entities/users/actions";
import { getAuthUserId } from "store/auth/selectors";
import { key } from "./reducer";
import { serverStatuses } from "./consts";
import { pluginResponseEvents, sendMessageToPlugin } from "components/routes/PluginGroup/pluginEvents";

const axios = getAxios('flow');
const idAxios = getAxios('id');

export const leavePage = () => ({type: constants.S_SOCPROF_LEAVE_PAGE})

export const disconnectLnAccount = () => async (dispatch, getState) => {
  const res = await axios.delete('/api/social-profiles/remove');

  dispatch({type: constants.S_SOVPROF_DISCONNECT_LN});
  const userId = getAuthUserId(getState());
  dispatch(setUserServer({userId, server: null}))

  return res;
}

export const updateSocialProfile = (profile) => (dispatch) => {
  dispatch({type: constants.S_SOCPROF_SUBMIT})
  return axios.post(`/api/social-profiles/upsert`, profile)
}

export const useDefaultSettings = () => (dispatch, getState) => {
  const state = getState()

  dispatch(setProfile({
    ...constants.defaultSettings,
    nickname: state[key].profile.nickname,
    ln_id: state[key].profile.ln_id,
  }))
}

export const setProfile = (profile) => ({
  type: constants.S_SOCPROF_SET_PROFILE,
  profile,
})

export const submitLnCredentials = ({login, password, navigator}) => (dispatch, getState) => {
  const server = getState().socialProfilePage.server || {};

  let method;
  if ([serverStatuses.LOGIN_ERROR, serverStatuses.ACCOUNT_BUSY].includes(server.status)) {
    method = () => idAxios.put('/server', {login, password, status: serverStatuses.LOGINING});
  } else {
    method = () => idAxios.put('/server/take', {login, password, session: {navigator}})
  }

  return method()
    .then(server => {
      server.status = serverStatuses.LOGINING;

      dispatch({
        type: constants.S_SOVPROF_SET_SERVER,
        server,
      });

      sendMessageToPlugin(pluginResponseEvents.UI_LOGIN_SUCCESS);
    })
    .catch(err => {
      if (err && err.responseCode === 404) {
        dispatch({type: constants.S_SOVPROF_NO_SERVER})
      }
    })
}

export const getServer = (cancelToken) => (dispatch) => {

  return idAxios.get('/server', {cancelToken})
    .then(server => {
      dispatch({
        type: constants.S_SOVPROF_SET_SERVER,
        server,
      })

      return server;
    })
}

export const submitCode = (code) => (dispatch) => {
  return idAxios.put('/server', {
    ln_code: code,
    status: serverStatuses.NEED_CODE,
  })
    .then(server => {
      dispatch({
        type: constants.S_SOVPROF_SET_SERVER,
        server,
      });

      return server;
    })
}

export const resendCode = () => (dispatch) => {
  return idAxios.put('/server', {
    ln_code: '',
    status: serverStatuses.LOGINING,
  })
    .then(server => {
      dispatch({
        type: constants.S_SOVPROF_SET_SERVER,
        server,
      });

      return server;
    })
}

export const getSocialProfile = () => (dispatch, getState) => {
  dispatch({type: constants.S_SOCPROF_GET_REQUEST});

  return Promise.all([axios.get('/api/social-profiles'), dispatch(getServer())])
    .then( ([profile, server]) => {
      const aggs = profile.aggs.users[getState().auth.id] || {};
      dispatch(changeMessagesCount(() => aggs.unread_chats_count || 0));

      if (!profile.limits) {
        profile.limits = profile.socialLimits;
        delete profile.socialLimits;
      }
      profile.limits = profile.limits.filter(l => l.type !== 'page_opened')

      dispatch({
        type: constants.S_SOCPROF_GET_SUCCSES,
        profile,
      });

      return profile;
    })
    .catch(err => {
      dispatch({
        type: constants.S_SOCPROF_GET_FAILURE,
        error: getError('Server error. Please try again later'),
      })
      if (err.responseCode !== 404) {
        throw err;
      }
    })
}

let _syncing = false;
let source;
export const syncServer = () => (dispatch) => {
  _syncing = true;

  (function recSync() {
    if (!_syncing) return;

    source = createSource();
    dispatch(getServer(source.token))
      // .then(server => {
      //   if (server.status === serverStatuses.BUSY) {
      //     dispatch(stopSyncingServer());
      //     return dispatch(getSocialProfile())
      //       .then(() => Promise.reject('ok'));
      //   }
      // })
      .then(() => new Promise(resolve => setTimeout(resolve, 5000)))
      .then(recSync)
      .catch(err => {
        if (err && err.responseCode === 404) {
          return new Promise(resolve => setTimeout(resolve, 5000))
            .then(recSync)
        }
      })
  })()
}
export const stopSyncingServer = () => (dispatch) => {
  source && source.cancel();
  _syncing = false
}
