import React, { Component } from 'react'
import Whoops from "components/common/Whoops"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    console.log('componentDidCatch', error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Whoops/>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary
