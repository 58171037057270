import React from "react";
import { useSelector } from "react-redux";
import cn from 'classnames';
import { useDrag } from 'react-dnd'
import find from 'lodash/find';
import difference from 'lodash/difference';
import { stepTypes } from "store/entities/flows/model/nodeTypes";
import { getFlowNodesAsObject } from "store/entities/flowStatistics/selectors";
import Icon from "components/common/elements/Icon";
import { dndItemTypes } from "../../dndTypes";
import Header from "./Header";
import Payload from "./Payload";

import BranchesStatistic from "./BranchesStatistic";

import styles from './RealNode.module.scss'

const isDraggable = ({ nodeId, model }) => {
  const node = find(model.nodes, { id: nodeId});
  if (!node.serverNodeId) return false;
  if (node.after.length === 1) return true;
  if (!node.union) return false;

  const unionNode = find(model.nodes, { id: node.union});
  if (unionNode.before.length !== node.after.length) return false;
  if (difference(unionNode.before, node.after).length !== 0) return false

  return true;
}

const RealNode = ({ focus, node, model, description, errors, onSubmit }) => {
  // const flowStatistics = useSelector((state) => getFlowStatisticsById(state, model.flow.id));
  const nodesObject = useSelector((state) => getFlowNodesAsObject(state, model.flow.id));
  const stepStatistics = nodesObject[node.serverNodeId];

  const canDrag = isDraggable({model, nodeId: node.id});
  const serverNode = find(model.flow.nodes, {id: node.serverNodeId});
  const [{isDragging}, drag, dragPreview] = useDrag(() => ({
    type: dndItemTypes.ACTION,
    item: node,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag,
  }), [canDrag]);

  const itemInfo = description[serverNode.type][serverNode.action];
  // добавил проверку так как тут падает приложение из за отсутсвующего action = ab_test
  if (!itemInfo) {
    return null;
  }


  return (
    <div
      onClick={focus}
      className={cn(styles.container, {
        [styles.ruleContainer]: serverNode.type === stepTypes.RULE,
        [styles.error]: errors?.length,
      })}
      ref={dragPreview}
      style={{opacity: isDragging ? 0.5 : 1}}
    >
      <div className={styles.titleRow}>
        <Header serverNode={serverNode} description={description}/>

        { canDrag && (
          <div ref={drag} className={styles.moveContainer + ' gs-scale-dnd'}>
            <Icon icon="move" size={20} color="#99ACC2" />
          </div>
        )}
      </div>

      <Payload
        stepStatistics={stepStatistics}
        serverNode={serverNode}
        description={description}
        errors={errors}
        onSubmit={onSubmit}
      />

      <BranchesStatistic stepStatistics={stepStatistics} node={node} model={model} serverNode={serverNode}/>
    </div>
  )
}

export default RealNode;
