import React, { useEffect, useState } from "react";
import cn from 'classnames';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import getAxios from 'services/axios';
import { cutHtmlTags } from "services/utils";
import { loadSnippets } from 'store/entities/snippets/actions'
import { loadEmailTemplates } from 'store/entities/emailTemplates/actions'
import Spinner from "components/common/elements/Spinner";
import TextRow from "components/common/inputs/FormRows/TextRow";

import styles from './TemplatesPopupContent.module.scss'

const axios = getAxios('flow');
const types = {
  SNIPPET: 'SNIPPET',
  EMAIL: 'EMAIL',
}

const TemplatesPopupContent = ({ onRequestClose, onSelect, type, processBody = true }) => {
  const dispatch = useDispatch();
  const [snippets, setSnippets] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [search, setSearch] = useState('');
  const userId = useParams().id;

  useEffect(() => {
    dispatch(loadSnippets())
      .then(res => setSnippets(res.data))

    dispatch(loadEmailTemplates())
      .then(res => setTemplates(res))
  }, [])

  const entities = type === types.SNIPPET ? snippets : templates;
  if (entities === null) {
    return <div className={styles.container}>
      <Spinner />
    </div>
  }

  const onSnippetSelect = (snippet) => {
    if (processBody) {
      axios.get(`/api/${type === types.SNIPPET ? 'snippets' : 'email-templates'}/${snippet.id}/process-body/${userId}`)
        .then(res => {
          onSelect({
            text: res,
            subject: snippet.subject,
            template_id: snippet.id,
            design: snippet.design,
          });
          onRequestClose();
        })
    } else {
      onSelect({
        text: snippet.body,
        subject: snippet.subject,
        template_id: snippet.id,
      });
      onRequestClose();
    }
  }

  return (
    <div className={cn(styles.container)}>
      <TextRow
        value={search}
        onChange={e => setSearch(e.target.value)}
        placeholder="Type to search"
        className={styles.search}
      />

      <ul className={cn(styles.list)}>
        {entities.filter(({name, body}) => name.match(RegExp(search, 'i')) || body.match(RegExp(search, 'i'))).map(snippet => (
          <li
            key={snippet.id}
            onClick={() => onSnippetSelect(snippet)}
            className={styles.snippet}
          >
            <div className={styles.name}> { snippet.name } </div>
            <div className={styles.text}>
              { cutHtmlTags(snippet.body) }
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
TemplatesPopupContent.types = types;

export default TemplatesPopupContent
