import sumBy from 'lodash/sumBy';
import {createSelector} from "reselect";

const sliceSelector = (state) => state.entities.flowStatistics;

const getFlowStatisticsById = (state, id) => sliceSelector(state)[id];

const defaultResult = {};
const synonymStats = {
  in_progress: 'new',
  done: 'closed',
  for_close: 'closed',
}
function getSynonymKey(key) {
  return synonymStats[key] || key;
}

const getFlowNodesAsObject = createSelector(
  [getFlowStatisticsById], (flowStatistics) => {
    if (!flowStatistics || !flowStatistics.nodes) {
      return defaultResult;
    }
    return flowStatistics.nodes;
    // const total = sumBy(flowStatistics.total.buckets, 'doc_count');
    //
    // return Object.values(flowStatistics.nodes.ids.buckets).reduce((acc, item)=> {
    //   acc[item.key] = {
    //     total,
    //     count: item.doc_count,
    //     branches: item.forks.buckets.map(data => ({
    //       branch_id: data.key,
    //       count: data.doc_count,
    //     })),
    //     stats: item.states.buckets.reduce((acc, stat) => {
    //       const key = getSynonymKey(stat.key);
    //       acc[key] = acc[key] || 0;
    //       acc[key] += stat.doc_count;
    //       return acc;
    //     }, {}),
    //   }
    //   return acc;
    // }, {});
  }
);

export {
  sliceSelector,
  getFlowStatisticsById,
  getFlowNodesAsObject,
};
