import * as actions from "./consts";
import getAxios from 'services/axios'
import { createCachedFunction } from "../utils";

const axios = getAxios('id');

const hardcodedPaddlePlans = [{
  id: 583812,
  name: 'Premium Monthly',
  interval: '1 month',
  price: '$99.00',
}, {
  id: 583815,
  name: 'Premium Yearly',
  interval: '1 year',
  price: '$948.00',
// }, {
//   id: 583817,
//   name: 'Test Premium',
//   interval: '1 month',
//   price: '$1.00',
}];

const cacher = createCachedFunction(() => (dispatch, _getState) => {
  return axios.get(`/api/billing/getsales/plans`)
    .then( plans => {
      dispatch({
        type: actions.PLANS_GET_SUCCESS,
        plans,
      })

      return plans.map(plan => ({
        id: plan.id,
        name: plan.name,
        interval: `1 ${plan.billing_type}`,
        price: `$${plan.recurring_price.USD}`,
      }));
    })
}, {recursively: true})

export const loadPlans = cacher.cachedFunction;

export const loadOurPlans = () => (_dispatch) =>
  axios.get('/api/billing/getsales/subscriptions/plans')

export const loadPlan = (planId) => (dispatch) => {
  if (!planId) return null;

  return axios.get(`/api/billing/getsales/plans/${planId}`)
    .then( plan => {
      dispatch({
        type: actions.PLANS_GET_SUCCESS,
        plans: [plan],
      })

      return plan
    })
}

export const getNextPlan = () => (_dispatch) => {
  return axios.get(`/api/billing/getsales/subscriptions/next-plan`)
}

export const changePlan = (plan_id, confirm_payment) => (dispatch) => {
  if (!plan_id) {
    return null;
  }

  return axios.post('/api/billing/getsales/plans/change-plan',  { plan_id, confirm_payment })
    .then( plan => {
      console.log('plan', plan);

      dispatch({
        type: actions.PLANS_GET_SUCCESS,
        plans: [plan],
      })

      return plan
    })
}
