import React from "react";
import cn from "classnames";
import { stepTypes } from "store/entities/flows/model/nodeTypes";
import Icon from 'components/common/elements/Icon'

import styles from './Symbol.module.scss'

const Symbol =  React.forwardRef(
  ({ type, icon, className = '', style = {}, ...other },
   ref,
) => (
  <div
    className={cn(styles.symbol, className, {
      [styles.action]: type === stepTypes.ACTION,
      [styles.trigger]: type === stepTypes.TRIGGER,
      [styles.rule]: type === stepTypes.RULE,
      [styles.util]: type === stepTypes.UTIL,
      [styles.end]: type === stepTypes.END,
    })}
    style={{
      ...style,
    }}
    ref={ref}
    {...other}
  >
    { Array.isArray(icon) && icon.map(({text, icon}, i) => <div key={i}>
      { text }
      { icon && <Icon icon={icon} size={12} color="white"/> }
    </div>)}
    { typeof icon === 'string' && <Icon icon={icon} className={styles.singleSvg}/> }
    { typeof icon === 'object' && icon && <div>
      {icon.text}
      {icon.icon && <Icon icon={icon.icon} className={styles.singleSvg} /> }
    </div> }
  </div>
))

export default Symbol;
