import React, { memo } from 'react'
import cn from 'classnames'
import { NavLink } from 'react-router-dom'

import styles from './Navigation.module.scss'
import { path as emailAccountPagePath } from "../EmailAccount";
import { path as mailboxesPagePath } from "../Mailboxes";
import { path as schedulesPath } from "../Schedules";
import { path as integrationsPath } from "../Integrations";
import { path as userPath } from "../User";
import { path as socialPath } from "../SocialProfile";
import { path as changePasswordPath } from "../ChangePassword";
import { path as teamPath } from "../Team";
import { path as subscriptionsPath } from "../Subscriptions";
import { serverStatuses } from "../SocialProfile/reducer/consts";

const Navigation = ({ isMailboxError, currentUserServer }) => (
  <div className={styles.container}>
    <div className={styles.title}>
      Workspace settings:
    </div>
    <div className={styles.links}>
      <NavLink
        to={socialPath}
        activeClassName="--active"
        className={styles.link}
      >
        <div className={styles.label}> LinkedIn Account </div>
        {!currentUserServer && (
          <div className={cn(styles.badge, '--gray')}>
            !
          </div>
        )}
        {currentUserServer && currentUserServer.status !== serverStatuses.BUSY && (
          <div className={cn(styles.badge, '--error')}>
            !
          </div>
        )}
      </NavLink>
      <NavLink
        to={mailboxesPagePath}
        activeClassName="--active"
        className={styles.link}
      >
        <div className={styles.label}> Email Account </div>
        {isMailboxError && (
          <div className={cn(styles.badge, '--error')}>
            !
          </div>
        )}
      </NavLink>
      <NavLink
        to={schedulesPath}
        activeClassName="--active"
        className={styles.link}
      >
        <div className={styles.label}> Schedules </div>
      </NavLink>
      <NavLink
        to={integrationsPath}
        activeClassName="--active"
        className={styles.link}
      >
        <div className={styles.label}> Integrations </div>
      </NavLink>
    </div>

    <div className={styles.title}>
      Profile:
    </div>
    <div className={styles.links}>
      <NavLink
        to={userPath}
        activeClassName="--active"
        className={styles.link}
      >
        <div className={styles.label}> Profile Details </div>
      </NavLink>
      <NavLink
        to={changePasswordPath}
        activeClassName="--active"
        className={styles.link}
      >
        <div className={styles.label}> Change Password </div>
      </NavLink>
      <NavLink
        to={teamPath}
        activeClassName="--active"
        className={styles.link}
      >
        <div className={styles.label}> Team Management </div>
      </NavLink>
      <NavLink
        to={subscriptionsPath}
        activeClassName="--active"
        className={styles.link}
      >
        <div className={styles.label}> Subscriptions </div>
      </NavLink>
    </div>
  </div>
)

export default memo(Navigation)
