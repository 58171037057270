import _ from 'lodash'
import axios from 'axios'
import history from 'services/browserHistory'
import appConfig from 'config';

export const createJwtService = (refreshEndpoint) => {
  const subscribers = [];

  const getToken = () => localStorage.getItem('token');
  const onChange = (token) => _.forEach(subscribers, cb => cb(token));

  const setToken = (token) => {
    if (getToken() == token) return;
    localStorage.setItem('token', token);
    onChange(token);
  }

  return {
    refreshToken: () => {
      return axios.post(refreshEndpoint, {scopes: 'gs'}, {
        headers: {Authorization: getToken()},
        withCredentials: true,
      })
        .then( response => {
          const newToken = response.headers.authorization;
          setToken(newToken);
          return newToken;
        } )
        // .catch( () => history.push('/login') )
    },
    getToken,
    setToken,
    removeToken: () => {
      localStorage.removeItem('token')
      onChange();
    },
    subscribe: (cb) => {
      subscribers.push(cb);
      return function unsubscribe() {
        _.pull(subscribers, cb)
      }
    },
  }
}

export default createJwtService(appConfig.endPoints.id + '/auth/refresh');
