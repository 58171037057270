import * as actions from "./consts";
import getAxios from 'services/axios'
import { createCachedFunction } from "../utils";
import { SET_ACCOUNT_STATUS, statuses } from "../../auth/consts";

const axios = getAxios('id');

const cacher = createCachedFunction(() => (dispatch, getState) => {
  return axios.get(`/api/billing/getsales/subscriptions`)
    .then( res => {
      dispatch({
        type: actions.SUBSCRIPTIONS_GET_SUCCESS,
        subscriptions: res.data,
      });

      return res;
    })
}, {recursively: true})

export const loadSubscriptions = cacher.cachedFunction;

export const getAvailableSeats = () => (dispatch) =>
  axios.get(`/api/billing/getsales/number-of-available-seats`)


export const createSubscription = (params) => (dispatch, getState) => {
  return axios.post(`/api/billing/getsales/subscriptions`, params)
    // тут возвращается непонятно что и подписка может быть еще не создана
    .then( res => {
      cacher.clearCache();
      console.log('create subscription', res)
      // dispatch({
      //   type: actions.SUBSCRIPTIONS_GET_SUCCESS,
      //   subscriptions: subscription,
      // });

      dispatch({
        type: SET_ACCOUNT_STATUS,
        status: statuses.ACTIVE,
        userId: getState().auth.id,
      })

      return res;
    })
}


export const resumeSubscription = (subscriptionId) => (dispatch) => {
  return axios.put(`/api/billing/getsales/getsales/subscriptions/${subscriptionId}/resume`)
    .then( subscription => {
console.log('resume subscription', subscription)
      dispatch({
        type: actions.SUBSCRIPTIONS_RESUME_SUCCESS,
        subscription,
      });
    })
}

export const cancelSubscription = (subscriptionId) => (dispatch) => {
  return axios.put(`/api/billing/getsales/subscriptions/${subscriptionId}/cancel`)
    .then( subscription => {
console.log('cancel subscription', subscription)
      dispatch({
        type: actions.SUBSCRIPTIONS_CANCEL_SUCCESS,
        subscriptionId,
      });
    })
}
