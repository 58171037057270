const sliceSelector = (state) => state.flowDetails;

const flowDetailsModeSelector = (state) => sliceSelector(state).mode;

const workTableSelector = (state) => sliceSelector(state).workTable;

const focusedNodeTempTypeSelector = (state) => workTableSelector(state).focusedNodeTempType;

const focusedNodeIdSelector = (state) =>  workTableSelector(state).focusedNodeId;

export {
  sliceSelector as flowDetailsSelector,
  flowDetailsModeSelector,
  workTableSelector,
  focusedNodeTempTypeSelector,
  focusedNodeIdSelector,
}
