import * as actions from './consts'
import momentTimezone from 'moment-timezone';

import { signUp as su } from 'store/auth/actions'
import { findGetParameter } from "services/utils";

export const setSubmitted = () => ({type: actions.SUP_SET_SUBMITED})

export const changeTab = (tab) => (dispatch, getState) => {
  const state = getState().signupPage;

  if (state.tab == tab || state.isAnimating) return;

  dispatch({
    type: actions.SUP_CHANGE_TAB,
    tab,
  });

  setTimeout( () => dispatch({type: actions.SUP_CHANGE_TAB_FINISHED}), 500);
}

export const signUp = (tinv) => (dispatch, getState) => {
  const state = getState().signupPage;

  if (state.isLoading) return Promise.reject(); // TODO: return memoized promise

  dispatch({type: actions.SUP_SIGNUP_REQUEST});

  const {
    full_name,
    email,
    password,
  } = state;

  return dispatch(su({
    tinv,
    timezone: momentTimezone.tz.guess(),
    full_name,
    email,
    password,
    password_confirmation: password,
    integration_id: findGetParameter('integration_id') || undefined,
  }))
    .then( user => {
      dispatch({type: actions.SUP_SIGNUP_SUCCESS})
      return user;
    })
    .catch( res => {
      dispatch({
        type: actions.SUP_SIGNUP_FAILURE,
        errors: res && res.error && res.error.data || {},
      })

      throw res;
    })
}

export const setField = (field, value) => ({
  type: actions.SUP_SET_FIELD,
  field,
  value,
})

