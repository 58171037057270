import React from 'react';
import cn from 'classnames';

import { container } from './ModalContainer.module.scss'

const ModalContainer = ({Tag = 'form', className = "", children}) => (
  <Tag className={cn(className, container)}>
    { children }
  </Tag>
)

export default ModalContainer
