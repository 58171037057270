import { useRef, useLayoutEffect, useEffect } from 'react';

const $ = window["$"];

export const Summernote = ({ defaultValue, onChange }) => {
  const ref = useRef();

  const handleChange = (text) => {
    onChange(text);
  }

  useLayoutEffect(() => {
    $(ref.current).summernote({
      height: 350,
      minHeight: null,
      maxHeight: null,
      dialogsInBody: true,
      codemirror: {
        theme: 'monokai'
      },
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['fontname', ['fontname']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'video']],
        ['view', ['fullscreen', 'codeview', 'help']],
      ],
      callbacks: {
        onChange: handleChange,
        onChangeCodeview: handleChange,
        onPaste: (e) => {
          const bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
          e.preventDefault();
          setTimeout(function () {
            document.execCommand('insertText', false, bufferText);
          }, 10);
        }
      },
    });
  }, []);


  useEffect(() => {
    if (!defaultValue) return;
    if (defaultValue === $(ref.current).summernote('code')) return;
    $(ref.current).summernote('code', defaultValue);
  }, [defaultValue]);

  return <div ref={ref}/>
}
