import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useLocation } from "react-router-dom";
import { Menu } from './Menu';
// import Search from './Search';
// import ActionsMenu from './ActionsMenu';
import { UserMenu } from './UserMenu';
import Preloader from 'components/common/elements/Preloader'
import PluginProxy from "./PluginProxy";
import useIsColumnPlugin from "../useIsColumnPlugin";

import { navigationMenu, navigationMenuOpened } from './SideMenu.module.scss'

const RunTasksButton = React.lazy(() => import('../../../routes/PluginGroup/PluginTasks/components/RunTasksButton'));

const SideMenu = (props) => {
  const isPlugin = useIsColumnPlugin();
  const { pathname } = useLocation();

  const {
    existMiddleMenu,
    isMenuOpen = false,
    openMobileMiddlemenu,
    setOpenMobileMiddlemenu
  } = props;

  return (
    <Suspense fallback={<Preloader/>}>
      <div className={cn(navigationMenu, { [navigationMenuOpened]: isMenuOpen })}>
        <Menu
          pathname={pathname}
          existMiddleMenu={existMiddleMenu}
          openMobileMiddlemenu={openMobileMiddlemenu}
          setOpenMobileMiddlemenu={setOpenMobileMiddlemenu}
        />
        {/*<ActionsMenu isMenuOpen={isMenuOpen}/>*/}
        {/*<Search isMenuOpen={isMenuOpen}/>*/}

        {isPlugin ?
          (<React.Fragment>
            <PluginProxy />
            <div style={{flex: 1}}/>
            {/* <RunTasksButton /> */}
          </React.Fragment>) :
          (<UserMenu isMenuOpen={isMenuOpen}/>)
        }
      </div>
    </Suspense>
  );
}

SideMenu.propTypes = {
  existMiddleMenu: PropTypes.bool,
}

export {
  SideMenu
}
