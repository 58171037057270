import {connect} from "react-redux";
import { useLocation } from 'react-router-dom'
import history from "services/browserHistory";
import Preloader from "components/common/elements/Preloader";
import { userStatuses } from "store/auth";
import { path as addCardPath } from "components/routes/authGroup/AddCard";
import { ReactComponent as Logo } from 'assets/black_logo.svg'
import { ReactComponent as Bg } from 'assets/login-bg.svg'
import { companiesList } from './const';

import style from './UnauthLayout.module.scss'


const UnauthLayout = ({ children, userStatus }) => {
  const { pathname } = useLocation();

  if (pathname != addCardPath && userStatus == userStatuses.AUTH) {
    history.push('/');
    return <Preloader/>;
  }

  return (
  <div className={style.componentContainer}>
    <Bg className={style.Bg}/>
    <Logo className={style.Logo}/>
    <div className={style.componentBox}>
      {children}
    </div>
    <div className={style.companies}>
      <h3>Trusted by thouthands of the world’s best companies</h3>
      <div className={style.companiesList}>
        {companiesList.map((company, index) => {
          return (
            <img key={index} src={company.logoPath}/>
          )
        })}
      </div>
    </div>
  </div>
  )
}

const mapStateToProps = (state) => ({
  userStatus: state.auth.status,
})

export default connect(mapStateToProps, {})(UnauthLayout)
