import React from "react";
import cn from 'classnames';
import { stepTypes } from "store/entities/flows/model/nodeTypes";

import styles from './NodeForm.module.scss'

const ChooseType = ({ setType }) => {
  return <div>
    <div> Add Flow Element </div>

    <ul className={styles.types}>
      <li onClick={() => setType(stepTypes.ACTION)} className={styles.type}>
        <div className={styles.action}/>

        <div className={styles.description}>
          <strong>Action</strong> do something
          <br/>
          Emails, Social Tasks, Tasks
        </div>
      </li>

      <li onClick={() => setType(stepTypes.TRIGGER)} className={styles.type}>
        <div className={styles.trigger}/>

        <div className={styles.description}>
          <strong>Trigger</strong> react on event
          <br/>
          Email Opened, Lead Connected
        </div>
      </li>

      <li onClick={() => setType(stepTypes.RULE)} className={cn(styles.type)}>
        <div className={styles.rule}/>

        <div className={styles.description}>
          <strong>Rule</strong> split on condition
          <br/>
          A/B Testing, Has Email, Company Size {">"} 50
        </div>
      </li>

      <li onClick={() => setType(stepTypes.UTIL)} className={cn(styles.type)}>
        <div className={styles.util}/>

        <div className={styles.description}>
          <strong>Util</strong> handy nodes
          <br/>
          Timer, Pause
        </div>
      </li>

      <li onClick={() => setType(stepTypes.END)} className={styles.type}>
        <div className={styles.end}/>

        <div className={styles.description}>
          <strong>End</strong> finish flow
        </div>
      </li>
    </ul>
  </div>
}

export default ChooseType
