const SEED_CONTACTS = 'SEED_CONTACTS';
const CONTACTS_DELETE_SUCCESS = 'CONTACTS_DELETE_SUCCESS';
const CONTACTS_SET_LIST_SUCCESS = 'CONTACTS_SET_LIST_SUCCESS';
const CONTACTS_SET_TAGS_SUCCESS = 'CONTACTS_SET_TAGS_SUCCESS';
const CONTACTS_DELETE_TAGS_SUCCESS = 'CONTACTS_DELETE_TAGS_SUCCESS';
const CONTACTS_SET_STAGE_SUCCESS = 'CONTACTS_SET_STAGE_SUCCESS';
const CONTACTS_SET_STAGE_PLURAL_SUCCESS = 'CONTACTS_SET_STAGE_PLURAL_SUCCESS';
const CONTACTS_SET_ASSIGN_PLURAL_SUCCESS = 'CONTACTS_SET_ASSIGN_PLURAL_SUCCESS';
const CONTACTS_ASSIGN_SUCCESS = 'CONTACTS_ASSIGN_SUCCESS';
const CONTACTS_SET_NETWORK_TYPE_SUCCESS = 'CONTACTS_SET_NETWORK_TYPE_SUCCESS';
const CONTACTS_MESSAGES_READ = 'CONTACTS_MESSAGES_READ';
const CONTACTS_ADD_ACTIVITY = 'CONTACTS_ADD_ACTIVITY';
const CONTACTS_CANCEL_ACTIVITY = 'CONTACTS_CANCEL_ACTIVITY';
const CONTACTS_MARK_UNREAD = 'CONTACTS_MARK_UNREAD';

const EMAIL_ACTIVITIES = {
  EMAIL_OPENED: 'email_opened',
  EMAIL_REPLIED: 'email_replied',
  EMAIL_SENT: 'email_sent',
  EMAIL_BOUNCED: 'email_bounced',
  EMAIL_UNSUBSCRIBE: 'lead_unsubscribed',
};

const SOCIAL_ACTIVITIES = {
  SOCIAL_DM_REPLIED: 'social_dm_replied',
  CHECK_DM_REPLIED: 'check_dm_replied',
  SOCIAL_DM_SENT: 'social_dm_sent',
  SOCIAL_DM: 'social_dm', // outdated
  SOCIAL_CONNECT_WITHDRAWN: 'social_connect_withdrawn',
  SOCIAL_WITHDRAWN_CONNECT: 'social_withdraw_connect',
  SOCIAL_CONNECT: 'social_connect',
  SOCIAL_CONNECTED: 'social_connected',
  SOCIAL_CONNECT_SENT: 'social_connect_sent',
  SOCIAL_ENDORSE_SKILLS: 'skills_endorsed', // social_endorse_skills
  SOCIAL_ACTIVITY: 'post_liked', // social_activity
  SOCIAL_COMMENT: 'post_commented', // social_comment
};

const FLOW_ACTIVITIES = {
  FLOW_FINISHED: 'flow_finished',
  FLOW_FAILED: 'flow_failed',
  FLOW_CANCELLED: 'flow_cancelled',
  ADDED_TO_FLOW: 'added_to_flow'
};

const TAGS_ACTIVITIES = {
  TAGS_ADDED: 'tags_added',
  TAGS_REMOVED: 'tags_removed'
};

const ALL_ACTIVITIES = {
  ...EMAIL_ACTIVITIES,
  ...SOCIAL_ACTIVITIES,
  ...FLOW_ACTIVITIES,
  ...TAGS_ACTIVITIES,
  ASSIGNED_TO: 'assigned_to',
  SYNCED_CRM: 'synced_crm',
  EXPORTED_TO_CSV: 'exported_to_csv',
  DATA_SOURCE_ADDED: 'data_source_added',
  LEAD_CREATED: 'lead_created',
  CUSTOM_TASK: 'profile_visited',

  STATUS_CHANGED: 'status_changed',
  LIST_CHANGED: 'list_changed',
};

const ActivitesNames = {
  [ALL_ACTIVITIES.LEAD_CREATED]: 'Lead Saved',
  [ALL_ACTIVITIES.EMAIL_OPENED]: 'Email Opened',
  [ALL_ACTIVITIES.EMAIL_REPLIED]: 'Email Replied',
  [ALL_ACTIVITIES.EMAIL_SENT]: 'Email Sent',
  [ALL_ACTIVITIES.EMAIL_BOUNCED]: 'Email Bounced',
  [ALL_ACTIVITIES.CHECK_DM_REPLIED]: 'Check Direct Message replied',
  [ALL_ACTIVITIES.SOCIAL_CONNECT]: 'Connect',
  [ALL_ACTIVITIES.SOCIAL_DM_REPLIED]: 'Direct Message replied',
  [ALL_ACTIVITIES.SOCIAL_DM]: 'Direct Message',
  [ALL_ACTIVITIES.SOCIAL_DM_SENT]: 'LinkedIn Direct Message Replied',
  [ALL_ACTIVITIES.SOCIAL_CONNECT_WITHDRAWN]: 'LinkedIn Connected',
  [ALL_ACTIVITIES.SOCIAL_WITHDRAWN_CONNECT]: 'Withdraw Connect',
  [ALL_ACTIVITIES.SOCIAL_CONNECTED]: 'Social Connected',
  [ALL_ACTIVITIES.SOCIAL_CONNECT_SENT]: 'Social Connect',
  [ALL_ACTIVITIES.SYNCED_CRM]: 'Synced to CRM',
  [ALL_ACTIVITIES.EXPORTED_TO_CSV]: 'Exported to CSV',
  [ALL_ACTIVITIES.TAGS_ADDED]: 'Tags Added to Lead',
  [ALL_ACTIVITIES.TAGS_REMOVED]: 'Tags Removed from Lead Removed',
  [ALL_ACTIVITIES.FLOW_FINISHED]: 'Flow Finished',
  [ALL_ACTIVITIES.FLOW_FAILED]: 'Flow Failed',
  [ALL_ACTIVITIES.FLOW_CANCELLED]: 'Flow cancelled',
  [ALL_ACTIVITIES.ADDED_TO_FLOW]: 'Added to Flow',
  [ALL_ACTIVITIES.DATA_SOURCE_ADDED]: 'Added To Data Source',
  [ALL_ACTIVITIES.ASSIGNED_TO]: 'Assigned to User',
  [ALL_ACTIVITIES.CUSTOM_TASK]: 'Profile Visit',
  [ALL_ACTIVITIES.SOCIAL_ENDORSE_SKILLS]: 'Skills Endorsement',
  [ALL_ACTIVITIES.SOCIAL_ACTIVITY]: 'Post Like',
  [ALL_ACTIVITIES.SOCIAL_COMMENT]: 'Post Comment',
};

export {
  SEED_CONTACTS,
  CONTACTS_DELETE_SUCCESS,
  CONTACTS_SET_LIST_SUCCESS,
  CONTACTS_SET_TAGS_SUCCESS,
  CONTACTS_DELETE_TAGS_SUCCESS,
  CONTACTS_SET_STAGE_SUCCESS,
  CONTACTS_SET_STAGE_PLURAL_SUCCESS,
  CONTACTS_SET_ASSIGN_PLURAL_SUCCESS,
  CONTACTS_ASSIGN_SUCCESS,
  CONTACTS_SET_NETWORK_TYPE_SUCCESS,
  CONTACTS_MESSAGES_READ,
  CONTACTS_ADD_ACTIVITY,
  CONTACTS_CANCEL_ACTIVITY,
  CONTACTS_MARK_UNREAD,
  ALL_ACTIVITIES,
  ActivitesNames,
}
