import React from "react";
import { ConfirmationModal } from 'components/common/modals/Confirmation'
import { stepTypes } from "store/entities/flows/model/nodeTypes";
import SelectRow from 'components/common/inputs/FormRows/SelectRow'
import AsyncActionButton from 'components/common/buttons/AsyncActionButton'
import Button from 'components/common/buttons/Button'
import { fieldTypes } from '../../../reducer/utils/description'
import OptionInputFacade from "./OptionInputFacade/OptionInputFacade";
import ActionOption from "./ActionOption/ActionOption";

import styles from './NodeForm.module.scss'

export const getDefaultPayload = (type, action, description) => {
  if (type === stepTypes.END) return {};

  const actions = description[type];
  const options = actions[action].fields;

  const res = options.reduce((res, field) => {
    let defaultValue;
    switch (field.type) {
      case fieldTypes.DELAY:
        defaultValue = field.default || 0;
        break;
      case fieldTypes.SELECT:
        defaultValue = field.default || null;
        break;
      case fieldTypes.RADIO:
        if (field.autoSelect && Array.isArray(field.options)) {
          defaultValue = field.options[0].value;
        } else {
          defaultValue = field.default || null;
        }
        break;
      case fieldTypes.TEXT:
        defaultValue = '';
        break;
      case fieldTypes.FILTERS:
        defaultValue = [{branch_id: 1, filters: {}}, {branch_id: 2}];
        break;
      case fieldTypes.QUALIFICATION:
        defaultValue = [{branch_id: 1, label: 'Default Name 1'}, {branch_id: 2, label: 'Default Name 2'}];
        break;
      case fieldTypes.AB:
        defaultValue = [{branch_id: 1, value: 50}, {branch_id: 2, value: 50}];
        break;
      default: {
        defaultValue = field.default;
      }
    }

    res[field.key] = defaultValue;
    return res;
  }, {})
  return res;
};

const isNotDelayOfAction = ({field, serverNode, type}) => {
  if (!serverNode && type === stepTypes.ACTION && field.type === fieldTypes.DELAY) return false;
  if (type !== stepTypes.ACTION) return true;
  if (field.type !== fieldTypes.DELAY) return true;
  if (serverNode.payload[field.key]) return true;

  return false;
}

const PayloadTab = ({ type, serverNode, uiNode, back, cancel, onSubmit, onDelete, action, setAction, model, description }) => {
  const actions = description[type];
  // const [action, setAction] = React.useState(serverNode && serverNode.action || Object.keys(actions)[0]);
  const [payload, setPayload] = React.useState(serverNode && serverNode.payload || getDefaultPayload(type, action, description));
  React.useEffect(() => {
    if (serverNode && serverNode.payload) return;
    setPayload(getDefaultPayload(type, action, description));
  }, [action])
  const isNew = !serverNode;
  if (!payload.triggers && actions[action] && actions[action].triggers) {
    payload.triggers = {}
    for (const trigger of actions[action].triggers) {
      payload.triggers[trigger.key] = {};
      for (const field of trigger.fields) {
        if (!field.default) continue;
        payload.triggers[trigger.key][field.key] = field.default;
      }
    }
  }

  const changeAction = (newAction) => {
    setPayload(p => ({
      ...getDefaultPayload(type, newAction, description),
      ...p,
    }))
    setAction(newAction);
  }

  return (
    <div className={styles.Form}>
      { type !== stepTypes.END &&
      <div className={styles.Actions}>
        <SelectRow
          label="Action Type"
          value={{value: action, label: actions[action].name}}
          onChange={ e => changeAction(e.value)}
          options={ Object.values(actions).filter(({isHidden}) => !isHidden).map(action => ({ value: action.id, label: action.name, type, icon: action.icon }) )}
          components={{
            Option: ActionOption,
            SingleValue: ActionOption,
          }}
        />
        { actions[action].fields
          .filter(field => isNotDelayOfAction({field, serverNode, type}))
          .map( field => (
            <OptionInputFacade
              key={field.key}
              model={model}
              description={description}
              field={field}
              value={payload[field.key]}
              nodeId={uiNode.id}
              onChange={ value => setPayload({
                ...payload,
                [field.key]: value,
              })}
              _payload={payload}
              _updatePayload={values => setPayload({
                ...payload,
                ...values,
              })}
            />
          ))
        }
        { actions[action].triggers && actions[action].triggers
          .map(trigger => trigger.fields
            .map(field => (
              <OptionInputFacade
                key={trigger.key + field.key}
                field={field}
                value={payload.triggers[trigger.key] && payload.triggers[trigger.key][field.key]}
                nodeId={uiNode.id}
                _payload={payload}
                onChange={ value => setPayload({
                  ...payload,
                  triggers: {
                    ...payload.triggers,
                    [trigger.key]: {
                      ...payload.triggers[trigger.key],
                      [field.key]: value,
                    },
                  },
                })}
              />
            ))
          )
        }
      </div>
      }

      { type === stepTypes.END && <span className="gs-text"> You are sure? </span>}

      <div className={styles.footer}>
        <span onClick={cancel} className={styles.cancel}> Cancel </span>
        <div>
          { !isNew && (
            <ConfirmationModal
              action={onDelete}
              mainText='Are you sure you want to delete node?'
              confirmText='Delete'
              style={{marginRight: '1em', display: 'inline'}}
            >
              <Button style={{backgroundColor: '#CBD6E2'}}>
                Delete
              </Button>
            </ConfirmationModal>
          )}
          <AsyncActionButton action={() => onSubmit({payload, action, type})}>
            {isNew ? 'Create' : 'Update'}
          </AsyncActionButton>
        </div>
      </div>
    </div>
  )
}

export default PayloadTab
