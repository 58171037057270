export const S_SOCPROF_LEAVE_PAGE = 'S_SOCPROF_LEAVE_PAGE';
export const S_SOCPROF_SET_PROFILE = 'S_SOCPROF_SET_PROFILE';
export const S_SOCPROF_SUBMIT = 'S_SOCPROF_SUBMIT';
export const S_SOVPROF_SET_SERVER = 'S_SOVPROF_SET_SERVER';
export const S_SOVPROF_NO_SERVER = 'S_SOVPROF_NO_SERVER';
export const S_SOVPROF_DISCONNECT_LN = 'S_SOVPROF_DISCONNECT_LN';

export const S_SOCPROF_GET_REQUEST = 'S_SOCPROF_GET_REQUEST';
export const S_SOCPROF_GET_SUCCSES = 'S_SOCPROF_GET_SUCCSES';
export const S_SOCPROF_GET_FAILURE = 'S_SOCPROF_GET_FAILURE';

export const serverStatuses = {
  BUSY: 'busy', // сервер залогинился -> всё хорошо
  // FREE: 'free',
  LOGINING: 'ready_for_login', // плагин на сервере вводит отданные ему логин/пароль -> показать спиннерв
  NEED_CODE: 'pass_code_needed',
  LOGIN_ERROR: 'login_error',
  CODE_ERROR: 'pass_code_error',
  PROCESSING: 'processing',
  ACCOUNT_BUSY: 'account_busy',
}

export const limitTypes = {
  CONNECT: {
    label: 'Connect',
    type: 'connect_sent',
    min: 10,
    max: 100,
    recommended: 50,
  },
  DM: {
    label: 'Direct Message',
    type: 'dm_sent',
    min: 10,
    max: 100,
    recommended: 50,
  },
  VISIT: {
    label: 'Profile Visit',
    type: 'profile_visited',
    min: 10,
    max: 200,
    recommended: 100,
  },
  LIKE: {
    label: 'Post Like',
    type: 'post_liked',
    min: 10,
    max: 100,
    recommended: 50,
  },
  ENDORSE: {
    label: 'Skill Endorse',
    type: 'skills_endorsed',
    min: 10,
    max: 100,
    recommended: 50,
  },
  COMMENT: {
    label: 'Post Comment',
    type: 'post_commented',
    min: 10,
    max: 100,
    recommended: 50,
  },
  WITHDRAW: {
    label: 'Connect Withdraw',
    type: 'connect_withdrawn',
    min: 10,
    max: 100,
    recommended: 50,
  },
};

export const defaultDelay = 180

export const defaultSettings = {
  nickname: '',
  min_delay_in_seconds: 20,
  max_delay_in_seconds: 30,
  limits: Object.values(limitTypes).map(({ type, recommended}) => ({
    type,
    daily_limit: recommended,
    delay_in_seconds: defaultDelay,
  })),
}
