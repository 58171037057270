import React, { useState } from 'react'
import cn from 'classnames'

import commonStyles from '../../TextEditor.module.scss'
import styles from './VarsController.module.scss'

export const tabs = {
  LEAD: "LEAD",
  COMPANY: "COMPANY",
  // CONDITION: "CONDITION",
  // TOOLS: "TOOLS",
}

export const varsTemplate = {
  [tabs.LEAD]: [
    {
      label: 'First Name',
      value: ['lead.first_name'],
    }, {
      label: 'Last Name',
      value: ['lead.last_name'],
    }, {
      label: 'Full Name',
      value: ['lead.name'],
    }, {
      label: 'Position',
      value: ['lead.position'],
    },
    {
      label: 'First Name (Russian Translation)',
      value: ['lead.first_name.ru'],
    },
  ],
  [tabs.COMPANY]: [
    {
      label: 'Name',
      value: ['company.name'],
    },
    {
      label: 'Industry',
      value: ['company.industry'],
    },
  ],
  [tabs.TOOLS]: [
    {
      label: 'Tracking Link',
      value: ['tracking'],
      modifier: {
        getValue: (value, additional) => `${value}:${additional}`,
        label: 'URL',
        validate: (v) => {
          if (!v) return 'Url is required';
          return false;
        },
      }
    }
  ],
  // [tabs.CONDITION]: [
  //   {
  //     label: 'мы нашли вас на Фейсбук',
  //     value: ['#if prospect.facebook', '#endif'],
  //   }, {
  //     label: 'мы нашли вас на Твиттер',
  //     value: ['#if prospect.twitter', '#endif'],
  //   },
  // ],
};

const PopoverContent = ({tab, changeTab, closePopover, isOpen}) => {
  return (
    <div className={cn(styles.container, {[styles.hide]: !isOpen})}>
      <div className={styles.groupContainer}>
        { Object.keys(tabs).map( theTab =>
          <div key={theTab}
               onClick={() => changeTab(theTab)}
               className={cn(styles.groupTitle, {[styles.selected]: tab == theTab})}
          >
            { theTab.toLowerCase() }
          </div>
        )}
      </div>

      { Object.keys(tabs).map(theTab => (
        <ul key={theTab} className={cn(styles.varContainer, {[styles.hide]: theTab != tab})}>
          { varsTemplate[theTab].map( item =>
            //    Обязательно button
            <li key={item.label} className={styles.varItem}>
              { !item.modifier &&
              <button onClick={closePopover} className={cn(styles.itemFaceBtn, "ql-insertVariable")} value={JSON.stringify(item.value)}>
                {item.label}
              </button>
              }
              { item.modifier && <ModifiableItem closePopover={closePopover} item={item}/>}
            </li>
          )}

        </ul>
      ))}

    </div>
  )
}

const ModifiableItem = ({ closePopover, item }) => {
  const [v, setV] = useState('');
  const [isActive, setIsActive] = useState(false);

  function onOk() {
    closePopover();
    setV('');
    setIsActive(false);
  }

  function onCancel() {
    setV('');
    setIsActive(false);
  }

  return (
    <React.Fragment>
      <span className={styles.itemFaceBtn} onClick={() => setIsActive(true)}>
        { item.label }
      </span>
      <div className={cn(styles.modifiableItem, {[styles.hide]: !isActive})}>
        <h3 className={styles.modTitle}> { item.label } </h3>
        <div className={styles.modMain}>
          <label className={styles.modLabel}>
            {item.modifier.label}:
            <input value={v} onChange={e => setV(e.target.value)} className={styles.modInput}/>
          </label>
        </div>
        <div className={styles.modFooter}>
          <button onClick={onOk}
                  className={cn("ql-insertVariable", styles.modOk)}
                  value={JSON.stringify(item.value.map(tv => item.modifier.getValue(tv, v)))}
                  disabled={item.modifier.validate(v)}
          >
            Ok
          </button>

          <span onClick={onCancel} className={styles.modCancel}> Cancel </span>
        </div>
      </div>
    </React.Fragment>
  )
}

class VarsController extends React.PureComponent {
  constructor() {
    super()

    this.state = {
      isOpen: false,
      disp: false, // смотри комментарий K1 (~130)
      tab: tabs.LEAD,
    }

    this.popoverRef = React.createRef();
    this.handleOuterClick = this.handleOuterClick.bind(this)
    this.changeTab = this.changeTab.bind(this)
  }

  changeTab (tab) {
    this.setState({tab})
  }
  toggleVars(val) {
    const isOpen = val == null ? !this.state.isOpen : val;

    this.setState({isOpen})

    // K1: ебаный костыль, потому что этот ебаный поповер в этом ебаном тулбаре ебаного квила скачет
    setTimeout(() => this.setState({
      disp: isOpen,
    }), isOpen ? 222 : 0)
  }

  componentDidMount() {
    this.forceUpdate() // чтобы перерендерить после установки рефа
    document.body.addEventListener('click', this.handleOuterClick)
  }
  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleOuterClick)
  }

  handleOuterClick(e) {
    // if (/vars-button/.test(e.target.className)) return
    if (e.target.closest('.var-cont')) return
    this.toggleVars(false)
  }

  render() {
    const { isOpen, tab, disp } = this.state;

    return (
      <div className={'var-cont ' + commonStyles.gsTextEditorToolbarGroup}>
        <span ref={this.popoverRef}
              className="var-cont"
              style={{'display': isOpen ? 'inherit' : 'none', 'zIndex': '1', position: 'relative' }}>
          <PopoverContent tab={tab} changeTab={this.changeTab} closePopover={() => this.toggleVars(false)} isOpen={disp}/>
        </span>
        <span onClick={ (e) => this.toggleVars() }
              className={`${commonStyles.gsTextEditorToolbarBtnVars} vars-button ${commonStyles.gsTextEditorToolbarGroup}`}>
          {'{ }'} Variables
        </span>
      </div>
    )
  }
}

export default VarsController
