import Loadable from 'react-loadable';
import Loading from "components/common/Loading";
import NotFoundRoute from "components/routes/NotFound";

export const MailboxesPage = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName:'MailboxesListPage' */ './container');
    return component.catch( e => console.log('MailboxesPage loading err', e) || NotFoundRoute );
  },
  loading: Loading,
});

export { default as path } from './path'
