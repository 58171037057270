import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Input from 'components/common/inputs/Input'
import SelectBox from 'components/common/inputs/SelectBox'

import styles from './Styles.module.scss'

const unitOptions = [
  {value: 86400, label: 'Days'},
  {value: 3600, label: 'Hours'},
  {value: 60, label: 'Minutes'},
  // {value: 1, label: 'Seconds'},
];

export const getDelayValue = (seconds) => {
  for (const unit of unitOptions) {
    if ( (seconds >= unit.value) && (seconds % unit.value === 0) ) {
      return {
        unit: unit.label,
        unitValue: unit.value,
        value: seconds / unit.value,
      }
    }
  }

  return {
    unit: unitOptions[0].label,
    unitValue: unitOptions[0].value,
    value: 0,
  }
}

class DelayInput extends PureComponent {
  constructor({ value }) {
    super();

    if (value == null) {
      this.state = {
        savedValue: 1,
        inputValue: 1,
        unit: unitOptions[0],
      }
    } else if (value === 0) {
      this.state = {
        savedValue: 1,
        inputValue: 0,
        unit: unitOptions[0],
      };
    } else {
      value = Math.max(value, 60);
      const { unitValue, unit, value: inputValue } = getDelayValue(value);

      this.state = {
        savedValue: inputValue,
        inputValue,
        unit: {
          label: unit,
          value: unitValue,
        },
      };
    }


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    const { onChange } = this.props;
    const { inputValue, unit } = this.state;

    const seconds = unit.value * inputValue;

    onChange && onChange(seconds);
  }
  handleInputChange(value) {
    value = value.replace(/[^0-9]/g, '');
    value = value && parseInt(value);

    if (value) {
      this.setState({savedValue: value});
    }

    this.setState({
      inputValue: value,
    }, this.onChange)
  }
  handleUnitChange(unit) {
    this.setState({unit}, this.onChange)
  }

  render() {
    const { unit, inputValue } = this.state;
    const { readOnly, field } = this.props;

    return (
      <div className={styles.container}>
        <label> { field.label || 'Delay' }  </label>
        <div className={styles.inputsCont}>
          <Input
            value={inputValue}
            onChange={e => this.handleInputChange(e.target.value)}
            className={styles.textInput}
            readOnly={readOnly}
          />
          <SelectBox
            options={unitOptions}
            value={unit}
            onChange={this.handleUnitChange}
            className={styles.selectInput}
            isDisabled={readOnly}
          />
        </div>
      </div>
    )
  }
}

DelayInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export {
  DelayInput as default,
  DelayInput,
  unitOptions,
}
