import * as actions from './consts'
import { entitySetter } from '../utils'

const ACTION_HANDLERS = {
  [actions.SCHEDULES_DELETE_SUCCESS]: (state, { id }) => {
    const s = {...state};

    delete s[id];

    return s;
  },
  [actions.SCHEDULES_SET]: (state, { schedules }) => {
    return entitySetter(state, schedules);
  },
  [actions.SCHEDULES_CREATE_TIMEBLOCK_SUCCESS]: (state, { scheduleId, timeblock }) => {
    const s = {...state};
    s[scheduleId] = {...s[scheduleId]};
    s[scheduleId].timeblocks = [...s[scheduleId].timeblocks, timeblock];

    return s;
  },
  [actions.SCHEDULES_UPDATE_TIMEBLOCK_SUCCESS]: (state, { scheduleId, timeblock }) => {
    const s = {...state};
    s[scheduleId] = {...s[scheduleId]};
    s[scheduleId].timeblocks = [...s[scheduleId].timeblocks];

    for (const index in s[scheduleId].timeblocks) {
      if (s[scheduleId].timeblocks[index].id == timeblock.id) {
        s[scheduleId].timeblocks[index] = timeblock;
        break;
      }
    }

    return s;
  },
  [actions.SCHEDULES_DELETE_TIMEBLOCK_SUCCESS]: (state, { scheduleId, timeblockId }) => {
    const s = {...state};
    s[scheduleId] = {...s[scheduleId]};
    s[scheduleId].timeblocks = [...s[scheduleId].timeblocks];

    for (const index in s[scheduleId].timeblocks) {
      if (s[scheduleId].timeblocks[index].id == timeblockId) {
        s[scheduleId].timeblocks.splice(index, 1);
        break;
      }
    }

    return s;
  },
}

const initialState = {};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
