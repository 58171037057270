import * as actions from './consts'
import getAxios from 'services/axios'
import sse, { SSE } from "services/serverSideEvents";
import { createCachedFunction } from '../utils'
import { changeMessagesCount } from "../../global";
import {messageStatuses, messageTypes} from "../messages/model/enums";
import setupLeadsLiveEventHandler from "./liveEvents";

setupLeadsLiveEventHandler();

const axios = getAxios('contact');
const flowsAxios = getAxios('flow');

export const markMessagesUnread = (leadId) => (dispatch) => {
  return flowsAxios.put(`/api/social-conversations/${leadId}/mark-unread`)
    .then(() => {
      dispatch(changeMessagesCount(s => s + 1));
      dispatch({
        type: actions.CONTACTS_MARK_UNREAD,
        leadId,
      })
    })
}

export const getLeadFields = () => (_dispatch) => {
  return axios.get('/api/file-exports/settings')
    .then(res => console.log('getLeadFields', res))
}

export const exportToCrm = (filter, type) => (_dispatch) => {
  return axios.put('/api/leads/export-crm', {filter, type})
}

export const onMessagesOpened = (leadId) => (dispatch, getState) => {
  const lead = getState().entities.contacts[leadId];
  if (!lead.markers || !lead.markers.dm_user_unread_count) return;

  dispatch(changeMessagesCount(s => s - 1))
  setTimeout(() => dispatch({
    type: actions.CONTACTS_MESSAGES_READ,
    leadId,
  }), 1111)
}

export const exportLeads = (filter) => (_dispatch) => {
  return axios.post('/api/file-exports', {filter, file_type: 'csv'})
}

export const createTask = (filter, task) => (dispatch) => {
  return flowsAxios.post(`/api/tasks`, {filter, task});
}

export const loadContacts = (params, cancelToken) => (dispatch) => {
  return axios.post('/api/leads/search', params, { cancelToken })
    .then( response => {
      dispatch({
        type: actions.SEED_CONTACTS,
        contacts: response.data,
      })

      return response;
    })
}

export const loadContact = (id) => (dispatch) => {
  return axios.get(`/api/leads/${id}`)
    .then( contact => {
      dispatch({
        type: actions.SEED_CONTACTS,
        contacts: contact,
      });

      return contact;
    })
}

export const deleteContacts = (params, { loadedSelection, selectedCount }) => (dispatch) => {
  return axios.delete('/api/leads', {data: params})
    .then(() => dispatch({
      type: actions.CONTACTS_DELETE_SUCCESS,
      loadedSelection,
      selectedCount,
    }))
}

export const getConfig = createCachedFunction(
  () => axios.get('/api/config')
).cachedFunction;

export const setTags = ({filter, tags, sync, loadedIds, include_coworkers}) => (dispatch, getState) => {

  return axios.put('/api/leads/tags', {filter, tags, sync, include_coworkers})
    .then(() => dispatch({
      type: actions.CONTACTS_SET_TAGS_SUCCESS,
      ids: filter.ids,
      all: filter.all,
      tags,
      loadedIds,
    }))
}

export const setList = ({filter, list, sync, loadedIds, include_coworkers}) => (dispatch, getState) => {
  return axios.put('/api/leads/list', {filter, list, sync, include_coworkers})
    .then(() => dispatch({
      type: actions.CONTACTS_SET_LIST_SUCCESS,
      ids: filter.ids,
      all: filter.all,
      list,
      loadedIds,
    }))
}

export const deleteTags = ({filter, tags, loadedIds, include_coworkers}) => (dispatch) => {
  return axios.delete('/api/leads/tags', {data: {filter, tags, include_coworkers}})
    .then(() => dispatch({
      type: actions.CONTACTS_DELETE_TAGS_SUCCESS,
      ids: filter.ids,
      all: filter.all,
      tags,
      loadedIds,
    }))
}

export const setStagePlural = ({filter, stage, loadedIds, include_coworkers}) => (dispatch) => {
  return axios.put('/api/leads/stage', {filter, stage, include_coworkers})
    .then(() => dispatch({
      type: actions.CONTACTS_SET_STAGE_PLURAL_SUCCESS,
      ids: filter.ids,
      all: filter.all,
      stage,
      loadedIds,
    }))
}

export const assignToPlural = ({loadedSelection, selectedCount, filter, assign_to, list_id, loadedIds, include_coworkers}) => (dispatch) => {
  return axios.put('/api/leads/assign', {filter, user_id: assign_to, list_id, include_coworkers})
    // .then(() => dispatch({
    //   type: actions.CONTACTS_SET_ASSIGN_PLURAL_SUCCESS,
    //   ids: filter.ids,
    //   all: filter.all,
    //   assign_to,
    //   loadedIds,
    // }))
    .then(() => dispatch({
      type: actions.CONTACTS_DELETE_SUCCESS,
      loadedSelection,
      selectedCount,
    }))
}

export const addActivity = ({leadId, activity}) => ({
  type: actions.CONTACTS_ADD_ACTIVITY,
  leadId,
  activity,
});

export const updateContact = (id, data) => (_dispatch) => {
  return axios.put(`/api/leads/${id}`, {...data})
    .then( contact => {
      _dispatch({
        type: actions.SEED_CONTACTS,
        contacts: contact,
      });

      return contact;
    })
}

export const setStage = (id, stage) => (dispatch) => {
  return axios.put(`/api/leads/${id}`, {stage})
    .then(() => dispatch({
      type: actions.CONTACTS_SET_STAGE_SUCCESS,
      id,
      stage,
    }))
}

export const assignTo = (leadId, userId) => (dispatch) => {
  return axios.put(`/api/leads/${leadId}`, { assigned_to: userId})
    .then( () => dispatch({
      type: actions.CONTACTS_ASSIGN_SUCCESS,
      leadId,
      userId,
    }))
}

export const setCompanyNetworkType = (leadId, networkType) => (dispatch) => {
  return axios.put(`/api/leads/${leadId}/network-type`, { network_type: networkType})
    .then( () => dispatch({
      type: actions.CONTACTS_SET_NETWORK_TYPE_SUCCESS,
      leadId,
      networkType,
    }))
}

const allMessageTypes = [messageStatuses.FAILED, messageStatuses.NEW, messageStatuses.IN_PROGRESS, messageStatuses.DONE, messageStatuses.CANCELED]
export const generateActivitiesForMessages = (leadId, theMessageTypes = allMessageTypes) => (dispatch, getState) => {
  const allMessages = getState().entities.messages;

  const filteredMessagesOfLead = Object.values(allMessages)
    .filter(message => message.lead_id === leadId)
    .filter(message => theMessageTypes.includes(message.status))

  const typesMap = {
    [messageTypes.INBOX]: actions.ALL_ACTIVITIES.SOCIAL_DM_REPLIED,
    [messageTypes.OUTBOX]: actions.ALL_ACTIVITIES.SOCIAL_DM_SENT,
  };

  for (const msg of filteredMessagesOfLead) {
    const isConnectMessage = msg.automation === "connect";

    dispatch({
      type: actions.CONTACTS_ADD_ACTIVITY,
      leadId,
      activity: {
        _generated: true,
        created_at: msg.order_ts || msg.created_at,
        data: {
          conversation_id: isConnectMessage ? undefined : msg.social_conversation_id,
          message_id: msg.id,
        },
        type: isConnectMessage ? actions.ALL_ACTIVITIES.SOCIAL_CONNECT_SENT : typesMap[msg.type],
      }
    });
  }
}
export const generateActivitiesForEmails = (leadId) => (dispatch, getState) => {
  const allEmails = getState().entities.emails;

  const filteredEmailsOfLead = Object.values(allEmails)
    .filter(message => message.lead_id === leadId)
    // .filter(message => theMessageTypes.includes(message.status))

  const typesMap = {
    [messageTypes.INBOX]: actions.ALL_ACTIVITIES.EMAIL_REPLIED,
    [messageTypes.OUTBOX]: actions.ALL_ACTIVITIES.EMAIL_SENT,
  };
  for (const msg of filteredEmailsOfLead) {
    dispatch({
      type: actions.CONTACTS_ADD_ACTIVITY,
      leadId,
      activity: {
        _generated: true,
        created_at: msg.order_ts || msg.created_at,
        data: {
          conversation_id: msg.social_conversation_id,
          message_id: msg.id,
        },
        type: typesMap[msg.type],
      }
    });
  }
}

export const createLead = (data) => (dispatch) => axios.post('/api/leads', data)
  .then(leads => {
    dispatch({
      type: actions.SEED_CONTACTS,
      contacts: leads,
    })

    return leads;
  })

export const checkLeads = (leads) => (_dispatch) => axios.put('/api/leads/check', {
  leads,
})

export const enrichLead = (leadId, data) => (_dispatch) => axios.put(`/api/leads/${leadId}/enrich`, data)

sse.listen((events => {
  for (const event of events) {
    if (event.type !== SSE.eventTypes.MESSAGE) return;
    // активити старые приходят. нельзя заменять
    // store.dispatch({
    //   type: actions.SEED_CONTACTS,
    //   contacts: [event.lead],
    // });
  }
}))

export const cancelActivity = (leadId, messageId) => (dispatch) => {
  return axios.delete(`/api//messages/${messageId}`)
    .then(() => dispatch({
      type: actions.CONTACTS_CANCEL_ACTIVITY,
      leadId,
      messageId
    }))
}
