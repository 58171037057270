import { useEffect, useState, useRef, usec } from "react";

export default () => {
  const layersMargin = 50;
  const layersElems = [...document.querySelectorAll('[data-flow-layer]')];
  const [layersY, setLayersY] = useState([]);
  const layerElemsRef = useRef([]);
  const [, setR] = useState(true);

  useEffect(() => {
    setR((p) => !p);
  }, []);

  const observerRef = useRef(new ResizeObserver(() => {
    let start = 0;
    const layersY = [];

    for (const layerNode of layerElemsRef.current) {
      start += layerNode.offsetHeight;
      layersY.push(start);
      start += layersMargin;
    }
    setLayersY(layersY);
  }))
  useEffect(() => {
    layerElemsRef.current.forEach(el => observerRef.current.unobserve(el));
    layerElemsRef.current = layersElems;

    layerElemsRef.current.forEach(el => observerRef.current.observe(el));
  }, [layersElems.length]);

  return layersY
}
