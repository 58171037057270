import React, { PureComponent, cloneElement } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ReactModal from 'react-modal'
import ModalContainer from "./Container";
import ModalHeader from "./Header";

import './Modal.module.scss'

class Modal extends PureComponent {
	constructor () {
		super();
		this.state = {
			isOpened: false
		};
	}

	afterOpenModal() {
		this.setState({isOpened: true})
	}

	closeModal() {
		this.setState({isOpened: false})
		setTimeout(() => {
			this.props.closeModal();
		}, 410)
	}
	render(){
		const {
      label,
      children,
      modalCustomStyles,
      isOpen,
      className='',
      overlayClassName='gs-modal__overlay',
    } = this.props;

		const { isOpened } = this.state;

		if (!children) {
      return null;
    }

		return (
			<ReactModal
				contentLabel={label}
				isOpen={isOpen}
        style={modalCustomStyles}
				onRequestClose={() => this.closeModal() }
				onAfterOpen={() => this.afterOpenModal()}
				shouldCloseOnOverlayClick={true}
				className={cn("gs-modal",{_open: isOpened}) + ' ' + className}
				overlayClassName={cn(overlayClassName,{_open: isOpened})}
			>
				{cloneElement(children, {
				  onRequestClose: () => this.closeModal(),
				})}
			</ReactModal>
		)
	}
}

ReactModal.setAppElement('#root');

Modal.propTypes = {
	label: PropTypes.string,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
};

Modal.Container = ModalContainer;
Modal.Header = ModalHeader;

export default Modal
