import React from 'react'
import cn from 'classnames'

import Datetime from 'react-datetime'

import styles from '../Styles.module.scss'

const DatetimeInput = ({ inputClassName, className, label, isError, isSuccess, additionalInfo, ...other }) => (
  <div
    className={cn(styles.inputWrapper, styles.DatetimeInputWrapper, {
    [className]: className,
    })}
    data-label={label}
  >
    <Datetime
      className={cn(styles.DatetimeInput, {
        [inputClassName]: inputClassName,
      })}
      {...other}
    />
  </div>
)

DatetimeInput.propTypes = {
};

export default DatetimeInput
