export const TASKS_LOAD_REQUEST = 'TASKS_LOAD_REQUEST';
export const TASKS_LOAD_SUCCESS = 'TASKS_LOAD_SUCCESS';
export const TASKS_LOAD_FAILURE = 'TASKS_LOAD_FAILURE';

export const TASKS_FOR_LEAD_LOAD_REQUEST = 'TASKS_FOR_LEAD_LOAD_REQUEST';
export const TASKS_FOR_LEAD_LOAD_SUCCESS = 'TASKS_FOR_LEAD_LOAD_SUCCESS';
export const TASKS_FOR_LEAD_LOAD_FAILURE = 'TASKS_FOR_LEAD_LOAD_FAILURE';

export const TASKS_LEAVE = 'TASKS_LEAVE';
export const TASKS_SELECTION_TOGGLE_PLURAL = 'TASKS_SELECTION_TOGGLE_PLURAL';
export const TASKS_SET_TOP_FILTER = 'TASKS_SET_TOP_FILTER';
export const TASKS_DROP_SEARCH = 'TASKS_DROP_SEARCH';
export const TASKS_LIVE_UPDATE = 'TASKS_LIVE_UPDATE';
export const TASKS_SET_URL_FILTERS = 'TASKS_SET_URL_FILTERS';

export const TASKS_SET_CURRENT_TASK = 'TASKS_SET_CURRENT_TASK';
export const TASKS_SET_IS_ACTIVE = 'TASKS_SET_IS_ACTIVE';
export const TASKS_SET_EXECUTE = 'TASKS_SET_EXECUTE';
export const TASKS_FINISH = 'TASKS_FINISH';

export const TASKS_TYPES = {
  Manual: 'manual',
  Semi: 'semi',
  Auto: 'auto',
};
