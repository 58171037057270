import * as actions from "./consts";
import getAxios from 'services/axios'
import store from 'store/store'
import { createCachedFunction } from "../utils";

const axios = getAxios('id');

// Тольуко для текущего юзера
export const updateUser = (params) => (dispath) => {
  return axios.put('/account', params)
    .then( user => {
      dispath(setUsers(user))
      return user;
    })
}

export const setUserServer = ({userId, server}) => ({
  type: actions.SET_USER_SERVER,
  userId,
  server,
})

export const createUserForTeam = (params) => (dispatch) => {
  return axios.post('/api/billing/getsales/plans/add-user', params)
    .then(user => {
      cacher.clearCache();
      console.log('createUserForTeam user', user)
      dispatch(setUsers([user]));
      return user;
    })
}

export const serUserMailbox = (userId, mailbox) => ({
  type: actions.SET_USER_MAILBOX,
  userId,
  mailbox
})

export const changeAvatar = (params) => (dispatch) =>
  axios.post('/account/avatar', params)
    .then( res => {
      // TODO: update store with new image
      // dispatch
      return res;
    })

export const changePassword = (oldPass, pass) => (dispatch) =>
  axios.put('/account/password', {
    old_password: oldPass,
    password: pass,
    password_confirmation: pass,
  })

export const setUsers = (users = []) => ({
  type: actions.SET_USERS,
  users,
})

const cacher = createCachedFunction(() => (dispatch) => {
  return axios.get('/account/team/members')
    .then( users => {
      dispatch(setUsers(Object.values(users)));

      return users;
    })
}, {recursively: true});

export const getTeam = cacher.cachedFunction;

export const getTeamOptions = ({withoutCurrent = false, onlyActives = true} = {}) => {
  const state = store.getState();

  return store.dispatch(getTeam())
    .then(members => members
      .filter(({id}) => !withoutCurrent || id !== state.auth.id)
      .filter(({subscription}) => !onlyActives || subscription && actions.USER_VISIBLE_STATUSES.includes(subscription.paddle_status))
      .map(member => ({
        value: member.id,
        label: `${member.first_name} ${member.last_name}`,
        user: member,
      }))
    )
}
