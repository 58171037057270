export default (allParents) => {
  const allMatches = allParents.map(() => []);
  const doneIndexes = allParents.map((p, i) => ({
    index: i,
    done: false,
  }));

  for (const index in allParents) {
    const parent = allParents[index];
    for (const nodeIndex in parent) {
      const nodeId = parent[nodeIndex];

      for (const anotherIndex in allParents) {
        if (anotherIndex === index) continue;

        const anotherParent = allParents[anotherIndex];
        const match = anotherParent.indexOf(nodeId);
        if (match !== -1) {
          allMatches[index].push({
            withParentIndex: anotherIndex,
            nodeIndexHere: nodeIndex,
            nodeIndexThere: match,
          })
        }
      }
    }
  }

  let res = [];
  let checkPerant = doneIndexes.filter(doneIndex => !doneIndex.done)[0];

  while (checkPerant) {
    const undoneMatches = allMatches[checkPerant.index].filter(match => !doneIndexes[match.withParentIndex].done);

    if (!allMatches[checkPerant.index].length || !undoneMatches.length) {
      checkPerant.done = true;
      res = res.concat(allParents[checkPerant.index]);
    } else {
      checkPerant.done = true;
      res = res.concat(allParents[checkPerant.index].slice(0, undoneMatches[0].nodeIndexHere));
    }

    checkPerant = doneIndexes.filter(doneIndex => !doneIndex.done)[0];
  }

  return res;
}
