import flowStyles from "../../flowsGroup/Flow/Details/Flow.module.scss";
import styles from "./CopyBar.module.scss"

import { useDispatch, useSelector } from "react-redux";
import history from 'services/browserHistory';
import { showError } from "services/utils";
import { copyFlow } from "store/entities/flows/actions"
import { getAuthUserId } from "store/auth/selectors";
import Button from "components/common/buttons/Button";
import AsyncButton from "components/common/buttons/AsyncActionButton";
import SignupForm from "components/routes/authGroup/Signup/SignupForm/container";
import createModal from 'components/hocs/ModalTrigger';
import flowPath from 'components/routes/flowsGroup/path';

const ModalContent = ({ onCopyFLow }) => {
  return (
    <div className={styles.modalContainer}>
      <SignupForm detached onSignUp={onCopyFLow} />
    </div>
  )
}

const SignUpModal = createModal(ModalContent);

export const CopyBar = ({ name, nodes }) => {
  const isUserAuthenticated = !!useSelector(getAuthUserId);
  const dispatch = useDispatch();

  const onCopyFLow = async () => {
    try {
      const res = await dispatch(copyFlow({name, nodes}));
      history.push(`${flowPath}/${res.id}`);
    } catch (err) {
      showError(err, "Failed to copy flow")
    }
  }

  return (
    <div className={flowStyles.topControl}>
      <div className={flowStyles.name}> {name} </div>

      { isUserAuthenticated && (
        <AsyncButton
          action={onCopyFLow}
          style={{backgroundColor: '#FF7A59'}}
        >
          Copy Workflow
        </AsyncButton>
      )}

      { !isUserAuthenticated && (
        <SignUpModal onCopyFLow={onCopyFLow}>
          <Button style={{backgroundColor: '#FF7A59'}}> Run on GetSales.io </Button>
        </SignUpModal>
      )}
    </div>
  )
}
