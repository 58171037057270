import React from 'react'
import PropTypes from 'prop-types'
import createModal from 'components/hocs/ModalTrigger'
import Filters from 'components/common/Filters'
import Button from "components/common/buttons/Button";
import Icon from "components/common/elements/Icon";
import BranchTo from "./BranchTo";

import styles from './Styles.module.scss'


const allowedFilter = ['tags', 'email.probability']
const FiltersConfirm = ({ onChange, filters, onRequestClose }) => {
  const [theFilters, setTheFilters] = React.useState(filters);

  const onSave = () => {
    onChange(theFilters);
    onRequestClose();
  }

  return (
    <div>
      <Filters
        filters={theFilters}
        onChange={setTheFilters}
        allowedFilter={allowedFilter}
      />

      <div style={{padding: '10px', float: 'right'}}>
        <Button onClick={onRequestClose}> Cancel </Button>

        <Button onClick={onSave}> Save </Button>
      </div>
    </div>
  )
}

const FilterModal = createModal(FiltersConfirm)

function isValueTypeWrong(value) {
  return !value || !value.some(val => val.filters);
}

const FiltersRule = ({ readOnly, value, onChange, nodeId, model, description }) => {
  const [nextBranchId, setNextBranchId] = React.useState(value ? Math.max(...value.map(v => v.branch_id)) + 1 : 3)

  if (isValueTypeWrong(value)) return null;
  const branches = value || [];

  function addBranch() {
    onChange([
      {
        branch_id: nextBranchId,
        filters: {},
      },
      ...branches,
    ]);
    setNextBranchId(nextBranchId + 1);
  }
  function removeBranch(index) {
    const newBranches = [...branches];
    newBranches.splice(index, 1);
    onChange(newBranches);
  }

  function handleFilterChange (index, filters) {
    const newValue = [...value];
    newValue[index].filters = filters;
    onChange(newValue);
  }

  return (
    <div className={styles.container}>
      <div className={styles.branchTitle}>
        <label> Branches </label>

        { !readOnly && <span onClick={addBranch} className={styles.addBranch}> Add Branch </span> }
      </div>

      <ul>
        { branches.map( ({ branch_id, filters }, index) => (
          <li key={branch_id} className={styles.abItem}>
            { filters && <React.Fragment>
              <span className={styles.filtersCount}> { Object.keys(filters).length } Filter{ Object.keys(filters).length > 1 ? 's' : ''} </span>
              <BranchTo uiNodeId={nodeId} branchId={branch_id} model={model} description={description}/>
              {!readOnly &&
                <FilterModal filters={filters} onChange={(newFilters) => handleFilterChange(index, newFilters)}
                             modalClassName={styles.filtersModal}>
                  <Icon icon="new-settings" size={20}/>
                </FilterModal>
              }
              { !readOnly && branches.length > 2 && <Icon icon="Cross" onClick={() => removeBranch(index)} size={20} className={styles.abCross}/>}
            </React.Fragment>}
            { !filters && <React.Fragment>
              <span className={styles.filtersCount}> Alternative </span>
              <BranchTo uiNodeId={nodeId} branchId={branch_id} model={model} description={description}/>
            </React.Fragment>}
            </li>
        ))}
      </ul>
    </div>
  )
}

FiltersRule.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    branch_id: PropTypes.number.isRequired,
    filters: PropTypes.object,
  })),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export default FiltersRule
