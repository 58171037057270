export { createCachedFunction } from 'services/utils'

export const entitySetter = (state, entities, isHardReset) => {
  const s = {...state};

  if (!Array.isArray(entities)) {
    entities = [entities];
  }

  entities.forEach( entity => {
    if (!entity) return;
    s[entity.id] = {
      ...( isHardReset ? {} : s[entity.id] ),
      ...entity,
    }
  });

  return s;
}

export const deleteByIds = (state, ids) => {
  if (!Array.isArray(ids)) {
    ids = [ids];
  }

  const s = {...state};
  for (const id of ids) {
    delete s[id];
  }

  return s
}
