import React from 'react'
import cn from 'classnames';
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import Symbol from "../../../Symbol/Symbol";
import { stepTypes } from "../../../../../reducer/utils/consts";

import styles from './Styles.module.scss'

const uiNodeId = (state, { uiNodeId }) => uiNodeId;
const branchId = (state, { branchId }) => branchId;
const model = (state, {workTable} ) => workTable || state.flowDetails.workTable;
const uiNodeSelector = createSelector(
  [model, uiNodeId],
  (model, uiNodeId) => model.nodes.find(uiNode => uiNode.id == uiNodeId)
)
const serverNodeSelector = createSelector(
  [model, uiNodeSelector],
  (model, uiNode) => model.flow.nodes.find(node => node.id == uiNode.serverNodeId)
)
const nextServerNodeSelector = createSelector(
  [model, serverNodeSelector, branchId],
  (model, serverNode, branchId) => {
    if (!serverNode) return null;
    const branch = serverNode.after.find(br => br.branch_id == branchId);
    if (!branch) return null;
    const id = branch.node_id;
    return model.flow.nodes.find(node => node.id == id);
  },
)

const BranchTo = ({ uiNodeId, branchId, model, description }) => {
  const nextServerNode = useSelector((state) => nextServerNodeSelector(state, {uiNodeId, branchId, workTable: model}))
  const desc = nextServerNode && description[nextServerNode.type][nextServerNode.action];

  return (
    <React.Fragment>
      { nextServerNode && <React.Fragment>
        <span style={{marginLeft: '12px'}}>
          to Node
          <span style={{color: '#FF7A59'}}> #{ nextServerNode.id } </span>
        </span>
        <div className={cn(styles.symbolContainer, {
          [styles.trigger]: nextServerNode.type === stepTypes.TRIGGER,
        })}>
          <Symbol type={nextServerNode.type} icon={desc && desc.icon} className={styles.toSymbol}/>
        </div>
        <span className={styles.abGrow}/>
      </React.Fragment>}
      { !nextServerNode && <span className={styles.abGrow} style={{marginLeft: '12px'}}> New Branch </span> }
    </React.Fragment>
  )
}

export default React.memo(BranchTo)
