import * as actions from './consts'
import { entitySetter } from '../utils'

const ACTION_HANDLERS = {
  [actions.SNIPPETS_DELETE_SUCCESS]: (state, { id }) => {
    let s = {...state};

    delete s[id];

    return s;
  },
  [actions.SNIPPETS_SET]: (state, { snippets }) => {
    let s = {...state};

     s = entitySetter(s, snippets);

    return s;
  },
}

const initialState = {
}

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
