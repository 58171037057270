import React from "react";

import styles from "./FilterValue.module.scss"

const FilterValue = ({field, payload}) => {
  return (
    <div className={styles.container}>
      {payload.branches.map(branch => (
        <div key={branch.branch_id} className={styles.branch}>
          { branch.filters && `${ Object.keys(branch.filters).length } Filters` }
          { !branch.filters && "Alternative"}
        </div>
      ))}
    </div>
  )
}

export default FilterValue;
